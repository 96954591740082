import { toDateString, parseDateString, parseTimes, getRange, dateAdd, formatDate, isAdult } from "./util";
import {
    Entity,
    PrimaryColumn,
    PrimaryGeneratedColumn,
    Column,
    OneToOne,
    OneToMany,
    ManyToOne,
    ManyToMany,
    CreateDateColumn,
    UpdateDateColumn,
    JoinTable,
    JoinColumn,
    Index,
    AfterLoad,
    ValueTransformer,
    Unique,
} from "typeorm";
import { Type, Exclude } from "class-transformer";
import Stripe from "stripe";
import { Request, ClientInfo } from "./transport";
import { getVersion } from "./version";
import { colors } from "./colors";
import { TimeResultItem, overlap } from "./hours";
import {
    TimeStatement,
    VacationStatement,
    BonusStatement,
    CostStatement,
    newTimeStatement,
    newVacationStatement,
    newCostStatement,
    newBonusStatement,
    PreviousAverages,
} from "./statement";
import { Issue, IssueType } from "./issues";
import { getHolidayForDate, HolidayName, HOLIDAYS } from "./holidays";
import { EntityFilterContext, getSpecificity, matchesFilters } from "./filters";
import { TransformDateProperties } from "./encoding";
import { DateRange, TimeResult } from "./time";
import {
    Days,
    Euros,
    Factor,
    Hours,
    Milliseconds,
    Percent,
    Rate,
    Meals,
    add,
    DateString,
} from "@pentacode/openapi/src/units";
import { Permission } from "./permissions";
import { HogastUnionId } from "./hogast";
import { Country, Localized, StateCode } from "./localized";

export enum Role {
    Admin = -1,
    Owner = 0,
    Manager = 1,
    Worker = 2,
}

export enum BreakMode {
    Auto,
    Manual,
    AutoPlusManual,
    Planned,
    PlannedPlusManual,
    AutoOrManual,
    PlannedOrManual,
}

export function breakModeLabel(mode: BreakMode) {
    switch (mode) {
        case BreakMode.Auto:
            return "Automatisch";
        case BreakMode.Manual:
            return "Manuell";
        case BreakMode.AutoPlusManual:
            return "Automatisch + Manuell";
        case BreakMode.Planned:
            return "Geplant";
        case BreakMode.PlannedPlusManual:
            return "Geplant + Manuell";
        case BreakMode.AutoOrManual:
            return "Automatisch oder Manuell";
        case BreakMode.PlannedOrManual:
            return "Geplant oder Manuell";
    }
}

export type CompanyListing = Pick<Company, "id" | "name" | "status"> & { venues: Pick<Venue, "id" | "name">[] };

export enum NominalHoursMode {
    WeekFactor,
    Exact,
    ExactWithoutHolidays,
    FixedDays,
    FixedDaysWithoutHolidays,
}

export enum EmploymentType {
    Regular,
    Marginal,
    Independent,
    ShortTerm,
    Trainee,
    MidiJob,
    WorkingStudent,
    DualStudent,
    Intern,
}

export function employmentTypeLabel(type: EmploymentType, full = false) {
    return full
        ? [
              "Vollzeit/Teilzeit",
              "Aushilfe",
              "Freier Mitarbeiter",
              "Kurzfristig Beschäftigt",
              "Auszubildender",
              "Midi-Job",
              "Werkstudent",
              "Dualer Student",
              "Praktikant",
          ][type]
        : [
              "VZ/TZ",
              "Aushilfe",
              "Fr. Mitarb.",
              "Kurzfr. Besch.",
              "Azubi",
              "Midi-Job",
              "Werkstudent",
              "Dualer Stud.",
              "Praktikant",
          ][type];
}

export function employmentTypeDescription(type: EmploymentType) {
    switch (type) {
        case EmploymentType.Regular:
            return "Der Mitarbeiter ist normal angestellt und bezieht ein monatliches oder stündliches Gehalt.";
        case EmploymentType.Marginal:
            return "Der Mitarbeiter ist geringfügig beschäftigt und darf nicht mehr als 538 € im Monat verdienen.";
        case EmploymentType.Independent:
            return "Der Mitarbeiter ist auf selbständiger Basis tätig und schreibt Rechnungen.";
        case EmploymentType.ShortTerm:
            return "Kurzfristige Beschäftigte und Saisonarbeiter dürfen nicht mehr als 70 Tage / Jahr beschäftigt werden.";
        case EmploymentType.Trainee:
            return "Bei Auszubildenden ist die Mindestlohn-Überprüfung ausgesetzt. Ansonsten gelten altersunabhängig die Standardregeln für Festangestellte.";
        case EmploymentType.MidiJob:
            return "Ein Midi-Job ist eine geringfügige Beschäftigung mit einem monatlichen Arbeitsentgeld zwischen 520,01 € und 2000 €. Midijobber sind versicherungspflichtig und dürfen maximal 124,4 Stunden im Monat arbeiten.";
        case EmploymentType.WorkingStudent:
            return "Werkstudenten sind an einer Fach- oder Hochschule Studierende, die neben dem Studium einer geringfügigen Beschäftigung nachgehen. Werkstudenten dürfen höchsten 20 Stunden pro Woche während der Vorlesungszeit und insgesamt nicht mehr als 26 Wochen im Jahr arbeiten.";
        case EmploymentType.DualStudent:
            return "Duales Studenten sind an einer Hochschule Studierende, die ihr Studium mit Praxiserfahrung in einem Unternehmen kombinieren. Im Gegensatz zu Werkstudenten sind duale Studenten voll sozialversicherungspflichtig und es gibt keine besonderen Einschränkungen der Arbeitszeit.";
        case EmploymentType.Intern:
            return "Praktikanten sind Personen, die eine in Studien- oder Prüfungsordnungen vorgeschriebene berufspraktische Tätigkeit im Rahmen eines Praktikums verrichten.";
        default:
            return "";
    }
}

export function employmentTypeColor(type: EmploymentType) {
    switch (type) {
        case EmploymentType.Regular:
            return colors.blue;
        case EmploymentType.Marginal:
            return colors.orange;
        case EmploymentType.Independent:
            return colors.violet;
        case EmploymentType.ShortTerm:
            return colors.green;
        case EmploymentType.Trainee:
            return colors.yellow;
        case EmploymentType.MidiJob:
            return colors.purple;
        case EmploymentType.WorkingStudent:
            return colors.teal;
        case EmploymentType.DualStudent:
            return colors.red;
        case EmploymentType.Intern:
            return colors.pink;
        default:
            return "";
    }
}

export function getEmploymentTypeGroup(type: EmploymentType) {
    switch (type) {
        case EmploymentType.Regular:
            return "101";
        case EmploymentType.Marginal:
            return "109";
        case EmploymentType.ShortTerm:
            return "110";
        case EmploymentType.Trainee:
            return "121";
        case EmploymentType.MidiJob:
            return "";
        case EmploymentType.WorkingStudent:
            return "106";
        case EmploymentType.DualStudent:
            return "102";
        case EmploymentType.Intern:
            return "105";
        case EmploymentType.Independent:
            return "";
        default:
            return "";
    }
}

export function getEmploymentTypes() {
    return Object.values(EmploymentType)
        .filter((t) => typeof t === "number")
        .map((type: EmploymentType) => ({
            type,
            label: employmentTypeLabel(type, true),
            labelShort: employmentTypeLabel(type),
            description: employmentTypeDescription(type),
            color: employmentTypeColor(type),
        }));
}

export enum VacationIncrement {
    Monthly,
    Hourly,
}

export interface AutoBreak {
    duration: Hours;
    amount: Hours;
}

const defaultAutoBreaks: AutoBreak[] = [
    { duration: 6 as Hours, amount: 0.5 as Hours },
    { duration: 9 as Hours, amount: 0.75 as Hours },
];

export enum AbsenceMode {
    Average,
    Fixed,
    FixedDays,
}

export enum BreakTiming {
    Start = "start",
    End = "end",
}

export enum MealsMode {
    Manual = "manual",
    Auto = "auto",
    Employee = "employee",
}

@Entity()
export class TimeSettings {
    constructor(vals: Partial<TimeSettings> = {}) {
        Object.assign(this, vals);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @ManyToOne("CompanySettings", "availableSettings", { eager: false })
    companySettings: any;

    @Column()
    name: string;

    @Column()
    companySettingsId: number;

    @Column({ default: true })
    trackingEnabled: boolean = true;

    @Column({ default: false })
    applyEarlyCheckin: boolean = false;

    @Column({ default: false })
    applyLateCheckout: boolean = false;

    @Column("float", { default: 0.5 })
    checkinWindow: Hours = 0.5 as Hours;

    @Column("float", { default: 11 })
    checkoutWindow: Hours = 11 as Hours;

    @Column({ default: 5 })
    checkinRounding: Hours = 5 as Hours;

    @Column({ default: -5 })
    checkoutRounding: Hours = -5 as Hours;

    @Column({ default: BreakMode.Auto })
    breakMode: BreakMode = BreakMode.Auto;

    @Column({ default: BreakTiming.Start })
    breakTiming: BreakTiming = BreakTiming.Start;

    @Column({ default: true })
    unplannedCheckins: boolean = true;

    @Column({ default: false })
    trackerDisplayEmployeeInfo: boolean = false;

    @Column({ default: true })
    trackerDisplayRoster: boolean = true;

    /** @deprecated as of v1.25.0 */
    @Column({ default: 0 })
    autoMeals: Meals = 0 as Meals;

    @Column({ default: MealsMode.Manual })
    mealsMode: MealsMode = MealsMode.Manual;

    @Column({ default: false })
    mealEnabledBreakfast: boolean = false;

    @Column("time", { nullable: true, default: null })
    mealStartBreakfast: string | null = null;

    @Column("time", { nullable: true, default: null })
    mealEndBreakfast: string | null = null;

    @Column({ default: true })
    mealEnabledLunch: boolean = true;

    @Column("time", { nullable: true, default: "00:00:00" })
    mealStartLunch: string | null = "00:00:00";

    @Column("time", { nullable: true, default: "24:00:00" })
    mealEndLunch: string | null = "24:00:00";

    @Column({ default: false })
    mealEnabledDinner: boolean = false;

    @Column("time", { nullable: true, default: null })
    mealStartDinner: string | null = null;

    @Column("time", { nullable: true, default: null })
    mealEndDinner: string | null = null;

    @Column("float", { default: 0 })
    mealsMinDuration: Hours = 0 as Hours;

    @Column("simple-json", { default: JSON.stringify(defaultAutoBreaks) })
    autoBreaks: AutoBreak[] = defaultAutoBreaks;

    @Column({ default: false })
    paidBreaksAuto: boolean = false;

    @Column({ default: false })
    paidBreaksManual: boolean = false;

    @Column({ default: false })
    trackingViaAppEnabled: boolean = false;

    @Column({ default: true })
    trackingViaAppRequiresToken: boolean = true;

    @Column("jsonb", { default: "[]" })
    loggingRequiresPhotoFor: TimeLogAction[] = [];

    @Column({ default: false })
    loggingRequiresLocation: boolean = false;

    /** @deprecated */
    @Column({ nullable: true })
    extractedFromCompany: number;

    /** @deprecated */
    @Column({ nullable: true })
    extractedFromVenue: number;

    /** @deprecated */
    @Column({ nullable: true })
    extractedFromDepartment: number;

    /** @deprecated */
    @Column({ nullable: true })
    extractedFromEmployee: number;

    @Column({ default: 0 })
    order: number = 0;

    @Column("jsonb", { default: "[]" })
    filters: EntityFilter[] = [];

    /** @deprecated */
    @Column("time", { default: "20:00:00" })
    bonusNight1Start: string = "20:00:00";

    /** @deprecated */
    @Column("time", { default: "06:00:00" })
    bonusNight1End: string = "06:00:00";

    /** @deprecated */
    @Column("time", { default: "00:00:00" })
    bonusNight2Start: string = "00:00:00";

    /** @deprecated */
    @Column("time", { default: "04:00:00" })
    bonusNight2End: string = "04:00:00";

    /** @deprecated */
    @Column("float", { default: 0 })
    bonusMinDuration: Hours = 0 as Hours;

    /** @deprecated */
    @Column("time", { default: "04:00:00" })
    bonusSundayNextDayUntil: string = "04:00:00";

    /* @deprecated */
    @Column("time", { default: "04:00:00" })
    bonusHolidayNextDayUntil: string = "04:00:00";

    /* @deprecated */
    @Column("time", { default: "14:00:00" })
    bonusChristmasEveFrom: string = "14:00:00";

    /* @deprecated */
    @Column("time", { default: "14:00:00" })
    bonusNewYearsEveFrom: string = "14:00:00";

    getBreakfastInterval(date: DateString = toDateString(new Date())) {
        return (
            this.mealEnabledBreakfast &&
            this.mealStartBreakfast &&
            this.mealEnabledBreakfast &&
            (parseTimes(date, this.mealStartBreakfast, this.mealEndBreakfast) as [Date, Date])
        );
    }

    getLunchInterval(date: DateString = toDateString(new Date())) {
        return (
            this.mealEnabledLunch &&
            this.mealStartLunch &&
            this.mealEnabledLunch &&
            (parseTimes(date, this.mealStartLunch, this.mealEndLunch) as [Date, Date])
        );
    }

    getDinnerInterval(date: DateString = toDateString(new Date())) {
        return (
            this.mealEnabledDinner &&
            this.mealStartDinner &&
            this.mealEnabledDinner &&
            (parseTimes(date, this.mealStartDinner, this.mealEndDinner) as [Date, Date])
        );
    }
}

export class AccountingSettings {
    constructor(vals: Partial<AccountingSettings> = {}) {
        Object.assign(this, vals);
    }

    @Column({ default: "" })
    client: string = "";

    @Column({ default: "" })
    consultant: string = "";

    @Column({ default: "1600" })
    cashbookLedger: string = "1600";

    @Column({ default: 4 })
    realAccountsLength: number = 4;

    @Column({ default: "" })
    companyNumber: string = "";

    @Type(() => AccountingSalaryConfig)
    @OneToMany(() => AccountingSalaryConfig, (v) => v.settings, { eager: true, cascade: true })
    salaryConfigs: AccountingSalaryConfig[];
}

export enum NotificationType {
    dummyValue = "remove_me_when_first_option_is_added",
}

export function notificationTypeLabel(type: NotificationType) {
    switch (type) {
    }
}

export class NotificationSettings {
    @Column({ type: "simple-array", default: "own_roster_updated" })
    email: NotificationType[];
}

export enum StaffAppFeature {
    Roster = "roster",
    Absences = "absences",
    Ledgers = "ledgers",
    Time = "time",
    Tracking = "tracking",
}

export function staffAppFeatureLabel(feature: StaffAppFeature) {
    switch (feature) {
        case StaffAppFeature.Roster:
            return "Dienstplan";
        case StaffAppFeature.Absences:
            return "Abwesenheiten";
        case StaffAppFeature.Ledgers:
            return "Konten";
        case StaffAppFeature.Time:
            return "Arbeitszeiten";
        case StaffAppFeature.Tracking:
            return "Stempeluhr";
    }
}

@Entity()
export class CompanySettings {
    constructor(vals: Partial<CompanySettings> = {}) {
        Object.assign(this, vals);
    }

    @PrimaryGeneratedColumn()
    id: number;

    /** @deprecated */
    @Type(() => TimeSettings)
    @OneToOne(() => TimeSettings, { nullable: true, cascade: false, eager: true, onDelete: "SET NULL" })
    @JoinColumn()
    time: TimeSettings;

    /** @deprecated */
    @Column({ nullable: true })
    timeId: number;

    @Column(() => AccountingSettings)
    accounting: AccountingSettings;

    @Column("date", { nullable: true })
    startLedgers: DateString | null;

    @Column({ default: NominalHoursMode.WeekFactor })
    nominalHoursMode: NominalHoursMode = NominalHoursMode.WeekFactor;

    @Column({ default: false })
    iterativeBreaks: boolean = false;

    @Column({ default: false })
    includeTaxedBonusesInBalance: boolean = false;

    // Until February 2021 we were erronously calculating the SHIFT average instead of the DAY average.
    // In order to avoid mayor retroactive changes to reports and ledgers, we decided to start using the
    // correct average starting with 2021. This value allows setting a different start date for the
    // new average calculation for each customer.
    @Column("date", { default: "2021-01-01" })
    startUsingDailyAverage: string;

    // Until Janary 2022 we were using a less accurate way of calculating previous daily averages.
    // In order to avoid mayor retroactive changes to reports and ledgers, we decided to start using the
    // more accurate average starting with 2022. This value allows setting a different start date for the
    // new average calculation for each customer.
    @Column("date", { default: "2022-01-01" })
    startUsingMoreAccurateAverage: string;

    @Column(() => NotificationSettings)
    defaultNotifications: NotificationSettings;

    @OneToMany(() => TimeSettings, (s) => s.companySettings, { eager: true })
    @Type(() => TimeSettings)
    availableTimeSettings: TimeSettings[];

    @Column("date", { nullable: true, default: null })
    commitTimeEntriesBefore: DateString | null;

    @Column("float", { default: 4.35 })
    weekFactor: Factor = 4.35 as Factor;

    @Column("float", { nullable: true, default: 2 })
    mealValueBreakfast: Rate<Euros, Meals> | null = null;

    @Column("float", { nullable: true, default: 3.8 })
    mealValueLunch: Rate<Euros, Meals> | null = null;

    @Column("float", { nullable: true, default: 3.8 })
    mealValueDinner: Rate<Euros, Meals> | null = null;
}

export const absenceAccountingSalaryConfigPropsWithWageType = [
    "vacation",
    "compDay",
    "sick",
    "childSick",
    "sickInKUG",
] as const;

export class AbsenceAccountingSalaryConfig {
    constructor(vals: Partial<AbsenceAccountingSalaryConfig> = {}) {
        Object.assign(this, vals);
    }

    @Column({ default: "" })
    vacation: string = "";

    @Column({ default: "" })
    compDay: string = "";

    @Column({ default: "" })
    sick: string = "";

    @Column({ default: "" })
    childSick: string = "";

    @Column({ default: "" })
    sickInKUG: string = "";

    updateWageTypeNumber(oldNumber: string, newNumber: string) {
        const updated: Record<string, string> = {};

        absenceAccountingSalaryConfigPropsWithWageType.forEach((prop) => {
            if (this[prop] === oldNumber) {
                updated[prop] = newNumber;
            }
        });
        Object.assign(this, { ...this, ...updated });
    }
}

export const bonusAccountingSalaryConfigPropsWithWageType = [
    "night1",
    "night2",
    "sunday",
    "holiday",
    "special",
] as const;

export class BonusAccountingSalaryConfig {
    constructor(vals: Partial<BonusAccountingSalaryConfig> = {}) {
        Object.assign(this, vals);
    }

    @Column({ default: "" })
    night1: string = "";

    @Column({ default: "" })
    night2: string = "";

    @Column({ default: "" })
    sunday: string = "";

    @Column({ default: "" })
    holiday: string = "";

    @Column({ default: "" })
    special: string = "";

    updateWageTypeNumber(oldNumber: string, newNumber: string) {
        const updated: Record<string, string> = {};

        bonusAccountingSalaryConfigPropsWithWageType.forEach((prop) => {
            if (this[prop] === oldNumber) {
                updated[prop] = newNumber;
            }
        });
        Object.assign(this, { ...this, ...updated });
    }
}

export const accountingSalaryConfigPropsWithWageType = [
    "workHourly",
    "workMonthly",
    "workHourlyDays",
    "workMonthlyDays",
    "absenceHourly",
    "absenceHourlyDays",
    "absenceMonthly",
    "absenceMonthlyDays",
    "bonusTaxFree",
    "bonusTaxed",
    "mealsValue",
    "mealsBreakfast",
    "mealsLunch",
    "mealsDinner",
    "commission",
    "bonusAdvance",
    "payAdvance",
    "salary",
] as const;

@Entity()
export class AccountingSalaryConfig {
    constructor(vals: Partial<AccountingSalaryConfig> = {}) {
        Object.assign(this, vals);
    }

    @PrimaryGeneratedColumn()
    id?: number;

    @Type(() => CompanySettings)
    @ManyToOne(() => CompanySettings)
    settings: CompanySettings;

    @Column({ default: "" })
    name: string = "";

    @Column({ default: "" })
    workHourly: string = "";

    @Column({ default: "" })
    workHourlyDays: string = "";

    @Column({ default: "" })
    workMonthly: string = "";

    @Column({ default: "" })
    workMonthlyDays: string = "";

    @Column({ default: "" })
    overtimeHourly: string = "";

    @Column({ default: "" })
    overtimeMonthly: string = "";

    @Type(() => AbsenceAccountingSalaryConfig)
    @Column(() => AbsenceAccountingSalaryConfig)
    absenceHourly: AbsenceAccountingSalaryConfig = new AbsenceAccountingSalaryConfig();

    @Type(() => AbsenceAccountingSalaryConfig)
    @Column(() => AbsenceAccountingSalaryConfig)
    absenceHourlyDays: AbsenceAccountingSalaryConfig = new AbsenceAccountingSalaryConfig();

    @Type(() => AbsenceAccountingSalaryConfig)
    @Column(() => AbsenceAccountingSalaryConfig)
    absenceMonthly: AbsenceAccountingSalaryConfig = new AbsenceAccountingSalaryConfig();

    @Type(() => AbsenceAccountingSalaryConfig)
    @Column(() => AbsenceAccountingSalaryConfig)
    absenceMonthlyDays: AbsenceAccountingSalaryConfig = new AbsenceAccountingSalaryConfig();

    /** @deprecated */
    @Type(() => BonusAccountingSalaryConfig)
    @Column(() => BonusAccountingSalaryConfig)
    bonusTaxFree: BonusAccountingSalaryConfig = new BonusAccountingSalaryConfig();

    /** @deprecated */
    @Type(() => BonusAccountingSalaryConfig)
    @Column(() => BonusAccountingSalaryConfig)
    bonusTaxed: BonusAccountingSalaryConfig = new BonusAccountingSalaryConfig();

    @Column({ default: "" })
    commission: string = "";

    @Column({ default: "" })
    bonusAdvance: string = "";

    @Column({ default: "" })
    salary: string = "";

    @Column({ default: "" })
    payAdvance: string = "";

    @Column("simple-array")
    employmentTypes: string[] = [];

    @Column("jsonb", { default: [] })
    entities: EntityFilter[];

    @Column({ default: 0 })
    order: number = 0;

    @OneToMany(() => AccountingSalaryConfigBonus, (bonus) => bonus.accountingSalaryConfig, {
        eager: true,
        cascade: true,
    })
    bonuses: AccountingSalaryConfigBonus[];

    @OneToMany(() => AccountingSalaryConfigBenefit, (benefit) => benefit.accountingSalaryConfig, {
        eager: true,
        cascade: true,
    })
    benefits: AccountingSalaryConfigBenefit[];

    @Column({ default: "" })
    mealsValue: string = "";

    @Column({ default: "" })
    mealsBreakfast: string = "";

    @Column({ default: "" })
    mealsLunch: string = "";

    @Column({ default: "" })
    mealsDinner: string = "";

    updateWageTypeNumber(oldNumber: string, newNumber: string) {
        const updated: Record<string, string> = {};

        accountingSalaryConfigPropsWithWageType.forEach((prop) => {
            if (this[prop] === oldNumber) {
                updated[prop] = newNumber;
            }
            // handle bonus and absence configs
            else if (
                this[prop] instanceof AbsenceAccountingSalaryConfig ||
                this[prop] instanceof BonusAccountingSalaryConfig
            ) {
                this[prop].updateWageTypeNumber(oldNumber, newNumber);
            }

            // handle benefits
            if (this.benefits) {
                this.benefits.forEach((benefit) => {
                    if (benefit.wageTypeNumber === oldNumber) {
                        benefit.wageTypeNumber = newNumber;
                    }
                });
            }

            if (this.bonuses) {
                this.bonuses.forEach((bonus) => {
                    if (bonus.taxed === oldNumber) {
                        bonus.taxed = newNumber;
                    }

                    if (bonus.taxFree === oldNumber) {
                        bonus.taxFree = newNumber;
                    }
                });
            }
        });
        Object.assign(this, { ...this, ...updated });
    }
}

export enum Feature {
    Employees = "employees",
    Roster = "roster",
    Time = "time",
    Revenues = "revenues",
    Planning = "planning",
    StaffApp = "staffapp",
}

export enum BillingMode {
    Legacy = "legacy",
    Default = "default",
    Hogast = "hogast",
}

@Entity()
export class BillingInfo {
    @PrimaryGeneratedColumn()
    id: number;

    @Column({ default: BillingMode.Default })
    billingMode: BillingMode = BillingMode.Default;

    @Column("text", { nullable: true })
    hogastUnionId: HogastUnionId | null = null;

    @Column("text", { nullable: true })
    hogastMemberId: string | null = null;

    @PrimaryColumn()
    customerId: string;

    @Column("jsonb", { nullable: true })
    customer: Stripe.Customer | null = null;

    @Column("jsonb", { default: "[]" })
    paymentMethods: Stripe.PaymentMethod[] = [];

    @Column("jsonb", { default: "[]" })
    invoices: Stripe.Invoice[] = [];

    get info() {
        if (!this.customer) {
            return {
                name: "",
                email: "",
                phone: "",
                comments: "",
                addressLine1: "",
                addressLine2: "",
                postalCode: "",
                city: "",
                vatId: "",
                billingMode: this.billingMode,
                hogastUnionId: this.hogastUnionId,
                hogastMemberId: this.hogastMemberId,
            };
        }

        return {
            name: this.customer.name || "",
            email: this.customer.email || "",
            phone: this.customer.phone || "",
            comments: this.customer.description || "",
            addressLine1: this.customer.address?.line1 || "",
            addressLine2: this.customer.address?.line2 || "",
            postalCode: this.customer.address?.postal_code || "",
            city: this.customer.address?.city || "",
            vatId: this.customer.tax_ids?.data?.[0]?.value || "",
            billingMode: this.billingMode,
            hogastUnionId: this.hogastUnionId,
            hogastMemberId: this.hogastMemberId,
        };
    }
}

export type EntityFilter =
    | {
          type: "venue";
          value: number;
      }
    | { type: "department"; value: number }
    | { type: "position"; value: number }
    | {
          type: "employmentType";
          value: EmploymentType;
      }
    | {
          type: "employeeStatus";
          value: EmployeeStatus;
      }
    | {
          type: "employeeId";
          value: number;
      }
    | {
          type: "staffNumber";
          value: number;
      }
    | {
          type: "employeeTag";
          value: EmployeeTag["id"];
      };

export interface CostCenter {
    name: string;
    number: string;
    entities: EntityFilter[];
}

export type Unit = "hours" | "days" | "euros" | "pieces";

export interface WageType {
    name: string;
    number: string;
    description: string;
    unit: Unit;
}

export enum CompanyStatus {
    Trialing = "trialing",
    Probation = "probation",
    Active = "active",
    Paused = "paused",
    Canceled = "canceled",
    PaymentOverdue = "payment_overdue",
}

@Entity()
export class Company {
    constructor(vals: Partial<Company> = {}) {
        Object.assign(this, vals);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    name: string = "";

    @Column({ default: "DE" })
    country: Country;

    @Column({ default: "" })
    address: string = "";

    @Column({ default: "" })
    city: string = "";

    @Column({ default: "" })
    postalCode: string = "";

    @Column({ default: "" })
    email: string = "";

    @Column({ default: "" })
    phone: string = "";

    @CreateDateColumn()
    @Type(() => Date)
    created: Date;

    @UpdateDateColumn()
    @Type(() => Date)
    updated: Date;

    @Type(() => Venue)
    @OneToMany(() => Venue, (v) => v.company, { eager: false })
    venues: Venue[];

    @Type(() => Venue)
    archivedVenues: Venue[] = [];

    @Type(() => Employee)
    @OneToMany(() => Employee, (v) => v.company, { eager: false })
    employees: Employee[];

    @Column()
    settingsId: number;

    @Type(() => CompanySettings)
    @OneToOne(() => CompanySettings, { eager: false, cascade: false })
    @JoinColumn()
    settings: CompanySettings;

    @Column("simple-array", { default: "employees,roster,time" })
    features: Feature[] = [Feature.Employees, Feature.Roster, Feature.Time];

    @Type(() => Account)
    owner: Account | undefined = undefined;

    @Type(() => BillingInfo)
    @OneToOne(() => BillingInfo, { eager: true, cascade: true, nullable: true })
    @JoinColumn()
    billing: BillingInfo | null = null;

    @Column({ default: "" })
    comments: string = "";

    @Column({ default: "" })
    secret?: string;

    @Column({ default: CompanyStatus.Active })
    readonly status: CompanyStatus;

    @OneToMany(() => CompanyLifeCycleEvent, (event) => event.company, { eager: false })
    @Type(() => CompanyLifeCycleEvent)
    lifeCycleEvents: CompanyLifeCycleEvent[];

    @Type(() => DocumentTag)
    @OneToMany(() => DocumentTag, (tag) => tag.company, { eager: true })
    documentTags: DocumentTag[];

    @Type(() => EmployeeTag)
    @OneToMany(() => EmployeeTag, (tag) => tag.company, { eager: true })
    employeeTags: EmployeeTag[];

    @Type(() => BonusType)
    @OneToMany(() => BonusType, (t) => t.company, { eager: true })
    bonusTypes: BonusType[];

    @Type(() => BenefitType)
    @OneToMany(() => BenefitType, (t) => t.company, { eager: true })
    benefitTypes: BenefitType[];

    @Column("jsonb", { default: "[]" })
    costCenters: CostCenter[];

    @Column("jsonb", { default: "[]" })
    wageTypes: WageType[] = [];

    @Column({ nullable: true })
    apiKey?: string;

    tempAuthTokens?: [string, string];

    get effectiveStatus(): CompanyStatus {
        const pastEventsWithStatus = this.lifeCycleEvents?.filter(
            (e) => !!e.status && e.date <= toDateString(new Date())
        );

        return pastEventsWithStatus?.length > 0 ? pastEventsWithStatus.reverse()[0].status! : this.status;
    }

    getTimeSettings(context: Omit<EntityFilterContext, "company"> = {}) {
        const ctx = { ...context, company: this };

        return (
            this.settings.availableTimeSettings
                .sort((a, b) => getSpecificity(b.filters) - getSpecificity(a.filters))
                .find((s) => matchesFilters(s.filters, ctx)) || new TimeSettings()
        );
    }

    getVenue(venueId: number) {
        return [...this.venues, ...this.archivedVenues].find(({ id }) => id === venueId) || null;
    }

    getDepartment(id: number | null | undefined) {
        for (const venue of [...this.venues, ...this.archivedVenues]) {
            for (const department of [...venue.departments, ...venue.archivedDepartments]) {
                if (department.id === id) {
                    return { department, venue };
                }
            }
        }
        return { venue: null, department: null };
    }

    getPositionColor(position: Position) {
        if (position.color) {
            return position.color;
        }
        const { department } = this.getDepartment(position.departmentId);
        const color = (department && department.color) || "";
        return colors[color] || color;
    }

    getTimeEntryColor(entry: TimeEntry) {
        if (entry.position) {
            return this.getPositionColor(entry.position);
        } else {
            return timeEntryTypeColor(entry.type);
        }
    }

    getTimeEntryTypeLabel(entry: TimeEntry) {
        return entry.position ? entry.position.name : Localized[this.country].timeEntryTypeLabel(entry.type);
    }

    getPositionLabel(pos: number | Position) {
        const position = typeof pos === "number" ? this.getPosition(pos)?.position : pos;
        if (!position) {
            return null;
        }

        const { venue, department } = this.getDepartment(position.departmentId);
        if (!venue || !department) {
            return position.name;
        }

        let label = `${department.name} / ${position.name}`;
        if (this.venues.length > 1) {
            label = `${venue.name} / ${label}`;
        }

        return label;
    }

    getPosition(id: number) {
        for (const venue of [...this.venues, ...this.archivedVenues]) {
            for (const department of [...venue.departments, ...venue.archivedDepartments]) {
                const position = [...department.positions, ...department.archivedPositions].find((r) => r.id === id);
                if (position) {
                    return {
                        venue,
                        department,
                        position,
                        label:
                            this.venues.length > 1
                                ? `${venue.name} / ${department.name} / ${position.name}`
                                : `${department.name} / ${position.name}`,
                        color: this.getPositionColor(position),
                    };
                }
            }
        }
        return null;
    }

    getEmployee(id: number) {
        return this.employees && this.employees.find((e) => e.id === id);
    }

    getEmployeeByStaffNumber(staffNumber: number) {
        return this.employees && this.employees.find((e) => e.staffNumber === staffNumber);
    }

    isOwner(account: Account) {
        const employee = this.employees.find((e) => e.accountId === account.id);
        return !!employee && employee.role === Role.Owner;
    }

    hasAccess(employee: Employee, context: Omit<EntityFilterContext, "company">) {
        return (
            employee.role <= Role.Owner || matchesFilters(employee.access?.filters || [], { company: this, ...context })
        );
    }

    hasPermission(account: Account | Employee, scope: Permission) {
        if (account instanceof Account && account.admin) {
            return true;
        }
        const employee = account instanceof Account ? this.employees.find((e) => e.accountId === account.id) : account;
        return !!employee && employee.hasPermission(scope);
    }

    getCostCenterForPosition(pos: Position | number) {
        const position = typeof pos === "number" ? this.getPosition(pos)?.position : pos;

        if (!position) {
            return null;
        }

        const departments: Department[] = [];

        const costCenter = this.costCenters?.find(({ entities }) => {
            for (const filter of entities) {
                if (filter.type === "venue") {
                    departments.push(...(this.venues.find((v) => v.id === filter.value)?.departments || []));
                } else if (filter.type === "department") {
                    const department = this.getDepartment(filter.value as number).department;
                    if (department) {
                        departments.push(department);
                    }
                }
            }

            return departments.some((dep) => dep.positions.some((pos) => pos.id === position.id));
        });

        return costCenter;
    }

    getCostCenter(context: Omit<EntityFilterContext, "company">) {
        const ctx = { ...context, company: this };
        return this.costCenters
            ?.sort((a, b) => getSpecificity(b.entities) - getSpecificity(a.entities))
            ?.find((c) => matchesFilters(c.entities, ctx));
    }

    getWageTypeSet(context: Omit<EntityFilterContext, "company">) {
        const ctx = { ...context, company: this };
        const wageTypeSets = this.settings.accounting.salaryConfigs;
        wageTypeSets.forEach(
            (set) =>
                (set.entities =
                    set.entities || set.employmentTypes.map((t) => ({ type: "employmentType", value: Number(t) })))
        );

        const contract = !context.date
            ? context.employee?.latestContract
            : typeof context.date === "string"
              ? context.employee?.getContractForDate(context.date)
              : context.employee?.getContractForRange(context.date);

        const salary = contract?.getSalary(context.position);

        if (salary?.accountingSalaryConfigId) {
            const set = wageTypeSets.find((s) => s.id === salary.accountingSalaryConfigId);
            if (set) {
                return set;
            }
        }

        return wageTypeSets
            .sort((a, b) => getSpecificity(b.entities) - getSpecificity(a.entities))
            .find((s) => matchesFilters(s.entities, ctx));
    }
}

@Entity()
export class Account {
    constructor(vals: Partial<Account> = {}) {
        Object.assign(this, vals);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column({ unique: true, nullable: true })
    email?: string;

    @Column({ default: "" })
    firstName: string = "";

    @Column({ default: "" })
    lastName: string = "";

    @CreateDateColumn()
    @Type(() => Date)
    created: Date;

    @UpdateDateColumn()
    @Type(() => Date)
    updated: Date;

    @Column("simple-json", { nullable: true })
    @Exclude()
    pwdHash: {
        salt: string;
        iter: number;
        hash: string;
    } | null = null;

    @OneToMany(() => Employee, (e) => e.account, { eager: true })
    @Type(() => Employee)
    profiles: Employee[];

    @Column({ nullable: true })
    @Exclude()
    setPwdToken?: string;

    @Type(() => Session)
    @OneToMany(() => Session, (s) => s.account)
    sessions: Session[];

    @Column({ default: false })
    admin: boolean = false;

    @Type(() => Company)
    company?: Company;

    hasPassword = false;

    @AfterLoad()
    protected _afterLoad() {
        this.hasPassword = !!this.pwdHash;
    }

    get name() {
        return `${this.firstName} ${this.lastName}`;
    }
}

@Entity()
export class Session {
    @PrimaryGeneratedColumn()
    id: number;

    @ManyToOne(() => Account, (a) => a.sessions, { onDelete: "CASCADE" })
    @Type(() => Account)
    account: Account;

    @Column()
    accountId: number;

    @ManyToOne(() => Company, { nullable: true, onDelete: "CASCADE" })
    @Type(() => Company)
    company?: Company | null;

    @Column({ nullable: true })
    companyId: number | null;

    @Column()
    token: string;

    @CreateDateColumn()
    created: Date;

    @Column({ nullable: true })
    scope: string;

    @Column({ type: Date, nullable: true })
    expires: Date | null;

    @Column({ type: Date, nullable: true })
    lastUsed: Date | null;

    @Column({ default: false })
    revoked: boolean = false;

    @Column({ type: "jsonb", nullable: true })
    clientInfo: ClientInfo | null = null;
}

@Entity()
@Unique(["companyId", "venueNumber"])
export class Venue {
    constructor(vals: Partial<Venue> = {}) {
        Object.assign(this, vals);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column({ type: "int", nullable: true })
    venueNumber?: number | null;

    @ManyToOne(() => Company, (c) => c.venues, { onDelete: "CASCADE" })
    @Type(() => Company)
    company: Company;

    @Column()
    companyId: number;

    @Column()
    name: string = "";

    @Column()
    address: string = "";

    @Column()
    city: string = "";

    @Column()
    postalCode: string = "";

    @Column()
    state: StateCode = "BY";

    @Column()
    email: string = "";

    @Column()
    phone: string = "";

    @Column({ default: 0 })
    order: number = 0;

    @OneToMany(() => Department, (d) => d.venue, { eager: true })
    @Type(() => Department)
    departments: Department[];

    @Type(() => Department)
    archivedDepartments: Department[] = [];

    @Column()
    archived: boolean = false;

    @OneToMany(() => RosterTemplate, (t) => t.venue, { eager: true, cascade: true })
    @Type(() => RosterTemplate)
    rosterTemplates: RosterTemplate[];

    /** @deprecated */
    @ManyToOne(() => TimeSettings, { eager: false, cascade: false, nullable: true, onDelete: "SET NULL" })
    @Type(() => TimeSettings)
    timeSettings: TimeSettings | null;

    /** @deprecated */
    @Column({ nullable: true })
    timeSettingsId: number | null;

    /** @deprecated */
    time: Partial<TimeSettings> & { custom: boolean };

    @Column("jsonb", { nullable: true })
    holidays: HolidayName[] | null;

    @Column("text", { nullable: true })
    accountingClient: string | null = null;

    @Column("text", { nullable: true })
    accountingConsultant: string | null = null;

    @Column("text", { nullable: true })
    cashbookLedger: string | null = null;

    @Column("int", { nullable: true })
    realAccountsLength: number | null = null;

    @Column("float", { nullable: true, default: null })
    mealValueBreakfast: Rate<Euros, Meals> | null;

    @Column("float", { nullable: true, default: null })
    mealValueLunch: Rate<Euros, Meals> | null = null;

    @Column("float", { nullable: true, default: null })
    mealValueDinner: Rate<Euros, Meals> | null = null;

    get enabledHolidays() {
        return (
            this.holidays ||
            Object.values(HOLIDAYS)
                .flat()
                .filter((h) => h.states.includes(this.state))
                .map((h) => h.name)
        );
    }

    get stateName() {
        const state = Object.values([Localized.AT.states, Localized.DE.states])
            .flat()
            .find((state) => state.code === this.state);
        return (state && state.name) || "";
    }
}

@Entity()
export class Department {
    constructor(vals: Partial<Department> = {}) {
        Object.assign(this, vals);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    name: string = "";

    @Column()
    venueId: number;

    @ManyToOne(() => Venue, (v) => v.departments, { onDelete: "CASCADE" })
    @Type(() => Venue)
    venue: Venue;

    @OneToMany(() => Position, (position) => position.department, { eager: true, cascade: true })
    @Type(() => Position)
    positions: Position[];

    @Type(() => Position)
    archivedPositions: Position[] = [];

    @Column()
    color: string;

    @Column({ default: 0 })
    order: number = 0;

    @Column({ default: false })
    archived: boolean = false;

    @Column("simple-array", { default: "" })
    rosterOrder: string[] = [];

    /** @deprecated */
    @ManyToOne(() => TimeSettings, { eager: false, cascade: false, nullable: true, onDelete: "SET NULL" })
    @Type(() => TimeSettings)
    timeSettings: TimeSettings | null;

    /** @deprecated */
    @Column({ nullable: true })
    timeSettingsId: number | null;

    @Column({ default: false })
    bonusesAreTaxed: boolean;

    /** @deprecated */
    time: Partial<TimeSettings> & { custom: boolean };
}

@Entity()
export class Position {
    constructor(vals?: { id?: number; name?: string; color?: string; order?: number; departmentId?: number }) {
        if (vals) {
            if (vals.id) {
                this.id = vals.id;
            }
            this.name = vals.name || "";
            this.color = vals.color || "";
            if (typeof vals.order === "number") {
                this.order = vals.order;
            }
            if (vals.departmentId) {
                this.departmentId = vals.departmentId;
            }
        }
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    name: string;

    @Column()
    departmentId: number;

    @ManyToOne(() => Department, (dep) => dep.positions, { primary: true, onDelete: "CASCADE" })
    department: Department;

    @Column({ default: true })
    active: boolean = true;

    @ManyToMany(() => Employee, (emp) => emp.positions)
    employees: Employee[];

    @ManyToMany(() => JobPosting, (posting) => posting.positions)
    jobPostings: JobPosting[];

    @Column({ default: 0 })
    order: number = 0;

    @Column({ default: "" })
    color: string;
}

export interface ShiftTemplate {
    type?: TimeEntryType;
    venue?: number;
    uses?: number;
    start?: string;
    end?: string;
    positionId?: number;
    breakPlanned?: number | null;
    favorite?: boolean;
}

export interface TimeFilter {
    from?: string | null;
    fromRule?: "open" | "closed" | "equal";
    to?: string | null;
    toRule?: "open" | "closed" | "equal";
}

export interface RosterTab {
    name: string;
    venue: number;
    departments?: number[];
    time?: TimeFilter;
    types?: TimeEntryType[];
}

export enum InviteStatus {
    Created = "created",
    Sent = "sent",
    DeliveryFailed = "delivery_failed",
    Rejected = "rejected",
    Accepted = "accepted",
}

export interface Invite {
    created: Date;
    status: InviteStatus;
    id: string;
    message: string;
    response?: string;
    inviter: number;
}

export enum EmployeeStatus {
    Probation = "probation",
    Active = "active",
    Retired = "retired",
}

export function employeeStatusLabel(status: EmployeeStatus) {
    switch (status) {
        case EmployeeStatus.Probation:
            return "Auf Probe";
        case EmployeeStatus.Active:
            return "Aktiv";
        case EmployeeStatus.Retired:
            return "Ausgeschieden";
    }
}

export function employeeStatusColor(status: EmployeeStatus) {
    switch (status) {
        case EmployeeStatus.Probation:
            return "orange";
        case EmployeeStatus.Active:
            return "green";
        case EmployeeStatus.Retired:
            return "red";
    }
}

export function employeeStatusIcon(status: EmployeeStatus) {
    switch (status) {
        case EmployeeStatus.Probation:
            return "person-circle-question";
        case EmployeeStatus.Active:
            return "person-circle-check";
        case EmployeeStatus.Retired:
            return "person-circle-xmark";
    }
}

export enum Gender {
    Male = "m",
    Female = "w",
    NonBinary = "d",
    Undetermined = "x",
}

export function genderLabel(gender: Gender) {
    switch (gender) {
        case Gender.Male:
            return "männlich";
        case Gender.Female:
            return "weiblich";
        case Gender.NonBinary:
            return "divers";
        case Gender.Undetermined:
            return "unbestimmt";
    }
}

export interface Access {
    filters: EntityFilter[];
    permissions: Permission[];
}

@Entity()
export class Employee {
    constructor(vals: Partial<Employee> = {}) {
        Object.assign(this, vals);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column({ nullable: true })
    accountId: number | null;

    @ManyToOne(() => Account, { nullable: true, onDelete: "SET NULL" })
    @Type(() => Account)
    account: Account | null;

    @Column()
    @Index()
    companyId: number;

    @ManyToOne(() => Company, (c) => c.employees, { onDelete: "CASCADE" })
    company: Company;

    @Column({ default: Role.Worker })
    role: Role = Role.Worker;

    /** @deprecated starting v1.25.0 in favor of `access` object */
    @Column("simple-array", { default: "" })
    permissions: string[] = [];

    @Column("jsonb", { nullable: true })
    access: Access | null = null;

    @Column({ nullable: true })
    staffNumber: number;

    @Column({ default: "" })
    firstName: string = "";

    @Column({ default: "" })
    lastName: string = "";

    @Column({ default: "" })
    title: string = "";

    @Column({ default: "" })
    callName: string = "";

    @Column({ default: "" })
    address: string = "";

    @Column({ default: "" })
    city: string = "";

    @Column({ default: "" })
    postalCode: string = "";

    @Column({ default: "" })
    email: string = "";

    @Column({ default: "" })
    phone: string = "";

    @Column({ default: "" })
    phone2: string = "";

    @Column({ type: "date", nullable: true })
    birthday: string;

    @Column({ default: "" })
    birthName: string;

    @Column({ default: "" })
    birthCity: string;

    @Column({ default: "" })
    birthCountry: string = "";

    @Column({ default: "" })
    nationality: string = "";

    @Column({ nullable: true })
    gender?: Gender;

    @Column({ default: "" })
    taxId: string = "";

    @Column({ default: "" })
    socialSecurityNumber: string = "";

    @Column({ default: "" })
    notes: string = "";

    @Column({ default: "" })
    avatar: string = "";

    @Column({ default: "" })
    timePin: string = "";

    @Type(() => Position)
    @ManyToMany(() => Position, (position) => position.employees, { eager: true })
    @JoinTable()
    positions: Position[];

    @Column("jsonb", { default: "[]" })
    positionsOrder: number[];

    @OneToMany(() => Contract, (contract) => contract.employee, { eager: true })
    @Type(() => Contract)
    contracts: Contract[];

    /** @deprecated */
    @ManyToOne(() => TimeSettings, { eager: false, cascade: false, nullable: true, onDelete: "SET NULL" })
    @Type(() => TimeSettings)
    timeSettings: TimeSettings | null;

    /** @deprecated */
    @Column({ nullable: true })
    timeSettingsId: number | null;

    @Column("simple-json", { default: "[]" })
    shiftTemplates: ShiftTemplate[] = [];

    @Column("simple-json", { default: "[]" })
    rosterTabs: RosterTab[] = [];

    @Column(() => NotificationSettings)
    notifications: NotificationSettings;

    @Column("jsonb", { nullable: true })
    invite?: Invite | null;

    @Column("simple-array", { default: Object.values(StaffAppFeature).join(",") })
    staffAppFeatures: StaffAppFeature[];

    /** @deprecated */
    time: Partial<TimeSettings> & { custom: boolean; pin: string };

    @Column({ default: false })
    isContactPerson: boolean;

    @Column({ default: EmployeeStatus.Active })
    status: EmployeeStatus = EmployeeStatus.Active;

    @Column("jsonb", { nullable: true })
    scheduledStatusChange: {
        status: EmployeeStatus;
        date: string;
    } | null = null;

    @ManyToMany(() => EmployeeTag, (tag) => tag.employees, { eager: true })
    @JoinTable()
    @Type(() => EmployeeTag)
    tags: EmployeeTag[];

    get name() {
        return `${this.firstName}${this.callName ? ` "${this.callName}"` : ""}${
            this.lastName ? ` ${this.lastName}` : ""
        }`;
    }

    get currentContract() {
        const today = toDateString(new Date());
        return (
            this.contracts?.find((contract) => contract.start <= today && (!contract.end || contract.end > today)) ||
            null
        );
    }

    get firstContract() {
        return this.contracts?.sort((a, b) => (a.start < b.start ? -1 : 1))[0] || null;
    }

    get latestContract() {
        return this.contracts?.sort((a, b) => (!a.end ? -1 : !b.end ? 1 : a.end > b.end ? -1 : 1))[0] || null;
    }

    get active() {
        return this.status !== EmployeeStatus.Retired;
    }

    getAllContractsForRange({ from, to }: DateRange) {
        if (!this.contracts) {
            return [];
        }
        const contracts = this.contracts.sort((a, b) => (a.start < b.start ? -1 : 1));
        return contracts.filter((c) => {
            return c.start < to && (!c.end || c.end > from);
        });
    }

    getContractForRange(params: DateRange) {
        return this.getAllContractsForRange(params)[0] || null;
    }

    getContractForMonth(year: number, month: number) {
        return this.getContractForRange(getRange(toDateString(new Date(year, month, 1)), "month"));
    }

    getContractForDate(date: string) {
        return this.contracts && this.contracts.find((c) => c.start <= date && (!c.end || c.end > date));
    }

    hasPermission(scope: Permission) {
        return this.role <= Role.Owner || this.access?.permissions.includes(scope);
    }

    isBirthDay(date: string | Date) {
        if (!this.birthday) {
            return false;
        }
        const d = date instanceof Date ? date : parseDateString(date);
        const bd = parseDateString(this.birthday);
        return bd && d && bd.getMonth() === d.getMonth() && bd.getDate() === d.getDate();
    }
}

@Entity()
export class Contract {
    constructor(vals: Partial<Contract> = {}) {
        Object.defineProperty(this, "inclusiveEnd", {
            enumerable: true,
            get: () => this.end && dateAdd(this.end, { days: -1 }),
            set: (end: DateString | null) => (this.end = (end && dateAdd(end, { days: 1 })) || null),
        });

        Object.assign(this, vals);
    }

    get defaultSalary() {
        return this.salaries.find((s) => s.positionId === null);
    }

    get fixedWorkDays() {
        return [NominalHoursMode.FixedDays, NominalHoursMode.FixedDaysWithoutHolidays].includes(this.nominalHoursMode);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    employeeId: number;

    @ManyToOne(() => Employee, (emp) => emp.contracts, { onDelete: "CASCADE" })
    employee: Employee;

    @CreateDateColumn()
    @Type(() => Date)
    created: Date;

    @UpdateDateColumn()
    @Type(() => Date)
    updated: Date;

    @Column("date")
    start: DateString;

    @Column({ type: "date", nullable: true })
    end: DateString | null = null;

    @Column({ default: NominalHoursMode.WeekFactor })
    nominalHoursMode: NominalHoursMode = NominalHoursMode.WeekFactor;

    @Column("float", { default: 0 })
    hoursPerWeek: Hours = 0 as Hours;

    @Column("simple-json", { nullable: true })
    hoursPerDay: Hours[] | null;

    @Column({ default: AbsenceMode.Average })
    absenceMode: AbsenceMode;

    @Column("float")
    absenceHours: Hours = 0 as Hours;

    @Column()
    annualTimeSheet: boolean = false;

    @Column({ default: false })
    enableSFNLedger: boolean = false;

    @Column("float")
    sfnAdvance: Euros = 0 as Euros;

    @Column("float")
    christmasBonus: Euros = 0 as Euros;

    @Column("float")
    vacationBonus: Euros = 0 as Euros;

    @Column({ default: EmploymentType.Regular })
    employmentType: EmploymentType = EmploymentType.Regular;

    @Column("float")
    maxSalary: Euros = 0 as Euros;

    @Column("float")
    vacationDays: Days = 0 as Days;

    @Column()
    vacationIncrement: VacationIncrement = VacationIncrement.Monthly;

    @Column("float", { default: 25 })
    bonusNight1: Percent = 25 as Percent;

    @Column("float", { default: 40 })
    bonusNight2: Percent = 40 as Percent;

    @Column("float", { default: 50 })
    bonusSunday: Percent = 50 as Percent;

    @Column("float", { default: 125 })
    bonusHoliday: Percent = 125 as Percent;

    @Column("float", { default: 0 })
    bonusSpecial: Percent = 0 as Percent;

    @Column({ default: true })
    stackBonuses: boolean = true;

    @Column("simple-json", { default: "[]", name: "benefits" })
    benefitsOld: Benefit[] = [];

    @OneToMany(() => Salary, (salary) => salary.contract, { eager: true })
    @Type(() => Salary)
    salaries: Salary[];

    @OneToMany(() => Bonus, (bonus) => bonus.contract, { eager: true })
    @Type(() => Bonus)
    bonuses: Bonus[];

    @OneToMany(() => Benefit, (benefit) => benefit.contract, { eager: true })
    @Type(() => Benefit)
    benefits: Benefit[];

    inclusiveEnd: DateString | null;

    @Column("simple-json", { default: "[]" })
    ignoreIssues: {
        type: IssueType;
        date: DateString;
    }[] = [];

    @Column({ default: "" })
    comment: string = "";

    @Column({ default: false })
    blocked: boolean = false;

    get nominalWeeklyHours(): Hours {
        return [NominalHoursMode.FixedDays, NominalHoursMode.FixedDaysWithoutHolidays].includes(this.nominalHoursMode)
            ? this.hoursPerDay?.reduce((total, day) => add(total, day), 0 as Hours) || (0 as Hours)
            : this.hoursPerWeek || (0 as Hours);
    }

    getSalary(obj: TimeEntry | Position | number | null | undefined) {
        let positionId: number | null = null;
        if (obj instanceof Position) {
            positionId = obj.id;
        } else if (obj instanceof TimeEntry) {
            positionId = obj.position?.id || null;
        } else {
            positionId = obj || null;
        }

        // Try to find position-specific salary first
        return (
            this.salaries.find((salary) => salary.positionId === positionId) ||
            // Otherwise choose default salary (no position attached)
            this.defaultSalary
        );
    }
}

@Entity()
@Index(["contractId", "positionId"], { unique: true })
export class Salary {
    constructor(init: Partial<Salary> = {}) {
        Object.assign(this, init);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column({ nullable: false })
    contractId: number;

    @ManyToOne(() => Contract, (c) => c.salaries, { onDelete: "CASCADE" })
    contract: Contract;

    @Column({ nullable: true })
    positionId: number | null;

    @ManyToOne(() => Position, { nullable: true, primary: true, onDelete: "CASCADE" })
    position: Position | null;

    @Column("float", { default: 0 })
    amount: Euros = 0 as Euros;

    @Column({ default: "hourly" })
    type: "hourly" | "monthly" = "hourly";

    @Column("float")
    commission: Percent = 0 as Percent;

    @Column({ nullable: true })
    accountingSalaryConfigId: number | null;

    @ManyToOne(() => AccountingSalaryConfig, { nullable: true })
    accountingSalaryConfig: AccountingSalaryConfig | null;
}

export enum TimeEntryType {
    Work = "working",
    Sick = "sick",
    Vacation = "vacation",
    ChildSick = "child_sick",
    SickInKUG = "sick_in_kug",
    School = "school",
    HourAdjustment = "hour_adjustment",
    VacationAdjustment = "vacation_adjustment",
    Free = "free",
    CompDay = "compday",
    ResetLedgers = "reset_ledgers",
}

export const absenceTypes = [
    TimeEntryType.Vacation,
    TimeEntryType.Sick,
    TimeEntryType.ChildSick,
    TimeEntryType.SickInKUG,
    TimeEntryType.CompDay,
];

export type AbsenceType = (typeof absenceTypes)[number];

export const timeEntryAbsenceTypes = [
    TimeEntryType.Sick,
    TimeEntryType.Vacation,
    TimeEntryType.ChildSick,
    TimeEntryType.SickInKUG,
    TimeEntryType.CompDay,
] as const;

export function timeEntryTypeColor(type: TimeEntryType) {
    switch (type) {
        case TimeEntryType.Vacation:
            return colors.blue;
        case TimeEntryType.Sick:
        case TimeEntryType.ChildSick:
        case TimeEntryType.SickInKUG:
            return colors.orange;
        case TimeEntryType.School:
            return colors.violet;
        case TimeEntryType.Free:
            return colors.grey;
        case TimeEntryType.CompDay:
            return colors.aqua;
        default:
            return "";
    }
}

export function companyStatusColor(status?: CompanyStatus) {
    switch (status) {
        case CompanyStatus.Trialing:
            return colors.grey;
        case CompanyStatus.Probation:
            return colors.purple;
        case CompanyStatus.Active:
            return colors.green;
        case CompanyStatus.Paused:
            return colors.orange;
        case CompanyStatus.Canceled:
            return colors.red;
        case CompanyStatus.PaymentOverdue:
            return colors.red;
        default:
            return "";
    }
}

export function companyStatusLabel(status?: CompanyStatus) {
    switch (status) {
        case CompanyStatus.Trialing:
            return "Testphase";
        case CompanyStatus.Probation:
            return "Probezeit";
        case CompanyStatus.Active:
            return "Aktiv";
        case CompanyStatus.Paused:
            return "Pausiert";
        case CompanyStatus.Canceled:
            return "Gekündigt";
        case CompanyStatus.PaymentOverdue:
            return "Zahlungsverzug";
        default:
            return "";
    }
}

export function availabilityColor(status: AvailabilityStatus) {
    switch (status) {
        case AvailabilityStatus.Available:
            return colors.green;
        case AvailabilityStatus.Unavailable:
            return colors.red;
        case AvailabilityStatus.Preferred:
            return colors.purple;
        case AvailabilityStatus.Unpreferred:
            return colors.orange;
        default:
            return "";
    }
}

export function availabilityLabel(status: AvailabilityStatus) {
    switch (status) {
        case AvailabilityStatus.Available:
            return "Verfügbar";
        case AvailabilityStatus.Unavailable:
            return "Nicht Verfügbar";
        case AvailabilityStatus.Preferred:
            return "Bevorzugt";
        case AvailabilityStatus.Unpreferred:
            return "Wunschfrei";
        default:
            return "";
    }
}

export function availabilityIcon(status: AvailabilityStatus) {
    switch (status) {
        case AvailabilityStatus.Available:
            return "thumbs-up";
        case AvailabilityStatus.Unavailable:
            return "ban";
        case AvailabilityStatus.Preferred:
            return "heart";
        case AvailabilityStatus.Unpreferred:
            return "couch";
        default:
            return "";
    }
}

export function repeatsLabel(repeats: Repeat) {
    switch (repeats) {
        case "never":
            return "niemals";
        case "weekly":
            return "wöchtentlich";
        case "monthly":
            return "monatlich";
        case "yearly":
            return "jährlich";
    }
}

export type TimeEntryPublishedInfo = {
    time: Date;
    deleted: Date | null;
    employeeId: number | null;
    date: DateString;
    positionId: number | null;
    startPlanned: Date | null;
    endPlanned: Date | null;
    comment?: string;
};

const timeEntryPublishedDateProperties = ["time", "deleted", "startPlanned", "endPlanned"] as const;

class DatePropertiesTransformer<T extends object = object> implements ValueTransformer {
    constructor(public properties: readonly (keyof T)[]) {}

    to(value: T): any {
        if (!value) {
            return value;
        }
        const obj: any = { ...value };
        for (const key of this.properties) {
            if (obj[key] instanceof Date) {
                obj[key] = obj[key].toISOString();
            }
        }
        return obj;
    }

    from(value: unknown): T {
        if (!value || typeof value !== "object") {
            return value as T;
        }
        const info: any = { ...value };
        for (const key of this.properties) {
            if (typeof info[key] === "string") {
                info[key] = new Date(info[key]);
            }
        }
        return info;
    }
}

@Entity()
@Index(["date"], { unique: false })
@Index(["employeeId", "date"], { unique: false })
export class TimeEntry {
    constructor(vals: Partial<TimeEntry> = {}) {
        Object.assign(this, vals);
    }

    @PrimaryColumn()
    id: string;

    @CreateDateColumn()
    @Type(() => Date)
    created: Date;

    @Column()
    @Type(() => Date)
    updated: Date = new Date();

    @Column({ type: Date, nullable: true })
    @Type(() => Date)
    deleted: Date | null = null;

    @Column({
        type: "jsonb",
        nullable: true,
        transformer: new DatePropertiesTransformer(timeEntryPublishedDateProperties),
    })
    @TransformDateProperties(timeEntryPublishedDateProperties)
    published: TimeEntryPublishedInfo | null = null;

    @Column({
        type: "jsonb",
        nullable: true,
        transformer: new DatePropertiesTransformer(timeEntryPublishedDateProperties),
    })
    @TransformDateProperties(timeEntryPublishedDateProperties)
    seen: TimeEntryPublishedInfo | null = null;

    @Column({ type: Date, nullable: true })
    @Type(() => Date)
    taken: Date | null = null;

    @Column({ type: Date, nullable: true })
    @Type(() => Date)
    offered: Date | null = null;

    @ManyToOne(() => Position, { eager: true, nullable: true, onDelete: "SET NULL" })
    @Type(() => Position)
    position: Position | null;

    @Column({ nullable: true })
    positionId: number | null;

    @ManyToOne(() => Employee, { eager: false, nullable: true, onDelete: "CASCADE" })
    @Type(() => Employee)
    employee: Employee | null;

    @Column({ nullable: true })
    employeeId: number | null;

    @Column("date")
    date: DateString;

    @Column("time", { nullable: true })
    startPlannedOld: string | null;

    @Column("time", { nullable: true })
    endPlannedOld: string | null;

    @Column("time", { nullable: true })
    startLoggedOld: string | null;

    @Column("time", { nullable: true })
    endLoggedOld: string | null;

    @Column("time", { nullable: true })
    startFinalOld: string | null;

    @Column("time", { nullable: true })
    endFinalOld: string | null;

    @Column("timestamptz", { nullable: true })
    @Type(() => Date)
    startPlanned: Date | null;

    @Column("timestamptz", { nullable: true })
    @Type(() => Date)
    endPlanned: Date | null;

    @Column("timestamptz", { nullable: true })
    @Type(() => Date)
    startLogged: Date | null;

    @Column("timestamptz", { nullable: true })
    @Type(() => Date)
    endLogged: Date | null;

    @Column("timestamptz", { nullable: true })
    @Type(() => Date)
    startFinal: Date | null;

    @Column("timestamptz", { nullable: true })
    @Type(() => Date)
    endFinal: Date | null;

    @Column({ type: "timestamp", nullable: true })
    @Type(() => Date)
    startBreak: Date | null = null;

    @Column({ type: "float", nullable: true })
    hours: Hours | null = null;

    @Column({ type: "float", nullable: true })
    days: Days | null = null;

    @Column("float", { nullable: true })
    resetBonus: Euros | null = null;

    @Column({ default: "" })
    comment: string = "";

    @Column()
    type: TimeEntryType = TimeEntryType.Work;

    @Column("float", { nullable: true })
    breakPlanned: Hours | null = null;

    @Column("float", { nullable: true })
    breakAuto: Hours | null = null;

    @Column("float", { nullable: true })
    breakLogged: Hours | null = null;

    @Column("float", { nullable: true })
    break: Hours | null = null;

    @Column({ default: 0 })
    mealsBreakfast: Meals = 0 as Meals;

    @Column({ default: 0 })
    mealsLunch: Meals = 0 as Meals;

    @Column({ default: 0 })
    mealsDinner: Meals = 0 as Meals;

    @Column({ default: true })
    paid: boolean = true;

    @Column("simple-json", { default: "[]" })
    ignoreIssues: IssueType[];

    @Column("float", { default: 0 })
    revenue: Euros = 0 as Euros;

    @Column("jsonb", { nullable: true })
    result: TimeResult | null = null;

    @Column("jsonb", { nullable: true })
    resultPlanned: TimeResult | null = null;

    @Column("text", { nullable: true })
    wageType: string | null = null;

    preview?: boolean;

    get planned(): [Date, Date] | null {
        return this.startPlanned && this.endPlanned && [this.startPlanned, this.endPlanned];
    }

    get logged(): [Date, Date] | null {
        return this.startLogged && this.endLogged && [this.startLogged, this.endLogged];
    }

    get final(): [Date, Date] | null {
        return this.startFinal && this.endFinal && [this.startFinal, this.endFinal];
    }

    get start(): Date {
        return this.startFinal || this.startPlanned || new Date(this.date);
    }

    get end(): Date {
        return this.endFinal || this.endPlanned || new Date(dateAdd(this.date, { days: 1 }));
    }

    get durationPlanned(): Hours {
        return ((this.planned && (this.planned[1].getTime() - this.planned[0].getTime()) / 3600 / 1000) || 0) as Hours;
    }

    get durationLogged(): Hours {
        return ((this.logged && (this.logged[1].getTime() - this.logged[0].getTime()) / 3600 / 1000) || 0) as Hours;
    }

    get durationFinal(): Hours {
        return ((this.final && (this.final[1].getTime() - this.final[0].getTime()) / 3600 / 1000) || 0) as Hours;
    }

    get duration(): Hours {
        const interval = this.final || this.planned;
        return ((interval && (interval[1].getTime() - interval[0].getTime()) / 3600 / 1000) || 0) as Hours;
    }

    get status() {
        if (this.type !== TimeEntryType.Work) {
            return "completed";
        }

        if (this.startFinal && this.endFinal) {
            return "completed";
        } else if (this.startBreak) {
            return "paused";
        } else if (this.startFinal) {
            return "ongoing";
        } else {
            return "scheduled";
        }
    }

    timeDisplay(mode: string) {
        switch (mode) {
            case "planned":
                const start = this.startPlanned?.toTimeString().slice(0, 5) || "offen";
                const end = this.endPlanned?.toTimeString().slice(0, 5) || "offen";
                return `${start} - ${end}`;
            case "active":
                return `${this.startFinal!.toTimeString().slice(0, 5)} - offen`;
            case "complete":
                return `${this.startFinal!.toTimeString().slice(0, 5)} - ${this.endFinal!.toTimeString().slice(0, 5)}`;
        }
    }

    get info() {
        return {
            date: this.date,
            employeeId: this.employeeId,
            positionId: this.position?.id || null,
            startPlanned: this.startPlanned,
            endPlanned: this.endPlanned,
            deleted: this.deleted || null,
            comment: this.comment,
        };
    }

    matchesInfo({ date, employeeId, positionId, startPlanned, endPlanned, deleted, comment }: TimeEntryPublishedInfo) {
        return (
            this.date === date &&
            this.employeeId === employeeId &&
            this.positionId === positionId &&
            this.startPlanned?.getTime() === startPlanned?.getTime() &&
            this.endPlanned?.getTime() === endPlanned?.getTime() &&
            Boolean(this.deleted) === Boolean(deleted) &&
            this.comment === comment
        );
    }

    getChangedProps(info: TimeEntryPublishedInfo | null = this.published): Set<keyof TimeEntryPublishedInfo> {
        const changes = new Set<keyof TimeEntryPublishedInfo>();

        if (!info) {
            return changes;
        }

        for (const prop of ["date", "employeeId", "positionId", "comment"] as const) {
            if (this[prop] !== info[prop]) {
                changes.add(prop);
            }
        }

        if (this.startPlanned?.getTime() !== info.startPlanned?.getTime()) {
            changes.add("startPlanned");
        }

        if (this.endPlanned?.getTime() !== info.endPlanned?.getTime()) {
            changes.add("endPlanned");
        }

        if (Boolean(this.deleted) !== Boolean(info.deleted)) {
            changes.add("deleted");
        }

        return changes;
    }

    get isSeen() {
        return this.published && this.seen && this.matchesInfo(this.seen);
    }

    setSeen() {
        this.seen = {
            ...this.info,
            time: new Date(),
        };
    }

    get isPublished() {
        return this.published && this.matchesInfo(this.published);
    }

    setPublished() {
        this.published = {
            ...this.info,
            time: new Date(),
        };
        return this;
    }

    get isPast() {
        return this.date < toDateString(new Date());
    }

    isWithin({ from, to, fromRule, toRule }: TimeFilter) {
        if (this.type !== TimeEntryType.Work) {
            return true;
        }

        const [start, end] = parseTimes(this.date, from, to);
        const [startPlanned, endPlanned] = [this.startPlanned, this.endPlanned];

        let startOk = false;
        let endOk = false;

        switch (fromRule) {
            case "equal":
                startOk = !start || (!!startPlanned && start.getTime() === startPlanned.getTime());
                break;
            case "open":
                startOk = !start || !endPlanned || endPlanned > start;
                break;
            default:
                startOk = !start || ((!startPlanned || startPlanned >= start) && (!endPlanned || endPlanned > start));
                break;
        }

        switch (toRule) {
            case "equal":
                endOk = !end || (!!endPlanned && end.getTime() === endPlanned.getTime());
                break;
            case "open":
                endOk = !end || !startPlanned || startPlanned < end;
                break;
            default:
                endOk = !end || ((!endPlanned || endPlanned <= end) && (!startPlanned || startPlanned < end));
                break;
        }

        return startOk && endOk;
    }

    overlapsWith(entry: TimeEntry) {
        return entry.planned && this.planned && overlap(this.planned, [entry.planned]) > 0;
    }
}

@Entity()
export class PublishedRoster {
    @PrimaryColumn()
    id: string;

    @CreateDateColumn()
    @Type(() => Date)
    created: Date;

    @Column("date")
    from: DateString;

    @Column("date")
    to: DateString;

    @Type(() => Venue)
    @ManyToOne(() => Venue, { eager: true, onDelete: "CASCADE" })
    venue: Venue;
}

@Entity()
export class RosterTemplate {
    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    name: string;

    @CreateDateColumn()
    @Type(() => Date)
    created: Date;

    @Type(() => Venue)
    @ManyToOne(() => Venue, (v) => v.rosterTemplates, { onDelete: "CASCADE" })
    venue: Venue;

    @Column("simple-json")
    shifts: {
        employee: number | null;
        position: number;
        day: number;
        start: string;
        end: string;
        break?: Hours | null;
    }[];
}

export enum TaxKey {
    None = 0,
    Free = 1,
    Sales7 = 2,
    Sales19 = 3,
    Input7 = 8,
    Input19 = 9,
    Input9_5 = 408,
    // Sales5 = 11,
    // Sales16 = 12,
    // Input5 = 13,
    // Input16 = 14
}

// @ts-ignore
typeof window !== "undefined" && (window.TaxKey = TaxKey);

export function taxKeyLabel(key: TaxKey, date?: string) {
    const reduced = date && date >= "2020-07-01" && date < "2021-01-01";
    switch (key) {
        case TaxKey.None:
            return "";
        case TaxKey.Free:
            return "Steuerfrei";
        case TaxKey.Sales7:
            return date ? (reduced ? "USt. 5%" : "USt. 7%") : "Ust. 5/7%";
        case TaxKey.Sales19:
            return date ? (reduced ? "USt. 16%" : "USt. 19%") : "Ust. 16/19%";
        case TaxKey.Input7:
            return date ? (reduced ? "VSt. 5%" : "VSt. 7%") : "VSt. 5/7%";
        case TaxKey.Input19:
            return date ? (reduced ? "VSt. 16%" : "VSt. 19%") : "VSt. 16/19%";
        case TaxKey.Input9_5:
            return date ? (date >= "2022-01-01" ? "VSt. 9,5%" : "VSt. 10,7%") : "VSt. 9,5/10,7%";
        default:
            return "";
    }
}

export function taxKeyValue(key: TaxKey, date?: string) {
    const reduced = date && date >= "2020-07-01" && date < "2021-01-01";
    switch (key) {
        case TaxKey.None:
        case TaxKey.Free:
            return 0;
        case TaxKey.Sales7:
        case TaxKey.Input7:
            return reduced ? 5 : 7;
        case TaxKey.Sales19:
        case TaxKey.Input19:
            return reduced ? 16 : 19;
        case TaxKey.Input9_5:
            return date && date >= "2022-01-01" ? 9.5 : 10.7;
        default:
            return 0;
    }
}

export function revenueTypeLabel(type: RevenueType) {
    switch (type) {
        case RevenueType.Sales:
            return "Einnahme";
        case RevenueType.Expense:
            return "Ausgabe";
        case RevenueType.PayAdvance:
            return "Gehaltsvorschuss";
        case RevenueType.BankDeposit:
            return "Bankeinzahlung";
        case RevenueType.CashCount:
            return "Kassenzählung";
        case RevenueType.Debt:
            return "Rechnung";
        case RevenueType.Adjustment:
            return "Ausgleichsbuchung";
        case RevenueType.Cashless:
            return "Bargeldlose Zahlung";
        case RevenueType.Other:
            return "Sonstiges";
    }
}

export function revenueTypeIcon(type: RevenueType) {
    switch (type) {
        case RevenueType.Sales:
            return "money-bill-wave";
        case RevenueType.Expense:
            return "shopping-cart";
        case RevenueType.PayAdvance:
            return "hand-holding-usd";
        case RevenueType.BankDeposit:
            return "piggy-bank";
        case RevenueType.CashCount:
            return "coins";
        case RevenueType.Debt:
            return "file-invoice";
        case RevenueType.Adjustment:
            return "balance-scale";
        case RevenueType.Cashless:
            return "credit-card";
        case RevenueType.Other:
            return "euro-sign";
        default:
            return "list";
    }
}

export enum RevenueType {
    Sales = "sales",
    Expense = "expense",
    Cashless = "cashless",
    Debt = "debt",
    Deposit = "deposit",
    PayAdvance = "advance",
    CashCount = "cash_count",
    Adjustment = "adjustment",
    BankDeposit = "bank_deposit",
    Other = "other",
}

export interface CountingLog {
    account: number;
    countedBy: string;
    count: { value: Euros; amount: Euros }[];
}

@Entity()
export class RevenueGroup {
    constructor(vals?: Partial<RevenueGroup>) {
        if (vals) {
            Object.assign(this, vals);
        }
    }

    @PrimaryGeneratedColumn()
    id: number;

    @CreateDateColumn()
    @Type(() => Date)
    created: Date;

    @UpdateDateColumn()
    @Type(() => Date)
    updated: Date;

    @Column()
    venueId: number;

    @ManyToOne(() => Venue, { eager: false, onDelete: "CASCADE" })
    venue: Venue;

    @Column()
    type: RevenueType = RevenueType.Other;

    @Column()
    name: string = "";

    @Column()
    taxKey: TaxKey = TaxKey.None;

    @Column()
    ledger: string = "";

    @Column()
    costCenter: string = "";

    @Column("boolean", { default: true })
    cashbook: boolean = true;

    @Column("boolean", { default: true })
    reporting: boolean = true;

    @Column("boolean", { default: true })
    daily: boolean = true;

    @Column("simple-json", { default: "[]" })
    attributions: { department: number; ratio: number }[] = [];

    @Column("int", { nullable: true })
    order: number | null = null;

    @Column({ default: "" })
    postingKey: string = "";

    lastUsed?: string;

    count: number = 0;

    amount: Euros = 0 as Euros;

    createEntry() {
        return new RevenueEntry({
            type: this.type,
            venueId: this.venueId,
            name: this.name,
            taxKey: this.taxKey,
            postingKey: this.postingKey,
            ledger: this.ledger,
            costCenter: this.costCenter,
            groupId: this.id,
            group: this,
        });
    }
}

@Entity()
@Index(["venueId", "date"], { unique: false })
export class RevenueEntry {
    constructor(vals?: Partial<RevenueEntry>) {
        if (vals) {
            Object.assign(this, vals);
        }
    }

    get text() {
        return this.name;
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    name: string = "";

    @Column()
    type: RevenueType = RevenueType.Other;

    @Column("int", { nullable: true })
    sequence: number | null;

    @Column("int", { nullable: true })
    subSequence: number | null;

    @Column("date")
    date: DateString;

    @CreateDateColumn()
    @Type(() => Date)
    created: Date;

    @UpdateDateColumn()
    @Type(() => Date)
    updated: Date;

    @Column("float")
    amount: Euros = 0 as Euros;

    @Column("float")
    tip: Euros = 0 as Euros;

    @Column("float")
    cashBalance: Euros = 0 as Euros;

    @Column()
    taxKey: TaxKey = TaxKey.None;

    @Column({ default: "" })
    postingKey: string = "";

    @Column()
    ledger: string = "";

    @Column()
    costCenter: string = "";

    @Column()
    receipt: string = "";

    @Column()
    invoice: string = "";

    @Column()
    venueId: number;

    @ManyToOne(() => Venue, { onDelete: "CASCADE" })
    venue: Venue;

    @Column()
    daily: boolean = false;

    @Column({ default: false })
    draft: boolean = false;

    @Column({ default: false })
    readonly: boolean = false;

    @Column({ nullable: true })
    employeeId: number;

    @ManyToOne(() => Employee, { nullable: true, onDelete: "SET NULL" })
    employee: Employee | null;

    @Column("simple-json", { nullable: true })
    countingLog: CountingLog | null = null;

    @Column("boolean", { nullable: true })
    paid: boolean | null = null;

    @Column("boolean", { default: true })
    cashbook: boolean = true;

    @Column("boolean", { default: true })
    reporting: boolean = true;

    @Column({ nullable: true })
    groupId: number | null;

    @ManyToOne(() => RevenueGroup, { nullable: true, eager: true })
    group?: RevenueGroup | null;

    randomId?: number;

    get cash(): Euros {
        switch (this.type) {
            case RevenueType.CashCount:
                return 0 as Euros;
            case RevenueType.Debt:
                return add(this.amount, this.tip);
            // case RevenueType.Expense:
            // case RevenueType.Cashless:
            // case RevenueType.Debt:
            // case RevenueType.SalaryAdvance:
            //     return -this.amount;
            default:
                return this.amount;
        }
    }

    get revenue(): Euros {
        switch (this.type) {
            case RevenueType.Sales:
            case RevenueType.Expense:
                return this.amount;
            default:
                return 0 as Euros;
        }
    }

    get attributions() {
        return (this.group && this.group.attributions) || [];
    }

    clone() {
        return new RevenueEntry({
            type: this.type,
            venueId: this.venueId,
            name: this.name,
            taxKey: this.taxKey,
            ledger: this.ledger,
            costCenter: this.costCenter,
            groupId: this.groupId,
        });
    }
}

@Entity()
export class RevenuePrediction {
    constructor(vals?: Partial<RevenuePrediction>) {
        if (vals) {
            Object.assign(this, vals);
        }
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column("date")
    date: DateString;

    @CreateDateColumn()
    @Type(() => Date)
    created: Date;

    @UpdateDateColumn()
    @Type(() => Date)
    updated: Date;

    @Column("float")
    amount: Euros = 0 as Euros;

    @Column()
    groupId: number;

    @ManyToOne(() => RevenueGroup, { onDelete: "CASCADE", eager: true })
    group: RevenueGroup;

    get attributions() {
        return (this.group && this.group.attributions) || [];
    }
}

@Entity()
@Index(["departmentId", "date"], { unique: true })
export class RosterTargets {
    @PrimaryGeneratedColumn()
    id?: number;

    @Column()
    departmentId: number;

    @ManyToOne(() => Department, { eager: false, onDelete: "CASCADE" })
    department: Department;

    @Column({ type: "date" })
    date: DateString;

    @Column("float", { nullable: true })
    revenue: Euros | null = null;

    @Column("float", { nullable: true })
    productivity: Rate<Euros, Hours> | null = null;

    @Column("float", { nullable: true })
    hours: Hours | null = null;

    @Column({ default: false })
    default: boolean = false;

    get weekDay() {
        return parseDateString(this.date)!.getDay();
    }
}

export class TaskTiming {
    time = new Date();
    task: string;
    duration?: Milliseconds;
    timing?: TaskTiming[];
    note?: string;

    private _currentStep?: TaskTiming;

    constructor(props: Partial<TaskTiming> = {}) {
        Object.assign(this, props);
    }

    start(task: string) {
        const timing = new TaskTiming({
            task,
        });

        if (!this.timing) {
            this.timing = [];
        }

        this.timing.push(timing);

        return timing;
    }

    step(task: string) {
        if (this._currentStep) {
            this._currentStep.done();
        }

        const step = (this._currentStep = this.start(task));
        return step;
    }

    done(note?: string) {
        if (this._currentStep) {
            this._currentStep.done();
        }
        this.note = note;
        this.duration = (Date.now() - this.time.getTime()) as Milliseconds;
    }

    toJSON() {
        return {
            time: this.time,
            task: this.task,
            duration: this.duration,
            timing: this.timing,
            note: this.note,
        };
    }
}

@Entity()
@Index(["time"], { unique: false })
export class RequestLog extends TaskTiming {
    constructor(req?: Request) {
        super();
        if (req) {
            this.task = req.method;
            this.version = getVersion();
            this.params = (req.params || []).map((p) => {
                p = { ...p };
                for (const key of Object.keys(p)) {
                    if (key.toLowerCase().includes("password")) {
                        p[key] = "[redacted]";
                    }
                }
                return p;
            });
            this.clientInfo = req.clientInfo;
            this.requestId = req.id;
        }
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    time: Date;

    @Column()
    version: string;

    @Column()
    task: string;

    @Column("jsonb")
    params: any;

    @Column("jsonb", { nullable: true })
    clientInfo?: ClientInfo;

    @Column("jsonb", { nullable: true })
    error?: { code: string; message: string };

    @Index()
    @ManyToOne(() => Session, { nullable: true, onDelete: "CASCADE" })
    session?: Session;

    @Column({ nullable: true })
    sessionId?: number;

    @Column({ nullable: true })
    requestId?: string;

    @Column({ type: "float" })
    duration: Milliseconds;

    @Column("jsonb")
    timing: TaskTiming[] = [];
}

@Entity()
export class MonthlyStatement {
    @ManyToOne(() => Employee, { primary: true, onDelete: "CASCADE", eager: false })
    employee: Employee;

    @PrimaryColumn()
    employeeId: number;

    @PrimaryColumn()
    year: number;

    @PrimaryColumn()
    month: number;

    @Column()
    @Type(() => Date)
    updated: Date = new Date();

    @Column()
    version: string = getVersion();

    @Column("jsonb", {
        nullable: true,
    })
    reset: Partial<TimeEntry> | null = null;

    @Column("jsonb")
    time: TimeStatement = newTimeStatement();

    @Column("jsonb")
    vacation: VacationStatement = newVacationStatement();

    @Column("jsonb")
    bonus: BonusStatement = newBonusStatement();

    @Column("jsonb")
    cost: CostStatement = newCostStatement();

    @Column("jsonb", { select: true })
    items: TimeResultItem[] = [];

    @Column("jsonb")
    @Type(() => Issue)
    issues: Issue[] = [];

    @Column("jsonb", { nullable: true })
    previousAverages?: PreviousAverages;

    get from() {
        return toDateString(new Date(this.year, this.month, 1));
    }

    get to() {
        return toDateString(new Date(this.year, this.month + 1, 1));
    }

    constructor(vals: Partial<MonthlyStatement> = {}) {
        Object.assign(this, vals);
    }
}

export enum AbsenceStatus {
    Requested = "requested",
    Approved = "approved",
    Denied = "denied",
    Inferred = "inferred",
}

@Entity()
export class Absence {
    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    type: TimeEntryType;

    @CreateDateColumn()
    @Type(() => Date)
    created: Date;

    @UpdateDateColumn()
    @Type(() => Date)
    updated: Date;

    @Column()
    employeeId: number;

    @ManyToOne(() => Employee, { onDelete: "CASCADE" })
    employee: Employee;

    @Column("date")
    start: DateString;

    @Column("date")
    end: DateString;

    @Column({ default: "" })
    notes: string;

    @Column()
    status: AbsenceStatus;

    @Column("jsonb", { nullable: true })
    request: {
        time: Date;
        message: string;
    } | null = null;

    @Column("jsonb", { nullable: true })
    response: {
        employeeId: number;
        time: Date;
        message: string;
    } | null = null;

    entries?: { id: string; date: DateString }[];
    dayCount?: number;

    constructor(vals: Partial<Absence> = {}) {
        Object.assign(this, vals);
    }
}

@Entity()
export class RosterNote {
    constructor(vals: Partial<RosterNote> = {}) {
        Object.assign(this, vals);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    text: string;

    @Column("date")
    start: DateString;

    @Column("date")
    end: DateString;

    @ManyToOne(() => Venue, { onDelete: "CASCADE" })
    venue: Venue;

    @Column()
    venueId: number;

    @Column({ type: "text", nullable: true })
    color: string | null = null;

    @Column("simple-array", { nullable: true })
    departments?: string[];

    get filters(): EntityFilter[] {
        return this.departments
            ? this.departments.map((d) => ({ type: "department", value: Number(d) }))
            : [{ type: "venue", value: this.venueId }];
    }
}

export class GetEmployeeStatementsParams {
    constructor(vals: Partial<GetEmployeeStatementsParams> = {}) {
        Object.assign(this, vals);
    }

    @Type(() => Company)
    company: Company;

    @Type(() => Employee)
    employee: Employee;

    calcFrom: DateString | null;

    cached: unknown[];

    @Type(() => TimeEntry)
    entries: TimeEntry[];

    @Type(() => TimeEntry)
    vacationEntries: TimeEntry[];

    to: string;
}

@Entity()
export class CompanyLifeCycleEvent {
    constructor(init: Partial<CompanyLifeCycleEvent> = {}) {
        Object.assign(this, init);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @ManyToOne(() => Company, (company) => company.lifeCycleEvents, {
        eager: false,
        nullable: false,
        onDelete: "CASCADE",
    })
    company: Company;

    @Column({ nullable: false })
    companyId: number;

    @Column()
    comment: string;

    @CreateDateColumn()
    @Type(() => Date)
    created: Date;

    @Column()
    createdBy: string;

    @Column({ nullable: true })
    status?: CompanyStatus;

    @Column("date")
    date: DateString;
}

export enum AvailabilityStatus {
    Available = "available",
    Unavailable = "unavailable",
    Preferred = "preferred",
    Unpreferred = "unpreferred",
}

export enum Repeat {
    Never = "never",
    Weekly = "weekly",
    Monthly = "monthly",
    Yearly = "yearly",
}

@Entity()
export class Availability {
    constructor(init: Partial<Availability> = {}) {
        Object.assign(this, init);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    employeeId: number;

    @ManyToOne(() => Employee, { onDelete: "CASCADE" })
    employee: Employee;

    @Column("date")
    date: DateString;

    @Column("time", { nullable: true })
    start: string | null = null;

    @Column("time", { nullable: true })
    end: string | null = null;

    @Column({ default: AvailabilityStatus.Available })
    status: AvailabilityStatus = AvailabilityStatus.Available;

    @Column({ default: "" })
    comment: string = "";

    @Column({ default: Repeat.Never })
    repeats: Repeat = Repeat.Never;

    get icon() {
        return availabilityIcon(this.status);
    }

    get timeLabel() {
        return this.start && this.end
            ? `${this.start.slice(0, 5)} - ${this.end.slice(0, 5)}`
            : this.start
              ? `ab ${this.start.slice(0, 5)}`
              : this.end
                ? `bis ${this.end.slice(0, 5)}`
                : "";
    }

    get label() {
        return this.timeLabel || availabilityLabel(this.status);
    }

    get fullLabel() {
        return `${availabilityLabel(this.status)} ${this.timeLabel}`;
    }

    get color() {
        return availabilityColor(this.status);
    }

    get repeatsLabel() {
        return repeatsLabel(this.repeats);
    }
}

export interface FormCondition {
    field: string;
    value: string | boolean | number;
}

export interface FormSection {
    title?: string;
    description?: string;
    fields: FormField[];
    condition?: FormCondition;
}

export type FormTableHeaders = Pick<FormField, "type" | "name" | "label" | "required">;

export interface FormField {
    type?: "string" | "number" | "date" | "boolean" | "table";
    label: string;
    required?: boolean;
    name: string;
    value?: string | number | boolean;
    options?: { label: string; value: string | number }[];
    description?: string;
    mapsTo?: keyof Employee & string;
    pattern?: string;
    managerOnly?: boolean;
    condition?: FormCondition;
    min?: string;
    max?: string;
    step?: string;
    headers?: FormTableHeaders[];
    data?: FormField[][];
}

export enum FormStatus {
    Draft = "draft",
    InProgress = "in_progress",
    Completed = "completed",
    Finalized = "finalized",
}

export function formStatusColor(status: FormStatus) {
    switch (status) {
        case FormStatus.Draft:
            return colors.grey;
        case FormStatus.InProgress:
            return colors.teal;
        case FormStatus.Completed:
            return colors.purple;
        case FormStatus.Finalized:
            return colors.green;
    }
}

export function formStatusLabel(status: FormStatus) {
    switch (status) {
        case FormStatus.Draft:
            return "Entwurf";
        case FormStatus.InProgress:
            return "In Bearbeitung";
        case FormStatus.Completed:
            return "Ausgefüllt";
        case FormStatus.Finalized:
            return "Fertiggestellt";
    }
}

export interface FormSignature {
    employeeId?: number;
    name: string;
    time: string;
    src: string;
    info?: {
        firstName: string;
        lastName: string;
        address: string;
        email: string;
        phone: string;
    };
}

@Entity()
export class Form {
    constructor(init: Partial<Form> = {}) {
        Object.assign(this, init);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    name: string;

    @Column()
    description: string = "";

    @ManyToOne(() => Company)
    company: Company;

    @Column()
    companyId: number;

    @Column("jsonb")
    sections: FormSection[];

    @Column()
    status: FormStatus = FormStatus.Draft;

    @Column()
    requiresEmployeeSignature: boolean = false;

    @Column()
    requiresManagerSignature: boolean = false;

    @Column()
    considerCaretakerSignatureIfUnderage: boolean = false;

    @Column({ default: false })
    autoFinalize: boolean = false;

    @Column("jsonb", { nullable: true })
    managerSignature?: FormSignature;

    @Column("jsonb", { nullable: true })
    caretakerSignature?: FormSignature;

    @Column("jsonb", { nullable: true })
    employeeSignature?: FormSignature;

    get fields() {
        return this.sections?.flatMap((section) => section.fields) || [];
    }

    checkCondition(condition?: FormCondition) {
        if (!condition) {
            return true;
        }

        const field = this.fields.find((field) => field.name === condition.field);
        return field?.value === condition.value;
    }

    prefillForEmployee(employee: Employee) {
        for (const field of this.fields) {
            if (field.mapsTo) {
                const existing = employee[field.mapsTo];
                if (!existing) {
                    continue;
                }

                if (field.options && field.type === "string") {
                    const value = String(employee[field.mapsTo]);
                    const mapped = field.options.find((o) => o.value === value);
                    if (mapped) {
                        field.value = value;
                    }
                    continue;
                }

                switch (field.type) {
                    case "string":
                        field.value = String(employee[field.mapsTo]);
                        break;
                    case "date": {
                        const value = String(employee[field.mapsTo]);
                        if (parseDateString(value)) {
                            field.value = value;
                        }
                        break;
                    }
                    case "number":
                        field.value = Number(employee[field.mapsTo]);
                        break;
                    case "boolean":
                        field.value = Boolean(employee[field.mapsTo]);
                        break;
                }
            }
        }
    }

    getHumanReadableValue(field: FormField) {
        switch (field.type) {
            case "date":
                return formatDate(field.value as string);
            case "boolean":
                return field.value ? "Ja" : "Nein";
            default:
                return field.options?.find((o) => o.value === field.value)?.label || field.value;
        }
    }

    get requiresCaretakerSignature(): boolean {
        if (!this.considerCaretakerSignatureIfUnderage) {
            return false;
        }

        const birthdayStr = this.fields.find((field) => field.name === "birthday")?.value;
        //FIXME: somewhere null is assigned as default value
        if (!birthdayStr || typeof birthdayStr !== "string" || birthdayStr === "null") {
            return false;
        }

        return !isAdult(new Date(birthdayStr), new Date());
    }
}

export enum MimeType {
    PDF = "application/pdf",
    PNG = "image/png",
    JPG = "image/jpeg",
    CSV = "text/csv",
    TXT = "text/plain",
    HTML = "text/html",
    XML = "text/xml",
    MSWordLegacy = "application/msword",
    MSExcelLegacy = "application/vnd.ms-excel",
    MSWord = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    MSExcel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    Zip = "application/zip",
}

export function fileIcon(type: MimeType) {
    switch (type) {
        case MimeType.PDF:
            return "file-pdf";
        case MimeType.TXT:
            return "file-text";
        case MimeType.CSV:
            return "file-csv";
        case MimeType.MSExcel:
        case MimeType.MSExcelLegacy:
            return "file-spreadsheet";
        case MimeType.MSWord:
        case MimeType.MSWordLegacy:
            return "file-word";
        case MimeType.PNG:
        case MimeType.JPG:
            return "file-image";
        case MimeType.Zip:
            return "file-zipper";
        default:
            return "file";
    }
}

export function fileExtension(type?: MimeType) {
    switch (type) {
        case MimeType.PDF:
            return "pdf";
        case MimeType.TXT:
            return "txt";
        case MimeType.CSV:
            return "csv";
        case MimeType.MSExcel:
            return "xlsx";
        case MimeType.MSExcelLegacy:
            return "xls";
        case MimeType.MSWord:
            return "docx";
        case MimeType.MSWordLegacy:
            return "doc";
        case MimeType.PNG:
            return "png";
        case MimeType.JPG:
            return "jpg";
        case MimeType.Zip:
            return "zip";
        case MimeType.HTML:
            return "html";
        default:
            return "file";
    }
}

@Entity()
export class Document {
    constructor(init: Partial<Document> = {}) {
        Object.assign(this, init);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    uuid: string;

    @Column()
    @Index()
    companyId: number;

    @ManyToOne(() => Company, { onDelete: "CASCADE" })
    company: Company;

    @Column()
    employeeId: number;

    @ManyToOne(() => Employee, { onDelete: "CASCADE" })
    employee: Employee;

    @CreateDateColumn()
    @Type(() => Date)
    created: Date;

    @UpdateDateColumn()
    @Type(() => Date)
    updated: Date;

    @Column({ nullable: true })
    createdById?: number;

    @ManyToOne(() => Employee, { onDelete: "SET NULL", nullable: true })
    createdBy?: Employee;

    @Column()
    name: string;

    @Column()
    type: MimeType;

    @Column()
    comment: string;

    @Column("date")
    date: DateString;

    @ManyToMany(() => DocumentTag, (tag) => tag.documents, { eager: true })
    @JoinTable()
    @Type(() => DocumentTag)
    tags: DocumentTag[];

    @Column({ nullable: true })
    url?: string;

    @Type(() => Form)
    @OneToOne(() => Form, { nullable: true, eager: true, onDelete: "SET NULL" })
    @JoinColumn()
    form: Form | null = null;
}

@Entity()
export class DocumentTag {
    constructor(init: Partial<DocumentTag> = {}) {
        Object.assign(this, init);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    name: string;

    @Column()
    color: string;

    @ManyToOne(() => Company, { onDelete: "CASCADE", eager: false })
    company?: Company;

    @Column()
    @Index()
    companyId: number;

    @ManyToMany(() => Document, (tag) => tag.tags, { eager: false })
    documents: Document[];

    @Column({ default: 0 })
    order: number = 0;
}

@Entity()
export class EmployeeTag {
    constructor(init: Partial<EmployeeTag> = {}) {
        Object.assign(this, init);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    name: string;

    @Column()
    color: string;

    @ManyToOne(() => Company, { onDelete: "CASCADE", eager: false })
    company?: Company;

    @Column()
    @Index()
    companyId: number;

    @ManyToMany(() => Employee, (employee) => employee.tags, { eager: false })
    employees: Employee[];

    @Column({ default: 0 })
    order: number = 0;
}

@Entity()
export class JobPosting {
    constructor(init: Partial<JobPosting> = {}) {
        Object.assign(this, init);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    @Index({ unique: true })
    uuid: string;

    @Column()
    title: string = "";

    @Column()
    description: string = "";

    @ManyToOne(() => Company, { onDelete: "CASCADE", eager: false })
    company: Company;

    @Column()
    @Index()
    companyId: number;

    @ManyToOne(() => Venue, { onDelete: "CASCADE", eager: false })
    venue: Venue;

    @Column()
    venueId: number;

    @Type(() => Position)
    @ManyToMany(() => Position, (position) => position.jobPostings, { eager: true })
    @JoinTable()
    positions: Position[];

    @Column()
    payType: "monthly" | "hourly" = "hourly";

    @Column("float", { nullable: true })
    payAmount?: Euros;

    @Column("date", { nullable: true })
    start: DateString | null = null;

    @Column("date", { nullable: true })
    end: DateString | null = null;

    @Column()
    employmentType: EmploymentType = EmploymentType.Regular;

    @Column("float", { nullable: true })
    hoursPerWeek: Hours | null;

    @Type(() => JobApplication)
    @OneToMany(() => JobApplication, (v) => v.posting, { eager: true })
    applications: JobApplication[];

    @Column("jsonb")
    images: string[] = [];
}

@Entity()
export class JobApplication {
    constructor(init: Partial<JobApplication> = {}) {
        Object.assign(this, init);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @ManyToOne(() => JobPosting, { onDelete: "CASCADE", eager: false })
    posting: JobPosting;

    @Column()
    postingId: number;

    @Column({ default: "" })
    firstName: string = "";

    @Column({ default: "" })
    lastName: string = "";

    @Column({ default: "" })
    address: string = "";

    @Column({ default: "" })
    city: string = "";

    @Column({ default: "" })
    postalCode: string = "";

    @Column({ default: "" })
    email: string = "";

    @Column({ default: "" })
    phone: string = "";

    @Column({ type: "date", nullable: true })
    birthday: DateString | null;

    @Column({ default: "" })
    notes: string = "";

    @Column({ default: "" })
    headShot: string = "";

    @Column("date", { nullable: true })
    availableFrom: DateString | null;

    @CreateDateColumn()
    @Type(() => Date)
    created: Date;

    @Column({ default: "" })
    message: string = "";
}

export enum BonusTypeMode {
    Daily = "daily",
    WeekDays = "weekdays",
    Date = "date",
    Holidays = "holidays",
}

@Entity()
@Index(["subjectId", "objectId"], { unique: true })
export class BonusTypeOverride {
    constructor(init: Partial<BonusTypeOverride> = {}) {
        Object.assign(this, init);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    @Index()
    subjectId: number;

    @ManyToOne("BonusType", { onDelete: "CASCADE", eager: false })
    subject: any;

    @Column()
    objectId: number;

    @ManyToOne("BonusType", { onDelete: "CASCADE", eager: false })
    object: any;
}

export enum BonusLegalType {
    Night1 = "night1",
    Night2 = "night2",
    Sunday = "sunday",
    Holiday = "holiday",
    Special = "special",
}

export enum BonusCompType {
    ModifiedHourlyRate = "modified_hourly_rate",
    FixedHourlyRate = "fixed_hourly_rate",
    FixedAmount = "fixed_amount",
}

@Entity()
export class BonusType {
    constructor(init: Partial<BonusType> = {}) {
        Object.assign(this, init);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @ManyToOne(() => Company, { onDelete: "CASCADE", eager: false })
    company: Company;

    @Column()
    @Index()
    companyId: number;

    @Column()
    mode: BonusTypeMode = BonusTypeMode.Daily;

    @Column()
    name: string = "";

    @Column("text", { nullable: true })
    date: string | null = null;

    @Column("jsonb", { nullable: true })
    weekDays: number[] | null = null;

    @Column("jsonb", { nullable: true })
    holidays: HolidayName[] | null = null;

    @Column("jsonb", { default: "[]" })
    intervals: [string, string][] = [["00:00", "24:00"]];

    @Column("float", { nullable: true })
    minDuration: Hours | null = null;

    @Column("float", { nullable: true })
    minDurationPercent: Percent | null = null;

    @Column("time", { nullable: true })
    shiftMustStartBefore: string | null = null;

    @Column("boolean", { default: true })
    taxFree: boolean = true;

    @Column("float", { default: 0 })
    taxFreeUpToRate: Rate<Euros, Hours>;

    @Column()
    continuedPay: boolean = false;

    @Column({ default: BonusCompType.ModifiedHourlyRate })
    compType: BonusCompType = BonusCompType.ModifiedHourlyRate;

    @Column("float", { default: 0 })
    maxPercent: Percent;

    @Column("float", { default: 0 })
    defaultPercent: Percent;

    @Column("float", { default: 0 })
    fixedHourlyRateMax: Rate<Euros, Hours>;

    @Column("float", { default: 0 })
    fixedHourlyRateDefault: Rate<Euros, Hours>;

    @Column("float", { default: 0 })
    fixedAmountMax: Euros;

    @Column("float", { default: 0 })
    fixedAmountDefault: Euros;

    @OneToMany(() => BonusTypeOverride, (override) => override.subject, { eager: true, cascade: true })
    overrides: BonusTypeOverride[];

    @Column({ default: false })
    archived: boolean = false;

    @Column({ default: 0 })
    order: number = 0;

    @Column({ nullable: true })
    legalType?: BonusLegalType;

    matchesDate(date: DateString, country: "DE" | "AT") {
        switch (this.mode) {
            case BonusTypeMode.Daily:
                return true;
            case BonusTypeMode.Date:
                return date.endsWith(this.date!);
            case BonusTypeMode.Holidays:
                return Boolean(getHolidayForDate(date, { holidays: this.holidays || undefined, country }));
            case BonusTypeMode.WeekDays:
                return this.weekDays?.includes(new Date(date).getDay());
        }
    }
}

@Entity()
export class Bonus {
    constructor(init: Partial<Bonus> = {}) {
        Object.assign(this, init);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column({ nullable: false })
    contractId: number;

    @ManyToOne(() => Contract, (c) => c.salaries, { onDelete: "CASCADE" })
    contract: Contract;

    @Column()
    typeId: number;

    @ManyToOne(() => BonusType, { onDelete: "CASCADE" })
    type: BonusType;

    @Column("float")
    percent: Percent;

    @Column("float", { nullable: true })
    hourlyRate?: Rate<Euros, Hours>;

    @Column("float", { nullable: true })
    fixedAmount?: Euros;

    @Column({ nullable: true })
    positionId: number | null;

    @ManyToOne(() => Position, { nullable: true, onDelete: "CASCADE" })
    position: Position | null;
}

@Entity()
@Index(["accountingSalaryConfigId", "bonusTypeId"], { unique: true })
export class AccountingSalaryConfigBonus {
    constructor(init: Partial<AccountingSalaryConfigBonus> = {}) {
        Object.assign(this, init);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    accountingSalaryConfigId: number;

    @ManyToOne(() => AccountingSalaryConfig, { onDelete: "CASCADE" })
    accountingSalaryConfig: AccountingSalaryConfig | null;

    @Column()
    bonusTypeId: number;

    @ManyToOne(() => BonusType, { onDelete: "CASCADE" })
    bonusType: BonusType;

    @Column({ default: "" })
    taxFree: string = "";

    @Column({ default: "" })
    taxed: string = "";
}

@Entity()
export class BenefitType {
    constructor(init: Partial<BenefitType> = {}) {
        Object.assign(this, init);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @ManyToOne(() => Company, { onDelete: "CASCADE", eager: false })
    company: Company;

    @Column()
    @Index()
    companyId: number;

    @Column()
    name: string = "";

    @Column({ default: false })
    archived: boolean = false;

    @Column({ default: false })
    includeInMinimumWageComparison: boolean = false;

    @Column({ default: false })
    includeInBonusPayments: boolean = false;

    @Column({ default: false })
    taxFree: boolean = false;

    @Column({ nullable: true })
    month?: number;

    @Column({ default: 0 })
    order: number = 0;
}

@Entity()
export class Benefit {
    constructor(init: Partial<Benefit> = {}) {
        Object.assign(this, init);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column({ nullable: false })
    contractId: number;

    @ManyToOne(() => Contract, (c) => c.salaries, { onDelete: "CASCADE" })
    contract: Contract;

    @Column()
    typeId: number;

    @ManyToOne(() => BenefitType, { onDelete: "CASCADE" })
    type: BenefitType;

    @Column("float")
    amount: Euros;
}

@Entity()
export class AccountingSalaryConfigBenefit {
    constructor(init: Partial<AccountingSalaryConfigBenefit> = {}) {
        Object.assign(this, init);
    }

    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    accountingSalaryConfigId: number;

    @ManyToOne(() => AccountingSalaryConfig, { onDelete: "CASCADE" })
    accountingSalaryConfig: AccountingSalaryConfig | null;

    @Column()
    benefitTypeId: number;

    @ManyToOne(() => BenefitType, { onDelete: "CASCADE" })
    benefitType: BenefitType;

    @Column()
    wageTypeNumber: string;
}

@Entity()
export class APIClient {
    constructor(vals: Partial<APIClient> = {}) {
        Object.assign(this, vals);
    }
    @PrimaryColumn()
    id: string;

    @Column()
    secret: string;

    @Column()
    name: string;

    @Column()
    email: string;

    @Column()
    website: string;
}

@Entity()
export class APIAuthAccess {
    constructor(vals: Partial<APIAuthAccess> = {}) {
        Object.assign(this, vals);
    }
    @PrimaryGeneratedColumn()
    id: number;

    @Column()
    companyId: number;

    @ManyToOne(() => Company, { onDelete: "CASCADE" })
    company: Company;

    @Column()
    clientId: string;

    @ManyToOne(() => APIClient, { onDelete: "CASCADE" })
    client: APIClient;

    @Column("jsonb")
    scopes: string[] = [];
}

@Entity()
export class APIAuthToken {
    constructor(init: Partial<APIAuthToken> = {}) {
        Object.assign(this, init);
    }

    @PrimaryColumn()
    id: string;

    @CreateDateColumn()
    @Type(() => Date)
    created: Date;

    @Column()
    token: string;

    @Column("jsonb")
    scopes: string[] = [];

    @Column({ nullable: true })
    clientId?: string;

    @ManyToOne(() => APIClient, { onDelete: "CASCADE", nullable: true })
    client?: APIClient;

    @Column()
    companyId: number;

    @ManyToOne(() => Company, { onDelete: "CASCADE" })
    company: Company;

    @Column("timestamp", { nullable: true })
    @Type(() => Date)
    expires: Date | null;
}

@Entity()
export class TimeLogDevice {
    constructor(init: Partial<TimeLogDevice> = {}) {
        Object.assign(this, init);
    }

    @PrimaryColumn()
    id: string;

    @ManyToOne(() => Company, { onDelete: "CASCADE" })
    @Type(() => Company)
    company?: Company;

    @Column()
    @Index()
    companyId: number;

    @Column({ nullable: true })
    clientId?: string;

    @ManyToOne(() => APIClient, { onDelete: "CASCADE", nullable: true })
    client?: APIClient;

    @Column({ nullable: true })
    authTokenId?: string;

    @ManyToOne(() => APIAuthToken, { onDelete: "SET NULL", nullable: true })
    authToken?: APIAuthToken;

    @Column("jsonb", { default: [] })
    filters: EntityFilter[];

    @Column()
    description: string = "";

    @Column({ nullable: true })
    image?: string;

    @Column()
    connectionCode: string = "";

    @Column({ nullable: true })
    type?: string;

    @Column({ nullable: true })
    vendor?: string;

    @Column({ nullable: true })
    model?: string;

    @Column({ nullable: true })
    userAgent?: string;

    @Column({ nullable: true })
    @Type(() => Date)
    lastActive?: Date;

    @Column({ default: true })
    displaySchedule: boolean = true;

    @Column({ default: true })
    displayUpcoming: boolean = true;

    @Column({ default: true })
    displayActive: boolean = true;

    @Column({ default: false })
    legacy: boolean = false;

    @Column("text", { nullable: true })
    appVersion: string | null = null;
}

export type TimeLogAction = "startShift" | "endShift" | "startBreak" | "endBreak";

export type TimeLogEventStatus = "pending" | "accepted" | "rejected";

export type Location = {
    latitude: number;
    longitude: number;
    accuracy?: number;
};

export function timeLogActionLabel(action: TimeLogAction) {
    switch (action) {
        case "startShift":
            return "Schichtbeginn";
        case "endShift":
            return "Schichtende";
        case "startBreak":
            return "Beginn Pause";
        case "endBreak":
            return "Ende Pause";
    }
}

export function timeLogActionIcon(action: TimeLogAction) {
    switch (action) {
        case "startShift":
            return "play-circle";
        case "endShift":
            return "stop-circle";
        case "startBreak":
        case "endBreak":
            return "coffee";
    }
}

@Entity()
export class TimeLogEvent {
    constructor(init: Partial<TimeLogEvent> = {}) {
        Object.assign(this, init);
    }

    @PrimaryColumn()
    id: string;

    @Column()
    action: TimeLogAction;

    @Column({ default: "terminal" })
    source: "terminal" | "legacy_terminal" | "employee_app" | "external" = "terminal";

    @Column({ nullable: true })
    deviceId?: string;

    @ManyToOne(() => TimeLogDevice, { onDelete: "SET NULL", nullable: true })
    device?: TimeLogDevice;

    @Column()
    employeeId: number;

    @ManyToOne(() => Employee, { onDelete: "CASCADE" })
    employee: Employee;

    @Column({ nullable: true })
    positionId?: number;

    @ManyToOne(() => Position, { onDelete: "CASCADE", nullable: true })
    position?: Position;

    @Column({ nullable: true })
    timeEntryId?: string;

    @ManyToOne(() => TimeEntry, { onDelete: "CASCADE", nullable: true })
    timeEntry?: TimeEntry;

    @Column()
    @Type(() => Date)
    time: Date;

    @Column()
    status: TimeLogEventStatus = "pending";

    @Column({ nullable: true })
    rejectedReason?: string;

    @Column("jsonb", { nullable: true })
    location?: Location;

    @Column({ nullable: true })
    image?: string;

    @Column({ default: "" })
    comment: string;

    @Column("jsonb", { nullable: true })
    meals?: {
        breakfast?: boolean;
        lunch?: boolean;
        dinner?: boolean;
    };
}

export type TimeBalance = {
    employeeId: number;
    from: DateString;
    to: DateString;
    reset?: Hours;
    carry: Hours;
    work: Hours;
    absences: Hours;
    adjustments: Hours;
    actual: Hours;
    nominal: Hours;
    difference: Hours;
    balance: Hours;
    subBalances?: Omit<TimeBalance, "subBalances">[];
};

export type VacationBalance = {
    employeeId: number;
    from: DateString;
    to: DateString;
    reset?: Days;
    carry: Days;
    adjustments: Days;
    taken: Days;
    actual: Days;
    nominal: Days;
    difference: Days;
    balance: Days;
    subBalances?: Omit<VacationBalance, "subBalances">[];
};

export type BonusesBalance = {
    employeeId: number;
    from: DateString;
    to: DateString;
    reset?: Euros;
    carry?: Euros;
    taxed: Euros;
    untaxed: Euros;
    actual: Euros;
    nominal?: Euros;
    difference?: Euros;
    balance?: Euros;
    subBalances?: Omit<BonusesBalance, "subBalances">[];
};

/** @deprecated Replaced by `TimeBalance`, `VacationBalance` and `BonusesBalance` */
export type Balances = DateRange & {
    employeeId: number;
    time: Omit<TimeBalance, "employeeId" | "from" | "to">;
    vacation: Omit<VacationBalance, "employeeId" | "from" | "to">;
    bonuses: Omit<BonusesBalance, "employeeId" | "from" | "to">;
};

@Entity()
@Index(["employeeId", "date"], { unique: true })
export class DailyResults {
    constructor(init: Partial<DailyResults> = {}) {
        Object.assign(this, init);
    }

    @ManyToOne(() => Employee, { primary: true, onDelete: "CASCADE", eager: false })
    employee: Employee;

    @PrimaryColumn()
    employeeId: number;

    @PrimaryColumn("date")
    date: DateString;

    @Column("jsonb")
    timeBalance: Omit<TimeBalance, "employeeId" | "from" | "to">;

    @Column("jsonb", { nullable: true })
    timeBalancePlanned: Omit<TimeBalance, "employeeId" | "from" | "to"> | null = null;

    @Column("jsonb")
    vacationBalance: Omit<VacationBalance, "employeeId" | "from" | "to">;

    @Column("jsonb")
    bonusesBalance: Omit<BonusesBalance, "employeeId" | "from" | "to">;

    @Column("jsonb")
    results: {
        work: TimeResult;
        workPlanned?: TimeResult;
        absences: TimeResult;
        adjustments: TimeResult;
        total: TimeResult;
    };

    @Column("jsonb")
    averages: {
        "13weeks": TimeResult;
    };

    @UpdateDateColumn()
    @Type(() => Date)
    updated: Date;
}
