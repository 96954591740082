import { css } from "lit";

export const animations = css`
    @keyframes shine {
        from {
            transform: translate(-100%);
        }
        to {
            transform: translate(100%);
        }
    }

    .shining {
        position: relative;
        overflow: hidden;
    }

    .shining::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
            100deg,
            rgba(255, 255, 255, 0) 10%,
            rgba(255, 255, 255, 0.5) 40%,
            rgba(255, 255, 255, 0.7) 50%,
            rgba(255, 255, 255, 0.5) 60%,
            rgba(255, 255, 255, 0) 90%
        );
        animation: shine 2s infinite;
        z-index: 1;
        opacity: 0.5;
        pointer-events: none;
    }

    .shining::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
            100deg,
            rgba(255, 255, 255, 0) 10%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 90%
        );
        animation: shine 2s 0.5s infinite;
        z-index: 1;
        opacity: 0.5;
        pointer-events: none;
    }

    .strong.shining::before,
    .strong.shining::after {
        opacity: 1;
    }

    @keyframes blink {
        from {
            opacity: 0;
        }
    }

    .blink {
        animation: blink 1.3s alternate infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }

    .spin {
        animation: spin linear 1s infinite;
    }

    @keyframes rumble {
        25% {
            transform: translate(5px, 0);
        }
        50% {
            transform: translate(-5px, -3px);
        }
        75% {
            transform: translate(5px, 2px);
        }
    }

    .rumble {
        animation: rumble 300ms;
    }
`;

export function rumble(el: HTMLElement) {
    el.classList.remove("rumble");
    el.offsetLeft;
    el.classList.add("rumble");
}
