import { Type, Exclude } from "class-transformer";
import {
    CreateCompanyParams,
    UpdateAccountParams,
    UpdateCompanyParams,
    LoginParams,
    RevokeSessionParams,
    CreateVenueParams,
    CreateDepartmentParams,
    UpdateDepartmentParams,
    UpdateVenueParams,
    CreateEmployeeParams,
    UpdateEmployeeParams,
    GetTimeEntriesParams,
    UpdateTimeEntriesParams,
    GetMonthlyStatementsParams,
    GetIssuesParams,
    GetRevenueEntriesParams,
} from "./api";
import { ClientInfo, Sender } from "./transport";
import { Storage } from "./storage";
import { Client } from "./client";
import {
    Company,
    Account,
    Session,
    Venue,
    Department,
    Employee,
    TimeEntry,
    Position,
    TimeEntryType,
    TimeSettings,
    ShiftTemplate,
    RosterTab,
    Absence,
    EmployeeStatus,
    RevenueType,
    EntityFilter,
} from "./model";
import { CryptoProvider } from "./crypto";
import { randomNumber, toDateString, parseDateString, getRange, throttle, getEmployeeOrderInDepartment } from "./util";
import { clone } from "./encoding";
import { calcAllHours, calcDistinctHours, calcTotalHours } from "./hours";
import { Issue } from "./issues";
import { ErrorCode } from "./error";
import { getPayrollReport, PayrollReport } from "./payroll";
import {
    EmployeeSortDirection,
    EmployeeSortProperty,
    EntityFilterContext,
    compareEmployeesFn,
    matchesFilters,
} from "./filters";
import { PERMISSIONS, Permission, PermissionInfo } from "./permissions";
import { DateRange, applyAutoBreaks, applyAutoMeals } from "./time";
import { DateString } from "@pentacode/openapi/src/units";
import { Localized } from "./localized";

export class AppSettings {
    /** @deprecated */
    rosterCondensedView: boolean = false;

    rosterUseNewVersion: boolean = true;
    rosterDisplayCosts: boolean = false;
    rosterDisplayTargets: boolean = false;
    rosterMirrorDepartments: boolean = true;
    rosterDisplayMode: "regular" | "compact" | "minimal" = "regular";
    rosterDisplayUnassigned: boolean = false;
    rosterDisplayAvailabilities: boolean = true;
    rosterDisplayTimeBalances: boolean = true;
    rosterDisplayNotes: boolean = true;
    rosterDisplaySuggestions: boolean = true;
    rosterDisplayFavSuggestionsOnly: boolean = false;

    timeLogShowEvents: boolean = true;
    timeLogShowManual: boolean = true;

    contractsSortDirection: "ascending" | "descending" = "descending";
}

export class EmployeeFilters {
    constructor(init: Partial<EmployeeFilters> = {}) {
        Object.assign(this, init);
    }

    filters: EntityFilter[] = [{ type: "employeeStatus", value: "active" as EmployeeStatus }];

    sortProperty: EmployeeSortProperty = "lastName";

    sortDirection: EmployeeSortDirection = "ascending";

    @Exclude()
    text: string = "";
}

export class State {
    @Type(() => Session)
    session: Session | null = null;

    @Type(() => Account)
    account: Account | null = null;

    @Type(() => AppSettings)
    settings: AppSettings = new AppSettings();

    @Type(() => TimeEntry)
    updatedTimeEntries: Map<string, TimeEntry> = new Map<string, TimeEntry>();

    @Type(() => TimeEntry)
    removedTimeEntries: Map<string, TimeEntry> = new Map<string, TimeEntry>();

    @Type(() => EmployeeFilters)
    employeeFilters: EmployeeFilters = new EmployeeFilters();

    @Exclude()
    issues: Issue[] = [];

    @Exclude()
    fetchingIssues = false;

    clientInfo?: ClientInfo;
}

export class App {
    api: Client;
    state: State = new State();

    loaded: Promise<void>;

    hasLoaded: boolean = false;

    get account() {
        return this.state.account;
    }

    get company() {
        return this.state.account && this.state.account.company;
    }

    get profile() {
        if (!this.account || !this.company) {
            return null;
        }

        let profile = this.company.employees.find((e) => e.accountId === this.account!.id);

        if (!profile) {
            profile = new Employee({
                accountId: this.account.id,
                firstName: this.account.firstName,
                lastName: this.account.lastName,
                email: this.account.email,
                positions: [],
                contracts: [],
            });
            this.company.employees.push(profile);
        }

        return profile;
    }

    get venues() {
        return this.company?.venues || [];
    }

    get accessibleVenues() {
        return this.venues.filter((venue) => this.hasAccess({ venue }));
    }

    get departments() {
        return this.venues.flatMap((v) => v.departments);
    }

    get accessibleDepartments() {
        return this.departments.filter((department) => this.hasAccess({ department }));
    }

    get employees() {
        return (
            this.company?.employees.sort((a, b) => (a.lastName > b.lastName ? 1 : a.lastName < b.lastName ? -1 : 0)) ||
            []
        );
    }

    get accessibleEmployees() {
        return this.employees.filter((employee) => this.hasAccess({ employee }));
    }

    get shiftTemplates(): ShiftTemplate[] {
        return (this.profile && this.profile.shiftTemplates) || [];
    }
    set shiftTemplates(shiftTemplates: ShiftTemplate[]) {
        if (this.profile) {
            this.profile.shiftTemplates = shiftTemplates;
            this.updateAccount({
                profile: { shiftTemplates },
            });
        }
    }

    get rosterTabs() {
        if (!this.profile || !this.company) {
            return [];
        }

        let tabs = this.profile.rosterTabs.length
            ? this.profile.rosterTabs
            : this.accessibleVenues.map(
                  (v) =>
                      ({
                          name: v.name,
                          venue: v.id,
                      }) as RosterTab
              );

        if (!this.profile.rosterTabs.length) {
            this.profile.rosterTabs = tabs;
        }

        tabs = tabs.filter((tab) => !!this.getVenue(tab.venue));

        for (const tab of tabs) {
            if (tab.types) {
                const types = new Set(tab.types);
                if (types.has(TimeEntryType.Sick)) {
                    types.add(TimeEntryType.SickInKUG);
                    types.add(TimeEntryType.ChildSick);
                } else {
                    types.delete(TimeEntryType.SickInKUG);
                    types.delete(TimeEntryType.ChildSick);
                }
                tab.types = [...types];
            }
        }

        return tabs;
    }

    get settings() {
        return this.state.settings;
    }

    get availableTimeSettings(): TimeSettings[] {
        if (!this.company) {
            return [];
        }

        return this.company.settings.availableTimeSettings.sort((a, b) => a.order - b.order);
    }

    get employeeFilters() {
        return this.state.employeeFilters;
    }

    get employeeFiltersActive() {
        return (
            this.state.employeeFilters.filters.some((f) => !(f.type === "employeeStatus" && f.value === "active")) ||
            !!this.state.employeeFilters.text
        );
    }

    get issues() {
        return this.state.issues.filter((issue) => {
            const employee = (issue.employee && this.getEmployee(issue.employee)) || undefined;
            return (
                this.hasAccess({ employee }) && issue.timeEntries.every((timeEntry) => this.hasAccess({ timeEntry }))
            );
        });
    }

    get locale() {
        return this.company?.country || "DE";
    }

    get localized() {
        return Localized[this.locale];
    }

    private _subscribers: Array<(state: State) => void> = [];

    private _activeSync: Promise<void> | null = null;
    private _queuedSync: Promise<void> | null = null;

    constructor(
        public storage: Storage,
        sender: Sender,
        public crypto: CryptoProvider
    ) {
        this.api = new Client(sender, crypto, () => this.state.clientInfo);
        this.loaded = this.load();
    }

    async load() {
        // load state from storage
        try {
            const state = await this.storage.get(State, "state");
            this.update(state);
            if (this.account && this.state.session!.scope !== "admin") {
                this.fetchAccount();
                this.fetchIssues();
                this.throttledSyncTimeEntries();
            }
        } catch (e) {
            this.update();
        }
        if (!this.state.employeeFilters) {
            this.resetEmployeeFilters();
        }
        this.hasLoaded = true;
    }

    async save() {
        await this.storage.set("state", this.state);
    }

    subscribe(fn: (state: State) => void) {
        if (this._subscribers.indexOf(fn) === -1) {
            this._subscribers.push(fn);
        }
    }

    unsubscribe(fn: (state: State) => void) {
        const index = this._subscribers.indexOf(fn);
        if (index === -1) {
            this._subscribers.splice(index, 1);
        }
    }

    publish() {
        for (const fn of this._subscribers) {
            fn(this.state);
        }
    }

    async update(changes?: Partial<State>) {
        if (changes) {
            Object.assign(this.state, changes);
        }
        this.api.session = this.state.session;
        this.publish();
        await this.save();
    }

    async updateSettings(vals: Partial<AppSettings>) {
        Object.assign(this.state.settings, vals);
        this.publish();
        await this.save();
    }

    async signup(params: Partial<CreateCompanyParams>) {
        await this.api.createCompany(new CreateCompanyParams(params));
        await this.login({
            email: params.owner!.email,
            password: params.owner!.password,
        });
    }

    async login(params: Partial<LoginParams>) {
        const session = (this.api.session = await this.api.login(new LoginParams(params)));
        const account = await this.api.me();
        this.update({ session, account });
        this.fetchIssues();
    }

    async selectCompany(company: Company | number) {
        const session = await this.api.selectCompany(typeof company === "number" ? company : company.id);
        const account = await this.api.me();
        this.state.updatedTimeEntries.clear();
        this.state.removedTimeEntries.clear();
        await this.update({ session, account, issues: [], employeeFilters: new EmployeeFilters() });
    }

    async logout() {
        if (this.state.session) {
            try {
                await this.api.revokeSession(new RevokeSessionParams({ id: this.state.session.id }));
            } catch (e) {}
        }
        const settings = this.state.settings;
        const state = new State();
        state.settings = settings;

        await this.update(state);
    }

    async fetchAccount() {
        const account = await this.api.me();
        this.update({ account });
    }

    async updateAccount(params: Partial<UpdateAccountParams>) {
        await this.api.updateAccount(new UpdateAccountParams(params));
        await this.fetchAccount();
    }

    async updateCompany(params: Partial<UpdateCompanyParams>) {
        await this.api.updateCompany(new UpdateCompanyParams(params));
        await this.fetchAccount();
    }

    async createVenue(params: Partial<CreateVenueParams>) {
        const venue = await this.api.createVenue(new CreateVenueParams(params));
        await this.fetchAccount();
        return venue;
    }

    async updateVenue(params: Partial<UpdateVenueParams>) {
        const venue = await this.api.updateVenue(new UpdateVenueParams(params));
        await this.fetchAccount();
        return venue;
    }

    async archiveVenue({ id }: { id: number }) {
        await this.api.archiveVenue(id);
        await this.fetchAccount();
    }

    async createDepartment(params: Partial<CreateDepartmentParams>) {
        const dep = await this.api.createDepartment(new CreateDepartmentParams({ ...params }));
        await this.fetchAccount();
        return dep;
    }

    async updateDepartment(params: Partial<UpdateDepartmentParams>) {
        const dep = await this.api.updateDepartment(new UpdateDepartmentParams({ ...params }));
        await this.fetchAccount();
        return dep;
    }

    getDepartment(id: number | null) {
        return (this.company && this.company.getDepartment(id)) || { venue: null, department: null };
    }

    getPosition(id: number) {
        return (this.company && this.company.getPosition(id)) || null;
    }

    getTimeSettings(context: Omit<EntityFilterContext, "company">) {
        return this.company?.getTimeSettings(context) || new TimeSettings();
    }

    getUnplannedShiftPositions(employee: Employee, venueId?: number, departments?: number[]) {
        const positions: { department: Department; position: Position }[] = [];
        for (const position of employee.positions.filter((p) => !departments || departments.includes(p.departmentId))) {
            const { venue, department } = this.getDepartment(position.departmentId);
            if (
                !venue ||
                !department ||
                (venueId && venue.id !== venueId) ||
                (departments && !departments.includes(department.id))
            ) {
                continue;
            }

            const { unplannedCheckins, trackingEnabled } = this.getTimeSettings({ employee, position });
            if (trackingEnabled && unplannedCheckins) {
                positions.push({ department, position });
            }
        }
        return positions;
    }

    async archiveDepartment(id: number) {
        await this.api.archiveDepartment(id);
        await this.fetchAccount();
    }

    async createEmployee(params: Partial<CreateEmployeeParams>) {
        const venue = await this.api.createEmployee(new CreateEmployeeParams(params));
        await this.fetchAccount();
        return venue;
    }

    async updateEmployee({ id }: Employee, params: Partial<UpdateEmployeeParams>, refresh = true) {
        const employee = await this.api.updateEmployee(new UpdateEmployeeParams({ ...params, id }));
        if (refresh) {
            await this.fetchAccount();
        }
        return employee;
    }

    getEmployee(id: number | null) {
        return (this.company && this.company.employees.find((e) => e.id === id)) || null;
    }

    getVenue(venueId: number): Venue | null {
        return this.company?.getVenue(venueId) || null;
    }

    getEmployeesForDepartment(dep: Department, employees = this.employees): Employee[] {
        if (!this.company) {
            return [];
        }

        return employees
            .filter((emp) => emp.positions.some((p) => p.departmentId === dep.id))
            .sort((a, b) => getEmployeeOrderInDepartment(a, dep) - getEmployeeOrderInDepartment(b, dep));
    }

    async generatePin() {
        let pin: string;

        // Randomly generate pin until we got a unique one
        do {
            pin = (await randomNumber(0, 9999, this.crypto.randomBytes)).toString().padStart(4, "0");
        } while (this.company!.employees.some((e) => e.timePin === pin));

        return pin;
    }

    normalizeEntry(
        entry: TimeEntry,
        {
            applyAutoMeals: autoMeals = false,
            otherEntries = [],
        }: { applyAutoMeals?: boolean; otherEntries?: TimeEntry[] }
    ) {
        if (entry.position && !entry.positionId) {
            entry.positionId = entry.position.id;
        }

        const { trackingEnabled } = this.getTimeSettings({
            timeEntry: entry,
        });

        // If timetracking is disabled for this department, make sure that planned and final time
        // are equal
        if (!trackingEnabled) {
            entry.startPlanned = entry.startFinal = entry.startFinal || entry.startPlanned;
            entry.endPlanned = entry.endFinal = entry.endFinal || entry.endPlanned;
        }

        // If shift is finished, set breaks
        if (entry.final) {
            entry.startBreak = null;

            const { breakMode, autoBreaks } = this.company!.getTimeSettings({
                timeEntry: entry,
            });
            applyAutoBreaks(entry, breakMode, autoBreaks);

            if (autoMeals) {
                const availableMeals = otherEntries
                    .filter((e) => !e.deleted && e.employeeId === entry.employeeId && e.date === entry.date)
                    .reduce(
                        (total, entry) => ({
                            breakfast: total.breakfast && !entry.mealsBreakfast,
                            lunch: total.lunch && !entry.mealsLunch,
                            dinner: total.dinner && !entry.mealsDinner,
                        }),
                        {
                            breakfast: true,
                            lunch: true,
                            dinner: true,
                        }
                    );
                applyAutoMeals(this.company!, entry, availableMeals);
            }
        }
    }

    async createOrUpdateTimeEntries(
        entries: TimeEntry | TimeEntry[],
        {
            applyAutoMeals = false,
            otherEntries = [],
            wait = false,
            publish = false,
            setUpdated = true,
        }: {
            applyAutoMeals?: boolean;
            otherEntries?: TimeEntry[];
            wait?: boolean;
            publish?: boolean;
            setUpdated?: boolean;
        } = {}
    ) {
        entries = Array.isArray(entries) ? entries : [entries];
        for (const e of entries) {
            this.normalizeEntry(e, { applyAutoMeals, otherEntries });

            if (setUpdated) {
                e.updated = new Date();
            }

            if (!e.id) {
                e.id = await this.crypto.uuid();
            }

            if (
                publish ||
                ![TimeEntryType.Work, TimeEntryType.CompDay, TimeEntryType.Free].includes(e.type) ||
                e.isPast
            ) {
                e.setPublished();
            }

            this.state.updatedTimeEntries.set(e.id, e);
        }

        if (wait) {
            await this.syncTimeEntries();
        } else {
            this.throttledSyncTimeEntries();
        }

        return entries;
    }

    async removeTimeEntries(entries: TimeEntry | TimeEntry[], wait = false, publish = false) {
        entries = Array.isArray(entries) ? entries : [entries];
        entries.forEach((e) => {
            e.deleted = e.updated = new Date();

            if (!e.published && !e.startFinal && !e.endFinal) {
                this.state.updatedTimeEntries.delete(e.id);
                this.state.removedTimeEntries.set(e.id, e);
            } else {
                if (
                    publish ||
                    ![TimeEntryType.Work, TimeEntryType.CompDay, TimeEntryType.Free].includes(e.type) ||
                    e.isPast ||
                    e.startFinal ||
                    e.endFinal
                ) {
                    e.setPublished();
                }
                this.state.updatedTimeEntries.set(e.id, e);
            }
        });

        if (wait) {
            await this.syncTimeEntries();
        } else {
            this.throttledSyncTimeEntries();
        }
    }

    async syncTimeEntries(): Promise<void> {
        let queued = this._queuedSync;
        let active = this._activeSync;

        if (queued) {
            // There is already a queued sync promise, so just return that one
            return queued;
        }

        if (active) {
            // There is already a synchronization in process. wait for the current sync to finish
            // before starting a new one.
            const next = () => {
                this._queuedSync = null;
                return this.syncTimeEntries();
            };
            queued = active.then(next, next);
            this._queuedSync = queued;
            return queued;
        }

        active = this._syncTimeEntries().then(
            () => {
                this._activeSync = null;
            },
            (e) => {
                this._activeSync = null;
                e.display = true;
                throw e;
            }
        );

        this._activeSync = active;

        return active;
    }

    throttledSyncTimeEntries = throttle(async () => {
        try {
            await this.save();
        } catch (e) {}
        this.syncTimeEntries();
    }, 1000);

    async getTimeEntries(params: Partial<GetTimeEntriesParams>) {
        const entries = await this.api.getTimeEntries(new GetTimeEntriesParams(params));
        return entries
            .filter((entry) => !this.isRemoved(entry))
            .map((entry) => this.state.updatedTimeEntries.get(entry.id) || entry);
    }

    hasPermission(scope: Permission) {
        return !!this.account && !!this.company && this.company.hasPermission(this.account, scope);
    }

    hasPermissionForEmployee(employee: Employee, scope?: Permission) {
        return this.hasAccess({ employee }) && (!scope || this.hasPermission(scope));
    }

    hasAccess(context: Omit<EntityFilterContext, "company">) {
        return (
            this.account &&
            this.profile &&
            this.company &&
            (this.account.admin || this.company.hasAccess(this.profile, context))
        );
    }

    getRelatedPermissions(key: Permission, scopes: readonly PermissionInfo[] = PERMISSIONS.children) {
        const res: { requires: Permission[]; sub: Permission[]; bound: Permission[] } = {
            requires: [],
            sub: [],
            bound: [],
        };

        for (const scope of scopes) {
            if (scope.requires?.includes(key) && scope.key) {
                res.requires.push(scope.key as Permission);
            }
            if (scope.key?.startsWith(key)) {
                res.sub.push(scope.key as Permission);
            }

            if (scope.children) {
                const { requires, sub, bound } = this.getRelatedPermissions(key, scope.children);
                res.requires.push(...requires);
                res.bound.push(...bound);
                res.sub.push(...sub);
            }
        }

        return res;
    }

    isUpdated(a: TimeEntry) {
        return this.state.updatedTimeEntries.has(a.id);
    }

    isRemoved(a: TimeEntry) {
        return this.state.removedTimeEntries.has(a.id);
    }

    async getMonthlyStatements({ employee, from, to, exclude, minVersion }: Partial<GetMonthlyStatementsParams> = {}) {
        if (!this.company) {
            return [];
        }

        if (!employee) {
            employee = this.accessibleEmployees
                .filter((e) => !from || !to || !!e.getContractForRange({ from, to }))
                .map((e) => e.id);
        }

        return this.api.getMonthlyStatements(
            new GetMonthlyStatementsParams({
                employee,
                from,
                to,
                exclude,
                minVersion,
            })
        );
    }

    async getMonthlyStatement(employee: number, date: DateString = toDateString(new Date())) {
        const statements = await this.getMonthlyStatements({ employee, ...getRange(date, "month") });
        return statements[0] || null;
    }

    async fetchIssues({ from, to }: Partial<DateRange> = {}) {
        if (!this.company || this.state.fetchingIssues || this.state.session!.scope !== "manage") {
            return;
        }
        this.update({ fetchingIssues: true });
        const issues = await this.api.getIssues(new GetIssuesParams({ from, to }));
        issues.sort((a, b) => (a.date > b.date ? -1 : 1));
        this.update({ issues, fetchingIssues: false });
    }

    async ignoreIssues(issues: Issue[]) {
        const entries = new Map<string, TimeEntry>();

        for (const issue of issues) {
            if (issue.timeEntries && issue.timeEntries.length) {
                for (let entry of issue.timeEntries) {
                    if (!entries.has(entry.id)) {
                        entries.set(entry.id, entry);
                    }
                    entry = entries.get(entry.id)!;

                    if (!entry.ignoreIssues.includes(issue.type)) {
                        entry.ignoreIssues.push(issue.type);
                    }
                }
            } else {
                const employee = this.getEmployee(issue.employee!)!;
                const d = parseDateString(issue.date)!;
                const contract = employee.getContractForMonth(d.getFullYear(), d.getMonth())!;
                if (!contract.ignoreIssues.some((i) => i.type == issue.type && i.date === issue.date)) {
                    contract.ignoreIssues.push({ type: issue.type, date: issue.date });
                    this.updateEmployee(employee, { contract });
                }
            }
        }

        this.createOrUpdateTimeEntries([...entries.values()]);

        this.update({ issues: this.state.issues.filter((i) => !issues.includes(i)) });
    }

    getPositionColor(position: Position) {
        return this.company?.getPositionColor(position);
    }

    getTimeEntryColor(entry: TimeEntry) {
        return this.company?.getTimeEntryColor(entry);
    }

    getTimeEntryLabel(entry: TimeEntry) {
        return this.company?.getTimeEntryTypeLabel(entry);
    }

    async getPayrollReports(
        from: DateString,
        to: DateString,
        employees: Employee[] = this.accessibleEmployees.filter((e) => e.status !== EmployeeStatus.Probation)
    ) {
        const timeEntries = await this.getTimeEntries({
            from: getRange(from, "month").from,
            to: getRange(to, "month").to,
            employee: employees.map((e) => e.id),
        });

        const advances = await this.api.getRevenueEntries(
            new GetRevenueEntriesParams({
                from,
                to,
                employee: employees.map((e) => e.id),
                type: [RevenueType.PayAdvance],
            })
        );

        const reports: PayrollReport[] = [];

        for (const employee of employees) {
            reports.push(
                getPayrollReport(
                    this.company!,
                    employee,
                    timeEntries.filter((s) => s.employeeId === employee.id),
                    advances.filter((re) => re.employeeId === employee.id),
                    from,
                    to
                )
            );
        }

        return reports;
    }

    async getHours(from: DateString, to: DateString) {
        const [entries, statements] = await Promise.all([
            this.getTimeEntries({
                from,
                to,
                type: [
                    TimeEntryType.Work,
                    TimeEntryType.Vacation,
                    TimeEntryType.Sick,
                    TimeEntryType.HourAdjustment,
                    TimeEntryType.VacationAdjustment,
                    TimeEntryType.CompDay,
                    TimeEntryType.ChildSick,
                    TimeEntryType.SickInKUG,
                ],
            }),
            this.getMonthlyStatements({ from: getRange(from, "month").from, to: getRange(to, "month").to }),
        ]);

        const data = [];

        for (const employee of this.accessibleEmployees.filter(
            (e) => e.status !== EmployeeStatus.Probation && this.hasPermissionForEmployee(e)
        )) {
            const statement = statements.find((s) => s.employeeId === employee.id);

            if (!statement) {
                continue;
            }

            const contract = employee.getContractForRange({ from, to });

            if (!contract) {
                continue;
            }

            const items = calcAllHours(
                employee,
                this.company!,
                entries.filter((e) => e.employeeId === employee.id),
                statement.previousAverages?.hoursPerWorkDay
            );

            const distinct = calcDistinctHours(items, this.company!);
            const total = calcTotalHours(items);
            data.push({
                employee,
                distinct,
                total,
                entries: entries.filter((e) => e.employeeId === employee.id),
                items,
                statement,
                contract,
            });
        }

        return data;
    }

    matchTimeSettings(s: TimeSettings) {
        return {
            global: this.company?.settings.timeId === s.id,
            venues: this.venues.filter((v) => v.timeSettingsId === s.id),
            departments: this.venues.flatMap((v) => v.departments.filter((d) => d.timeSettingsId === s.id)),
            employees: this.employees.filter((e) => s.id && e.timeSettingsId === s.id),
        };
    }

    isAvailable(
        entry: TimeEntry,
        employee: Employee,
        otherEntries: TimeEntry[],
        absences: Absence[],
        requirePublished = true
    ) {
        const contract = employee.getContractForDate(entry.date);
        return (
            !entry.startFinal &&
            !entry.endFinal &&
            employee.access?.permissions.includes("staff.roster.take") &&
            !!employee.accountId &&
            (!entry.employeeId ||
                (entry.employeeId !== employee.id && entry.offered && entry.offered > entry.updated)) &&
            (!requirePublished || entry.isPublished) &&
            !entry.deleted &&
            employee.positions.some((p) => p.id == entry.position?.id) &&
            !otherEntries.some((e) => !e.deleted && e.employeeId === employee.id && e.overlapsWith(entry)) &&
            !absences.some((a) => a.employeeId === employee.id && a.start <= entry.date && a.end > entry.date) &&
            !!contract &&
            !contract.blocked
        );
    }

    updateEmployeeFilters(upd: Partial<EmployeeFilters>) {
        this.update({
            employeeFilters: Object.assign(this.employeeFilters, upd),
        });
    }

    resetEmployeeFilters() {
        this.updateEmployeeFilters(new EmployeeFilters());
    }

    employeeMatchesFilters(employee: Employee, date?: string | DateRange) {
        const { text, filters } = this.employeeFilters;
        return (
            `${employee.name} ${employee.staffNumber}`.toLowerCase().includes(text.toLowerCase()) &&
            matchesFilters(filters, { company: this.company!, employee, date })
        );
    }

    getFilteredEmployees(range?: DateRange) {
        return this.accessibleEmployees
            .filter((e) => this.employeeMatchesFilters(e, range))
            .sort(compareEmployeesFn(this.employeeFilters));
    }

    private async _syncTimeEntries() {
        const updated = [...this.state.updatedTimeEntries.values()].map(clone);
        const removed = [...this.state.removedTimeEntries.values()];

        if (updated.length || removed.length) {
            try {
                await this.api.updateTimeEntries(new UpdateTimeEntriesParams({ updated, removed }));

                removed.forEach((e) => this.state.removedTimeEntries.delete(e.id));
                updated.forEach((entry) => {
                    const updatedEntry = this.state.updatedTimeEntries.get(entry.id);
                    if (updatedEntry && updatedEntry.updated <= entry.updated) {
                        this.state.updatedTimeEntries.delete(entry.id);
                    }
                });

                this.save();
            } catch (e) {
                if (e.code !== ErrorCode.FAILED_CONNECTION) {
                    this.state.updatedTimeEntries.clear();
                    this.state.removedTimeEntries.clear();
                    this.save();
                    throw e;
                }
            }
        }
    }
}
