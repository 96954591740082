import { Company, Employee, EmploymentType, TimeEntryType } from "./model";
import { TimeResultItem, getBonusHourlyRate } from "./hours";
import { round, toDateString } from "./util";

export const MINIMUM_HOURLY_RATE = [
    ["2025-01-01", 12.82],
    ["2024-01-01", 12.41],
    ["2022-10-01", 12.0],
    ["2022-07-01", 10.45],
    ["2022-01-01", 9.82],
    ["2021-07-01", 9.6],
    ["2021-01-01", 9.5],
    ["2020-01-01", 9.35],
    ["2019-01-01", 9.19],
    ["2018-01-01", 8.84],
    ["2017-01-01", 8.84],
    ["2015-01-01", 8.5],
] as [string, number][];

export function getMinimumHourlyRate(date: string | Date = new Date()): number {
    if (date instanceof Date) {
        date = toDateString(date);
    }

    const period = MINIMUM_HOURLY_RATE.find(([start]) => date >= start);
    return period ? period[1] : 0;
}

export function getAncillaryCostFactor(type: EmploymentType) {
    const ANCILLARY_COST_FACTORS: { [type: number]: [number, number] } = {
        [EmploymentType.ShortTerm]: [0, 0],
        [EmploymentType.Independent]: [0, 0],
        [EmploymentType.Marginal]: [0.348, 0],
        [EmploymentType.Regular]: [0.19825, 0.19825],
        [EmploymentType.Trainee]: [0.19825, 0.19825],
        [EmploymentType.MidiJob]: [0.19825, 0.19825],
        [EmploymentType.WorkingStudent]: [0.0935, 0.0935],
        [EmploymentType.DualStudent]: [0.19825, 0.19825],
        [EmploymentType.Intern]: [0, 0],
    };
    return ANCILLARY_COST_FACTORS[type] || [0, 0];
}

export function getMaxPay(
    type: EmploymentType,
    date: string | Date = new Date(),
    company: Company
): number | undefined {
    const MAXIMUM_MONTHLY_PAY: Record<string, Record<string, [string, number][]>> = {
        DE: {
            [EmploymentType.Marginal]: [
                ["2024-01-01", 538],
                ["2022-10-01", 520],
                ["2013-01-01", 450],
            ],
            [EmploymentType.MidiJob]: [
                ["2023-01-01", 2000],
                ["2022-10-01", 1600],
                ["2019-07-01", 1300],
                ["2013-01-01", 850],
            ],
        },
        AT: {
            [EmploymentType.Marginal]: [
                ["2024-01-01", 518.44],
                ["2023-01-01", 500.91],
                ["2022-01-01", 485.85],
                ["2021-01-01", 475.86],
                ["2020-01-01", 460.66],
                ["2019-01-01", 446.81],
                ["2018-01-01", 438.05],
                ["2017-01-01", 425.07],
            ],
        },
    };

    if (date instanceof Date) {
        date = toDateString(date);
    }

    const timeline = MAXIMUM_MONTHLY_PAY[company.country][type] as [string, number][] | undefined;

    const period = timeline && timeline.find(([start]) => date >= start);
    return period ? period[1] : undefined;
}

export function calcPay(company: Company, employee: Employee, { entry, result }: TimeResultItem) {
    const contract = employee.getContractForDate(entry.date);

    if (!contract) {
        return;
    }

    const salary =
        // Try to find position-specific salary first
        (entry.position && contract.salaries.find((salary) => salary.positionId === entry.position!.id)) ||
        // Otherwise choose default salary (no position attached)
        contract.salaries.find((salary) => salary.positionId === null);

    if (!salary) {
        return;
    }

    const { amount, type, commission } = salary;
    const fees = getAncillaryCostFactor(contract.employmentType);

    const hourly = getBonusHourlyRate(company, contract, entry);

    result.hourlyGross = hourly;
    result.hourlyNet = result.hourlyGross * (1 - fees[1]);
    result.hourlyCost = result.hourlyGross * (1 + fees[0]);

    result.workGross = hourly * result.full;
    result.workNet = result.workGross * (1 - fees[1]);
    result.workCost = result.workGross * (1 + fees[0]);

    result.breaksGross = type === "hourly" ? hourly * (result.paid - result.full) : 0;
    result.breaksNet = result.breaksGross * (1 - fees[1]);
    result.breaksCost = result.breaksGross * (1 + fees[0]);

    result.salaryGross = hourly * result.paid;
    result.salaryNet = result.salaryGross * (1 - fees[1]);
    result.salaryCost = result.salaryGross * (1 + fees[0]);

    result.commissionGross = result.revenue * commission * 0.01;
    result.commissionNet = result.commissionGross * (1 - fees[1]);
    result.commissionCost = result.commissionGross * (1 + fees[0]);

    for (const each of ["night1", "night2", "sunday", "holiday", "special"] as const) {
        const cap = each[0].toUpperCase() + each.slice(1);
        const dur = result[each];
        // @ts-ignore
        const factor = 0.01 * contract["bonus" + cap];
        const bonus = hourly * dur * factor;

        let bonusFree: number;
        let bonusNotFree: number;

        if (entry.type === TimeEntryType.Work) {
            // Tax free bonuses only for up to 25 eur/hour
            bonusFree = hourly ? (Math.min(hourly, 25) / hourly) * bonus : 0;
            bonusNotFree = bonus - bonusFree;
        } else {
            bonusFree = 0;
            bonusNotFree = bonus;
        }

        // @ts-ignore
        result[(each + "Gross") as keyof typeof result] = bonusFree + bonusNotFree;
        // @ts-ignore
        result[each + "Net"] = bonusFree + bonusNotFree * (1 - fees[1]);
        // @ts-ignore
        result[each + "Cost"] = bonusFree + bonusNotFree * (1 + fees[0]);
    }

    result.monthlyGross = type === "monthly" ? amount : 0;
    result.monthlyNet = type === "monthly" ? amount * (1 - fees[1]) : 0;
    result.monthlyCost = type === "monthly" ? amount * (1 + fees[0]) : 0;

    const bonusFree = result.bonuses
        .filter((bonus) => bonus.taxFree)
        .reduce((total, bonus) => total + round(bonus.duration, 2) * bonus.hourlyRate * (bonus.percent / 100), 0);
    const bonusTaxed = result.bonuses
        .filter((bonus) => !bonus.taxFree)
        .reduce((total, bonus) => total + round(bonus.duration, 2) * bonus.hourlyRate * (bonus.percent / 100), 0);

    result.bonusGross = bonusFree + bonusTaxed;
    result.bonusNet = bonusFree + bonusTaxed * (1 - fees[1]);
    result.bonusCost = bonusFree + bonusTaxed * (1 + fees[0]);
}
