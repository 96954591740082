import { html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { mixins } from "../styles";
import { Dialog } from "./dialog";
import { wait } from "@pentacode/core/src/util";

export type CaptureImageOptions = {
    countdown?: number;
    allowSelectFile?: boolean;
    mirrorDisplay?: boolean;
};

@customElement("ptc-capture-image")
export class CaptureImage extends Dialog<CaptureImageOptions | undefined, string> {
    @query(".live-canvas")
    private _liveCanvas: HTMLCanvasElement;

    @query(".preview-canvas")
    private _previewCanvas: HTMLCanvasElement;

    @query(".reticule")
    private _reticule: HTMLDivElement;

    @query("input[type='file']")
    private _fileInput: HTMLInputElement;

    @query(".countdown")
    private _countdownEl: HTMLDivElement;

    @state()
    private _reticuleSize: number = 500;

    @state()
    private _previewing = false;

    @state()
    private _cameraUnavailable: boolean = false;

    @state()
    private _options: CaptureImageOptions;

    private _video: HTMLVideoElement;

    private _previewMessages = ["Gut schaust aus!", "Et voilà!", "Wunderschön!", "Unwiederstehlich!", "Aber Hallo! 😍"];

    async show(opts: CaptureImageOptions = {}) {
        this._options = {
            allowSelectFile: true,
            mirrorDisplay: false,
            ...opts,
        };
        this.loading = true;
        const promise = super.show();

        this._previewing = false;
        try {
            await this._startCapture();
            this._cameraUnavailable = false;
            if (this._options.countdown) {
                this._startCountdown();
            }
        } catch (e) {
            this._cameraUnavailable = true;
        }
        this.loading = false;

        await this.updateComplete;
        this._reticuleSize = Math.min(this.offsetHeight, this.offsetWidth) - 80;
        return promise;
    }

    done(data?: string) {
        this._stopCapture();
        super.done(data);
    }

    private _startCapture() {
        return new Promise<void>((resolve, reject) => {
            const tick = async () => {
                if (this._video.readyState !== this._video.HAVE_ENOUGH_DATA) {
                    requestAnimationFrame(() => tick());
                    return;
                }

                const canvas = this._liveCanvas.getContext("2d")!;
                this._liveCanvas.height = this._video.videoHeight;
                this._liveCanvas.width = this._video.videoWidth;
                canvas.drawImage(this._video, 0, 0, this._liveCanvas.width, this._liveCanvas.height);
                requestAnimationFrame(() => tick());
                resolve();
            };

            if (!this._video) {
                this._video = document.createElement("video");
                this._video.setAttribute("playsinline", "");
                this._video.setAttribute("muted", "");
                this._video.setAttribute("autoplay", "");
            }

            navigator.mediaDevices.getUserMedia({ audio: false, video: { facingMode: "user" } }).then((stream) => {
                // Use facingMode: environment to attemt to get the front camera on phones
                this._video.srcObject = stream;
                this._video.play();
                requestAnimationFrame(() => tick());
            }, reject);
        });
    }

    private _stopCapture() {
        const stream: MediaStream | null = this._video && (this._video.srcObject as MediaStream);
        if (stream) {
            for (const track of stream.getTracks()) {
                track.stop();
            }
        }

        this._video && (this._video.srcObject = null);
        const canvas = this._liveCanvas.getContext("2d")!;
        canvas.clearRect(0, 0, this._liveCanvas.width, this._liveCanvas.width);
    }

    private _takePicture() {
        const { width: actualWidth, height: actualHeight } = this._liveCanvas.getBoundingClientRect();
        const { width: bitmapWidth, height: bitmapHeight } = this._liveCanvas;
        const ratio = Math.min(bitmapWidth / actualWidth, bitmapHeight / actualHeight);
        const { width: reticuleWidth, height: reticuleHeight } = this._reticule.getBoundingClientRect();

        const width = reticuleWidth * ratio;
        const height = reticuleHeight * ratio;
        const top = bitmapHeight / 2 - height / 2;
        const left = bitmapWidth / 2 - width / 2;

        this._previewing = true;

        const ctx = this._previewCanvas.getContext("2d")!;
        ctx.imageSmoothingQuality = "high";
        ctx.drawImage(
            this._liveCanvas,
            left,
            top,
            width,
            height,
            0,
            0,
            this._previewCanvas.width,
            this._previewCanvas.height
        );
    }

    private async _startCountdown() {
        let count = this._options.countdown;

        while (count) {
            this._countdownEl.style.animation = "";
            this._countdownEl.innerHTML = count.toString();
            this._countdownEl.offsetLeft;
            this._countdownEl.style.animation = "countdown 1s";
            await wait(1000);
            count--;
        }

        this._takePicture();
        this._confirm();
    }

    private _retake() {
        this._previewing = false;
        if (this._options.countdown) {
            this._startCountdown();
        }
    }

    private _confirm() {
        this.done(this._previewCanvas.toDataURL());
    }

    private _previewMessage() {
        const index = Math.floor(Math.random() * 1000) % this._previewMessages.length;
        return this._previewMessages[index];
    }

    private _selectFile() {
        this._fileInput.click();
    }

    private _loadFile() {
        const file = this._fileInput.files![0];
        const reader = new FileReader();
        reader.onload = async () => {
            const img = new Image();
            img.onload = () => {
                this._previewing = true;
                const canvas = this._previewCanvas;
                const cropSize = Math.min(img.width, img.height);
                const left = (img.width - cropSize) / 2;
                const top = (img.height - cropSize) / 2;
                const ctx = canvas.getContext("2d")!;

                ctx.imageSmoothingQuality = "high";
                ctx.drawImage(img, left, top, cropSize, cropSize, 0, 0, canvas.width, canvas.height);
            };
            img.src = reader.result as string;
            this._fileInput.value = "";
        };
        reader.readAsDataURL(file);
    }

    static styles = [
        ...Dialog.styles,
        css`
            .inner {
                ${mixins.fullbleed()};
                border-radius: 0;
                max-width: 100%;
                background: transparent;
            }

            .scrim {
                background: transparent;
            }

            .live-view {
                ${mixins.fullbleed()};
                display: flex;
                flex-direction: column;
            }

            .live-canvas {
                ${mixins.fullbleed()};
                background: var(--color-bg);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .preview {
                ${mixins.fullbleed()};
                background: var(--color-bg);
            }

            .preview-canvas {
                border-radius: 100%;
                width: 200px;
                height: 200px;
                border: solid 5px var(--color-bg);
                margin: 2em;
                box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 5px;
            }

            .reticule {
                ${mixins.fullbleed()};
                border: solid 3px white;
                border-radius: 100%;
                margin: auto;
                box-shadow: rgba(0, 0, 0, 0.8) 0 0 0 2000px;
                position: relative;
                overflow: visible;
            }

            .close-button {
                position: absolute;
                top: 1em;
                right: 1em;
                background: var(--color-bg);
            }

            .shutter-button {
                position: absolute;
                bottom: 1em;
                right: 1em;
                line-height: 1em;
            }

            .upload-button {
                background: var(--color-bg);
                color: var(--color-primary);
                position: absolute;
                bottom: 1em;
                left: 1em;
                line-height: 1em;
            }

            .shutter-button i,
            .upload-button i {
                width: 1em;
            }

            .actions :not(:last-child) {
                margin-right: 0.5em;
            }

            .camera-unavailable {
                background: var(--color-fg);
                color: var(--color-bg);
            }

            .countdown {
                font-size: 5em;
                color: var(--color-bg);
            }

            @keyframes countdown {
                from {
                    transform: scale(1.5);
                    opacity: 0.5;
                }
            }
        `,
    ];

    renderContent() {
        return html`
            <div class="live-view" ?hidden=${this._previewing || this._cameraUnavailable}>
                <canvas
                    class="live-canvas"
                    style="transform: ${this._options.mirrorDisplay ? "scaleX(-1)" : ""}"
                ></canvas>
                <div class="reticule" style="width: ${this._reticuleSize}px; height: ${this._reticuleSize}px;"></div>
                <div class="fullbleed centering layout">
                    <div class="countdown"></div>
                </div>
                <button
                    class="massive primary icon shutter-button"
                    @click=${this._takePicture}
                    ?hidden=${this._options.countdown}
                >
                    <i class="camera"></i>
                </button>
                <button
                    class="massive icon upload-button"
                    @click=${this._selectFile}
                    ?hidden=${this._options.countdown}
                >
                    <i class="image"></i>
                </button>
            </div>

            <div
                class="center-aligning center-justifying fullbleed vertical layout camera-unavailable"
                ?hidden=${this._previewing || !this._cameraUnavailable}
            >
                <div class="large padded-full">Keine Kamera verfügbar.</div>
                <button class="primary" @click=${this._selectFile} ?hidden=${!this._options.allowSelectFile}>
                    <i class="image"></i>
                    Datei Wählen
                </button>
            </div>

            <div class="preview center-aligning center-justifying vertical layout" ?hidden=${!this._previewing}>
                <h1>${this._previewMessage()}</h1>
                <div>Willst du das Bild übernehmen?</div>
                <canvas class="preview-canvas" width="200" height="200"></canvas>
                <div class="actions">
                    <button class="primary" @click=${this._confirm}>Übernehmen</button>
                    <button @click=${this._retake}>Nochmal</button>
                </div>
            </div>

            <button class="icon large close-button" @click=${() => this.done()} ?hidden=${!!this._options.countdown}>
                <i class="times"></i>
            </button>

            <input type="file" accept="image/png,image/jpg" hidden @change=${() => this._loadFile()} />
        `;
    }
}
