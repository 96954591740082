import { Company, Department, Employee, EmploymentType, EntityFilter, Position, TimeEntry, Venue } from "./model";
import { DateRange } from "./time";

export interface EntityFilterContext {
    company: Company;
    employee?: Employee;
    date?: string | DateRange;
    venue?: Venue | null;
    department?: Department | null;
    position?: Position | number | null;
    timeEntry?: TimeEntry;
}

export function matchesEmploymentType(employee: Employee, type: EmploymentType, date?: string | DateRange): boolean {
    if (!employee) {
        return false;
    }

    const contract = !date
        ? employee.currentContract
        : typeof date === "string"
          ? employee.getContractForDate(date)
          : employee.getAllContractsForRange(date);

    if (Array.isArray(contract)) {
        return contract.some((c) => c.employmentType === type);
    }

    return contract?.employmentType === type;
}

export function matchesFilter(
    filter: EntityFilter,
    { company, employee, date, venue, department, position, timeEntry }: EntityFilterContext
): boolean {
    if (timeEntry?.positionId && !position) {
        position = timeEntry.positionId;
    }

    if (typeof position === "number") {
        position = company.getPosition(position)?.position;
    }

    if (timeEntry?.employeeId && !employee) {
        employee = company.getEmployee(timeEntry.employeeId);
    }

    if (["employeeId", "employeeTag", "staffNumber"].includes(filter.type) && timeEntry && !employee) {
        return false;
    }

    const positions = getPositionsByFilter(filter, company);

    switch (filter.type) {
        case "venue":
        case "department":
        case "position":
            return (
                (!venue ||
                    venue.departments.some((dep) =>
                        dep.positions.some((pos) => positions?.some((p) => p.id === pos.id))
                    )) &&
                (!department || department.positions.some((pos) => positions?.some((p) => p.id === pos.id))) &&
                (!position || positions?.some((p) => p.id === (position as Position).id)) &&
                (!employee || employee.positions.some((pos) => positions?.some((p) => p.id === pos.id)))
            );
        case "employmentType":
            return !!employee && matchesEmploymentType(employee, filter.value, date);
        case "employeeStatus":
            if (!employee) {
                return true;
            }
            return employee!.status === filter.value;
        case "employeeId":
            return (
                (!venue ||
                    venue.departments.some((dep) =>
                        dep.positions.some((pos) => positions?.some((p) => p.id === pos.id))
                    )) &&
                (!department || department.positions.some((pos) => positions?.some((p) => p.id === pos.id))) &&
                (!position || positions?.some((p) => p.id === (position as Position).id)) &&
                (!employee || employee.id === filter.value)
            );
        case "staffNumber":
            return (
                (!venue ||
                    venue.departments.some((dep) =>
                        dep.positions.some((pos) => positions?.some((p) => p.id === pos.id))
                    )) &&
                (!department || department.positions.some((pos) => positions?.some((p) => p.id === pos.id))) &&
                (!position || positions?.some((p) => p.id === (position as Position).id)) &&
                (!employee || employee.staffNumber === filter.value)
            );
        case "employeeTag":
            return !employee || employee.tags?.some((tag) => tag.id === filter.value);
    }
}

export function matchesFilters(filters: EntityFilter[], context: EntityFilterContext) {
    const filterTypes: {
        workspace: EntityFilter[];
        employee: EntityFilter[];
        employmentType: EntityFilter[];
        employeeStatus: EntityFilter[];
        employeeTag: EntityFilter[];
    } = {
        workspace: [],
        employee: [],
        employmentType: [],
        employeeStatus: [],
        employeeTag: [],
    };

    for (const filter of filters) {
        switch (filter.type) {
            case "venue":
            case "department":
            case "position":
                filterTypes.workspace.push(filter);
                break;
            case "employeeId":
            case "staffNumber":
                filterTypes.employee.push(filter);
                break;
            case "employmentType":
                filterTypes.employmentType.push(filter);
                break;
            case "employeeStatus":
                filterTypes.employeeStatus.push(filter);
                break;
            case "employeeTag":
                filterTypes.employeeTag.push(filter);
                break;
        }
    }

    return [...Object.values(filterTypes)].every(
        (filters) => !filters.length || filters.some((filter) => matchesFilter(filter, context))
    );
}

export function getSpecificity(filters: EntityFilter[]) {
    const types = new Set(filters.map((f) => f.type));
    let score = types.size;
    if (types.has("employeeId")) {
        score += 10;
    }
    return score;
}

export function serializeFilters(filters: EntityFilter[]) {
    return btoa(JSON.stringify(filters)).replace(/=/g, "");
}

export function deserializeFilters(filters: string) {
    return JSON.parse(atob(filters)) as EntityFilter[];
}

export type EmployeeSortProperty = "firstName" | "lastName" | "staffNumber" | "birthday" | "email" | "birthday_date";

export type EmployeeSortDirection = "ascending" | "descending";

export function compareEmployeesFn({
    sortProperty,
    sortDirection,
}: {
    sortProperty: EmployeeSortProperty;
    sortDirection: EmployeeSortDirection;
}) {
    return (a: Employee, b: Employee) => {
        let valueA = a[sortProperty as keyof Employee] || "";
        let valueB = b[sortProperty as keyof Employee] || "";

        if (sortProperty === "birthday_date") {
            // Remove year
            valueA = a.birthday?.slice(5);
            valueB = b.birthday?.slice(5);
        }

        switch (sortDirection) {
            case "descending":
                return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
            case "ascending":
                return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        }
    };
}

function getPositionsByFilter(filter: EntityFilter, company: Company): Position[] {
    switch (filter.type) {
        case "venue":
            return company.getVenue(filter.value)?.departments.flatMap((dep) => dep.positions) ?? [];
        case "department":
            return company.getDepartment(filter.value).department?.positions ?? [];
        case "position": {
            const position = company.getPosition(filter.value)?.position;
            return position ? [position] : [];
        }
        case "employeeId":
            return company.getEmployee(filter.value)?.positions ?? [];
        case "staffNumber":
            return company.getEmployeeByStaffNumber(filter.value)?.positions ?? [];
        case "employeeStatus":
        case "employmentType":
        case "employeeTag":
            return [];
    }
}
