declare const __brand: unique symbol;
declare const __numberator: unique symbol;
declare const __demoniator: unique symbol;

type Brand<B> = { [__brand]: B };

/**
 * Generic type for "branding" other types (usually primitives).
 * See: https://egghead.io/blog/using-branded-types-in-typescript
 */
export type Branded<T, B> = T & Brand<B>;

export type Milliseconds = Branded<number, "millisecond">;
export type Seconds = Branded<number, "second">;
export type Hours = Branded<number, "hour">;

export type DateString = Branded<string, "date">;

export function millisecondsToSeconds(milliseconds: Milliseconds): Seconds {
    return (milliseconds / 1000) as Seconds;
}

export function secondsToHours(seconds: Seconds): Hours {
    return (seconds / 60 / 60) as Hours;
}

export function millisecondsToHours(seconds: Milliseconds): Hours {
    return (seconds / 60 / 60 / 1000) as Hours;
}

export function hoursToSeconds(hours: Hours): Seconds {
    return round(hours * 60 * 60) as Seconds;
}

export type Days = Branded<number, "days">;

export type Euros = Branded<number, "euro">;
export type EuroCents = Branded<number, "euro cent">;

export function eurosToCents(euros: Euros): EuroCents {
    return round(euros * 100) as EuroCents;
}

export function centsToEuros(cents: EuroCents): Euros {
    return (cents / 100) as Euros;
}

export type Percent = Branded<number, "percent">;
export type Factor = Branded<number, "factor">;

export function percentToFactor(percent: Percent): Factor {
    return (percent / 100) as Factor;
}

export function factorToPercent(factor: Factor): Percent {
    return (factor * 100) as Percent;
}

export type Meals = Branded<number, "meal">;

export type Rate<Numberator extends number, Denominator extends number> = number & {
    [__numberator]: Numberator;
    [__demoniator]: Denominator;
};

export function getRate<A extends number, B extends number>(a: A, b: B): Rate<A, B> {
    return (b === 0 ? 0 : a / b) as Rate<A, B>;
}

export function multiplyWithRate<A extends number, B extends number>(value: A, rate: Rate<B, A>): B {
    return (value * rate) as B;
}

export function multiplyWithFactor<T extends number>(value: T, factor: Factor): T {
    return (value * factor) as T;
}

export function multiplyWithPercent<T extends number>(value: T, percent: Percent): T {
    return (value * percentToFactor(percent)) as T;
}

export function add<T extends number, R extends T>(first: T, ...values: R[]): T {
    return (first + values.reduce((total, value) => total + value, 0 as T)) as T;
}

export function subtract<T extends number, R extends T>(first: T, ...values: R[]): T {
    return (first - values.reduce((total, value) => total + value, 0 as T)) as T;
}

export function round<T extends number>(value: T, decimals = 0) {
    const factor = Math.pow(10, decimals);
    return (Math.round(value * factor) / factor) as T;
}

export function min<T extends number, R extends T>(first: T, ...values: R[]): T {
    return Math.min(first, ...values) as T;
}

export function max<T extends number, R extends T>(first: T, ...values: R[]): T {
    return Math.max(first, ...values) as T;
}
