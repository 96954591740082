import { LitElement, html, css } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { Employee, EmployeeStatus } from "@pentacode/core/src/model";
import { app } from "../init";
import { shared, mixins } from "../styles";
import { singleton } from "../lib/singleton";
import { CaptureImage } from "./capture-image";

@customElement("ptc-avatar")
export class Avatar extends LitElement {
    @property({ attribute: false })
    employee: Employee | null;

    @property({ type: Boolean })
    editable: boolean = false;

    @property()
    newImage?: string;

    @property({ attribute: false })
    badge?: {
        icon?: string;
        text?: string;
        color?: string;
    };

    @state()
    private _hideImage = false;

    @singleton("ptc-capture-image")
    private _captureImage: CaptureImage;

    updated(changes: Map<string, any>) {
        if (changes.has("employee")) {
            this.newImage = undefined;
        }
    }

    private async _edit() {
        const image = await this._captureImage.show();
        if (image) {
            this.newImage = image;
            this.dispatchEvent(new CustomEvent("edit", { detail: { image } }));
        }
    }

    static styles = [
        shared,
        css`
            :host {
                display: block;
                position: relative;
                border-radius: 100%;
                background: var(--color-bg);
            }

            .wrapper {
                border: solid 0.15em;
                border-radius: 100%;
                text-align: center;
                color: var(--shade-5);
                width: 2.5em;
                height: 2.5em;
                line-height: 2.5em;
                overflow: hidden;
                position: relative;
            }

            .initials {
                font-size: 1.1em;
                letter-spacing: -0.05em;
            }

            img {
                ${mixins.fullbleed()};
                width: 100%;
                height: 100%;
                object-fit: cover;
                background: #f1f1f1;
                border-radius: 100%;
            }

            .edit-button {
                position: absolute;
                right: 0;
                bottom: 0;
                font-size: 0.35em;
                background: var(--color-bg);
                box-shadow: rgba(0, 0, 0, 0.3) 0 0 2px;
            }

            :host(:hover) .edit-button {
                box-shadow: rgba(0, 0, 0, 0.3) 0 0 2px;
                background: var(--color-primary);
                color: var(--color-bg);
            }

            .badge {
                position: absolute;
                right: 0px;
                bottom: 0px;
                font-size: 0.6em;
                background: var(--color-highlight, var(--orange));
                color: var(--color-bg);
                border-radius: 100%;
                padding: 0.3em;
                font-weight: bold;
            }
        `,
    ];

    render() {
        if (!this.employee) {
            return html`
                <div class="wrapper" title="Nicht Zugewiesen">
                    <div class="initials">
                        <i class="semibold user-slash"></i>
                    </div>
                </div>
            `;
        }

        let colors: string[] =
            (this.employee.positions &&
                this.employee.positions.map((position) => position.color || app.getPositionColor(position)!)) ||
            [];

        switch (colors.length) {
            case 0:
                colors = [];
            case 1:
                colors = [colors[0], colors[0], colors[0], colors[0]];
                break;
            case 2:
                colors = [colors[0], colors[0], colors[1], colors[1]];
                break;
            case 3:
                colors = [colors[0], colors[0], colors[1], colors[2]];
                break;
            default:
                colors = [colors[0], colors[1], colors[2], colors[3]];
        }

        const pic = this.newImage || this.employee.avatar;

        return html`
            <div
                class="wrapper"
                style="border-top-color:${colors[0]}; border-right-color:${colors[1]}; border-bottom-color: ${colors[2]}; border-left-color: ${colors[3]};"
                @click=${this.editable ? this._edit : null}
                title=${this.employee?.name}
            >
                <div class="initials">
                    ${this.employee.firstName && this.employee.lastName
                        ? html`
                              ${this.employee.firstName.substring(0, 1).toUpperCase()}
                              ${this.employee.lastName.substring(0, 1).toUpperCase()}
                          `
                        : html` <i class="user"></i> `}
                </div>

                ${pic
                    ? html`
                          <img
                              src=${pic}
                              ?hidden=${this._hideImage}
                              @error=${() => (this._hideImage = true)}
                              @load=${() => (this._hideImage = false)}
                          />
                      `
                    : ""}
            </div>

            ${this.editable
                ? html`
                      <button class="icon button edit-button" @click=${this._edit}>
                          <i class="camera"></i>
                      </button>
                  `
                : this.badge
                  ? html`<div class="badge ${this.badge.color || ""}">
                        ${this.badge.icon ? html` <i class="${this.badge.icon}"></i>` : ""}
                        ${this.badge.text ? html`<div class="smaller half-padded">${this.badge.text}</div>` : ""}
                    </div> `
                  : this.employee.status === EmployeeStatus.Probation
                    ? html` <div class="badge"><i class="timer"></i></div> `
                    : this.employee.status === EmployeeStatus.Retired
                      ? html` <div class="red badge"><i class="person-to-door"></i></div>`
                      : ""}
        `;
    }
}
