import {
    Employee,
    TimeEntry,
    TimeEntryType,
    VacationIncrement,
    Company,
    EmploymentType,
    MonthlyStatement,
    GetEmployeeStatementsParams,
    BonusLegalType,
} from "./model";
import { toDateString, parseDateString, getRange, dateAdd, parseTimes } from "./util";
import {
    calcNominalHours,
    calcAllHours,
    calcTotalHours,
    calcAverageHours,
    calcAverageDailyHours,
    calcDistinctHours,
    TimeResult,
    TimeResultItem,
    newTimeResult,
    calcNominalBonus,
    CalcHoursMode,
    calcTotalResult,
    getBonusHourlyRate,
} from "./hours";
import { calcVacationEntitlement } from "./vacation";
import { Issue, IssueType, getIssues } from "./issues";
import { getMinimumHourlyRate } from "./salary";
import { getVersion } from "./version";
import { plainToClass } from "class-transformer";
import { DateString, Meals } from "@pentacode/openapi/src/units";
import { DateRange } from "./time";

export { MonthlyStatement, GetEmployeeStatementsParams };

export interface TimeStatement {
    reset?: number;

    carry: number;
    nominal: number;
    actual: number;
    difference: number;
    balance: number;

    total: TimeResult;
    average: TimeResult;
    distinct: TimeResult[];
    work: TimeResult;
    absence: TimeResult;

    /** @deprecated as of v1.20.2 */
    previousAverage: TimeResult;
    /** @deprecated as of v1.20.2 */
    averageByDay: TimeResult[];
    /** @deprecated as of v1.20.2 */
    averageDaysPerWeek: number;
    /** @deprecated as of v1.20.2 */
    commonWorkDays: number[];
}

export function newTimeStatement(vals: Partial<TimeStatement> = {}): TimeStatement {
    const statement = {
        carry: 0,
        nominal: 0,
        total: newTimeResult(),
        average: newTimeResult(),
        previousAverage: newTimeResult(),
        distinct: [],

        ...vals,
    } as TimeStatement;

    statement.work = calcTotalResult(
        statement.distinct.filter((h) => h.type === TimeEntryType.Work && !h.bonusesAreTaxed)
    );

    statement.absence = calcTotalResult(
        statement.distinct.filter((h) => h.type !== TimeEntryType.Work || h.bonusesAreTaxed)
    );

    statement.actual = statement.total.full;

    statement.difference = statement.actual - statement.nominal;

    statement.balance =
        (typeof statement.reset === "number" ? statement.reset : statement.carry) + statement.difference;

    return statement;
}

export interface VacationStatement {
    carry: number;
    nominal: number;
    actual: number;
    reset?: number;

    carryYear: number;
    startYear?: DateString;
    nominalYear: number;
    actualYear: number;

    difference: number;
    balance: number;
}

export function newVacationStatement(vals: Partial<VacationStatement> = {}): VacationStatement {
    const statement = {
        carry: 0,
        nominal: 0,
        actual: 0,

        carryYear: 0,
        nominalYear: 0,
        actualYear: 0,

        ...vals,
    };

    statement.difference = statement.nominal - statement.actual;

    statement.balance =
        (typeof statement.reset === "number" ? statement.reset : statement.carry) + statement.difference;

    return statement as VacationStatement;
}

export interface BonusStatement {
    nominal: number;
    actual: number;
    taxed: number;
    carry?: number;
    reset?: number;
    includeTaxed?: boolean;
    difference: number;
    balance?: number;
}

export function newBonusStatement(vals: Partial<BonusStatement> = {}) {
    const statement = {
        nominal: 0,
        actual: 0,
        taxed: 0,

        ...vals,
    } as BonusStatement;

    statement.difference = statement.includeTaxed
        ? statement.actual + statement.taxed - statement.nominal
        : statement.actual - statement.nominal;

    if (typeof statement.carry !== "number") {
        statement.balance = undefined;
    } else {
        statement.balance =
            (typeof statement.reset === "number" ? statement.reset : statement.carry) + statement.difference;
    }

    return statement;
}

export interface CostStatement {
    work: number;
    breaks: number;
    vacation: number;
    sick: number;
    bonus: number;
    commission: number;
    savings: number;
    totalGross: number;
    benefits: number;
    total: number;
}

export function newCostStatement(vals: Partial<CostStatement> = {}): CostStatement {
    const statement = {
        work: 0,
        breaks: 0,
        vacation: 0,
        sick: 0,
        bonus: 0,
        commission: 0,
        savings: 0,
        totalGross: 0,
        benefits: 0,

        ...vals,
    };

    statement.total =
        statement.work +
        statement.vacation +
        statement.sick +
        statement.bonus +
        statement.commission +
        statement.breaks +
        statement.benefits;

    return statement as CostStatement;
}

export type PreviousAverages = {
    hoursByWeekDay: TimeResult[];
    workDaysPerWeek: number;
    hoursPerWorkDay: TimeResult;
    commonWorkDays: number[];
};

export function getPreviousAverages(items: TimeResultItem[]): PreviousAverages {
    const lastItem = [...items].reverse().find((e) => e.entry.type === TimeEntryType.Work);
    const today = toDateString(new Date());
    const to = lastItem && lastItem.entry.date < today ? dateAdd(lastItem.entry.date, { days: 1 }) : today;
    const from = dateAdd(to, { days: -13 * 7 });
    items = items.filter((r) => r.entry.date >= from && r.entry.date <= to);

    // Calcuate average hours by day
    const hoursByWeekDay: TimeResult[] = [];
    const weekDayCount = [0, 0, 0, 0, 0, 0, 0];
    for (let i = 0; i < 7; i++) {
        const itms = items.filter(
            (item) => item.entry.type === TimeEntryType.Work && parseDateString(item.entry.date)!.getDay() === i
        );
        weekDayCount[i] += itms.length;
        hoursByWeekDay.push(calcAverageDailyHours(itms));
    }

    // Calculate average days per week
    let date = from;
    let totalDays = 0;
    let nWeeks = 0;
    while (date < to) {
        const nextMon = dateAdd(date, { days: 7 });
        const n = new Set(
            items
                .filter(
                    (item) =>
                        [
                            TimeEntryType.Work,
                            TimeEntryType.Vacation,
                            TimeEntryType.SickInKUG,
                            TimeEntryType.ChildSick,
                            TimeEntryType.CompDay,
                        ].includes(item.entry.type) &&
                        item.entry.date >= date &&
                        item.entry.date < nextMon &&
                        !!item.result.full
                )
                .map((item) => item.entry.date)
        ).size;
        if (n) {
            totalDays += n;
            nWeeks++;
        }
        date = nextMon;
    }
    const workDaysPerWeek = nWeeks ? totalDays / nWeeks : 0;

    // Calculate common work days
    const commonWorkDays = weekDayCount
        .map((count, i) => [count, i] as [any, number])
        .sort(([a], [b]) => b - a)
        .slice(0, Math.round(workDaysPerWeek))
        .map(([, i]) => i);

    const hoursPerWorkDay = calcAverageDailyHours(items.filter((i) => i.entry.type === TimeEntryType.Work));

    return {
        hoursByWeekDay,
        workDaysPerWeek,
        hoursPerWorkDay,
        commonWorkDays,
    };
}

export function getTimeStatement(
    company: Company,
    employee: Employee,
    entries: TimeEntry[],
    { from, to }: DateRange,
    carry: number = 0,
    previousAverages: PreviousAverages,
    reset?: number,
    mode?: CalcHoursMode
) {
    const {
        hoursByWeekDay: averageByDay,
        workDaysPerWeek: averageDaysPerWeek,
        commonWorkDays,
        hoursPerWorkDay: dailyAverage,
    } = previousAverages;

    // Make sure only entries for this employee are considered!
    entries = entries.filter((e) => !e.deleted && e.employeeId === employee.id && e.date >= from && e.date < to);

    // Evaluate time entries
    const items = calcAllHours(
        employee,
        company,
        entries.filter((entry) => entry.date >= from && entry.date < to),
        dailyAverage,
        mode
    );

    const nominal = calcNominalHours(company, employee, { from, to });
    const total = calcTotalHours(items.map((item) => item));
    // Until February 2021 we were erronously calculating the SHIFT average instead of the DAY average.
    // In order to avoid mayor retroactive changes to reports and ledgers, we decided to start using the
    // correct average starting with 2021.
    const average =
        from >= (company.settings.startUsingDailyAverage || "2021-01-01")
            ? calcAverageDailyHours(items.filter((i) => i.entry.type === TimeEntryType.Work))
            : calcAverageHours(items.filter((i) => i.entry.type === TimeEntryType.Work).map((item) => item.result));
    const distinct = calcDistinctHours(items, company);

    return {
        time: newTimeStatement({
            carry,
            reset,
            nominal,
            total,
            average,
            distinct,
            previousAverage: dailyAverage,
            averageByDay,
            averageDaysPerWeek,
            commonWorkDays,
        }),
        items,
    };
}

export function countVacationDays(items: TimeEntry[]) {
    return items.reduce(
        (total, { type, days }) =>
            total +
            (type === TimeEntryType.VacationAdjustment && typeof days === "number"
                ? days
                : type === TimeEntryType.Vacation
                  ? 1
                  : 0),
        0
    );
}

export function getVacationStatement(
    company: Company,
    employee: Employee,
    entries: TimeEntry[],
    { from, to }: DateRange,
    time: TimeStatement,
    carry: number = 0,
    carryYear: number = 0,
    reset?: number,
    startYear?: DateString
) {
    const contract = employee.getContractForRange({ from, to });
    if (!contract) {
        return newVacationStatement({
            reset,
            carry,
            carryYear,
        });
    }
    const vacationEntitlement = calcVacationEntitlement(employee, { from, to }, company.settings);
    const vacationFactor =
        contract.vacationIncrement === VacationIncrement.Hourly && time.nominal ? time.actual / time.nominal : 1;
    const nominal = vacationFactor * vacationEntitlement;
    const actual = countVacationDays(entries.filter((e) => e.date >= from && e.date < to));

    const range = getRange(from, "year");

    if (typeof reset === "number") {
        carryYear = reset;
        startYear = from;
    }

    if (startYear) {
        range.from = startYear;
    }

    const nominalYear = calcVacationEntitlement(employee, range, company.settings);
    const actualYear = countVacationDays(entries.filter((e) => e.date >= range.from && e.date < range.to));

    return newVacationStatement({
        carry,
        carryYear,
        reset,
        nominal,
        actual,
        nominalYear,
        actualYear,
        startYear,
    });
}

export function getBonusStatement(
    company: Company,
    employee: Employee,
    time: TimeStatement,
    { from, to }: DateRange,
    carry?: number,
    reset?: number
) {
    const nominal = calcNominalBonus(employee, { from, to });
    const contract = employee.getContractForRange({ from, to });
    if (!contract) {
        return newBonusStatement({
            carry,
            reset,
            nominal,
        });
    }

    const actual = time.total.bonuses
        .filter((bonus) => bonus.taxFree)
        .reduce((total, bonus) => total + bonus.duration * bonus.hourlyRate * 0.01 * bonus.percent, 0);
    const taxed = time.total.bonuses
        .filter((bonus) => !bonus.taxFree)
        .reduce((total, bonus) => total + bonus.duration * bonus.hourlyRate * 0.01 * bonus.percent, 0);

    const includeTaxed = company.settings.includeTaxedBonusesInBalance;
    return newBonusStatement({
        carry,
        reset,
        nominal,
        actual,
        taxed,
        includeTaxed,
    });
}

export function getCostStatement(
    company: Company,
    employee: Employee,
    entries: TimeEntry[],
    time: TimeStatement,
    { from, to }: DateRange,
    previousAverages: PreviousAverages
) {
    const contract = employee.getContractForRange({ from, to });
    if (!contract) {
        return newCostStatement();
    }
    // calculate costs based on logged times
    const maximizedItems = calcAllHours(
        employee,
        company,
        entries.filter((entry) => entry.date >= from && entry.date < to),
        previousAverages.hoursPerWorkDay,
        "max"
    );

    const maximizedHours = calcTotalHours(maximizedItems);
    const savings = maximizedHours.salaryCost - time.total.salaryCost;
    // Calculate monthly portion of work, vacation and sick hours
    const monthlyWork = calcTotalResult(
        time.distinct.filter((h) => [TimeEntryType.Work, TimeEntryType.CompDay].includes(h.type!) && !!h.monthlyCost)
    );
    const monthlyVacation = calcTotalResult(
        time.distinct.filter((h) => h.type === TimeEntryType.Vacation && !!h.monthlyCost)
    );
    const monthlySick = calcTotalResult(
        time.distinct.filter(
            (h) =>
                [TimeEntryType.Sick, TimeEntryType.ChildSick, TimeEntryType.SickInKUG].includes(h.type!) &&
                !!h.monthlyCost
        )
    );

    // Make sure the correct monthly salary is used even if any of these are empty
    // TODO: This seems kinda hacky, so maybe find a differet solution?
    monthlyWork.monthlyCost =
        monthlyVacation.monthlyCost =
        monthlySick.monthlyCost =
            monthlyWork.monthlyCost || monthlyVacation.monthlyCost || monthlySick.monthlyCost;
    monthlyWork.monthlyGross =
        monthlyVacation.monthlyGross =
        monthlySick.monthlyGross =
            monthlyWork.monthlyGross || monthlyVacation.monthlyGross || monthlySick.monthlyGross;
    monthlyWork.monthlyNet =
        monthlyVacation.monthlyNet =
        monthlySick.monthlyNet =
            monthlyWork.monthlyNet || monthlyVacation.monthlyNet || monthlySick.monthlyNet;

    // Get the total (actual) cost
    const monthlyTotal = calcTotalResult([monthlyWork, monthlyVacation, monthlySick]);
    const monthlyCost = monthlyTotal.salaryCost + monthlyTotal.bonusCost || 1;

    // Calculate ratio for work, vacation and sick
    const workRatio = (monthlyWork.workCost + monthlyWork.bonusCost) / monthlyCost;
    const breaksRatio = monthlyWork.breaksCost / monthlyCost;
    const vacationRatio = (monthlyVacation.salaryCost + monthlyVacation.bonusCost) / monthlyCost;
    const sickRatio = (monthlySick.salaryCost + monthlySick.bonusCost) / monthlyCost;

    // Hourly work goes on top
    const hourlyWork = calcTotalResult(
        time.distinct.filter((h) => [TimeEntryType.Work, TimeEntryType.CompDay].includes(h.type!) && !h.monthlyCost)
    );
    const hourlyVacation = calcTotalResult(
        time.distinct.filter((h) => h.type === TimeEntryType.Vacation && !h.monthlyCost)
    );
    const hourlySick = calcTotalResult(
        time.distinct.filter(
            (h) =>
                [TimeEntryType.Sick, TimeEntryType.ChildSick, TimeEntryType.SickInKUG].includes(h.type!) &&
                !h.monthlyCost
        )
    );

    return newCostStatement({
        totalGross:
            monthlyTotal.monthlyGross + hourlyWork.salaryGross + hourlyVacation.salaryGross + hourlySick.salaryGross,
        work: monthlyTotal.monthlyCost * workRatio + hourlyWork.workCost,
        breaks: monthlyTotal.monthlyCost * breaksRatio + hourlyWork.breaksCost,
        vacation: monthlyTotal.monthlyCost * vacationRatio + hourlyVacation.salaryCost,
        sick: monthlyTotal.monthlyCost * sickRatio + hourlySick.salaryCost,
        bonus: time.total.bonusCost,
        commission: time.total.commissionCost,
        benefits: contract.benefits.reduce((total, benefit) => total + benefit.amount, 0),
        savings,
    });
}

export function getMonthlyIssues(
    company: Company,
    employee: Employee,
    entries: TimeEntry[],
    time: TimeStatement,
    cost: CostStatement,
    { from, to }: DateRange
) {
    const issues = getIssues(employee, company, entries, { from, to });
    const contract = employee.getContractForRange({ from, to });

    if (!contract) {
        return issues;
    }

    const lastOfMonth = dateAdd(to, { days: -1 });

    if (!contract.annualTimeSheet) {
        const minSalary = getMinimumHourlyRate(from);
        const hours = calcTotalResult(
            time.distinct.filter((h) =>
                [
                    TimeEntryType.Work,
                    TimeEntryType.Vacation,
                    TimeEntryType.Sick,
                    TimeEntryType.CompDay,
                    TimeEntryType.ChildSick,
                    TimeEntryType.SickInKUG,
                ].includes(h.type!)
            )
        );

        const effectiveHourlyRate = Math.round((cost.totalGross / hours.paid) * 100) / 100;

        if (
            contract.employmentType !== EmploymentType.Trainee &&
            hours.paid &&
            cost.totalGross &&
            effectiveHourlyRate < minSalary
        ) {
            issues.push(
                new Issue({
                    type: IssueType.DeficientSalary,
                    date: lastOfMonth,
                    employee: employee.id,
                    additionalInfo: {
                        hours: hours.paid,
                        salary: cost.totalGross,
                        effectiveHourlyRate,
                        minHourlyRate: minSalary,
                    },
                    ignored: contract.ignoreIssues.some(
                        ({ type, date }) => type === IssueType.DeficientSalary && date === lastOfMonth
                    ),
                })
            );
        }
    }

    if (
        [EmploymentType.Marginal, EmploymentType.MidiJob].includes(contract.employmentType) &&
        cost.totalGross > contract.maxSalary
    ) {
        issues.push(
            new Issue({
                type: IssueType.ExcessiveSalary,
                date: lastOfMonth,
                employee: employee.id,
                additionalInfo: {
                    maxSalary: contract.maxSalary,
                    actualSalary: cost.totalGross,
                },
                ignored: contract.ignoreIssues.some(
                    ({ type, date }) => type === IssueType.ExcessiveSalary && date === lastOfMonth
                ),
            })
        );
    }

    return issues;
}

export function getMonthlyStatement(
    employee: Employee,
    company: Company,
    entries: TimeEntry[],
    year: number,
    month: number,
    prev: MonthlyStatement[],
    previousAverages?: PreviousAverages,
    mode?: CalcHoursMode,
    vacationEntries = entries.filter((e) => [TimeEntryType.Vacation, TimeEntryType.VacationAdjustment].includes(e.type))
) {
    const { from, to } = getRange(toDateString(new Date(year, month, 1)), "month");
    const last = prev[prev.length - 1];

    if (last && last.year * 12 + last.month !== year * 12 + month - 1) {
        throw new Error(
            `Falscher Vormonat! Mitarbeiter: ${employee.id}; Jahr: ${year}; Monat: ${month} - ${last.year}, ${last.month}`
        );
    }

    // Make sure only entries for this employee are considered!
    entries = entries.filter((e) => e.employeeId === employee.id && !e.deleted);
    vacationEntries = vacationEntries.filter((e) => e.employeeId === employee.id && !e.deleted);

    // Find TimeEntry for resetting balance, if any
    const reset = entries.find(
        (entry) => entry.type === TimeEntryType.ResetLedgers && entry.date >= from && entry.date < to
    );

    if (!previousAverages) {
        previousAverages = getPreviousAverages(prev.flatMap((s) => s.items));

        // Before 2022, we were using a less accurate way of calculating previous averages
        if (from < (company.settings.startUsingMoreAccurateAverage || "2022-01-01")) {
            previousAverages.hoursPerWorkDay = calcAverageHours(
                prev
                    .slice(-3)
                    .filter((s) => !!s.time.actual)
                    .map((m) => m.time.average)
            );
        }
    }

    const { time, items } = getTimeStatement(
        company,
        employee,
        entries,
        { from, to },
        last && last.time.balance,
        previousAverages,
        reset && typeof reset.hours === "number" ? reset.hours : undefined,
        mode
    );

    const vacation = getVacationStatement(
        company,
        employee,
        vacationEntries,
        { from, to },
        time,
        last && last.vacation.balance,
        last ? (month === 0 ? last.vacation.balance : last.vacation.carryYear) : 0,
        reset && typeof reset.days === "number" ? reset.days : undefined,
        last && month !== 0 ? last.vacation.startYear : undefined
    );

    const sfnLedgerEnabled = employee.getAllContractsForRange({ from, to }).some((c) => c.enableSFNLedger);
    const bonusCarry = month === 0 ? 0 : last?.bonus.balance || 0;
    const bonus = getBonusStatement(
        company,
        employee,
        time,
        { from, to },
        sfnLedgerEnabled ? bonusCarry : undefined,
        reset && typeof reset.resetBonus === "number" ? reset.resetBonus : undefined
    );

    const cost = getCostStatement(company, employee, entries, time, { from, to }, previousAverages);

    const issues = getMonthlyIssues(company, employee, entries, time, cost, { from, to });

    return new MonthlyStatement({
        employeeId: employee.id,
        year,
        month,
        time,
        vacation,
        bonus,
        cost,
        items,
        issues,
        reset,
        updated: new Date(),
        previousAverages,
        version: getVersion(),
    });
}

export function recalcMonthlyStatement(
    employee: Employee,
    company: Company,
    statement: MonthlyStatement,
    entries: TimeEntry[] = statement.items?.map((item) =>
        item.entry instanceof TimeEntry ? item.entry : plainToClass(TimeEntry, item.entry as any)
    ) || [],
    mode?: CalcHoursMode
) {
    const commitBefore = company.settings.commitTimeEntriesBefore;
    if (commitBefore && statement.to <= commitBefore) {
        for (const item of statement.items) {
            if (!(item.entry instanceof TimeEntry)) {
                item.entry = plainToClass(TimeEntry, item.entry as any);
            }
        }
        return statement;
    }
    const prevStatement = new MonthlyStatement();
    prevStatement.time.balance = statement.time.carry;
    prevStatement.vacation.balance = statement.vacation.carry;
    prevStatement.vacation.carryYear = statement.vacation.carryYear;
    prevStatement.vacation.startYear = statement.vacation.startYear;
    prevStatement.bonus.balance = statement.bonus.carry;
    prevStatement.year = statement.month ? statement.year : statement.year - 1;
    prevStatement.month = statement.month ? statement.month - 1 : 11;
    const vacationOtherMonths = statement.vacation.actualYear - statement.vacation.actual;
    const re = getMonthlyStatement(
        employee,
        company,
        entries,
        statement.year,
        statement.month,
        [prevStatement],
        statement.previousAverages,
        mode
    );
    re.vacation.carryYear = statement.vacation.carryYear;
    re.vacation.actualYear = vacationOtherMonths + re.vacation.actual;
    re.time.reset = statement.time.reset;
    re.vacation.reset = statement.vacation.reset;
    re.bonus.reset = statement.bonus.reset;
    return re;
}

export function getEmployeeStatements({
    company,
    employee,
    calcFrom,
    cached,
    entries,
    vacationEntries,
    to,
}: GetEmployeeStatementsParams): MonthlyStatement[] {
    if (!calcFrom) {
        return [];
    }

    const earliestDate = entries.length && getRange(entries[0].date, "month").from;

    if (!cached.length && earliestDate && earliestDate > calcFrom) {
        calcFrom = earliestDate;
    }

    calcFrom = getRange(calcFrom, "month").from;

    const statements = cached as MonthlyStatement[];
    const updated: MonthlyStatement[] = [];

    while (calcFrom < to) {
        const d = parseDateString(calcFrom)!;
        const year = d.getFullYear();
        const month = d.getMonth();

        const statement = getMonthlyStatement(
            employee,
            company,
            entries,
            year,
            month,
            statements,
            undefined,
            undefined,
            vacationEntries
        );

        statements.push(statement);
        updated.push(statement);
        calcFrom = dateAdd(calcFrom, { months: 1 });
    }

    return updated;
}

export function migrateTimeEntryTimesAndMeals(
    entry: TimeEntry & {
        startPlanned: Date | string | null;
        endPlanned: Date | string | null;
        startLogged: Date | string | null;
        endLogged: Date | string | null;
        startFinal: Date | string | null;
        endFinal: Date | string | null;
        meals?: number;
    }
) {
    if (
        (typeof entry.startPlanned === "string" && !entry.startPlanned.includes("T")) ||
        (typeof entry.endPlanned === "string" && !entry.endPlanned.includes("T"))
    ) {
        const [startPlanned, endPlanned] = parseTimes(
            entry.date,
            entry.startPlanned as string,
            entry.endPlanned as string
        );
        entry.startPlanned = startPlanned;
        entry.endPlanned = endPlanned;
    }
    if (
        (typeof entry.startLogged === "string" && !entry.startLogged.includes("T")) ||
        (typeof entry.endLogged === "string" && !entry.endLogged.includes("T"))
    ) {
        const [startLogged, endLogged] = parseTimes(entry.date, entry.startLogged as string, entry.endLogged as string);
        entry.startLogged = startLogged;
        entry.endLogged = endLogged;
    }
    if (
        (typeof entry.startFinal === "string" && !entry.startFinal.includes("T")) ||
        (typeof entry.endFinal === "string" && !entry.endFinal.includes("T"))
    ) {
        const [startFinal, endFinal] = parseTimes(entry.date, entry.startFinal as string, entry.endFinal as string);
        entry.startFinal = startFinal;
        entry.endFinal = endFinal;
    }

    entry.mealsBreakfast = entry.mealsBreakfast || (0 as Meals);
    entry.mealsDinner = entry.mealsDinner || (0 as Meals);
    entry.mealsLunch = (entry.mealsLunch || entry.meals || 0) as Meals;
}

/**
 * Migrates time entry times (<= v1.25.0) to new structure
 * @deprecated
 */
export function migrateLegacyTimesAndMeals(statement: MonthlyStatement) {
    statement.items?.forEach(({ entry }) => migrateTimeEntryTimesAndMeals(entry));
    statement.issues?.forEach((issue) => issue.timeEntries.forEach((entry) => migrateTimeEntryTimesAndMeals(entry)));
}

/**
 * Migrates old-style store averages (<= v1.20.1) to new structure
 * @deprecated
 */
export function migrateLegacyAverages(statement: MonthlyStatement) {
    if (!statement.previousAverages) {
        statement.previousAverages = {
            hoursPerWorkDay: statement.time.previousAverage,
            hoursByWeekDay: statement.time.averageByDay,
            workDaysPerWeek: statement.time.averageDaysPerWeek,
            commonWorkDays: statement.time.commonWorkDays,
        };
    }

    statement.time.previousAverage = statement.previousAverages.hoursPerWorkDay;
    statement.time.averageByDay = statement.previousAverages.hoursByWeekDay;
    statement.time.averageDaysPerWeek = statement.previousAverages.workDaysPerWeek;
    statement.time.commonWorkDays = statement.previousAverages.commonWorkDays;
}

/**
 * Migrates old bonuses (< v1.24.0) to new architecture
 * @deprecated
 */
export function migrateLegacyBonuses(company: Company, statement: MonthlyStatement) {
    if (statement.time.total.bonuses) {
        return;
    }

    const employee = company.getEmployee(statement.employeeId);
    const contract = employee?.getContractForRange(statement);
    if (!contract || !statement.items) {
        return;
    }

    for (const { result } of statement.items) {
        result.bonuses = [];

        for (const legalType of Object.values(BonusLegalType)) {
            if (result[legalType]) {
                const bonus = contract.bonuses.find(
                    (bonus) => company.bonusTypes.find((type) => type.id === bonus.typeId)?.legalType === legalType
                );
                if (bonus) {
                    result.bonuses.push({
                        bonusId: bonus.id,
                        bonusTypeId: bonus.typeId,
                        percent: bonus.percent,
                        hourlyRate: getBonusHourlyRate(company, contract),
                        duration: result[legalType],
                        taxFree: result.type === TimeEntryType.Work,
                    });
                }
            }
        }
    }
}
