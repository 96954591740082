import { html, css, TemplateResult } from "lit";
import { customElement, property } from "lit/decorators.js";
import { getSingleton } from "../lib/singleton";
import { Dialog } from "./dialog";

const defaultButtonLabel = "OK";

export type AlertType = "info" | "warning" | "destructive" | "question" | "success";

export type AlertOption =
    | TemplateResult
    | string
    | { label: TemplateResult | string; value?: string | boolean | number; buttonClass?: string };

export interface AlertOptions {
    message?: string | TemplateResult;
    title?: string;
    options?: AlertOption[];
    type?: AlertType;
    icon?: string;
    preventDismiss?: boolean;
    hideIcon?: boolean;
    optionsLayout?: "horizontal" | "vertical";
    maxWidth?: string;
}

@customElement("pl-alert-dialog")
export class AlertDialog extends Dialog<AlertOptions, string | boolean | number> {
    @property()
    buttonLabel: string = defaultButtonLabel;
    @property()
    dialogTitle: string = "";
    @property()
    message: string | TemplateResult = "";
    @property({ reflect: true, attribute: "type" })
    type: AlertType = "info";
    @property()
    icon = "";
    @property({ attribute: false })
    options: AlertOption[] = [];
    @property({ type: Boolean })
    hideIcon: boolean = false;
    @property()
    optionsLayout: "horizontal" | "vertical" = "horizontal";
    @property()
    maxWidth: string = "28em";

    static styles = [
        ...Dialog.styles,
        css`
            :host {
                z-index: 500;
            }

            .inner {
                padding: 0.5em;
            }

            .alert-icon {
                font-size: 3rem;
                margin: 0.1em;
            }

            .title {
                font-weight: bold;
                font-size: var(--font-size-large);
                margin: 0.5em;
            }

            .text {
                margin: 0.5em;
            }
        `,
    ];

    renderContent() {
        const { message, dialogTitle, options, optionsLayout, icon } = this;
        return html`
            <div class="start-aligning horizontal layout" ?hidden=${!dialogTitle && !message}>
                <i class="${icon} alert-icon" ?hidden=${this.hideIcon}></i>
                <div class="stretch">
                    <div class="title" ?hidden=${!dialogTitle}>${dialogTitle}</div>
                    <div class="text">${message}</div>
                </div>
            </div>

            <div class="top-margined spacing ${optionsLayout} layout">
                ${options.map((o: AlertOption, i: number) => {
                    const isPlain = typeof o === "string" || !!o["_$litType$" as keyof typeof o];
                    return html`
                        <button
                            class="tap stretch ${(!isPlain && (o as any).buttonClass) || this._buttonClass(i)}"
                            @click=${() =>
                                this.done(isPlain || typeof (o as any).value === "undefined" ? i : (o as any).value)}
                        >
                            ${isPlain ? o : (o as any).label}
                        </button>
                    `;
                })}
            </div>
        `;
    }

    done(i: string | boolean | number = -1) {
        super.done(i);
    }

    show({
        message = "",
        title = "",
        options = ["OK"],
        type = "info",
        preventDismiss = false,
        icon,
        hideIcon = false,
        optionsLayout,
        maxWidth = "28em",
    }: AlertOptions = {}) {
        this.message = message;
        this.dialogTitle = title;
        this.type = type;
        this.preventDismiss = preventDismiss;
        this.options = options;
        this.icon = icon || this._icon(type);
        this.hideIcon = hideIcon;
        this.optionsLayout = optionsLayout || (this.options.length > 2 ? "horizontal" : "vertical");
        this.maxWidth = maxWidth;

        this.style.setProperty("--dialog-max-width", maxWidth);

        return super.show();
    }

    private _icon(type: string) {
        switch (type) {
            case "info":
                return "info-circle";
            case "warning":
                return "exclamation-triangle";
            case "success":
                return "check";
            case "question":
            case "destructive":
                return "question-circle";
            default:
                return "";
        }
    }

    private _buttonClass(i: number) {
        if (i === 0) {
            return this.type === "destructive" ? "negative" : "primary";
        } else {
            return "transparent";
        }
    }
}

export async function alert(message: string | TemplateResult, opts: AlertOptions = {}) {
    const dialog = getSingleton("pl-alert-dialog") as AlertDialog;
    if (dialog.promise) {
        await dialog.promise;
    }
    return dialog.show({ ...opts, message });
}

export async function confirm(
    message: string | TemplateResult,
    confirmLabel: string = "OK",
    cancelLabel = "Abbrechen",
    opts: AlertOptions = {}
) {
    const choice = await alert(message, { ...opts, options: [confirmLabel, cancelLabel] });
    return choice === 0;
}
