import { css } from "lit";

export const icons = css`
    .fa,
    i {
        font-family: "Font Awesome 6 Pro";
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        line-height: 1;
        text-rendering: auto;
        width: 1.5em;
        height: 1.5em;
        line-height: 1.5em;
        border-radius: 1.5em;
        text-align: center;
    }

    /* font awesome uses the unicode private use area (pua) to ensure screen
readers do not read off random characters that represent icons */
    .fa-0::before {
        content: "\\30";
    }

    .fa-1::before {
        content: "\\31";
    }

    .fa-2::before {
        content: "\\32";
    }

    .fa-3::before {
        content: "\\33";
    }

    .fa-4::before {
        content: "\\34";
    }

    .fa-5::before {
        content: "\\35";
    }

    .fa-6::before {
        content: "\\36";
    }

    .fa-7::before {
        content: "\\37";
    }

    .fa-8::before {
        content: "\\38";
    }

    .fa-9::before {
        content: "\\39";
    }

    .fa-00::before {
        content: "\\e467";
    }

    .fa-360-degrees::before {
        content: "\\e2dc";
    }

    .fa-a::before,
    i.a::before {
        content: "\\41";
    }

    .fa-abacus::before,
    i.abacus::before {
        content: "\\f640";
    }

    .fa-accent-grave::before,
    i.accent-grave::before {
        content: "\\60";
    }

    .fa-acorn::before,
    i.acorn::before {
        content: "\\f6ae";
    }

    .fa-address-book::before,
    i.address-book::before {
        content: "\\f2b9";
    }

    .fa-contact-book::before,
    i.contact-book::before {
        content: "\\f2b9";
    }

    .fa-address-card::before,
    i.address-card::before {
        content: "\\f2bb";
    }

    .fa-contact-card::before,
    i.contact-card::before {
        content: "\\f2bb";
    }

    .fa-vcard::before,
    i.vcard::before {
        content: "\\f2bb";
    }

    .fa-air-conditioner::before,
    i.air-conditioner::before {
        content: "\\f8f4";
    }

    .fa-airplay::before,
    i.airplay::before {
        content: "\\e089";
    }

    .fa-alarm-clock::before,
    i.alarm-clock::before {
        content: "\\f34e";
    }

    .fa-alarm-exclamation::before,
    i.alarm-exclamation::before {
        content: "\\f843";
    }

    .fa-alarm-plus::before,
    i.alarm-plus::before {
        content: "\\f844";
    }

    .fa-alarm-snooze::before,
    i.alarm-snooze::before {
        content: "\\f845";
    }

    .fa-album::before,
    i.album::before {
        content: "\\f89f";
    }

    .fa-album-circle-plus::before,
    i.album-circle-plus::before {
        content: "\\e48c";
    }

    .fa-album-circle-user::before,
    i.album-circle-user::before {
        content: "\\e48d";
    }

    .fa-album-collection::before,
    i.album-collection::before {
        content: "\\f8a0";
    }

    .fa-album-collection-circle-plus::before,
    i.album-collection-circle-plus::before {
        content: "\\e48e";
    }

    .fa-album-collection-circle-user::before,
    i.album-collection-circle-user::before {
        content: "\\e48f";
    }

    .fa-alicorn::before,
    i.alicorn::before {
        content: "\\f6b0";
    }

    .fa-alien::before,
    i.alien::before {
        content: "\\f8f5";
    }

    .fa-alien-8bit::before,
    i.alien-8bit::before {
        content: "\\f8f6";
    }

    .fa-alien-monster::before,
    i.alien-monster::before {
        content: "\\f8f6";
    }

    .fa-align-center::before,
    i.align-center::before {
        content: "\\f037";
    }

    .fa-align-justify::before,
    i.align-justify::before {
        content: "\\f039";
    }

    .fa-align-left::before,
    i.align-left::before {
        content: "\\f036";
    }

    .fa-align-right::before,
    i.align-right::before {
        content: "\\f038";
    }

    .fa-align-slash::before,
    i.align-slash::before {
        content: "\\f846";
    }

    .fa-alt::before,
    i.alt::before {
        content: "\\e08a";
    }

    .fa-amp-guitar::before,
    i.amp-guitar::before {
        content: "\\f8a1";
    }

    .fa-ampersand::before,
    i.ampersand::before {
        content: "\\26";
    }

    .fa-anchor::before,
    i.anchor::before {
        content: "\\f13d";
    }

    .fa-anchor-circle-check::before,
    i.anchor-circle-check::before {
        content: "\\e4aa";
    }

    .fa-anchor-circle-exclamation::before,
    i.anchor-circle-exclamation::before {
        content: "\\e4ab";
    }

    .fa-anchor-circle-xmark::before,
    i.anchor-circle-xmark::before {
        content: "\\e4ac";
    }

    .fa-anchor-lock::before,
    i.anchor-lock::before {
        content: "\\e4ad";
    }

    .fa-angel::before,
    i.angel::before {
        content: "\\f779";
    }

    .fa-angle::before,
    i.angle::before {
        content: "\\e08c";
    }

    .fa-angle-90::before,
    i.angle-90::before {
        content: "\\e08d";
    }

    .fa-angle-down::before,
    i.angle-down::before {
        content: "\\f107";
    }

    .fa-angle-left::before,
    i.angle-left::before {
        content: "\\f104";
    }

    .fa-angle-right::before,
    i.angle-right::before {
        content: "\\f105";
    }

    .fa-angle-up::before,
    i.angle-up::before {
        content: "\\f106";
    }

    .fa-angles-down::before,
    i.angles-down::before {
        content: "\\f103";
    }

    .fa-angle-double-down::before,
    i.angle-double-down::before {
        content: "\\f103";
    }

    .fa-angles-left::before,
    i.angles-left::before {
        content: "\\f100";
    }

    .fa-angle-double-left::before,
    i.angle-double-left::before {
        content: "\\f100";
    }

    .fa-angles-right::before,
    i.angles-right::before {
        content: "\\f101";
    }

    .fa-angle-double-right::before,
    i.angle-double-right::before {
        content: "\\f101";
    }

    .fa-angles-up::before,
    i.angles-up::before {
        content: "\\f102";
    }

    .fa-angle-double-up::before,
    i.angle-double-up::before {
        content: "\\f102";
    }

    .fa-ankh::before,
    i.ankh::before {
        content: "\\f644";
    }

    .fa-apartment::before,
    i.apartment::before {
        content: "\\e468";
    }

    .fa-aperture::before,
    i.aperture::before {
        content: "\\e2df";
    }

    .fa-apostrophe::before,
    i.apostrophe::before {
        content: "\\27";
    }

    .fa-apple-core::before,
    i.apple-core::before {
        content: "\\e08f";
    }

    .fa-apple-whole::before,
    i.apple-whole::before {
        content: "\\f5d1";
    }

    .fa-apple-alt::before,
    i.apple-alt::before {
        content: "\\f5d1";
    }

    .fa-archway::before,
    i.archway::before {
        content: "\\f557";
    }

    .fa-arrow-down::before,
    i.arrow-down::before {
        content: "\\f063";
    }

    .fa-arrow-down-1-9::before,
    i.arrow-down-1-9::before {
        content: "\\f162";
    }

    .fa-sort-numeric-asc::before,
    i.sort-numeric-asc::before {
        content: "\\f162";
    }

    .fa-sort-numeric-down::before,
    i.sort-numeric-down::before {
        content: "\\f162";
    }

    .fa-arrow-down-9-1::before,
    i.arrow-down-9-1::before {
        content: "\\f886";
    }

    .fa-sort-numeric-desc::before,
    i.sort-numeric-desc::before {
        content: "\\f886";
    }

    .fa-sort-numeric-down-alt::before,
    i.sort-numeric-down-alt::before {
        content: "\\f886";
    }

    .fa-arrow-down-a-z::before,
    i.arrow-down-a-z::before {
        content: "\\f15d";
    }

    .fa-sort-alpha-asc::before,
    i.sort-alpha-asc::before {
        content: "\\f15d";
    }

    .fa-sort-alpha-down::before,
    i.sort-alpha-down::before {
        content: "\\f15d";
    }

    .fa-arrow-down-arrow-up::before,
    i.arrow-down-arrow-up::before {
        content: "\\f883";
    }

    .fa-sort-alt::before,
    i.sort-alt::before {
        content: "\\f883";
    }

    .fa-arrow-down-big-small::before,
    i.arrow-down-big-small::before {
        content: "\\f88c";
    }

    .fa-sort-size-down::before,
    i.sort-size-down::before {
        content: "\\f88c";
    }

    .fa-arrow-down-from-dotted-line::before,
    i.arrow-down-from-dotted-line::before {
        content: "\\e090";
    }

    .fa-arrow-down-from-line::before,
    i.arrow-down-from-line::before {
        content: "\\f345";
    }

    .fa-arrow-from-top::before,
    i.arrow-from-top::before {
        content: "\\f345";
    }

    .fa-arrow-down-left::before,
    i.arrow-down-left::before {
        content: "\\e091";
    }

    .fa-arrow-down-left-and-arrow-up-right-to-center::before,
    i.arrow-down-left-and-arrow-up-right-to-center::before {
        content: "\\e092";
    }

    .fa-arrow-down-long::before,
    i.arrow-down-long::before {
        content: "\\f175";
    }

    .fa-long-arrow-down::before,
    i.long-arrow-down::before {
        content: "\\f175";
    }

    .fa-arrow-down-right::before,
    i.arrow-down-right::before {
        content: "\\e093";
    }

    .fa-arrow-down-short-wide::before,
    i.arrow-down-short-wide::before {
        content: "\\f884";
    }

    .fa-sort-amount-desc::before,
    i.sort-amount-desc::before {
        content: "\\f884";
    }

    .fa-sort-amount-down-alt::before,
    i.sort-amount-down-alt::before {
        content: "\\f884";
    }

    .fa-arrow-down-small-big::before,
    i.arrow-down-small-big::before {
        content: "\\f88d";
    }

    .fa-sort-size-down-alt::before,
    i.sort-size-down-alt::before {
        content: "\\f88d";
    }

    .fa-arrow-down-square-triangle::before,
    i.arrow-down-square-triangle::before {
        content: "\\f889";
    }

    .fa-sort-shapes-down-alt::before,
    i.sort-shapes-down-alt::before {
        content: "\\f889";
    }

    .fa-arrow-down-to-arc::before,
    i.arrow-down-to-arc::before {
        content: "\\e4ae";
    }

    .fa-arrow-down-to-bracket::before,
    i.arrow-down-to-bracket::before {
        content: "\\e094";
    }

    .fa-arrow-down-to-dotted-line::before,
    i.arrow-down-to-dotted-line::before {
        content: "\\e095";
    }

    .fa-arrow-down-to-line::before,
    i.arrow-down-to-line::before {
        content: "\\f33d";
    }

    .fa-arrow-to-bottom::before,
    i.arrow-to-bottom::before {
        content: "\\f33d";
    }

    .fa-arrow-down-to-square::before,
    i.arrow-down-to-square::before {
        content: "\\e096";
    }

    .fa-arrow-down-triangle-square::before,
    i.arrow-down-triangle-square::before {
        content: "\\f888";
    }

    .fa-sort-shapes-down::before,
    i.sort-shapes-down::before {
        content: "\\f888";
    }

    .fa-arrow-down-up-across-line::before,
    i.arrow-down-up-across-line::before {
        content: "\\e4af";
    }

    .fa-arrow-down-up-lock::before,
    i.arrow-down-up-lock::before {
        content: "\\e4b0";
    }

    .fa-arrow-down-wide-short::before,
    i.arrow-down-wide-short::before {
        content: "\\f160";
    }

    .fa-sort-amount-asc::before,
    i.sort-amount-asc::before {
        content: "\\f160";
    }

    .fa-sort-amount-down::before,
    i.sort-amount-down::before {
        content: "\\f160";
    }

    .fa-arrow-down-z-a::before,
    i.arrow-down-z-a::before {
        content: "\\f881";
    }

    .fa-sort-alpha-desc::before,
    i.sort-alpha-desc::before {
        content: "\\f881";
    }

    .fa-sort-alpha-down-alt::before,
    i.sort-alpha-down-alt::before {
        content: "\\f881";
    }

    .fa-arrow-left::before,
    i.arrow-left::before {
        content: "\\f060";
    }

    .fa-arrow-left-from-line::before,
    i.arrow-left-from-line::before {
        content: "\\f344";
    }

    .fa-arrow-from-right::before,
    i.arrow-from-right::before {
        content: "\\f344";
    }

    .fa-arrow-left-long::before,
    i.arrow-left-long::before {
        content: "\\f177";
    }

    .fa-long-arrow-left::before,
    i.long-arrow-left::before {
        content: "\\f177";
    }

    .fa-arrow-left-long-to-line::before,
    i.arrow-left-long-to-line::before {
        content: "\\e3d4";
    }

    .fa-arrow-left-to-line::before,
    i.arrow-left-to-line::before {
        content: "\\f33e";
    }

    .fa-arrow-to-left::before,
    i.arrow-to-left::before {
        content: "\\f33e";
    }

    .fa-arrow-pointer::before,
    i.arrow-pointer::before {
        content: "\\f245";
    }

    .fa-mouse-pointer::before,
    i.mouse-pointer::before {
        content: "\\f245";
    }

    .fa-arrow-right::before,
    i.arrow-right::before {
        content: "\\f061";
    }

    .fa-arrow-right-arrow-left::before,
    i.arrow-right-arrow-left::before {
        content: "\\f0ec";
    }

    .fa-exchange::before,
    i.exchange::before {
        content: "\\f0ec";
    }

    .fa-arrow-right-from-arc::before,
    i.arrow-right-from-arc::before {
        content: "\\e4b1";
    }

    .fa-arrow-right-from-bracket::before,
    i.arrow-right-from-bracket::before {
        content: "\\f08b";
    }

    .fa-sign-out::before,
    i.sign-out::before {
        content: "\\f08b";
    }

    .fa-arrow-right-from-line::before,
    i.arrow-right-from-line::before {
        content: "\\f343";
    }

    .fa-arrow-from-left::before,
    i.arrow-from-left::before {
        content: "\\f343";
    }

    .fa-arrow-right-long::before,
    i.arrow-right-long::before {
        content: "\\f178";
    }

    .fa-long-arrow-right::before,
    i.long-arrow-right::before {
        content: "\\f178";
    }

    .fa-arrow-right-long-to-line::before,
    i.arrow-right-long-to-line::before {
        content: "\\e3d5";
    }

    .fa-arrow-right-to-arc::before,
    i.arrow-right-to-arc::before {
        content: "\\e4b2";
    }

    .fa-arrow-right-to-bracket::before,
    i.arrow-right-to-bracket::before {
        content: "\\f090";
    }

    .fa-sign-in::before,
    i.sign-in::before {
        content: "\\f090";
    }

    .fa-arrow-right-to-city::before,
    i.arrow-right-to-city::before {
        content: "\\e4b3";
    }

    .fa-arrow-right-to-line::before,
    i.arrow-right-to-line::before {
        content: "\\f340";
    }

    .fa-arrow-to-right::before,
    i.arrow-to-right::before {
        content: "\\f340";
    }

    .fa-arrow-rotate-left::before,
    i.arrow-rotate-left::before {
        content: "\\f0e2";
    }

    .fa-arrow-left-rotate::before,
    i.arrow-left-rotate::before {
        content: "\\f0e2";
    }

    .fa-arrow-rotate-back::before,
    i.arrow-rotate-back::before {
        content: "\\f0e2";
    }

    .fa-arrow-rotate-backward::before,
    i.arrow-rotate-backward::before {
        content: "\\f0e2";
    }

    .fa-undo::before,
    i.undo::before {
        content: "\\f0e2";
    }

    .fa-arrow-rotate-right::before,
    i.arrow-rotate-right::before {
        content: "\\f01e";
    }

    .fa-arrow-right-rotate::before,
    i.arrow-right-rotate::before {
        content: "\\f01e";
    }

    .fa-arrow-rotate-forward::before,
    i.arrow-rotate-forward::before {
        content: "\\f01e";
    }

    .fa-redo::before,
    i.redo::before {
        content: "\\f01e";
    }

    .fa-arrow-trend-down::before,
    i.arrow-trend-down::before {
        content: "\\e097";
    }

    .fa-arrow-trend-up::before,
    i.arrow-trend-up::before {
        content: "\\e098";
    }

    .fa-arrow-turn-down::before,
    i.arrow-turn-down::before {
        content: "\\f149";
    }

    .fa-level-down::before,
    i.level-down::before {
        content: "\\f149";
    }

    .fa-arrow-turn-down-left::before,
    i.arrow-turn-down-left::before {
        content: "\\e2e1";
    }

    .fa-arrow-turn-down-right::before,
    i.arrow-turn-down-right::before {
        content: "\\e3d6";
    }

    .fa-arrow-turn-up::before,
    i.arrow-turn-up::before {
        content: "\\f148";
    }

    .fa-level-up::before,
    i.level-up::before {
        content: "\\f148";
    }

    .fa-arrow-up::before,
    i.arrow-up::before {
        content: "\\f062";
    }

    .fa-arrow-up-1-9::before,
    i.arrow-up-1-9::before {
        content: "\\f163";
    }

    .fa-sort-numeric-up::before,
    i.sort-numeric-up::before {
        content: "\\f163";
    }

    .fa-arrow-up-9-1::before,
    i.arrow-up-9-1::before {
        content: "\\f887";
    }

    .fa-sort-numeric-up-alt::before,
    i.sort-numeric-up-alt::before {
        content: "\\f887";
    }

    .fa-arrow-up-a-z::before,
    i.arrow-up-a-z::before {
        content: "\\f15e";
    }

    .fa-sort-alpha-up::before,
    i.sort-alpha-up::before {
        content: "\\f15e";
    }

    .fa-arrow-up-arrow-down::before,
    i.arrow-up-arrow-down::before {
        content: "\\e099";
    }

    .fa-sort-up-down::before,
    i.sort-up-down::before {
        content: "\\e099";
    }

    .fa-arrow-up-big-small::before,
    i.arrow-up-big-small::before {
        content: "\\f88e";
    }

    .fa-sort-size-up::before,
    i.sort-size-up::before {
        content: "\\f88e";
    }

    .fa-arrow-up-from-arc::before,
    i.arrow-up-from-arc::before {
        content: "\\e4b4";
    }

    .fa-arrow-up-from-bracket::before,
    i.arrow-up-from-bracket::before {
        content: "\\e09a";
    }

    .fa-arrow-up-from-dotted-line::before,
    i.arrow-up-from-dotted-line::before {
        content: "\\e09b";
    }

    .fa-arrow-up-from-ground-water::before,
    i.arrow-up-from-ground-water::before {
        content: "\\e4b5";
    }

    .fa-arrow-up-from-line::before,
    i.arrow-up-from-line::before {
        content: "\\f342";
    }

    .fa-arrow-from-bottom::before,
    i.arrow-from-bottom::before {
        content: "\\f342";
    }

    .fa-arrow-up-from-square::before,
    i.arrow-up-from-square::before {
        content: "\\e09c";
    }

    .fa-arrow-up-from-water-pump::before,
    i.arrow-up-from-water-pump::before {
        content: "\\e4b6";
    }

    .fa-arrow-up-left::before,
    i.arrow-up-left::before {
        content: "\\e09d";
    }

    .fa-arrow-up-left-from-circle::before,
    i.arrow-up-left-from-circle::before {
        content: "\\e09e";
    }

    .fa-arrow-up-long::before,
    i.arrow-up-long::before {
        content: "\\f176";
    }

    .fa-long-arrow-up::before,
    i.long-arrow-up::before {
        content: "\\f176";
    }

    .fa-arrow-up-right::before,
    i.arrow-up-right::before {
        content: "\\e09f";
    }

    .fa-arrow-up-right-and-arrow-down-left-from-center::before,
    i.arrow-up-right-and-arrow-down-left-from-center::before {
        content: "\\e0a0";
    }

    .fa-arrow-up-right-dots::before,
    i.arrow-up-right-dots::before {
        content: "\\e4b7";
    }

    .fa-arrow-up-right-from-square::before,
    i.arrow-up-right-from-square::before {
        content: "\\f08e";
    }

    .fa-external-link::before,
    i.external-link::before {
        content: "\\f08e";
    }

    .fa-arrow-up-short-wide::before,
    i.arrow-up-short-wide::before {
        content: "\\f885";
    }

    .fa-sort-amount-up-alt::before,
    i.sort-amount-up-alt::before {
        content: "\\f885";
    }

    .fa-arrow-up-small-big::before,
    i.arrow-up-small-big::before {
        content: "\\f88f";
    }

    .fa-sort-size-up-alt::before,
    i.sort-size-up-alt::before {
        content: "\\f88f";
    }

    .fa-arrow-up-square-triangle::before,
    i.arrow-up-square-triangle::before {
        content: "\\f88b";
    }

    .fa-sort-shapes-up-alt::before,
    i.sort-shapes-up-alt::before {
        content: "\\f88b";
    }

    .fa-arrow-up-to-dotted-line::before,
    i.arrow-up-to-dotted-line::before {
        content: "\\e0a1";
    }

    .fa-arrow-up-to-line::before,
    i.arrow-up-to-line::before {
        content: "\\f341";
    }

    .fa-arrow-to-top::before,
    i.arrow-to-top::before {
        content: "\\f341";
    }

    .fa-arrow-up-triangle-square::before,
    i.arrow-up-triangle-square::before {
        content: "\\f88a";
    }

    .fa-sort-shapes-up::before,
    i.sort-shapes-up::before {
        content: "\\f88a";
    }

    .fa-arrow-up-wide-short::before,
    i.arrow-up-wide-short::before {
        content: "\\f161";
    }

    .fa-sort-amount-up::before,
    i.sort-amount-up::before {
        content: "\\f161";
    }

    .fa-arrow-up-z-a::before,
    i.arrow-up-z-a::before {
        content: "\\f882";
    }

    .fa-sort-alpha-up-alt::before,
    i.sort-alpha-up-alt::before {
        content: "\\f882";
    }

    .fa-arrows-cross::before,
    i.arrows-cross::before {
        content: "\\e0a2";
    }

    .fa-arrows-down-to-line::before,
    i.arrows-down-to-line::before {
        content: "\\e4b8";
    }

    .fa-arrows-down-to-people::before,
    i.arrows-down-to-people::before {
        content: "\\e4b9";
    }

    .fa-arrows-from-dotted-line::before,
    i.arrows-from-dotted-line::before {
        content: "\\e0a3";
    }

    .fa-arrows-from-line::before,
    i.arrows-from-line::before {
        content: "\\e0a4";
    }

    .fa-arrows-left-right::before,
    i.arrows-left-right::before {
        content: "\\f07e";
    }

    .fa-arrows-h::before,
    i.arrows-h::before {
        content: "\\f07e";
    }

    .fa-arrows-left-right-to-line::before,
    i.arrows-left-right-to-line::before {
        content: "\\e4ba";
    }

    .fa-arrows-maximize::before,
    i.arrows-maximize::before {
        content: "\\f31d";
    }

    .fa-expand-arrows::before,
    i.expand-arrows::before {
        content: "\\f31d";
    }

    .fa-arrows-minimize::before,
    i.arrows-minimize::before {
        content: "\\e0a5";
    }

    .fa-compress-arrows::before,
    i.compress-arrows::before {
        content: "\\e0a5";
    }

    .fa-arrows-repeat::before,
    i.arrows-repeat::before {
        content: "\\f364";
    }

    .fa-repeat-alt::before,
    i.repeat-alt::before {
        content: "\\f364";
    }

    .fa-arrows-repeat-1::before,
    i.arrows-repeat-1::before {
        content: "\\f366";
    }

    .fa-repeat-1-alt::before,
    i.repeat-1-alt::before {
        content: "\\f366";
    }

    .fa-arrows-retweet::before,
    i.arrows-retweet::before {
        content: "\\f361";
    }

    .fa-retweet-alt::before,
    i.retweet-alt::before {
        content: "\\f361";
    }

    .fa-arrows-rotate::before,
    i.arrows-rotate::before {
        content: "\\f021";
    }

    .fa-refresh::before,
    i.refresh::before {
        content: "\\f021";
    }

    .fa-sync::before,
    i.sync::before {
        content: "\\f021";
    }

    .fa-arrows-spin::before,
    i.arrows-spin::before {
        content: "\\e4bb";
    }

    .fa-arrows-split-up-and-left::before,
    i.arrows-split-up-and-left::before {
        content: "\\e4bc";
    }

    .fa-arrows-to-circle::before,
    i.arrows-to-circle::before {
        content: "\\e4bd";
    }

    .fa-arrows-to-dot::before,
    i.arrows-to-dot::before {
        content: "\\e4be";
    }

    .fa-arrows-to-dotted-line::before,
    i.arrows-to-dotted-line::before {
        content: "\\e0a6";
    }

    .fa-arrows-to-eye::before,
    i.arrows-to-eye::before {
        content: "\\e4bf";
    }

    .fa-arrows-to-line::before,
    i.arrows-to-line::before {
        content: "\\e0a7";
    }

    .fa-arrows-turn-right::before,
    i.arrows-turn-right::before {
        content: "\\e4c0";
    }

    .fa-arrows-turn-to-dots::before,
    i.arrows-turn-to-dots::before {
        content: "\\e4c1";
    }

    .fa-arrows-up-down::before,
    i.arrows-up-down::before {
        content: "\\f07d";
    }

    .fa-arrows-v::before,
    i.arrows-v::before {
        content: "\\f07d";
    }

    .fa-arrows-up-down-left-right::before,
    i.arrows-up-down-left-right::before {
        content: "\\f047";
    }

    .fa-arrows::before,
    i.arrows::before {
        content: "\\f047";
    }

    .fa-arrows-up-to-line::before,
    i.arrows-up-to-line::before {
        content: "\\e4c2";
    }

    .fa-asterisk::before,
    i.asterisk::before {
        content: "\\2a";
    }

    .fa-at::before,
    i.at::before {
        content: "\\40";
    }

    .fa-atom::before,
    i.atom::before {
        content: "\\f5d2";
    }

    .fa-atom-simple::before,
    i.atom-simple::before {
        content: "\\f5d3";
    }

    .fa-atom-alt::before,
    i.atom-alt::before {
        content: "\\f5d3";
    }

    .fa-audio-description::before,
    i.audio-description::before {
        content: "\\f29e";
    }

    .fa-audio-description-slash::before,
    i.audio-description-slash::before {
        content: "\\e0a8";
    }

    .fa-austral-sign::before,
    i.austral-sign::before {
        content: "\\e0a9";
    }

    .fa-avocado::before,
    i.avocado::before {
        content: "\\e0aa";
    }

    .fa-award::before,
    i.award::before {
        content: "\\f559";
    }

    .fa-award-simple::before,
    i.award-simple::before {
        content: "\\e0ab";
    }

    .fa-axe::before,
    i.axe::before {
        content: "\\f6b2";
    }

    .fa-axe-battle::before,
    i.axe-battle::before {
        content: "\\f6b3";
    }

    .fa-b::before,
    i.b::before {
        content: "\\42";
    }

    .fa-baby::before,
    i.baby::before {
        content: "\\f77c";
    }

    .fa-baby-carriage::before,
    i.baby-carriage::before {
        content: "\\f77d";
    }

    .fa-carriage-baby::before,
    i.carriage-baby::before {
        content: "\\f77d";
    }

    .fa-backpack::before,
    i.backpack::before {
        content: "\\f5d4";
    }

    .fa-backward::before,
    i.backward::before {
        content: "\\f04a";
    }

    .fa-backward-fast::before,
    i.backward-fast::before {
        content: "\\f049";
    }

    .fa-fast-backward::before,
    i.fast-backward::before {
        content: "\\f049";
    }

    .fa-backward-step::before,
    i.backward-step::before {
        content: "\\f048";
    }

    .fa-step-backward::before,
    i.step-backward::before {
        content: "\\f048";
    }

    .fa-bacon::before,
    i.bacon::before {
        content: "\\f7e5";
    }

    .fa-bacteria::before,
    i.bacteria::before {
        content: "\\e059";
    }

    .fa-bacterium::before,
    i.bacterium::before {
        content: "\\e05a";
    }

    .fa-badge::before,
    i.badge::before {
        content: "\\f335";
    }

    .fa-badge-check::before,
    i.badge-check::before {
        content: "\\f336";
    }

    .fa-badge-dollar::before,
    i.badge-dollar::before {
        content: "\\f645";
    }

    .fa-badge-percent::before,
    i.badge-percent::before {
        content: "\\f646";
    }

    .fa-badge-sheriff::before,
    i.badge-sheriff::before {
        content: "\\f8a2";
    }

    .fa-badger-honey::before,
    i.badger-honey::before {
        content: "\\f6b4";
    }

    .fa-badminton::before,
    i.badminton::before {
        content: "\\e33a";
    }

    .fa-bag-shopping::before,
    i.bag-shopping::before {
        content: "\\f290";
    }

    .fa-shopping-bag::before,
    i.shopping-bag::before {
        content: "\\f290";
    }

    .fa-bagel::before,
    i.bagel::before {
        content: "\\e3d7";
    }

    .fa-bags-shopping::before,
    i.bags-shopping::before {
        content: "\\f847";
    }

    .fa-baguette::before,
    i.baguette::before {
        content: "\\e3d8";
    }

    .fa-bahai::before,
    i.bahai::before {
        content: "\\f666";
    }

    .fa-baht-sign::before,
    i.baht-sign::before {
        content: "\\e0ac";
    }

    .fa-ball-pile::before,
    i.ball-pile::before {
        content: "\\f77e";
    }

    .fa-balloon::before,
    i.balloon::before {
        content: "\\e2e3";
    }

    .fa-balloons::before,
    i.balloons::before {
        content: "\\e2e4";
    }

    .fa-ballot::before,
    i.ballot::before {
        content: "\\f732";
    }

    .fa-ballot-check::before,
    i.ballot-check::before {
        content: "\\f733";
    }

    .fa-ban::before,
    i.ban::before {
        content: "\\f05e";
    }

    .fa-cancel::before,
    i.cancel::before {
        content: "\\f05e";
    }

    .fa-ban-bug::before,
    i.ban-bug::before {
        content: "\\f7f9";
    }

    .fa-debug::before,
    i.debug::before {
        content: "\\f7f9";
    }

    .fa-ban-parking::before,
    i.ban-parking::before {
        content: "\\f616";
    }

    .fa-parking-circle-slash::before,
    i.parking-circle-slash::before {
        content: "\\f616";
    }

    .fa-ban-smoking::before,
    i.ban-smoking::before {
        content: "\\f54d";
    }

    .fa-smoking-ban::before,
    i.smoking-ban::before {
        content: "\\f54d";
    }

    .fa-banana::before,
    i.banana::before {
        content: "\\e2e5";
    }

    .fa-bandage::before,
    i.bandage::before {
        content: "\\f462";
    }

    .fa-band-aid::before,
    i.band-aid::before {
        content: "\\f462";
    }

    .fa-bangladeshi-taka-sign::before,
    i.bangladeshi-taka-sign::before {
        content: "\\e2e6";
    }

    .fa-banjo::before,
    i.banjo::before {
        content: "\\f8a3";
    }

    .fa-barcode::before,
    i.barcode::before {
        content: "\\f02a";
    }

    .fa-barcode-read::before,
    i.barcode-read::before {
        content: "\\f464";
    }

    .fa-barcode-scan::before,
    i.barcode-scan::before {
        content: "\\f465";
    }

    .fa-bars::before,
    i.bars::before {
        content: "\\f0c9";
    }

    .fa-navicon::before,
    i.navicon::before {
        content: "\\f0c9";
    }

    .fa-bars-filter::before,
    i.bars-filter::before {
        content: "\\e0ad";
    }

    .fa-bars-progress::before,
    i.bars-progress::before {
        content: "\\f828";
    }

    .fa-tasks-alt::before,
    i.tasks-alt::before {
        content: "\\f828";
    }

    .fa-bars-sort::before,
    i.bars-sort::before {
        content: "\\e0ae";
    }

    .fa-bars-staggered::before,
    i.bars-staggered::before {
        content: "\\f550";
    }

    .fa-reorder::before,
    i.reorder::before {
        content: "\\f550";
    }

    .fa-stream::before,
    i.stream::before {
        content: "\\f550";
    }

    .fa-baseball::before,
    i.baseball::before {
        content: "\\f433";
    }

    .fa-baseball-ball::before,
    i.baseball-ball::before {
        content: "\\f433";
    }

    .fa-baseball-bat-ball::before,
    i.baseball-bat-ball::before {
        content: "\\f432";
    }

    .fa-basket-shopping::before,
    i.basket-shopping::before {
        content: "\\f291";
    }

    .fa-shopping-basket::before,
    i.shopping-basket::before {
        content: "\\f291";
    }

    .fa-basket-shopping-simple::before,
    i.basket-shopping-simple::before {
        content: "\\e0af";
    }

    .fa-shopping-basket-alt::before,
    i.shopping-basket-alt::before {
        content: "\\e0af";
    }

    .fa-basketball::before,
    i.basketball::before {
        content: "\\f434";
    }

    .fa-basketball-ball::before,
    i.basketball-ball::before {
        content: "\\f434";
    }

    .fa-basketball-hoop::before,
    i.basketball-hoop::before {
        content: "\\f435";
    }

    .fa-bat::before,
    i.bat::before {
        content: "\\f6b5";
    }

    .fa-bath::before,
    i.bath::before {
        content: "\\f2cd";
    }

    .fa-bathtub::before,
    i.bathtub::before {
        content: "\\f2cd";
    }

    .fa-battery-bolt::before,
    i.battery-bolt::before {
        content: "\\f376";
    }

    .fa-battery-empty::before,
    i.battery-empty::before {
        content: "\\f244";
    }

    .fa-battery-0::before,
    i.battery-0::before {
        content: "\\f244";
    }

    .fa-battery-exclamation::before,
    i.battery-exclamation::before {
        content: "\\e0b0";
    }

    .fa-battery-full::before,
    i.battery-full::before {
        content: "\\f240";
    }

    .fa-battery::before,
    i.battery::before {
        content: "\\f240";
    }

    .fa-battery-5::before,
    i.battery-5::before {
        content: "\\f240";
    }

    .fa-battery-half::before,
    i.battery-half::before {
        content: "\\f242";
    }

    .fa-battery-3::before,
    i.battery-3::before {
        content: "\\f242";
    }

    .fa-battery-low::before,
    i.battery-low::before {
        content: "\\e0b1";
    }

    .fa-battery-1::before,
    i.battery-1::before {
        content: "\\e0b1";
    }

    .fa-battery-quarter::before,
    i.battery-quarter::before {
        content: "\\f243";
    }

    .fa-battery-2::before,
    i.battery-2::before {
        content: "\\f243";
    }

    .fa-battery-slash::before,
    i.battery-slash::before {
        content: "\\f377";
    }

    .fa-battery-three-quarters::before,
    i.battery-three-quarters::before {
        content: "\\f241";
    }

    .fa-battery-4::before,
    i.battery-4::before {
        content: "\\f241";
    }

    .fa-bed::before,
    i.bed::before {
        content: "\\f236";
    }

    .fa-bed-bunk::before,
    i.bed-bunk::before {
        content: "\\f8f8";
    }

    .fa-bed-empty::before,
    i.bed-empty::before {
        content: "\\f8f9";
    }

    .fa-bed-front::before,
    i.bed-front::before {
        content: "\\f8f7";
    }

    .fa-bed-alt::before,
    i.bed-alt::before {
        content: "\\f8f7";
    }

    .fa-bed-pulse::before,
    i.bed-pulse::before {
        content: "\\f487";
    }

    .fa-procedures::before,
    i.procedures::before {
        content: "\\f487";
    }

    .fa-bee::before,
    i.bee::before {
        content: "\\e0b2";
    }

    .fa-beer-mug::before,
    i.beer-mug::before {
        content: "\\e0b3";
    }

    .fa-beer-foam::before,
    i.beer-foam::before {
        content: "\\e0b3";
    }

    .fa-beer-mug-empty::before,
    i.beer-mug-empty::before {
        content: "\\f0fc";
    }

    .fa-beer::before,
    i.beer::before {
        content: "\\f0fc";
    }

    .fa-bell::before,
    i.bell::before {
        content: "\\f0f3";
    }

    .fa-bell-concierge::before,
    i.bell-concierge::before {
        content: "\\f562";
    }

    .fa-concierge-bell::before,
    i.concierge-bell::before {
        content: "\\f562";
    }

    .fa-bell-exclamation::before,
    i.bell-exclamation::before {
        content: "\\f848";
    }

    .fa-bell-on::before,
    i.bell-on::before {
        content: "\\f8fa";
    }

    .fa-bell-plus::before,
    i.bell-plus::before {
        content: "\\f849";
    }

    .fa-bell-school::before,
    i.bell-school::before {
        content: "\\f5d5";
    }

    .fa-bell-school-slash::before,
    i.bell-school-slash::before {
        content: "\\f5d6";
    }

    .fa-bell-slash::before,
    i.bell-slash::before {
        content: "\\f1f6";
    }

    .fa-bells::before,
    i.bells::before {
        content: "\\f77f";
    }

    .fa-bench-tree::before,
    i.bench-tree::before {
        content: "\\e2e7";
    }

    .fa-bezier-curve::before,
    i.bezier-curve::before {
        content: "\\f55b";
    }

    .fa-bicycle::before,
    i.bicycle::before {
        content: "\\f206";
    }

    .fa-binary::before,
    i.binary::before {
        content: "\\e33b";
    }

    .fa-binary-circle-check::before,
    i.binary-circle-check::before {
        content: "\\e33c";
    }

    .fa-binary-lock::before,
    i.binary-lock::before {
        content: "\\e33d";
    }

    .fa-binary-slash::before,
    i.binary-slash::before {
        content: "\\e33e";
    }

    .fa-binoculars::before,
    i.binoculars::before {
        content: "\\f1e5";
    }

    .fa-biohazard::before,
    i.biohazard::before {
        content: "\\f780";
    }

    .fa-bird::before,
    i.bird::before {
        content: "\\e469";
    }

    .fa-bitcoin-sign::before,
    i.bitcoin-sign::before {
        content: "\\e0b4";
    }

    .fa-blanket::before,
    i.blanket::before {
        content: "\\f498";
    }

    .fa-blanket-fire::before,
    i.blanket-fire::before {
        content: "\\e3da";
    }

    .fa-blender::before,
    i.blender::before {
        content: "\\f517";
    }

    .fa-blender-phone::before,
    i.blender-phone::before {
        content: "\\f6b6";
    }

    .fa-blinds::before,
    i.blinds::before {
        content: "\\f8fb";
    }

    .fa-blinds-open::before,
    i.blinds-open::before {
        content: "\\f8fc";
    }

    .fa-blinds-raised::before,
    i.blinds-raised::before {
        content: "\\f8fd";
    }

    .fa-block::before,
    i.block::before {
        content: "\\e46a";
    }

    .fa-block-brick::before,
    i.block-brick::before {
        content: "\\e3db";
    }

    .fa-wall-brick::before,
    i.wall-brick::before {
        content: "\\e3db";
    }

    .fa-block-brick-fire::before,
    i.block-brick-fire::before {
        content: "\\e3dc";
    }

    .fa-firewall::before,
    i.firewall::before {
        content: "\\e3dc";
    }

    .fa-block-question::before,
    i.block-question::before {
        content: "\\e3dd";
    }

    .fa-block-quote::before,
    i.block-quote::before {
        content: "\\e0b5";
    }

    .fa-blog::before,
    i.blog::before {
        content: "\\f781";
    }

    .fa-blueberries::before,
    i.blueberries::before {
        content: "\\e2e8";
    }

    .fa-bold::before,
    i.bold::before {
        content: "\\f032";
    }

    .fa-bolt::before,
    i.bolt::before {
        content: "\\f0e7";
    }

    .fa-zap::before,
    i.zap::before {
        content: "\\f0e7";
    }

    .fa-bolt-auto::before,
    i.bolt-auto::before {
        content: "\\e0b6";
    }

    .fa-bolt-lightning::before,
    i.bolt-lightning::before {
        content: "\\e0b7";
    }

    .fa-bolt-slash::before,
    i.bolt-slash::before {
        content: "\\e0b8";
    }

    .fa-bomb::before,
    i.bomb::before {
        content: "\\f1e2";
    }

    .fa-bone::before,
    i.bone::before {
        content: "\\f5d7";
    }

    .fa-bone-break::before,
    i.bone-break::before {
        content: "\\f5d8";
    }

    .fa-bong::before,
    i.bong::before {
        content: "\\f55c";
    }

    .fa-book::before,
    i.book::before {
        content: "\\f02d";
    }

    .fa-book-arrow-right::before,
    i.book-arrow-right::before {
        content: "\\e0b9";
    }

    .fa-book-arrow-up::before,
    i.book-arrow-up::before {
        content: "\\e0ba";
    }

    .fa-book-atlas::before,
    i.book-atlas::before {
        content: "\\f558";
    }

    .fa-atlas::before,
    i.atlas::before {
        content: "\\f558";
    }

    .fa-book-bible::before,
    i.book-bible::before {
        content: "\\f647";
    }

    .fa-bible::before,
    i.bible::before {
        content: "\\f647";
    }

    .fa-book-blank::before,
    i.book-blank::before {
        content: "\\f5d9";
    }

    .fa-book-alt::before,
    i.book-alt::before {
        content: "\\f5d9";
    }

    .fa-book-bookmark::before,
    i.book-bookmark::before {
        content: "\\e0bb";
    }

    .fa-book-circle-arrow-right::before,
    i.book-circle-arrow-right::before {
        content: "\\e0bc";
    }

    .fa-book-circle-arrow-up::before,
    i.book-circle-arrow-up::before {
        content: "\\e0bd";
    }

    .fa-book-copy::before,
    i.book-copy::before {
        content: "\\e0be";
    }

    .fa-book-font::before,
    i.book-font::before {
        content: "\\e0bf";
    }

    .fa-book-heart::before,
    i.book-heart::before {
        content: "\\f499";
    }

    .fa-book-journal-whills::before,
    i.book-journal-whills::before {
        content: "\\f66a";
    }

    .fa-journal-whills::before,
    i.journal-whills::before {
        content: "\\f66a";
    }

    .fa-book-medical::before,
    i.book-medical::before {
        content: "\\f7e6";
    }

    .fa-book-open::before,
    i.book-open::before {
        content: "\\f518";
    }

    .fa-book-open-cover::before,
    i.book-open-cover::before {
        content: "\\e0c0";
    }

    .fa-book-open-alt::before,
    i.book-open-alt::before {
        content: "\\e0c0";
    }

    .fa-book-open-reader::before,
    i.book-open-reader::before {
        content: "\\f5da";
    }

    .fa-book-reader::before,
    i.book-reader::before {
        content: "\\f5da";
    }

    .fa-book-quran::before,
    i.book-quran::before {
        content: "\\f687";
    }

    .fa-quran::before,
    i.quran::before {
        content: "\\f687";
    }

    .fa-book-section::before,
    i.book-section::before {
        content: "\\e0c1";
    }

    .fa-book-law::before,
    i.book-law::before {
        content: "\\e0c1";
    }

    .fa-book-skull::before,
    i.book-skull::before {
        content: "\\f6b7";
    }

    .fa-book-dead::before,
    i.book-dead::before {
        content: "\\f6b7";
    }

    .fa-book-sparkles::before,
    i.book-sparkles::before {
        content: "\\f6b8";
    }

    .fa-book-spells::before,
    i.book-spells::before {
        content: "\\f6b8";
    }

    .fa-book-tanakh::before,
    i.book-tanakh::before {
        content: "\\f827";
    }

    .fa-tanakh::before,
    i.tanakh::before {
        content: "\\f827";
    }

    .fa-book-user::before,
    i.book-user::before {
        content: "\\f7e7";
    }

    .fa-bookmark::before,
    i.bookmark::before {
        content: "\\f02e";
    }

    .fa-bookmark-slash::before,
    i.bookmark-slash::before {
        content: "\\e0c2";
    }

    .fa-books::before,
    i.books::before {
        content: "\\f5db";
    }

    .fa-books-medical::before,
    i.books-medical::before {
        content: "\\f7e8";
    }

    .fa-boombox::before,
    i.boombox::before {
        content: "\\f8a5";
    }

    .fa-boot::before,
    i.boot::before {
        content: "\\f782";
    }

    .fa-boot-heeled::before,
    i.boot-heeled::before {
        content: "\\e33f";
    }

    .fa-booth-curtain::before,
    i.booth-curtain::before {
        content: "\\f734";
    }

    .fa-border-all::before,
    i.border-all::before {
        content: "\\f84c";
    }

    .fa-border-bottom::before,
    i.border-bottom::before {
        content: "\\f84d";
    }

    .fa-border-bottom-right::before,
    i.border-bottom-right::before {
        content: "\\f854";
    }

    .fa-border-style-alt::before,
    i.border-style-alt::before {
        content: "\\f854";
    }

    .fa-border-center-h::before,
    i.border-center-h::before {
        content: "\\f89c";
    }

    .fa-border-center-v::before,
    i.border-center-v::before {
        content: "\\f89d";
    }

    .fa-border-inner::before,
    i.border-inner::before {
        content: "\\f84e";
    }

    .fa-border-left::before,
    i.border-left::before {
        content: "\\f84f";
    }

    .fa-border-none::before,
    i.border-none::before {
        content: "\\f850";
    }

    .fa-border-outer::before,
    i.border-outer::before {
        content: "\\f851";
    }

    .fa-border-right::before,
    i.border-right::before {
        content: "\\f852";
    }

    .fa-border-top::before,
    i.border-top::before {
        content: "\\f855";
    }

    .fa-border-top-left::before,
    i.border-top-left::before {
        content: "\\f853";
    }

    .fa-border-style::before,
    i.border-style::before {
        content: "\\f853";
    }

    .fa-bore-hole::before,
    i.bore-hole::before {
        content: "\\e4c3";
    }

    .fa-bottle-droplet::before,
    i.bottle-droplet::before {
        content: "\\e4c4";
    }

    .fa-bottle-water::before,
    i.bottle-water::before {
        content: "\\e4c5";
    }

    .fa-bow-arrow::before,
    i.bow-arrow::before {
        content: "\\f6b9";
    }

    .fa-bowl-chopsticks::before,
    i.bowl-chopsticks::before {
        content: "\\e2e9";
    }

    .fa-bowl-chopsticks-noodles::before,
    i.bowl-chopsticks-noodles::before {
        content: "\\e2ea";
    }

    .fa-bowl-food::before,
    i.bowl-food::before {
        content: "\\e4c6";
    }

    .fa-bowl-hot::before,
    i.bowl-hot::before {
        content: "\\f823";
    }

    .fa-soup::before,
    i.soup::before {
        content: "\\f823";
    }

    .fa-bowl-rice::before,
    i.bowl-rice::before {
        content: "\\e2eb";
    }

    .fa-bowl-scoop::before,
    i.bowl-scoop::before {
        content: "\\e3de";
    }

    .fa-bowl-shaved-ice::before,
    i.bowl-shaved-ice::before {
        content: "\\e3de";
    }

    .fa-bowl-scoops::before,
    i.bowl-scoops::before {
        content: "\\e3df";
    }

    .fa-bowl-soft-serve::before,
    i.bowl-soft-serve::before {
        content: "\\e46b";
    }

    .fa-bowl-spoon::before,
    i.bowl-spoon::before {
        content: "\\e3e0";
    }

    .fa-bowling-ball::before,
    i.bowling-ball::before {
        content: "\\f436";
    }

    .fa-bowling-ball-pin::before,
    i.bowling-ball-pin::before {
        content: "\\e0c3";
    }

    .fa-bowling-pins::before,
    i.bowling-pins::before {
        content: "\\f437";
    }

    .fa-box::before,
    i.box::before {
        content: "\\f466";
    }

    .fa-box-archive::before,
    i.box-archive::before {
        content: "\\f187";
    }

    .fa-archive::before,
    i.archive::before {
        content: "\\f187";
    }

    .fa-box-ballot::before,
    i.box-ballot::before {
        content: "\\f735";
    }

    .fa-box-check::before,
    i.box-check::before {
        content: "\\f467";
    }

    .fa-box-circle-check::before,
    i.box-circle-check::before {
        content: "\\e0c4";
    }

    .fa-box-dollar::before,
    i.box-dollar::before {
        content: "\\f4a0";
    }

    .fa-box-usd::before,
    i.box-usd::before {
        content: "\\f4a0";
    }

    .fa-box-heart::before,
    i.box-heart::before {
        content: "\\f49d";
    }

    .fa-box-open::before,
    i.box-open::before {
        content: "\\f49e";
    }

    .fa-box-open-full::before,
    i.box-open-full::before {
        content: "\\f49c";
    }

    .fa-box-full::before,
    i.box-full::before {
        content: "\\f49c";
    }

    .fa-box-taped::before,
    i.box-taped::before {
        content: "\\f49a";
    }

    .fa-box-alt::before,
    i.box-alt::before {
        content: "\\f49a";
    }

    .fa-box-tissue::before,
    i.box-tissue::before {
        content: "\\e05b";
    }

    .fa-boxes-packing::before,
    i.boxes-packing::before {
        content: "\\e4c7";
    }

    .fa-boxes-stacked::before,
    i.boxes-stacked::before {
        content: "\\f468";
    }

    .fa-boxes::before,
    i.boxes::before {
        content: "\\f468";
    }

    .fa-boxes-alt::before,
    i.boxes-alt::before {
        content: "\\f468";
    }

    .fa-boxing-glove::before,
    i.boxing-glove::before {
        content: "\\f438";
    }

    .fa-glove-boxing::before,
    i.glove-boxing::before {
        content: "\\f438";
    }

    .fa-bracket-curly::before,
    i.bracket-curly::before {
        content: "\\7b";
    }

    .fa-bracket-curly-left::before,
    i.bracket-curly-left::before {
        content: "\\7b";
    }

    .fa-bracket-curly-right::before,
    i.bracket-curly-right::before {
        content: "\\7d";
    }

    .fa-bracket-round::before,
    i.bracket-round::before {
        content: "\\28";
    }

    .fa-parenthesis::before,
    i.parenthesis::before {
        content: "\\28";
    }

    .fa-bracket-round-right::before,
    i.bracket-round-right::before {
        content: "\\29";
    }

    .fa-bracket-square::before,
    i.bracket-square::before {
        content: "\\5b";
    }

    .fa-bracket::before,
    i.bracket::before {
        content: "\\5b";
    }

    .fa-bracket-left::before,
    i.bracket-left::before {
        content: "\\5b";
    }

    .fa-bracket-square-right::before,
    i.bracket-square-right::before {
        content: "\\5d";
    }

    .fa-brackets-curly::before,
    i.brackets-curly::before {
        content: "\\f7ea";
    }

    .fa-brackets-round::before,
    i.brackets-round::before {
        content: "\\e0c5";
    }

    .fa-parentheses::before,
    i.parentheses::before {
        content: "\\e0c5";
    }

    .fa-brackets-square::before,
    i.brackets-square::before {
        content: "\\f7e9";
    }

    .fa-brackets::before,
    i.brackets::before {
        content: "\\f7e9";
    }

    .fa-braille::before,
    i.braille::before {
        content: "\\f2a1";
    }

    .fa-brain::before,
    i.brain::before {
        content: "\\f5dc";
    }

    .fa-brain-arrow-curved-right::before,
    i.brain-arrow-curved-right::before {
        content: "\\f677";
    }

    .fa-mind-share::before,
    i.mind-share::before {
        content: "\\f677";
    }

    .fa-brain-circuit::before,
    i.brain-circuit::before {
        content: "\\e0c6";
    }

    .fa-brake-warning::before,
    i.brake-warning::before {
        content: "\\e0c7";
    }

    .fa-brazilian-real-sign::before,
    i.brazilian-real-sign::before {
        content: "\\e46c";
    }

    .fa-bread-loaf::before,
    i.bread-loaf::before {
        content: "\\f7eb";
    }

    .fa-bread-slice::before,
    i.bread-slice::before {
        content: "\\f7ec";
    }

    .fa-bread-slice-butter::before,
    i.bread-slice-butter::before {
        content: "\\e3e1";
    }

    .fa-bridge::before,
    i.bridge::before {
        content: "\\e4c8";
    }

    .fa-bridge-circle-check::before,
    i.bridge-circle-check::before {
        content: "\\e4c9";
    }

    .fa-bridge-circle-exclamation::before,
    i.bridge-circle-exclamation::before {
        content: "\\e4ca";
    }

    .fa-bridge-circle-xmark::before,
    i.bridge-circle-xmark::before {
        content: "\\e4cb";
    }

    .fa-bridge-lock::before,
    i.bridge-lock::before {
        content: "\\e4cc";
    }

    .fa-bridge-suspension::before,
    i.bridge-suspension::before {
        content: "\\e4cd";
    }

    .fa-bridge-water::before,
    i.bridge-water::before {
        content: "\\e4ce";
    }

    .fa-briefcase::before,
    i.briefcase::before {
        content: "\\f0b1";
    }

    .fa-briefcase-arrow-right::before,
    i.briefcase-arrow-right::before {
        content: "\\e2f2";
    }

    .fa-briefcase-blank::before,
    i.briefcase-blank::before {
        content: "\\e0c8";
    }

    .fa-briefcase-medical::before,
    i.briefcase-medical::before {
        content: "\\f469";
    }

    .fa-brightness::before,
    i.brightness::before {
        content: "\\e0c9";
    }

    .fa-brightness-low::before,
    i.brightness-low::before {
        content: "\\e0ca";
    }

    .fa-bring-forward::before,
    i.bring-forward::before {
        content: "\\f856";
    }

    .fa-bring-front::before,
    i.bring-front::before {
        content: "\\f857";
    }

    .fa-broccoli::before,
    i.broccoli::before {
        content: "\\e3e2";
    }

    .fa-broom::before,
    i.broom::before {
        content: "\\f51a";
    }

    .fa-broom-ball::before,
    i.broom-ball::before {
        content: "\\f458";
    }

    .fa-quidditch::before,
    i.quidditch::before {
        content: "\\f458";
    }

    .fa-quidditch-broom-ball::before,
    i.quidditch-broom-ball::before {
        content: "\\f458";
    }

    .fa-browser::before,
    i.browser::before {
        content: "\\f37e";
    }

    .fa-browsers::before,
    i.browsers::before {
        content: "\\e0cb";
    }

    .fa-brush::before,
    i.brush::before {
        content: "\\f55d";
    }

    .fa-bucket::before,
    i.bucket::before {
        content: "\\e4cf";
    }

    .fa-bug::before,
    i.bug::before {
        content: "\\f188";
    }

    .fa-bug-slash::before,
    i.bug-slash::before {
        content: "\\e490";
    }

    .fa-bugs::before,
    i.bugs::before {
        content: "\\e4d0";
    }

    .fa-building::before,
    i.building::before {
        content: "\\f1ad";
    }

    .fa-building-circle-arrow-right::before,
    i.building-circle-arrow-right::before {
        content: "\\e4d1";
    }

    .fa-building-circle-check::before,
    i.building-circle-check::before {
        content: "\\e4d2";
    }

    .fa-building-circle-exclamation::before,
    i.building-circle-exclamation::before {
        content: "\\e4d3";
    }

    .fa-building-circle-xmark::before,
    i.building-circle-xmark::before {
        content: "\\e4d4";
    }

    .fa-building-columns::before,
    i.building-columns::before {
        content: "\\f19c";
    }

    .fa-bank::before,
    i.bank::before {
        content: "\\f19c";
    }

    .fa-institution::before,
    i.institution::before {
        content: "\\f19c";
    }

    .fa-museum::before,
    i.museum::before {
        content: "\\f19c";
    }

    .fa-university::before,
    i.university::before {
        content: "\\f19c";
    }

    .fa-building-flag::before,
    i.building-flag::before {
        content: "\\e4d5";
    }

    .fa-building-lock::before,
    i.building-lock::before {
        content: "\\e4d6";
    }

    .fa-building-ngo::before,
    i.building-ngo::before {
        content: "\\e4d7";
    }

    .fa-building-shield::before,
    i.building-shield::before {
        content: "\\e4d8";
    }

    .fa-building-un::before,
    i.building-un::before {
        content: "\\e4d9";
    }

    .fa-building-user::before,
    i.building-user::before {
        content: "\\e4da";
    }

    .fa-building-wheat::before,
    i.building-wheat::before {
        content: "\\e4db";
    }

    .fa-buildings::before,
    i.buildings::before {
        content: "\\e0cc";
    }

    .fa-bullhorn::before,
    i.bullhorn::before {
        content: "\\f0a1";
    }

    .fa-bullseye::before,
    i.bullseye::before {
        content: "\\f140";
    }

    .fa-bullseye-arrow::before,
    i.bullseye-arrow::before {
        content: "\\f648";
    }

    .fa-bullseye-pointer::before,
    i.bullseye-pointer::before {
        content: "\\f649";
    }

    .fa-burger::before,
    i.burger::before {
        content: "\\f805";
    }

    .fa-hamburger::before,
    i.hamburger::before {
        content: "\\f805";
    }

    .fa-burger-cheese::before,
    i.burger-cheese::before {
        content: "\\f7f1";
    }

    .fa-cheeseburger::before,
    i.cheeseburger::before {
        content: "\\f7f1";
    }

    .fa-burger-fries::before,
    i.burger-fries::before {
        content: "\\e0cd";
    }

    .fa-burger-glass::before,
    i.burger-glass::before {
        content: "\\e0ce";
    }

    .fa-burger-lettuce::before,
    i.burger-lettuce::before {
        content: "\\e3e3";
    }

    .fa-burger-soda::before,
    i.burger-soda::before {
        content: "\\f858";
    }

    .fa-burrito::before,
    i.burrito::before {
        content: "\\f7ed";
    }

    .fa-burst::before,
    i.burst::before {
        content: "\\e4dc";
    }

    .fa-bus::before,
    i.bus::before {
        content: "\\f207";
    }

    .fa-bus-school::before,
    i.bus-school::before {
        content: "\\f5dd";
    }

    .fa-bus-simple::before,
    i.bus-simple::before {
        content: "\\f55e";
    }

    .fa-bus-alt::before,
    i.bus-alt::before {
        content: "\\f55e";
    }

    .fa-business-time::before,
    i.business-time::before {
        content: "\\f64a";
    }

    .fa-briefcase-clock::before,
    i.briefcase-clock::before {
        content: "\\f64a";
    }

    .fa-butter::before,
    i.butter::before {
        content: "\\e3e4";
    }

    .fa-c::before,
    i.c::before {
        content: "\\43";
    }

    .fa-cabin::before,
    i.cabin::before {
        content: "\\e46d";
    }

    .fa-cabinet-filing::before,
    i.cabinet-filing::before {
        content: "\\f64b";
    }

    .fa-cable-car::before,
    i.cable-car::before {
        content: "\\e0cf";
    }

    .fa-cactus::before,
    i.cactus::before {
        content: "\\f8a7";
    }

    .fa-cake-candles::before,
    i.cake-candles::before {
        content: "\\f1fd";
    }

    .fa-birthday-cake::before,
    i.birthday-cake::before {
        content: "\\f1fd";
    }

    .fa-cake::before,
    i.cake::before {
        content: "\\f1fd";
    }

    .fa-cake-slice::before,
    i.cake-slice::before {
        content: "\\e3e5";
    }

    .fa-shortcake::before,
    i.shortcake::before {
        content: "\\e3e5";
    }

    .fa-calculator::before,
    i.calculator::before {
        content: "\\f1ec";
    }

    .fa-calculator-simple::before,
    i.calculator-simple::before {
        content: "\\f64c";
    }

    .fa-calculator-alt::before,
    i.calculator-alt::before {
        content: "\\f64c";
    }

    .fa-calendar::before,
    i.calendar::before {
        content: "\\f133";
    }

    .fa-calendar-arrow-down::before,
    i.calendar-arrow-down::before {
        content: "\\e0d0";
    }

    .fa-calendar-download::before,
    i.calendar-download::before {
        content: "\\e0d0";
    }

    .fa-calendar-arrow-up::before,
    i.calendar-arrow-up::before {
        content: "\\e0d1";
    }

    .fa-calendar-upload::before,
    i.calendar-upload::before {
        content: "\\e0d1";
    }

    .fa-calendar-check::before,
    i.calendar-check::before {
        content: "\\f274";
    }

    .fa-calendar-circle-exclamation::before,
    i.calendar-circle-exclamation::before {
        content: "\\e46e";
    }

    .fa-calendar-circle-minus::before,
    i.calendar-circle-minus::before {
        content: "\\e46f";
    }

    .fa-calendar-circle-plus::before,
    i.calendar-circle-plus::before {
        content: "\\e470";
    }

    .fa-calendar-circle-user::before,
    i.calendar-circle-user::before {
        content: "\\e471";
    }

    .fa-calendar-clock::before,
    i.calendar-clock::before {
        content: "\\e0d2";
    }

    .fa-calendar-time::before,
    i.calendar-time::before {
        content: "\\e0d2";
    }

    .fa-calendar-day::before,
    i.calendar-day::before {
        content: "\\f783";
    }

    .fa-calendar-days::before,
    i.calendar-days::before {
        content: "\\f073";
    }

    .fa-calendar-alt::before,
    i.calendar-alt::before {
        content: "\\f073";
    }

    .fa-calendar-exclamation::before,
    i.calendar-exclamation::before {
        content: "\\f334";
    }

    .fa-calendar-heart::before,
    i.calendar-heart::before {
        content: "\\e0d3";
    }

    .fa-calendar-image::before,
    i.calendar-image::before {
        content: "\\e0d4";
    }

    .fa-calendar-lines::before,
    i.calendar-lines::before {
        content: "\\e0d5";
    }

    .fa-calendar-note::before,
    i.calendar-note::before {
        content: "\\e0d5";
    }

    .fa-calendar-lines-pen::before,
    i.calendar-lines-pen::before {
        content: "\\e472";
    }

    .fa-calendar-minus::before,
    i.calendar-minus::before {
        content: "\\f272";
    }

    .fa-calendar-pen::before,
    i.calendar-pen::before {
        content: "\\f333";
    }

    .fa-calendar-edit::before,
    i.calendar-edit::before {
        content: "\\f333";
    }

    .fa-calendar-plus::before,
    i.calendar-plus::before {
        content: "\\f271";
    }

    .fa-calendar-range::before,
    i.calendar-range::before {
        content: "\\e0d6";
    }

    .fa-calendar-star::before,
    i.calendar-star::before {
        content: "\\f736";
    }

    .fa-calendar-week::before,
    i.calendar-week::before {
        content: "\\f784";
    }

    .fa-calendar-xmark::before,
    i.calendar-xmark::before {
        content: "\\f273";
    }

    .fa-calendar-times::before,
    i.calendar-times::before {
        content: "\\f273";
    }

    .fa-calendars::before,
    i.calendars::before {
        content: "\\e0d7";
    }

    .fa-camcorder::before,
    i.camcorder::before {
        content: "\\f8a8";
    }

    .fa-video-handheld::before,
    i.video-handheld::before {
        content: "\\f8a8";
    }

    .fa-camera::before,
    i.camera::before {
        content: "\\f030";
    }

    .fa-camera-alt::before,
    i.camera-alt::before {
        content: "\\f030";
    }

    .fa-camera-cctv::before,
    i.camera-cctv::before {
        content: "\\f8ac";
    }

    .fa-cctv::before,
    i.cctv::before {
        content: "\\f8ac";
    }

    .fa-camera-movie::before,
    i.camera-movie::before {
        content: "\\f8a9";
    }

    .fa-camera-polaroid::before,
    i.camera-polaroid::before {
        content: "\\f8aa";
    }

    .fa-camera-retro::before,
    i.camera-retro::before {
        content: "\\f083";
    }

    .fa-camera-rotate::before,
    i.camera-rotate::before {
        content: "\\e0d8";
    }

    .fa-camera-security::before,
    i.camera-security::before {
        content: "\\f8fe";
    }

    .fa-camera-home::before,
    i.camera-home::before {
        content: "\\f8fe";
    }

    .fa-camera-slash::before,
    i.camera-slash::before {
        content: "\\e0d9";
    }

    .fa-camera-viewfinder::before,
    i.camera-viewfinder::before {
        content: "\\e0da";
    }

    .fa-screenshot::before,
    i.screenshot::before {
        content: "\\e0da";
    }

    .fa-camera-web::before,
    i.camera-web::before {
        content: "\\f832";
    }

    .fa-webcam::before,
    i.webcam::before {
        content: "\\f832";
    }

    .fa-camera-web-slash::before,
    i.camera-web-slash::before {
        content: "\\f833";
    }

    .fa-webcam-slash::before,
    i.webcam-slash::before {
        content: "\\f833";
    }

    .fa-campfire::before,
    i.campfire::before {
        content: "\\f6ba";
    }

    .fa-campground::before,
    i.campground::before {
        content: "\\f6bb";
    }

    .fa-can-food::before,
    i.can-food::before {
        content: "\\e3e6";
    }

    .fa-candle-holder::before,
    i.candle-holder::before {
        content: "\\f6bc";
    }

    .fa-candy::before,
    i.candy::before {
        content: "\\e3e7";
    }

    .fa-candy-bar::before,
    i.candy-bar::before {
        content: "\\e3e8";
    }

    .fa-chocolate-bar::before,
    i.chocolate-bar::before {
        content: "\\e3e8";
    }

    .fa-candy-cane::before,
    i.candy-cane::before {
        content: "\\f786";
    }

    .fa-candy-corn::before,
    i.candy-corn::before {
        content: "\\f6bd";
    }

    .fa-cannabis::before,
    i.cannabis::before {
        content: "\\f55f";
    }

    .fa-capsules::before,
    i.capsules::before {
        content: "\\f46b";
    }

    .fa-car::before,
    i.car::before {
        content: "\\f1b9";
    }

    .fa-automobile::before,
    i.automobile::before {
        content: "\\f1b9";
    }

    .fa-car-battery::before,
    i.car-battery::before {
        content: "\\f5df";
    }

    .fa-battery-car::before,
    i.battery-car::before {
        content: "\\f5df";
    }

    .fa-car-bolt::before,
    i.car-bolt::before {
        content: "\\e341";
    }

    .fa-car-building::before,
    i.car-building::before {
        content: "\\f859";
    }

    .fa-car-bump::before,
    i.car-bump::before {
        content: "\\f5e0";
    }

    .fa-car-burst::before,
    i.car-burst::before {
        content: "\\f5e1";
    }

    .fa-car-crash::before,
    i.car-crash::before {
        content: "\\f5e1";
    }

    .fa-car-bus::before,
    i.car-bus::before {
        content: "\\f85a";
    }

    .fa-car-circle-bolt::before,
    i.car-circle-bolt::before {
        content: "\\e342";
    }

    .fa-car-garage::before,
    i.car-garage::before {
        content: "\\f5e2";
    }

    .fa-car-mirrors::before,
    i.car-mirrors::before {
        content: "\\e343";
    }

    .fa-car-on::before,
    i.car-on::before {
        content: "\\e4dd";
    }

    .fa-car-rear::before,
    i.car-rear::before {
        content: "\\f5de";
    }

    .fa-car-alt::before,
    i.car-alt::before {
        content: "\\f5de";
    }

    .fa-car-side::before,
    i.car-side::before {
        content: "\\f5e4";
    }

    .fa-car-side-bolt::before,
    i.car-side-bolt::before {
        content: "\\e344";
    }

    .fa-car-tilt::before,
    i.car-tilt::before {
        content: "\\f5e5";
    }

    .fa-car-tunnel::before,
    i.car-tunnel::before {
        content: "\\e4de";
    }

    .fa-car-wash::before,
    i.car-wash::before {
        content: "\\f5e6";
    }

    .fa-car-wrench::before,
    i.car-wrench::before {
        content: "\\f5e3";
    }

    .fa-car-mechanic::before,
    i.car-mechanic::before {
        content: "\\f5e3";
    }

    .fa-caravan::before,
    i.caravan::before {
        content: "\\f8ff";
    }

    .fa-caravan-simple::before,
    i.caravan-simple::before {
        content: "\\e000";
    }

    .fa-caravan-alt::before,
    i.caravan-alt::before {
        content: "\\e000";
    }

    .fa-card-club::before,
    i.card-club::before {
        content: "\\e3e9";
    }

    .fa-card-diamond::before,
    i.card-diamond::before {
        content: "\\e3ea";
    }

    .fa-card-heart::before,
    i.card-heart::before {
        content: "\\e3eb";
    }

    .fa-card-spade::before,
    i.card-spade::before {
        content: "\\e3ec";
    }

    .fa-cards::before,
    i.cards::before {
        content: "\\e3ed";
    }

    .fa-cards-blank::before,
    i.cards-blank::before {
        content: "\\e4df";
    }

    .fa-caret-down::before,
    i.caret-down::before {
        content: "\\f0d7";
    }

    .fa-caret-left::before,
    i.caret-left::before {
        content: "\\f0d9";
    }

    .fa-caret-right::before,
    i.caret-right::before {
        content: "\\f0da";
    }

    .fa-caret-up::before,
    i.caret-up::before {
        content: "\\f0d8";
    }

    .fa-carrot::before,
    i.carrot::before {
        content: "\\f787";
    }

    .fa-cars::before,
    i.cars::before {
        content: "\\f85b";
    }

    .fa-cart-arrow-down::before,
    i.cart-arrow-down::before {
        content: "\\f218";
    }

    .fa-cart-arrow-up::before,
    i.cart-arrow-up::before {
        content: "\\e3ee";
    }

    .fa-cart-circle-arrow-down::before,
    i.cart-circle-arrow-down::before {
        content: "\\e3ef";
    }

    .fa-cart-circle-arrow-up::before,
    i.cart-circle-arrow-up::before {
        content: "\\e3f0";
    }

    .fa-cart-circle-check::before,
    i.cart-circle-check::before {
        content: "\\e3f1";
    }

    .fa-cart-circle-exclamation::before,
    i.cart-circle-exclamation::before {
        content: "\\e3f2";
    }

    .fa-cart-circle-plus::before,
    i.cart-circle-plus::before {
        content: "\\e3f3";
    }

    .fa-cart-circle-xmark::before,
    i.cart-circle-xmark::before {
        content: "\\e3f4";
    }

    .fa-cart-flatbed::before,
    i.cart-flatbed::before {
        content: "\\f474";
    }

    .fa-dolly-flatbed::before,
    i.dolly-flatbed::before {
        content: "\\f474";
    }

    .fa-cart-flatbed-boxes::before,
    i.cart-flatbed-boxes::before {
        content: "\\f475";
    }

    .fa-dolly-flatbed-alt::before,
    i.dolly-flatbed-alt::before {
        content: "\\f475";
    }

    .fa-cart-flatbed-empty::before,
    i.cart-flatbed-empty::before {
        content: "\\f476";
    }

    .fa-dolly-flatbed-empty::before,
    i.dolly-flatbed-empty::before {
        content: "\\f476";
    }

    .fa-cart-flatbed-suitcase::before,
    i.cart-flatbed-suitcase::before {
        content: "\\f59d";
    }

    .fa-luggage-cart::before,
    i.luggage-cart::before {
        content: "\\f59d";
    }

    .fa-cart-minus::before,
    i.cart-minus::before {
        content: "\\e0db";
    }

    .fa-cart-plus::before,
    i.cart-plus::before {
        content: "\\f217";
    }

    .fa-cart-shopping::before,
    i.cart-shopping::before {
        content: "\\f07a";
    }

    .fa-shopping-cart::before,
    i.shopping-cart::before {
        content: "\\f07a";
    }

    .fa-cart-shopping-fast::before,
    i.cart-shopping-fast::before {
        content: "\\e0dc";
    }

    .fa-cart-xmark::before,
    i.cart-xmark::before {
        content: "\\e0dd";
    }

    .fa-cash-register::before,
    i.cash-register::before {
        content: "\\f788";
    }

    .fa-cassette-betamax::before,
    i.cassette-betamax::before {
        content: "\\f8a4";
    }

    .fa-betamax::before,
    i.betamax::before {
        content: "\\f8a4";
    }

    .fa-cassette-tape::before,
    i.cassette-tape::before {
        content: "\\f8ab";
    }

    .fa-cassette-vhs::before,
    i.cassette-vhs::before {
        content: "\\f8ec";
    }

    .fa-vhs::before,
    i.vhs::before {
        content: "\\f8ec";
    }

    .fa-castle::before,
    i.castle::before {
        content: "\\e0de";
    }

    .fa-cat::before,
    i.cat::before {
        content: "\\f6be";
    }

    .fa-cat-space::before,
    i.cat-space::before {
        content: "\\e001";
    }

    .fa-cauldron::before,
    i.cauldron::before {
        content: "\\f6bf";
    }

    .fa-cedi-sign::before,
    i.cedi-sign::before {
        content: "\\e0df";
    }

    .fa-cent-sign::before,
    i.cent-sign::before {
        content: "\\e3f5";
    }

    .fa-certificate::before,
    i.certificate::before {
        content: "\\f0a3";
    }

    .fa-chair::before,
    i.chair::before {
        content: "\\f6c0";
    }

    .fa-chair-office::before,
    i.chair-office::before {
        content: "\\f6c1";
    }

    .fa-chalkboard::before,
    i.chalkboard::before {
        content: "\\f51b";
    }

    .fa-blackboard::before,
    i.blackboard::before {
        content: "\\f51b";
    }

    .fa-chalkboard-user::before,
    i.chalkboard-user::before {
        content: "\\f51c";
    }

    .fa-chalkboard-teacher::before,
    i.chalkboard-teacher::before {
        content: "\\f51c";
    }

    .fa-champagne-glass::before,
    i.champagne-glass::before {
        content: "\\f79e";
    }

    .fa-glass-champagne::before,
    i.glass-champagne::before {
        content: "\\f79e";
    }

    .fa-champagne-glasses::before,
    i.champagne-glasses::before {
        content: "\\f79f";
    }

    .fa-glass-cheers::before,
    i.glass-cheers::before {
        content: "\\f79f";
    }

    .fa-charging-station::before,
    i.charging-station::before {
        content: "\\f5e7";
    }

    .fa-chart-area::before,
    i.chart-area::before {
        content: "\\f1fe";
    }

    .fa-area-chart::before,
    i.area-chart::before {
        content: "\\f1fe";
    }

    .fa-chart-bar::before,
    i.chart-bar::before {
        content: "\\f080";
    }

    .fa-bar-chart::before,
    i.bar-chart::before {
        content: "\\f080";
    }

    .fa-chart-bullet::before,
    i.chart-bullet::before {
        content: "\\e0e1";
    }

    .fa-chart-candlestick::before,
    i.chart-candlestick::before {
        content: "\\e0e2";
    }

    .fa-chart-column::before,
    i.chart-column::before {
        content: "\\e0e3";
    }

    .fa-chart-gantt::before,
    i.chart-gantt::before {
        content: "\\e0e4";
    }

    .fa-chart-line::before,
    i.chart-line::before {
        content: "\\f201";
    }

    .fa-line-chart::before,
    i.line-chart::before {
        content: "\\f201";
    }

    .fa-chart-line-down::before,
    i.chart-line-down::before {
        content: "\\f64d";
    }

    .fa-chart-line-up::before,
    i.chart-line-up::before {
        content: "\\e0e5";
    }

    .fa-chart-mixed::before,
    i.chart-mixed::before {
        content: "\\f643";
    }

    .fa-analytics::before,
    i.analytics::before {
        content: "\\f643";
    }

    .fa-chart-network::before,
    i.chart-network::before {
        content: "\\f78a";
    }

    .fa-chart-pie::before,
    i.chart-pie::before {
        content: "\\f200";
    }

    .fa-pie-chart::before,
    i.pie-chart::before {
        content: "\\f200";
    }

    .fa-chart-pie-simple::before,
    i.chart-pie-simple::before {
        content: "\\f64e";
    }

    .fa-chart-pie-alt::before,
    i.chart-pie-alt::before {
        content: "\\f64e";
    }

    .fa-chart-pyramid::before,
    i.chart-pyramid::before {
        content: "\\e0e6";
    }

    .fa-chart-radar::before,
    i.chart-radar::before {
        content: "\\e0e7";
    }

    .fa-chart-scatter::before,
    i.chart-scatter::before {
        content: "\\f7ee";
    }

    .fa-chart-scatter-3d::before,
    i.chart-scatter-3d::before {
        content: "\\e0e8";
    }

    .fa-chart-scatter-bubble::before,
    i.chart-scatter-bubble::before {
        content: "\\e0e9";
    }

    .fa-chart-simple::before,
    i.chart-simple::before {
        content: "\\e473";
    }

    .fa-chart-simple-horizontal::before,
    i.chart-simple-horizontal::before {
        content: "\\e474";
    }

    .fa-chart-tree-map::before,
    i.chart-tree-map::before {
        content: "\\e0ea";
    }

    .fa-chart-user::before,
    i.chart-user::before {
        content: "\\f6a3";
    }

    .fa-user-chart::before,
    i.user-chart::before {
        content: "\\f6a3";
    }

    .fa-chart-waterfall::before,
    i.chart-waterfall::before {
        content: "\\e0eb";
    }

    .fa-check::before,
    i.check::before {
        content: "\\f00c";
    }

    .fa-check-double::before,
    i.check-double::before {
        content: "\\f560";
    }

    .fa-check-to-slot::before,
    i.check-to-slot::before {
        content: "\\f772";
    }

    .fa-vote-yea::before,
    i.vote-yea::before {
        content: "\\f772";
    }

    .fa-cheese::before,
    i.cheese::before {
        content: "\\f7ef";
    }

    .fa-cheese-swiss::before,
    i.cheese-swiss::before {
        content: "\\f7f0";
    }

    .fa-cherries::before,
    i.cherries::before {
        content: "\\e0ec";
    }

    .fa-chess::before,
    i.chess::before {
        content: "\\f439";
    }

    .fa-chess-bishop::before,
    i.chess-bishop::before {
        content: "\\f43a";
    }

    .fa-chess-bishop-piece::before,
    i.chess-bishop-piece::before {
        content: "\\f43b";
    }

    .fa-chess-bishop-alt::before,
    i.chess-bishop-alt::before {
        content: "\\f43b";
    }

    .fa-chess-board::before,
    i.chess-board::before {
        content: "\\f43c";
    }

    .fa-chess-clock::before,
    i.chess-clock::before {
        content: "\\f43d";
    }

    .fa-chess-clock-flip::before,
    i.chess-clock-flip::before {
        content: "\\f43e";
    }

    .fa-chess-clock-alt::before,
    i.chess-clock-alt::before {
        content: "\\f43e";
    }

    .fa-chess-king::before,
    i.chess-king::before {
        content: "\\f43f";
    }

    .fa-chess-king-piece::before,
    i.chess-king-piece::before {
        content: "\\f440";
    }

    .fa-chess-king-alt::before,
    i.chess-king-alt::before {
        content: "\\f440";
    }

    .fa-chess-knight::before,
    i.chess-knight::before {
        content: "\\f441";
    }

    .fa-chess-knight-piece::before,
    i.chess-knight-piece::before {
        content: "\\f442";
    }

    .fa-chess-knight-alt::before,
    i.chess-knight-alt::before {
        content: "\\f442";
    }

    .fa-chess-pawn::before,
    i.chess-pawn::before {
        content: "\\f443";
    }

    .fa-chess-pawn-piece::before,
    i.chess-pawn-piece::before {
        content: "\\f444";
    }

    .fa-chess-pawn-alt::before,
    i.chess-pawn-alt::before {
        content: "\\f444";
    }

    .fa-chess-queen::before,
    i.chess-queen::before {
        content: "\\f445";
    }

    .fa-chess-queen-piece::before,
    i.chess-queen-piece::before {
        content: "\\f446";
    }

    .fa-chess-queen-alt::before,
    i.chess-queen-alt::before {
        content: "\\f446";
    }

    .fa-chess-rook::before,
    i.chess-rook::before {
        content: "\\f447";
    }

    .fa-chess-rook-piece::before,
    i.chess-rook-piece::before {
        content: "\\f448";
    }

    .fa-chess-rook-alt::before,
    i.chess-rook-alt::before {
        content: "\\f448";
    }

    .fa-chestnut::before,
    i.chestnut::before {
        content: "\\e3f6";
    }

    .fa-chevron-down::before,
    i.chevron-down::before {
        content: "\\f078";
    }

    .fa-chevron-left::before,
    i.chevron-left::before {
        content: "\\f053";
    }

    .fa-chevron-right::before,
    i.chevron-right::before {
        content: "\\f054";
    }

    .fa-chevron-up::before,
    i.chevron-up::before {
        content: "\\f077";
    }

    .fa-chevrons-down::before,
    i.chevrons-down::before {
        content: "\\f322";
    }

    .fa-chevron-double-down::before,
    i.chevron-double-down::before {
        content: "\\f322";
    }

    .fa-chevrons-left::before,
    i.chevrons-left::before {
        content: "\\f323";
    }

    .fa-chevron-double-left::before,
    i.chevron-double-left::before {
        content: "\\f323";
    }

    .fa-chevrons-right::before,
    i.chevrons-right::before {
        content: "\\f324";
    }

    .fa-chevron-double-right::before,
    i.chevron-double-right::before {
        content: "\\f324";
    }

    .fa-chevrons-up::before,
    i.chevrons-up::before {
        content: "\\f325";
    }

    .fa-chevron-double-up::before,
    i.chevron-double-up::before {
        content: "\\f325";
    }

    .fa-child::before,
    i.child::before {
        content: "\\f1ae";
    }

    .fa-child-rifle::before,
    i.child-rifle::before {
        content: "\\e4e0";
    }

    .fa-children::before,
    i.children::before {
        content: "\\e4e1";
    }

    .fa-chimney::before,
    i.chimney::before {
        content: "\\f78b";
    }

    .fa-chopsticks::before,
    i.chopsticks::before {
        content: "\\e3f7";
    }

    .fa-church::before,
    i.church::before {
        content: "\\f51d";
    }

    .fa-circle::before,
    i.circle::before {
        content: "\\f111";
    }

    .fa-circle-0::before,
    i.circle-0::before {
        content: "\\e0ed";
    }

    .fa-circle-1::before,
    i.circle-1::before {
        content: "\\e0ee";
    }

    .fa-circle-2::before,
    i.circle-2::before {
        content: "\\e0ef";
    }

    .fa-circle-3::before,
    i.circle-3::before {
        content: "\\e0f0";
    }

    .fa-circle-4::before,
    i.circle-4::before {
        content: "\\e0f1";
    }

    .fa-circle-5::before,
    i.circle-5::before {
        content: "\\e0f2";
    }

    .fa-circle-6::before,
    i.circle-6::before {
        content: "\\e0f3";
    }

    .fa-circle-7::before,
    i.circle-7::before {
        content: "\\e0f4";
    }

    .fa-circle-8::before,
    i.circle-8::before {
        content: "\\e0f5";
    }

    .fa-circle-9::before,
    i.circle-9::before {
        content: "\\e0f6";
    }

    .fa-circle-a::before,
    i.circle-a::before {
        content: "\\e0f7";
    }

    .fa-circle-ampersand::before,
    i.circle-ampersand::before {
        content: "\\e0f8";
    }

    .fa-circle-arrow-down::before,
    i.circle-arrow-down::before {
        content: "\\f0ab";
    }

    .fa-arrow-circle-down::before,
    i.arrow-circle-down::before {
        content: "\\f0ab";
    }

    .fa-circle-arrow-down-left::before,
    i.circle-arrow-down-left::before {
        content: "\\e0f9";
    }

    .fa-circle-arrow-down-right::before,
    i.circle-arrow-down-right::before {
        content: "\\e0fa";
    }

    .fa-circle-arrow-left::before,
    i.circle-arrow-left::before {
        content: "\\f0a8";
    }

    .fa-arrow-circle-left::before,
    i.arrow-circle-left::before {
        content: "\\f0a8";
    }

    .fa-circle-arrow-right::before,
    i.circle-arrow-right::before {
        content: "\\f0a9";
    }

    .fa-arrow-circle-right::before,
    i.arrow-circle-right::before {
        content: "\\f0a9";
    }

    .fa-circle-arrow-up::before,
    i.circle-arrow-up::before {
        content: "\\f0aa";
    }

    .fa-arrow-circle-up::before,
    i.arrow-circle-up::before {
        content: "\\f0aa";
    }

    .fa-circle-arrow-up-left::before,
    i.circle-arrow-up-left::before {
        content: "\\e0fb";
    }

    .fa-circle-arrow-up-right::before,
    i.circle-arrow-up-right::before {
        content: "\\e0fc";
    }

    .fa-circle-b::before,
    i.circle-b::before {
        content: "\\e0fd";
    }

    .fa-circle-bolt::before,
    i.circle-bolt::before {
        content: "\\e0fe";
    }

    .fa-circle-book-open::before,
    i.circle-book-open::before {
        content: "\\e0ff";
    }

    .fa-book-circle::before,
    i.book-circle::before {
        content: "\\e0ff";
    }

    .fa-circle-bookmark::before,
    i.circle-bookmark::before {
        content: "\\e100";
    }

    .fa-bookmark-circle::before,
    i.bookmark-circle::before {
        content: "\\e100";
    }

    .fa-circle-c::before,
    i.circle-c::before {
        content: "\\e101";
    }

    .fa-circle-calendar::before,
    i.circle-calendar::before {
        content: "\\e102";
    }

    .fa-calendar-circle::before,
    i.calendar-circle::before {
        content: "\\e102";
    }

    .fa-circle-camera::before,
    i.circle-camera::before {
        content: "\\e103";
    }

    .fa-camera-circle::before,
    i.camera-circle::before {
        content: "\\e103";
    }

    .fa-circle-caret-down::before,
    i.circle-caret-down::before {
        content: "\\f32d";
    }

    .fa-caret-circle-down::before,
    i.caret-circle-down::before {
        content: "\\f32d";
    }

    .fa-circle-caret-left::before,
    i.circle-caret-left::before {
        content: "\\f32e";
    }

    .fa-caret-circle-left::before,
    i.caret-circle-left::before {
        content: "\\f32e";
    }

    .fa-circle-caret-right::before,
    i.circle-caret-right::before {
        content: "\\f330";
    }

    .fa-caret-circle-right::before,
    i.caret-circle-right::before {
        content: "\\f330";
    }

    .fa-circle-caret-up::before,
    i.circle-caret-up::before {
        content: "\\f331";
    }

    .fa-caret-circle-up::before,
    i.caret-circle-up::before {
        content: "\\f331";
    }

    .fa-circle-check::before,
    i.circle-check::before {
        content: "\\f058";
    }

    .fa-check-circle::before,
    i.check-circle::before {
        content: "\\f058";
    }

    .fa-circle-chevron-down::before,
    i.circle-chevron-down::before {
        content: "\\f13a";
    }

    .fa-chevron-circle-down::before,
    i.chevron-circle-down::before {
        content: "\\f13a";
    }

    .fa-circle-chevron-left::before,
    i.circle-chevron-left::before {
        content: "\\f137";
    }

    .fa-chevron-circle-left::before,
    i.chevron-circle-left::before {
        content: "\\f137";
    }

    .fa-circle-chevron-right::before,
    i.circle-chevron-right::before {
        content: "\\f138";
    }

    .fa-chevron-circle-right::before,
    i.chevron-circle-right::before {
        content: "\\f138";
    }

    .fa-circle-chevron-up::before,
    i.circle-chevron-up::before {
        content: "\\f139";
    }

    .fa-chevron-circle-up::before,
    i.chevron-circle-up::before {
        content: "\\f139";
    }

    .fa-circle-d::before,
    i.circle-d::before {
        content: "\\e104";
    }

    .fa-circle-dashed::before,
    i.circle-dashed::before {
        content: "\\e105";
    }

    .fa-circle-divide::before,
    i.circle-divide::before {
        content: "\\e106";
    }

    .fa-circle-dollar::before,
    i.circle-dollar::before {
        content: "\\f2e8";
    }

    .fa-dollar-circle::before,
    i.dollar-circle::before {
        content: "\\f2e8";
    }

    .fa-usd-circle::before,
    i.usd-circle::before {
        content: "\\f2e8";
    }

    .fa-circle-dollar-to-slot::before,
    i.circle-dollar-to-slot::before {
        content: "\\f4b9";
    }

    .fa-donate::before,
    i.donate::before {
        content: "\\f4b9";
    }

    .fa-circle-dot::before,
    i.circle-dot::before {
        content: "\\f192";
    }

    .fa-dot-circle::before,
    i.dot-circle::before {
        content: "\\f192";
    }

    .fa-circle-down::before,
    i.circle-down::before {
        content: "\\f358";
    }

    .fa-arrow-alt-circle-down::before,
    i.arrow-alt-circle-down::before {
        content: "\\f358";
    }

    .fa-circle-down-left::before,
    i.circle-down-left::before {
        content: "\\e107";
    }

    .fa-circle-down-right::before,
    i.circle-down-right::before {
        content: "\\e108";
    }

    .fa-circle-e::before,
    i.circle-e::before {
        content: "\\e109";
    }

    .fa-circle-ellipsis::before,
    i.circle-ellipsis::before {
        content: "\\e10a";
    }

    .fa-circle-ellipsis-vertical::before,
    i.circle-ellipsis-vertical::before {
        content: "\\e10b";
    }

    .fa-circle-envelope::before,
    i.circle-envelope::before {
        content: "\\e10c";
    }

    .fa-envelope-circle::before,
    i.envelope-circle::before {
        content: "\\e10c";
    }

    .fa-circle-exclamation::before,
    i.circle-exclamation::before {
        content: "\\f06a";
    }

    .fa-exclamation-circle::before,
    i.exclamation-circle::before {
        content: "\\f06a";
    }

    .fa-circle-exclamation-check::before,
    i.circle-exclamation-check::before {
        content: "\\e10d";
    }

    .fa-circle-f::before,
    i.circle-f::before {
        content: "\\e10e";
    }

    .fa-circle-g::before,
    i.circle-g::before {
        content: "\\e10f";
    }

    .fa-circle-h::before,
    i.circle-h::before {
        content: "\\f47e";
    }

    .fa-hospital-symbol::before,
    i.hospital-symbol::before {
        content: "\\f47e";
    }

    .fa-circle-half::before,
    i.circle-half::before {
        content: "\\e110";
    }

    .fa-circle-half-stroke::before,
    i.circle-half-stroke::before {
        content: "\\f042";
    }

    .fa-adjust::before,
    i.adjust::before {
        content: "\\f042";
    }

    .fa-circle-heart::before,
    i.circle-heart::before {
        content: "\\f4c7";
    }

    .fa-heart-circle::before,
    i.heart-circle::before {
        content: "\\f4c7";
    }

    .fa-circle-i::before,
    i.circle-i::before {
        content: "\\e111";
    }

    .fa-circle-info::before,
    i.circle-info::before {
        content: "\\f05a";
    }

    .fa-info-circle::before,
    i.info-circle::before {
        content: "\\f05a";
    }

    .fa-circle-j::before,
    i.circle-j::before {
        content: "\\e112";
    }

    .fa-circle-k::before,
    i.circle-k::before {
        content: "\\e113";
    }

    .fa-circle-l::before,
    i.circle-l::before {
        content: "\\e114";
    }

    .fa-circle-left::before,
    i.circle-left::before {
        content: "\\f359";
    }

    .fa-arrow-alt-circle-left::before,
    i.arrow-alt-circle-left::before {
        content: "\\f359";
    }

    .fa-circle-location-arrow::before,
    i.circle-location-arrow::before {
        content: "\\f602";
    }

    .fa-location-circle::before,
    i.location-circle::before {
        content: "\\f602";
    }

    .fa-circle-m::before,
    i.circle-m::before {
        content: "\\e115";
    }

    .fa-circle-microphone::before,
    i.circle-microphone::before {
        content: "\\e116";
    }

    .fa-microphone-circle::before,
    i.microphone-circle::before {
        content: "\\e116";
    }

    .fa-circle-microphone-lines::before,
    i.circle-microphone-lines::before {
        content: "\\e117";
    }

    .fa-microphone-circle-alt::before,
    i.microphone-circle-alt::before {
        content: "\\e117";
    }

    .fa-circle-minus::before,
    i.circle-minus::before {
        content: "\\f056";
    }

    .fa-minus-circle::before,
    i.minus-circle::before {
        content: "\\f056";
    }

    .fa-circle-n::before,
    i.circle-n::before {
        content: "\\e118";
    }

    .fa-circle-nodes::before,
    i.circle-nodes::before {
        content: "\\e4e2";
    }

    .fa-circle-notch::before,
    i.circle-notch::before {
        content: "\\f1ce";
    }

    .fa-circle-o::before,
    i.circle-o::before {
        content: "\\e119";
    }

    .fa-circle-p::before,
    i.circle-p::before {
        content: "\\e11a";
    }

    .fa-circle-parking::before,
    i.circle-parking::before {
        content: "\\f615";
    }

    .fa-parking-circle::before,
    i.parking-circle::before {
        content: "\\f615";
    }

    .fa-circle-pause::before,
    i.circle-pause::before {
        content: "\\f28b";
    }

    .fa-pause-circle::before,
    i.pause-circle::before {
        content: "\\f28b";
    }

    .fa-circle-phone::before,
    i.circle-phone::before {
        content: "\\e11b";
    }

    .fa-phone-circle::before,
    i.phone-circle::before {
        content: "\\e11b";
    }

    .fa-circle-phone-flip::before,
    i.circle-phone-flip::before {
        content: "\\e11c";
    }

    .fa-phone-circle-alt::before,
    i.phone-circle-alt::before {
        content: "\\e11c";
    }

    .fa-circle-phone-hangup::before,
    i.circle-phone-hangup::before {
        content: "\\e11d";
    }

    .fa-phone-circle-down::before,
    i.phone-circle-down::before {
        content: "\\e11d";
    }

    .fa-circle-play::before,
    i.circle-play::before {
        content: "\\f144";
    }

    .fa-play-circle::before,
    i.play-circle::before {
        content: "\\f144";
    }

    .fa-circle-plus::before,
    i.circle-plus::before {
        content: "\\f055";
    }

    .fa-plus-circle::before,
    i.plus-circle::before {
        content: "\\f055";
    }

    .fa-circle-q::before,
    i.circle-q::before {
        content: "\\e11e";
    }

    .fa-circle-quarter::before,
    i.circle-quarter::before {
        content: "\\e11f";
    }

    .fa-circle-quarters::before,
    i.circle-quarters::before {
        content: "\\e3f8";
    }

    .fa-circle-question::before,
    i.circle-question::before {
        content: "\\f059";
    }

    .fa-question-circle::before,
    i.question-circle::before {
        content: "\\f059";
    }

    .fa-circle-r::before,
    i.circle-r::before {
        content: "\\e120";
    }

    .fa-circle-radiation::before,
    i.circle-radiation::before {
        content: "\\f7ba";
    }

    .fa-radiation-alt::before,
    i.radiation-alt::before {
        content: "\\f7ba";
    }

    .fa-circle-right::before,
    i.circle-right::before {
        content: "\\f35a";
    }

    .fa-arrow-alt-circle-right::before,
    i.arrow-alt-circle-right::before {
        content: "\\f35a";
    }

    .fa-circle-s::before,
    i.circle-s::before {
        content: "\\e121";
    }

    .fa-circle-small::before,
    i.circle-small::before {
        content: "\\e122";
    }

    .fa-circle-sort::before,
    i.circle-sort::before {
        content: "\\e030";
    }

    .fa-sort-circle::before,
    i.sort-circle::before {
        content: "\\e030";
    }

    .fa-circle-sort-down::before,
    i.circle-sort-down::before {
        content: "\\e031";
    }

    .fa-sort-circle-down::before,
    i.sort-circle-down::before {
        content: "\\e031";
    }

    .fa-circle-sort-up::before,
    i.circle-sort-up::before {
        content: "\\e032";
    }

    .fa-sort-circle-up::before,
    i.sort-circle-up::before {
        content: "\\e032";
    }

    .fa-circle-star::before,
    i.circle-star::before {
        content: "\\e123";
    }

    .fa-star-circle::before,
    i.star-circle::before {
        content: "\\e123";
    }

    .fa-circle-stop::before,
    i.circle-stop::before {
        content: "\\f28d";
    }

    .fa-stop-circle::before,
    i.stop-circle::before {
        content: "\\f28d";
    }

    .fa-circle-t::before,
    i.circle-t::before {
        content: "\\e124";
    }

    .fa-circle-three-quarters::before,
    i.circle-three-quarters::before {
        content: "\\e125";
    }

    .fa-circle-trash::before,
    i.circle-trash::before {
        content: "\\e126";
    }

    .fa-trash-circle::before,
    i.trash-circle::before {
        content: "\\e126";
    }

    .fa-circle-u::before,
    i.circle-u::before {
        content: "\\e127";
    }

    .fa-circle-up::before,
    i.circle-up::before {
        content: "\\f35b";
    }

    .fa-arrow-alt-circle-up::before,
    i.arrow-alt-circle-up::before {
        content: "\\f35b";
    }

    .fa-circle-up-left::before,
    i.circle-up-left::before {
        content: "\\e128";
    }

    .fa-circle-up-right::before,
    i.circle-up-right::before {
        content: "\\e129";
    }

    .fa-circle-user::before,
    i.circle-user::before {
        content: "\\f2bd";
    }

    .fa-user-circle::before,
    i.user-circle::before {
        content: "\\f2bd";
    }

    .fa-circle-v::before,
    i.circle-v::before {
        content: "\\e12a";
    }

    .fa-circle-video::before,
    i.circle-video::before {
        content: "\\e12b";
    }

    .fa-video-circle::before,
    i.video-circle::before {
        content: "\\e12b";
    }

    .fa-circle-w::before,
    i.circle-w::before {
        content: "\\e12c";
    }

    .fa-circle-waveform-lines::before,
    i.circle-waveform-lines::before {
        content: "\\e12d";
    }

    .fa-waveform-circle::before,
    i.waveform-circle::before {
        content: "\\e12d";
    }

    .fa-circle-x::before,
    i.circle-x::before {
        content: "\\e12e";
    }

    .fa-circle-xmark::before,
    i.circle-xmark::before {
        content: "\\f057";
    }

    .fa-times-circle::before,
    i.times-circle::before {
        content: "\\f057";
    }

    .fa-xmark-circle::before,
    i.xmark-circle::before {
        content: "\\f057";
    }

    .fa-circle-y::before,
    i.circle-y::before {
        content: "\\e12f";
    }

    .fa-circle-z::before,
    i.circle-z::before {
        content: "\\e130";
    }

    .fa-citrus::before,
    i.citrus::before {
        content: "\\e2f4";
    }

    .fa-citrus-slice::before,
    i.citrus-slice::before {
        content: "\\e2f5";
    }

    .fa-city::before,
    i.city::before {
        content: "\\f64f";
    }

    .fa-clapperboard::before,
    i.clapperboard::before {
        content: "\\e131";
    }

    .fa-clapperboard-play::before,
    i.clapperboard-play::before {
        content: "\\e132";
    }

    .fa-clarinet::before,
    i.clarinet::before {
        content: "\\f8ad";
    }

    .fa-claw-marks::before,
    i.claw-marks::before {
        content: "\\f6c2";
    }

    .fa-clipboard::before,
    i.clipboard::before {
        content: "\\f328";
    }

    .fa-clipboard-check::before,
    i.clipboard-check::before {
        content: "\\f46c";
    }

    .fa-clipboard-list::before,
    i.clipboard-list::before {
        content: "\\f46d";
    }

    .fa-clipboard-list-check::before,
    i.clipboard-list-check::before {
        content: "\\f737";
    }

    .fa-clipboard-medical::before,
    i.clipboard-medical::before {
        content: "\\e133";
    }

    .fa-clipboard-prescription::before,
    i.clipboard-prescription::before {
        content: "\\f5e8";
    }

    .fa-clipboard-question::before,
    i.clipboard-question::before {
        content: "\\e4e3";
    }

    .fa-clipboard-user::before,
    i.clipboard-user::before {
        content: "\\f7f3";
    }

    .fa-clock::before,
    i.clock::before {
        content: "\\f017";
    }

    .fa-clock-four::before,
    i.clock-four::before {
        content: "\\f017";
    }

    .fa-clock-desk::before,
    i.clock-desk::before {
        content: "\\e134";
    }

    .fa-clock-eight::before,
    i.clock-eight::before {
        content: "\\e345";
    }

    .fa-clock-eight-thirty::before,
    i.clock-eight-thirty::before {
        content: "\\e346";
    }

    .fa-clock-eleven::before,
    i.clock-eleven::before {
        content: "\\e347";
    }

    .fa-clock-eleven-thirty::before,
    i.clock-eleven-thirty::before {
        content: "\\e348";
    }

    .fa-clock-five::before,
    i.clock-five::before {
        content: "\\e349";
    }

    .fa-clock-five-thirty::before,
    i.clock-five-thirty::before {
        content: "\\e34a";
    }

    .fa-clock-four-thirty::before,
    i.clock-four-thirty::before {
        content: "\\e34b";
    }

    .fa-clock-nine::before,
    i.clock-nine::before {
        content: "\\e34c";
    }

    .fa-clock-nine-thirty::before,
    i.clock-nine-thirty::before {
        content: "\\e34d";
    }

    .fa-clock-one::before,
    i.clock-one::before {
        content: "\\e34e";
    }

    .fa-clock-one-thirty::before,
    i.clock-one-thirty::before {
        content: "\\e34f";
    }

    .fa-clock-rotate-left::before,
    i.clock-rotate-left::before {
        content: "\\f1da";
    }

    .fa-history::before,
    i.history::before {
        content: "\\f1da";
    }

    .fa-clock-seven::before,
    i.clock-seven::before {
        content: "\\e350";
    }

    .fa-clock-seven-thirty::before,
    i.clock-seven-thirty::before {
        content: "\\e351";
    }

    .fa-clock-six::before,
    i.clock-six::before {
        content: "\\e352";
    }

    .fa-clock-six-thirty::before,
    i.clock-six-thirty::before {
        content: "\\e353";
    }

    .fa-clock-ten::before,
    i.clock-ten::before {
        content: "\\e354";
    }

    .fa-clock-ten-thirty::before,
    i.clock-ten-thirty::before {
        content: "\\e355";
    }

    .fa-clock-three::before,
    i.clock-three::before {
        content: "\\e356";
    }

    .fa-clock-three-thirty::before,
    i.clock-three-thirty::before {
        content: "\\e357";
    }

    .fa-clock-twelve::before,
    i.clock-twelve::before {
        content: "\\e358";
    }

    .fa-clock-twelve-thirty::before,
    i.clock-twelve-thirty::before {
        content: "\\e359";
    }

    .fa-clock-two::before,
    i.clock-two::before {
        content: "\\e35a";
    }

    .fa-clock-two-thirty::before,
    i.clock-two-thirty::before {
        content: "\\e35b";
    }

    .fa-clone::before,
    i.clone::before {
        content: "\\f24d";
    }

    .fa-closed-captioning::before,
    i.closed-captioning::before {
        content: "\\f20a";
    }

    .fa-closed-captioning-slash::before,
    i.closed-captioning-slash::before {
        content: "\\e135";
    }

    .fa-clothes-hanger::before,
    i.clothes-hanger::before {
        content: "\\e136";
    }

    .fa-cloud::before,
    i.cloud::before {
        content: "\\f0c2";
    }

    .fa-cloud-arrow-down::before,
    i.cloud-arrow-down::before {
        content: "\\f0ed";
    }

    .fa-cloud-download::before,
    i.cloud-download::before {
        content: "\\f0ed";
    }

    .fa-cloud-download-alt::before,
    i.cloud-download-alt::before {
        content: "\\f0ed";
    }

    .fa-cloud-arrow-up::before,
    i.cloud-arrow-up::before {
        content: "\\f0ee";
    }

    .fa-cloud-upload::before,
    i.cloud-upload::before {
        content: "\\f0ee";
    }

    .fa-cloud-upload-alt::before,
    i.cloud-upload-alt::before {
        content: "\\f0ee";
    }

    .fa-cloud-bolt::before,
    i.cloud-bolt::before {
        content: "\\f76c";
    }

    .fa-thunderstorm::before,
    i.thunderstorm::before {
        content: "\\f76c";
    }

    .fa-cloud-bolt-moon::before,
    i.cloud-bolt-moon::before {
        content: "\\f76d";
    }

    .fa-thunderstorm-moon::before,
    i.thunderstorm-moon::before {
        content: "\\f76d";
    }

    .fa-cloud-bolt-sun::before,
    i.cloud-bolt-sun::before {
        content: "\\f76e";
    }

    .fa-thunderstorm-sun::before,
    i.thunderstorm-sun::before {
        content: "\\f76e";
    }

    .fa-cloud-check::before,
    i.cloud-check::before {
        content: "\\e35c";
    }

    .fa-cloud-drizzle::before,
    i.cloud-drizzle::before {
        content: "\\f738";
    }

    .fa-cloud-exclamation::before,
    i.cloud-exclamation::before {
        content: "\\e491";
    }

    .fa-cloud-fog::before,
    i.cloud-fog::before {
        content: "\\f74e";
    }

    .fa-fog::before,
    i.fog::before {
        content: "\\f74e";
    }

    .fa-cloud-hail::before,
    i.cloud-hail::before {
        content: "\\f739";
    }

    .fa-cloud-hail-mixed::before,
    i.cloud-hail-mixed::before {
        content: "\\f73a";
    }

    .fa-cloud-meatball::before,
    i.cloud-meatball::before {
        content: "\\f73b";
    }

    .fa-cloud-minus::before,
    i.cloud-minus::before {
        content: "\\e35d";
    }

    .fa-cloud-moon::before,
    i.cloud-moon::before {
        content: "\\f6c3";
    }

    .fa-cloud-moon-rain::before,
    i.cloud-moon-rain::before {
        content: "\\f73c";
    }

    .fa-cloud-music::before,
    i.cloud-music::before {
        content: "\\f8ae";
    }

    .fa-cloud-plus::before,
    i.cloud-plus::before {
        content: "\\e35e";
    }

    .fa-cloud-question::before,
    i.cloud-question::before {
        content: "\\e492";
    }

    .fa-cloud-rain::before,
    i.cloud-rain::before {
        content: "\\f73d";
    }

    .fa-cloud-rainbow::before,
    i.cloud-rainbow::before {
        content: "\\f73e";
    }

    .fa-cloud-showers::before,
    i.cloud-showers::before {
        content: "\\f73f";
    }

    .fa-cloud-showers-heavy::before,
    i.cloud-showers-heavy::before {
        content: "\\f740";
    }

    .fa-cloud-showers-water::before,
    i.cloud-showers-water::before {
        content: "\\e4e4";
    }

    .fa-cloud-slash::before,
    i.cloud-slash::before {
        content: "\\e137";
    }

    .fa-cloud-sleet::before,
    i.cloud-sleet::before {
        content: "\\f741";
    }

    .fa-cloud-snow::before,
    i.cloud-snow::before {
        content: "\\f742";
    }

    .fa-cloud-sun::before,
    i.cloud-sun::before {
        content: "\\f6c4";
    }

    .fa-cloud-sun-rain::before,
    i.cloud-sun-rain::before {
        content: "\\f743";
    }

    .fa-cloud-word::before,
    i.cloud-word::before {
        content: "\\e138";
    }

    .fa-cloud-xmark::before,
    i.cloud-xmark::before {
        content: "\\e35f";
    }

    .fa-clouds::before,
    i.clouds::before {
        content: "\\f744";
    }

    .fa-clouds-moon::before,
    i.clouds-moon::before {
        content: "\\f745";
    }

    .fa-clouds-sun::before,
    i.clouds-sun::before {
        content: "\\f746";
    }

    .fa-clover::before,
    i.clover::before {
        content: "\\e139";
    }

    .fa-club::before,
    i.club::before {
        content: "\\f327";
    }

    .fa-coconut::before,
    i.coconut::before {
        content: "\\e2f6";
    }

    .fa-code::before,
    i.code::before {
        content: "\\f121";
    }

    .fa-code-branch::before,
    i.code-branch::before {
        content: "\\f126";
    }

    .fa-code-commit::before,
    i.code-commit::before {
        content: "\\f386";
    }

    .fa-code-compare::before,
    i.code-compare::before {
        content: "\\e13a";
    }

    .fa-code-fork::before,
    i.code-fork::before {
        content: "\\e13b";
    }

    .fa-code-merge::before,
    i.code-merge::before {
        content: "\\f387";
    }

    .fa-code-pull-request::before,
    i.code-pull-request::before {
        content: "\\e13c";
    }

    .fa-code-pull-request-closed::before,
    i.code-pull-request-closed::before {
        content: "\\e3f9";
    }

    .fa-code-pull-request-draft::before,
    i.code-pull-request-draft::before {
        content: "\\e3fa";
    }

    .fa-code-simple::before,
    i.code-simple::before {
        content: "\\e13d";
    }

    .fa-coffee-bean::before,
    i.coffee-bean::before {
        content: "\\e13e";
    }

    .fa-coffee-beans::before,
    i.coffee-beans::before {
        content: "\\e13f";
    }

    .fa-coffee-pot::before,
    i.coffee-pot::before {
        content: "\\e002";
    }

    .fa-coffin::before,
    i.coffin::before {
        content: "\\f6c6";
    }

    .fa-coffin-cross::before,
    i.coffin-cross::before {
        content: "\\e051";
    }

    .fa-coin::before,
    i.coin::before {
        content: "\\f85c";
    }

    .fa-coin-blank::before,
    i.coin-blank::before {
        content: "\\e3fb";
    }

    .fa-coin-front::before,
    i.coin-front::before {
        content: "\\e3fc";
    }

    .fa-coin-vertical::before,
    i.coin-vertical::before {
        content: "\\e3fd";
    }

    .fa-coins::before,
    i.coins::before {
        content: "\\f51e";
    }

    .fa-colon::before,
    i.colon::before {
        content: "\\3a";
    }

    .fa-colon-sign::before,
    i.colon-sign::before {
        content: "\\e140";
    }

    .fa-columns-3::before,
    i.columns-3::before {
        content: "\\e361";
    }

    .fa-comet::before,
    i.comet::before {
        content: "\\e003";
    }

    .fa-comma::before,
    i.comma::before {
        content: "\\2c";
    }

    .fa-command::before,
    i.command::before {
        content: "\\e142";
    }

    .fa-comment::before,
    i.comment::before {
        content: "\\f075";
    }

    .fa-comment-arrow-down::before,
    i.comment-arrow-down::before {
        content: "\\e143";
    }

    .fa-comment-arrow-up::before,
    i.comment-arrow-up::before {
        content: "\\e144";
    }

    .fa-comment-arrow-up-right::before,
    i.comment-arrow-up-right::before {
        content: "\\e145";
    }

    .fa-comment-captions::before,
    i.comment-captions::before {
        content: "\\e146";
    }

    .fa-comment-check::before,
    i.comment-check::before {
        content: "\\f4ac";
    }

    .fa-comment-code::before,
    i.comment-code::before {
        content: "\\e147";
    }

    .fa-comment-dollar::before,
    i.comment-dollar::before {
        content: "\\f651";
    }

    .fa-comment-dots::before,
    i.comment-dots::before {
        content: "\\f4ad";
    }

    .fa-commenting::before,
    i.commenting::before {
        content: "\\f4ad";
    }

    .fa-comment-exclamation::before,
    i.comment-exclamation::before {
        content: "\\f4af";
    }

    .fa-comment-image::before,
    i.comment-image::before {
        content: "\\e148";
    }

    .fa-comment-lines::before,
    i.comment-lines::before {
        content: "\\f4b0";
    }

    .fa-comment-medical::before,
    i.comment-medical::before {
        content: "\\f7f5";
    }

    .fa-comment-middle::before,
    i.comment-middle::before {
        content: "\\e149";
    }

    .fa-comment-middle-top::before,
    i.comment-middle-top::before {
        content: "\\e14a";
    }

    .fa-comment-minus::before,
    i.comment-minus::before {
        content: "\\f4b1";
    }

    .fa-comment-music::before,
    i.comment-music::before {
        content: "\\f8b0";
    }

    .fa-comment-pen::before,
    i.comment-pen::before {
        content: "\\f4ae";
    }

    .fa-comment-edit::before,
    i.comment-edit::before {
        content: "\\f4ae";
    }

    .fa-comment-plus::before,
    i.comment-plus::before {
        content: "\\f4b2";
    }

    .fa-comment-question::before,
    i.comment-question::before {
        content: "\\e14b";
    }

    .fa-comment-quote::before,
    i.comment-quote::before {
        content: "\\e14c";
    }

    .fa-comment-slash::before,
    i.comment-slash::before {
        content: "\\f4b3";
    }

    .fa-comment-smile::before,
    i.comment-smile::before {
        content: "\\f4b4";
    }

    .fa-comment-sms::before,
    i.comment-sms::before {
        content: "\\f7cd";
    }

    .fa-sms::before,
    i.sms::before {
        content: "\\f7cd";
    }

    .fa-comment-text::before,
    i.comment-text::before {
        content: "\\e14d";
    }

    .fa-comment-xmark::before,
    i.comment-xmark::before {
        content: "\\f4b5";
    }

    .fa-comment-times::before,
    i.comment-times::before {
        content: "\\f4b5";
    }

    .fa-comments::before,
    i.comments::before {
        content: "\\f086";
    }

    .fa-comments-dollar::before,
    i.comments-dollar::before {
        content: "\\f653";
    }

    .fa-comments-question::before,
    i.comments-question::before {
        content: "\\e14e";
    }

    .fa-comments-question-check::before,
    i.comments-question-check::before {
        content: "\\e14f";
    }

    .fa-compact-disc::before,
    i.compact-disc::before {
        content: "\\f51f";
    }

    .fa-compass::before,
    i.compass::before {
        content: "\\f14e";
    }

    .fa-compass-drafting::before,
    i.compass-drafting::before {
        content: "\\f568";
    }

    .fa-drafting-compass::before,
    i.drafting-compass::before {
        content: "\\f568";
    }

    .fa-compass-slash::before,
    i.compass-slash::before {
        content: "\\f5e9";
    }

    .fa-compress::before,
    i.compress::before {
        content: "\\f066";
    }

    .fa-compress-wide::before,
    i.compress-wide::before {
        content: "\\f326";
    }

    .fa-computer::before,
    i.computer::before {
        content: "\\e4e5";
    }

    .fa-computer-classic::before,
    i.computer-classic::before {
        content: "\\f8b1";
    }

    .fa-computer-mouse::before,
    i.computer-mouse::before {
        content: "\\f8cc";
    }

    .fa-mouse::before,
    i.mouse::before {
        content: "\\f8cc";
    }

    .fa-computer-mouse-scrollwheel::before,
    i.computer-mouse-scrollwheel::before {
        content: "\\f8cd";
    }

    .fa-mouse-alt::before,
    i.mouse-alt::before {
        content: "\\f8cd";
    }

    .fa-computer-speaker::before,
    i.computer-speaker::before {
        content: "\\f8b2";
    }

    .fa-container-storage::before,
    i.container-storage::before {
        content: "\\f4b7";
    }

    .fa-conveyor-belt::before,
    i.conveyor-belt::before {
        content: "\\f46e";
    }

    .fa-conveyor-belt-boxes::before,
    i.conveyor-belt-boxes::before {
        content: "\\f46f";
    }

    .fa-conveyor-belt-alt::before,
    i.conveyor-belt-alt::before {
        content: "\\f46f";
    }

    .fa-conveyor-belt-empty::before,
    i.conveyor-belt-empty::before {
        content: "\\e150";
    }

    .fa-cookie::before,
    i.cookie::before {
        content: "\\f563";
    }

    .fa-cookie-bite::before,
    i.cookie-bite::before {
        content: "\\f564";
    }

    .fa-copy::before,
    i.copy::before {
        content: "\\f0c5";
    }

    .fa-copyright::before,
    i.copyright::before {
        content: "\\f1f9";
    }

    .fa-corn::before,
    i.corn::before {
        content: "\\f6c7";
    }

    .fa-corner::before,
    i.corner::before {
        content: "\\e3fe";
    }

    .fa-couch::before,
    i.couch::before {
        content: "\\f4b8";
    }

    .fa-cow::before,
    i.cow::before {
        content: "\\f6c8";
    }

    .fa-cowbell::before,
    i.cowbell::before {
        content: "\\f8b3";
    }

    .fa-cowbell-circle-plus::before,
    i.cowbell-circle-plus::before {
        content: "\\f8b4";
    }

    .fa-cowbell-more::before,
    i.cowbell-more::before {
        content: "\\f8b4";
    }

    .fa-crab::before,
    i.crab::before {
        content: "\\e3ff";
    }

    .fa-crate-apple::before,
    i.crate-apple::before {
        content: "\\f6b1";
    }

    .fa-apple-crate::before,
    i.apple-crate::before {
        content: "\\f6b1";
    }

    .fa-crate-empty::before,
    i.crate-empty::before {
        content: "\\e151";
    }

    .fa-credit-card::before,
    i.credit-card::before {
        content: "\\f09d";
    }

    .fa-credit-card-alt::before,
    i.credit-card-alt::before {
        content: "\\f09d";
    }

    .fa-credit-card-blank::before,
    i.credit-card-blank::before {
        content: "\\f389";
    }

    .fa-credit-card-front::before,
    i.credit-card-front::before {
        content: "\\f38a";
    }

    .fa-cricket-bat-ball::before,
    i.cricket-bat-ball::before {
        content: "\\f449";
    }

    .fa-cricket::before,
    i.cricket::before {
        content: "\\f449";
    }

    .fa-croissant::before,
    i.croissant::before {
        content: "\\f7f6";
    }

    .fa-crop::before,
    i.crop::before {
        content: "\\f125";
    }

    .fa-crop-simple::before,
    i.crop-simple::before {
        content: "\\f565";
    }

    .fa-crop-alt::before,
    i.crop-alt::before {
        content: "\\f565";
    }

    .fa-cross::before,
    i.cross::before {
        content: "\\f654";
    }

    .fa-crosshairs::before,
    i.crosshairs::before {
        content: "\\f05b";
    }

    .fa-crow::before,
    i.crow::before {
        content: "\\f520";
    }

    .fa-crown::before,
    i.crown::before {
        content: "\\f521";
    }

    .fa-crutch::before,
    i.crutch::before {
        content: "\\f7f7";
    }

    .fa-crutches::before,
    i.crutches::before {
        content: "\\f7f8";
    }

    .fa-cruzeiro-sign::before,
    i.cruzeiro-sign::before {
        content: "\\e152";
    }

    .fa-crystal-ball::before,
    i.crystal-ball::before {
        content: "\\e362";
    }

    .fa-cube::before,
    i.cube::before {
        content: "\\f1b2";
    }

    .fa-cubes::before,
    i.cubes::before {
        content: "\\f1b3";
    }

    .fa-cubes-stacked::before,
    i.cubes-stacked::before {
        content: "\\e4e6";
    }

    .fa-cucumber::before,
    i.cucumber::before {
        content: "\\e401";
    }

    .fa-cup-straw::before,
    i.cup-straw::before {
        content: "\\e363";
    }

    .fa-cup-straw-swoosh::before,
    i.cup-straw-swoosh::before {
        content: "\\e364";
    }

    .fa-cup-togo::before,
    i.cup-togo::before {
        content: "\\f6c5";
    }

    .fa-coffee-togo::before,
    i.coffee-togo::before {
        content: "\\f6c5";
    }

    .fa-cupcake::before,
    i.cupcake::before {
        content: "\\e402";
    }

    .fa-curling-stone::before,
    i.curling-stone::before {
        content: "\\f44a";
    }

    .fa-curling::before,
    i.curling::before {
        content: "\\f44a";
    }

    .fa-custard::before,
    i.custard::before {
        content: "\\e403";
    }

    .fa-d::before,
    i.d::before {
        content: "\\44";
    }

    .fa-dagger::before,
    i.dagger::before {
        content: "\\f6cb";
    }

    .fa-dash::before,
    i.dash::before {
        content: "\\e404";
    }

    .fa-database::before,
    i.database::before {
        content: "\\f1c0";
    }

    .fa-deer::before,
    i.deer::before {
        content: "\\f78e";
    }

    .fa-deer-rudolph::before,
    i.deer-rudolph::before {
        content: "\\f78f";
    }

    .fa-delete-left::before,
    i.delete-left::before {
        content: "\\f55a";
    }

    .fa-backspace::before,
    i.backspace::before {
        content: "\\f55a";
    }

    .fa-delete-right::before,
    i.delete-right::before {
        content: "\\e154";
    }

    .fa-democrat::before,
    i.democrat::before {
        content: "\\f747";
    }

    .fa-desktop::before,
    i.desktop::before {
        content: "\\f390";
    }

    .fa-desktop-alt::before,
    i.desktop-alt::before {
        content: "\\f390";
    }

    .fa-desktop-arrow-down::before,
    i.desktop-arrow-down::before {
        content: "\\e155";
    }

    .fa-dharmachakra::before,
    i.dharmachakra::before {
        content: "\\f655";
    }

    .fa-diagram-cells::before,
    i.diagram-cells::before {
        content: "\\e475";
    }

    .fa-diagram-lean-canvas::before,
    i.diagram-lean-canvas::before {
        content: "\\e156";
    }

    .fa-diagram-nested::before,
    i.diagram-nested::before {
        content: "\\e157";
    }

    .fa-diagram-next::before,
    i.diagram-next::before {
        content: "\\e476";
    }

    .fa-diagram-predecessor::before,
    i.diagram-predecessor::before {
        content: "\\e477";
    }

    .fa-diagram-previous::before,
    i.diagram-previous::before {
        content: "\\e478";
    }

    .fa-diagram-project::before,
    i.diagram-project::before {
        content: "\\f542";
    }

    .fa-project-diagram::before,
    i.project-diagram::before {
        content: "\\f542";
    }

    .fa-diagram-sankey::before,
    i.diagram-sankey::before {
        content: "\\e158";
    }

    .fa-diagram-subtask::before,
    i.diagram-subtask::before {
        content: "\\e479";
    }

    .fa-diagram-successor::before,
    i.diagram-successor::before {
        content: "\\e47a";
    }

    .fa-diagram-venn::before,
    i.diagram-venn::before {
        content: "\\e15a";
    }

    .fa-dial::before,
    i.dial::before {
        content: "\\e15b";
    }

    .fa-dial-med-high::before,
    i.dial-med-high::before {
        content: "\\e15b";
    }

    .fa-dial-high::before,
    i.dial-high::before {
        content: "\\e15c";
    }

    .fa-dial-low::before,
    i.dial-low::before {
        content: "\\e15d";
    }

    .fa-dial-max::before,
    i.dial-max::before {
        content: "\\e15e";
    }

    .fa-dial-med::before,
    i.dial-med::before {
        content: "\\e15f";
    }

    .fa-dial-med-low::before,
    i.dial-med-low::before {
        content: "\\e160";
    }

    .fa-dial-min::before,
    i.dial-min::before {
        content: "\\e161";
    }

    .fa-dial-off::before,
    i.dial-off::before {
        content: "\\e162";
    }

    .fa-diamond::before,
    i.diamond::before {
        content: "\\f219";
    }

    .fa-diamond-exclamation::before,
    i.diamond-exclamation::before {
        content: "\\e405";
    }

    .fa-diamond-turn-right::before,
    i.diamond-turn-right::before {
        content: "\\f5eb";
    }

    .fa-directions::before,
    i.directions::before {
        content: "\\f5eb";
    }

    .fa-dice::before,
    i.dice::before {
        content: "\\f522";
    }

    .fa-dice-d10::before,
    i.dice-d10::before {
        content: "\\f6cd";
    }

    .fa-dice-d12::before,
    i.dice-d12::before {
        content: "\\f6ce";
    }

    .fa-dice-d20::before,
    i.dice-d20::before {
        content: "\\f6cf";
    }

    .fa-dice-d4::before,
    i.dice-d4::before {
        content: "\\f6d0";
    }

    .fa-dice-d6::before,
    i.dice-d6::before {
        content: "\\f6d1";
    }

    .fa-dice-d8::before,
    i.dice-d8::before {
        content: "\\f6d2";
    }

    .fa-dice-five::before,
    i.dice-five::before {
        content: "\\f523";
    }

    .fa-dice-four::before,
    i.dice-four::before {
        content: "\\f524";
    }

    .fa-dice-one::before,
    i.dice-one::before {
        content: "\\f525";
    }

    .fa-dice-six::before,
    i.dice-six::before {
        content: "\\f526";
    }

    .fa-dice-three::before,
    i.dice-three::before {
        content: "\\f527";
    }

    .fa-dice-two::before,
    i.dice-two::before {
        content: "\\f528";
    }

    .fa-diploma::before,
    i.diploma::before {
        content: "\\f5ea";
    }

    .fa-scroll-ribbon::before,
    i.scroll-ribbon::before {
        content: "\\f5ea";
    }

    .fa-disc-drive::before,
    i.disc-drive::before {
        content: "\\f8b5";
    }

    .fa-disease::before,
    i.disease::before {
        content: "\\f7fa";
    }

    .fa-display::before,
    i.display::before {
        content: "\\e163";
    }

    .fa-display-arrow-down::before,
    i.display-arrow-down::before {
        content: "\\e164";
    }

    .fa-display-code::before,
    i.display-code::before {
        content: "\\e165";
    }

    .fa-desktop-code::before,
    i.desktop-code::before {
        content: "\\e165";
    }

    .fa-display-medical::before,
    i.display-medical::before {
        content: "\\e166";
    }

    .fa-desktop-medical::before,
    i.desktop-medical::before {
        content: "\\e166";
    }

    .fa-display-slash::before,
    i.display-slash::before {
        content: "\\e2fa";
    }

    .fa-desktop-slash::before,
    i.desktop-slash::before {
        content: "\\e2fa";
    }

    .fa-distribute-spacing-horizontal::before,
    i.distribute-spacing-horizontal::before {
        content: "\\e365";
    }

    .fa-distribute-spacing-vertical::before,
    i.distribute-spacing-vertical::before {
        content: "\\e366";
    }

    .fa-ditto::before,
    i.ditto::before {
        content: "\\22";
    }

    .fa-divide::before,
    i.divide::before {
        content: "\\f529";
    }

    .fa-dna::before,
    i.dna::before {
        content: "\\f471";
    }

    .fa-do-not-enter::before,
    i.do-not-enter::before {
        content: "\\f5ec";
    }

    .fa-dog::before,
    i.dog::before {
        content: "\\f6d3";
    }

    .fa-dog-leashed::before,
    i.dog-leashed::before {
        content: "\\f6d4";
    }

    .fa-dollar-sign::before,
    i.dollar-sign::before {
        content: "\\24";
    }

    .fa-dollar::before,
    i.dollar::before {
        content: "\\24";
    }

    .fa-usd::before,
    i.usd::before {
        content: "\\24";
    }

    .fa-dolly::before,
    i.dolly::before {
        content: "\\f472";
    }

    .fa-dolly-box::before,
    i.dolly-box::before {
        content: "\\f472";
    }

    .fa-dolly-empty::before,
    i.dolly-empty::before {
        content: "\\f473";
    }

    .fa-dolphin::before,
    i.dolphin::before {
        content: "\\e168";
    }

    .fa-dong-sign::before,
    i.dong-sign::before {
        content: "\\e169";
    }

    .fa-donut::before,
    i.donut::before {
        content: "\\e406";
    }

    .fa-doughnut::before,
    i.doughnut::before {
        content: "\\e406";
    }

    .fa-door-closed::before,
    i.door-closed::before {
        content: "\\f52a";
    }

    .fa-door-open::before,
    i.door-open::before {
        content: "\\f52b";
    }

    .fa-dove::before,
    i.dove::before {
        content: "\\f4ba";
    }

    .fa-down::before,
    i.down::before {
        content: "\\f354";
    }

    .fa-arrow-alt-down::before,
    i.arrow-alt-down::before {
        content: "\\f354";
    }

    .fa-down-from-dotted-line::before,
    i.down-from-dotted-line::before {
        content: "\\e407";
    }

    .fa-down-from-line::before,
    i.down-from-line::before {
        content: "\\f349";
    }

    .fa-arrow-alt-from-top::before,
    i.arrow-alt-from-top::before {
        content: "\\f349";
    }

    .fa-down-left::before,
    i.down-left::before {
        content: "\\e16a";
    }

    .fa-down-left-and-up-right-to-center::before,
    i.down-left-and-up-right-to-center::before {
        content: "\\f422";
    }

    .fa-compress-alt::before,
    i.compress-alt::before {
        content: "\\f422";
    }

    .fa-down-long::before,
    i.down-long::before {
        content: "\\f309";
    }

    .fa-long-arrow-alt-down::before,
    i.long-arrow-alt-down::before {
        content: "\\f309";
    }

    .fa-down-right::before,
    i.down-right::before {
        content: "\\e16b";
    }

    .fa-down-to-bracket::before,
    i.down-to-bracket::before {
        content: "\\e4e7";
    }

    .fa-down-to-dotted-line::before,
    i.down-to-dotted-line::before {
        content: "\\e408";
    }

    .fa-down-to-line::before,
    i.down-to-line::before {
        content: "\\f34a";
    }

    .fa-arrow-alt-to-bottom::before,
    i.arrow-alt-to-bottom::before {
        content: "\\f34a";
    }

    .fa-download::before,
    i.download::before {
        content: "\\f019";
    }

    .fa-dragon::before,
    i.dragon::before {
        content: "\\f6d5";
    }

    .fa-draw-circle::before,
    i.draw-circle::before {
        content: "\\f5ed";
    }

    .fa-draw-polygon::before,
    i.draw-polygon::before {
        content: "\\f5ee";
    }

    .fa-draw-square::before,
    i.draw-square::before {
        content: "\\f5ef";
    }

    .fa-dreidel::before,
    i.dreidel::before {
        content: "\\f792";
    }

    .fa-drone::before,
    i.drone::before {
        content: "\\f85f";
    }

    .fa-drone-front::before,
    i.drone-front::before {
        content: "\\f860";
    }

    .fa-drone-alt::before,
    i.drone-alt::before {
        content: "\\f860";
    }

    .fa-droplet::before,
    i.droplet::before {
        content: "\\f043";
    }

    .fa-tint::before,
    i.tint::before {
        content: "\\f043";
    }

    .fa-droplet-degree::before,
    i.droplet-degree::before {
        content: "\\f748";
    }

    .fa-dewpoint::before,
    i.dewpoint::before {
        content: "\\f748";
    }

    .fa-droplet-percent::before,
    i.droplet-percent::before {
        content: "\\f750";
    }

    .fa-humidity::before,
    i.humidity::before {
        content: "\\f750";
    }

    .fa-droplet-slash::before,
    i.droplet-slash::before {
        content: "\\f5c7";
    }

    .fa-tint-slash::before,
    i.tint-slash::before {
        content: "\\f5c7";
    }

    .fa-drum::before,
    i.drum::before {
        content: "\\f569";
    }

    .fa-drum-steelpan::before,
    i.drum-steelpan::before {
        content: "\\f56a";
    }

    .fa-drumstick::before,
    i.drumstick::before {
        content: "\\f6d6";
    }

    .fa-drumstick-bite::before,
    i.drumstick-bite::before {
        content: "\\f6d7";
    }

    .fa-dryer::before,
    i.dryer::before {
        content: "\\f861";
    }

    .fa-dryer-heat::before,
    i.dryer-heat::before {
        content: "\\f862";
    }

    .fa-dryer-alt::before,
    i.dryer-alt::before {
        content: "\\f862";
    }

    .fa-duck::before,
    i.duck::before {
        content: "\\f6d8";
    }

    .fa-dumbbell::before,
    i.dumbbell::before {
        content: "\\f44b";
    }

    .fa-dumpster::before,
    i.dumpster::before {
        content: "\\f793";
    }

    .fa-dumpster-fire::before,
    i.dumpster-fire::before {
        content: "\\f794";
    }

    .fa-dungeon::before,
    i.dungeon::before {
        content: "\\f6d9";
    }

    .fa-e::before,
    i.e::before {
        content: "\\45";
    }

    .fa-ear::before,
    i.ear::before {
        content: "\\f5f0";
    }

    .fa-ear-deaf::before,
    i.ear-deaf::before {
        content: "\\f2a4";
    }

    .fa-deaf::before,
    i.deaf::before {
        content: "\\f2a4";
    }

    .fa-deafness::before,
    i.deafness::before {
        content: "\\f2a4";
    }

    .fa-hard-of-hearing::before,
    i.hard-of-hearing::before {
        content: "\\f2a4";
    }

    .fa-ear-listen::before,
    i.ear-listen::before {
        content: "\\f2a2";
    }

    .fa-assistive-listening-systems::before,
    i.assistive-listening-systems::before {
        content: "\\f2a2";
    }

    .fa-ear-muffs::before,
    i.ear-muffs::before {
        content: "\\f795";
    }

    .fa-earth-africa::before,
    i.earth-africa::before {
        content: "\\f57c";
    }

    .fa-globe-africa::before,
    i.globe-africa::before {
        content: "\\f57c";
    }

    .fa-earth-americas::before,
    i.earth-americas::before {
        content: "\\f57d";
    }

    .fa-earth::before,
    i.earth::before {
        content: "\\f57d";
    }

    .fa-earth-america::before,
    i.earth-america::before {
        content: "\\f57d";
    }

    .fa-globe-americas::before,
    i.globe-americas::before {
        content: "\\f57d";
    }

    .fa-earth-asia::before,
    i.earth-asia::before {
        content: "\\f57e";
    }

    .fa-globe-asia::before,
    i.globe-asia::before {
        content: "\\f57e";
    }

    .fa-earth-europe::before,
    i.earth-europe::before {
        content: "\\f7a2";
    }

    .fa-globe-europe::before,
    i.globe-europe::before {
        content: "\\f7a2";
    }

    .fa-earth-oceania::before,
    i.earth-oceania::before {
        content: "\\e47b";
    }

    .fa-globe-oceania::before,
    i.globe-oceania::before {
        content: "\\e47b";
    }

    .fa-eclipse::before,
    i.eclipse::before {
        content: "\\f749";
    }

    .fa-egg::before,
    i.egg::before {
        content: "\\f7fb";
    }

    .fa-egg-fried::before,
    i.egg-fried::before {
        content: "\\f7fc";
    }

    .fa-eggplant::before,
    i.eggplant::before {
        content: "\\e16c";
    }

    .fa-eject::before,
    i.eject::before {
        content: "\\f052";
    }

    .fa-elephant::before,
    i.elephant::before {
        content: "\\f6da";
    }

    .fa-elevator::before,
    i.elevator::before {
        content: "\\e16d";
    }

    .fa-ellipsis::before,
    i.ellipsis::before {
        content: "\\f141";
    }

    .fa-ellipsis-h::before,
    i.ellipsis-h::before {
        content: "\\f141";
    }

    .fa-ellipsis-stroke::before,
    i.ellipsis-stroke::before {
        content: "\\f39b";
    }

    .fa-ellipsis-h-alt::before,
    i.ellipsis-h-alt::before {
        content: "\\f39b";
    }

    .fa-ellipsis-stroke-vertical::before,
    i.ellipsis-stroke-vertical::before {
        content: "\\f39c";
    }

    .fa-ellipsis-v-alt::before,
    i.ellipsis-v-alt::before {
        content: "\\f39c";
    }

    .fa-ellipsis-vertical::before,
    i.ellipsis-vertical::before {
        content: "\\f142";
    }

    .fa-ellipsis-v::before,
    i.ellipsis-v::before {
        content: "\\f142";
    }

    .fa-empty-set::before,
    i.empty-set::before {
        content: "\\f656";
    }

    .fa-engine::before,
    i.engine::before {
        content: "\\e16e";
    }

    .fa-engine-warning::before,
    i.engine-warning::before {
        content: "\\f5f2";
    }

    .fa-engine-exclamation::before,
    i.engine-exclamation::before {
        content: "\\f5f2";
    }

    .fa-envelope::before,
    i.envelope::before {
        content: "\\f0e0";
    }

    .fa-envelope-circle-check::before,
    i.envelope-circle-check::before {
        content: "\\e4e8";
    }

    .fa-envelope-dot::before,
    i.envelope-dot::before {
        content: "\\e16f";
    }

    .fa-envelope-badge::before,
    i.envelope-badge::before {
        content: "\\e16f";
    }

    .fa-envelope-open::before,
    i.envelope-open::before {
        content: "\\f2b6";
    }

    .fa-envelope-open-dollar::before,
    i.envelope-open-dollar::before {
        content: "\\f657";
    }

    .fa-envelope-open-text::before,
    i.envelope-open-text::before {
        content: "\\f658";
    }

    .fa-envelopes::before,
    i.envelopes::before {
        content: "\\e170";
    }

    .fa-envelopes-bulk::before,
    i.envelopes-bulk::before {
        content: "\\f674";
    }

    .fa-mail-bulk::before,
    i.mail-bulk::before {
        content: "\\f674";
    }

    .fa-equals::before,
    i.equals::before {
        content: "\\3d";
    }

    .fa-eraser::before,
    i.eraser::before {
        content: "\\f12d";
    }

    .fa-escalator::before,
    i.escalator::before {
        content: "\\e171";
    }

    .fa-ethernet::before,
    i.ethernet::before {
        content: "\\f796";
    }

    .fa-euro-sign::before,
    i.euro-sign::before {
        content: "\\f153";
    }

    .fa-eur::before,
    i.eur::before {
        content: "\\f153";
    }

    .fa-euro::before,
    i.euro::before {
        content: "\\f153";
    }

    .fa-exclamation::before,
    i.exclamation::before {
        content: "\\21";
    }

    .fa-expand::before,
    i.expand::before {
        content: "\\f065";
    }

    .fa-expand-wide::before,
    i.expand-wide::before {
        content: "\\f320";
    }

    .fa-explosion::before,
    i.explosion::before {
        content: "\\e4e9";
    }

    .fa-eye::before,
    i.eye::before {
        content: "\\f06e";
    }

    .fa-eye-dropper::before,
    i.eye-dropper::before {
        content: "\\f1fb";
    }

    .fa-eye-dropper-empty::before,
    i.eye-dropper-empty::before {
        content: "\\f1fb";
    }

    .fa-eyedropper::before,
    i.eyedropper::before {
        content: "\\f1fb";
    }

    .fa-eye-dropper-full::before,
    i.eye-dropper-full::before {
        content: "\\e172";
    }

    .fa-eye-dropper-half::before,
    i.eye-dropper-half::before {
        content: "\\e173";
    }

    .fa-eye-evil::before,
    i.eye-evil::before {
        content: "\\f6db";
    }

    .fa-eye-low-vision::before,
    i.eye-low-vision::before {
        content: "\\f2a8";
    }

    .fa-low-vision::before,
    i.low-vision::before {
        content: "\\f2a8";
    }

    .fa-eye-slash::before,
    i.eye-slash::before {
        content: "\\f070";
    }

    .fa-eyes::before,
    i.eyes::before {
        content: "\\e367";
    }

    .fa-f::before,
    i.f::before {
        content: "\\46";
    }

    .fa-face-angry::before,
    i.face-angry::before {
        content: "\\f556";
    }

    .fa-angry::before,
    i.angry::before {
        content: "\\f556";
    }

    .fa-face-angry-horns::before,
    i.face-angry-horns::before {
        content: "\\e368";
    }

    .fa-face-anguished::before,
    i.face-anguished::before {
        content: "\\e369";
    }

    .fa-face-anxious-sweat::before,
    i.face-anxious-sweat::before {
        content: "\\e36a";
    }

    .fa-face-astonished::before,
    i.face-astonished::before {
        content: "\\e36b";
    }

    .fa-face-awesome::before,
    i.face-awesome::before {
        content: "\\e409";
    }

    .fa-gave-dandy::before,
    i.gave-dandy::before {
        content: "\\e409";
    }

    .fa-face-beam-hand-over-mouth::before,
    i.face-beam-hand-over-mouth::before {
        content: "\\e47c";
    }

    .fa-face-clouds::before,
    i.face-clouds::before {
        content: "\\e47d";
    }

    .fa-face-confounded::before,
    i.face-confounded::before {
        content: "\\e36c";
    }

    .fa-face-confused::before,
    i.face-confused::before {
        content: "\\e36d";
    }

    .fa-face-cowboy-hat::before,
    i.face-cowboy-hat::before {
        content: "\\e36e";
    }

    .fa-face-diagonal-mouth::before,
    i.face-diagonal-mouth::before {
        content: "\\e47e";
    }

    .fa-face-disappointed::before,
    i.face-disappointed::before {
        content: "\\e36f";
    }

    .fa-face-disguise::before,
    i.face-disguise::before {
        content: "\\e370";
    }

    .fa-face-dizzy::before,
    i.face-dizzy::before {
        content: "\\f567";
    }

    .fa-dizzy::before,
    i.dizzy::before {
        content: "\\f567";
    }

    .fa-face-dotted::before,
    i.face-dotted::before {
        content: "\\e47f";
    }

    .fa-face-downcast-sweat::before,
    i.face-downcast-sweat::before {
        content: "\\e371";
    }

    .fa-face-drooling::before,
    i.face-drooling::before {
        content: "\\e372";
    }

    .fa-face-exhaling::before,
    i.face-exhaling::before {
        content: "\\e480";
    }

    .fa-face-explode::before,
    i.face-explode::before {
        content: "\\e2fe";
    }

    .fa-exploding-head::before,
    i.exploding-head::before {
        content: "\\e2fe";
    }

    .fa-face-expressionless::before,
    i.face-expressionless::before {
        content: "\\e373";
    }

    .fa-face-eyes-xmarks::before,
    i.face-eyes-xmarks::before {
        content: "\\e374";
    }

    .fa-face-fearful::before,
    i.face-fearful::before {
        content: "\\e375";
    }

    .fa-face-flushed::before,
    i.face-flushed::before {
        content: "\\f579";
    }

    .fa-flushed::before,
    i.flushed::before {
        content: "\\f579";
    }

    .fa-face-frown::before,
    i.face-frown::before {
        content: "\\f119";
    }

    .fa-frown::before,
    i.frown::before {
        content: "\\f119";
    }

    .fa-face-frown-open::before,
    i.face-frown-open::before {
        content: "\\f57a";
    }

    .fa-frown-open::before,
    i.frown-open::before {
        content: "\\f57a";
    }

    .fa-face-frown-slight::before,
    i.face-frown-slight::before {
        content: "\\e376";
    }

    .fa-face-glasses::before,
    i.face-glasses::before {
        content: "\\e377";
    }

    .fa-face-grimace::before,
    i.face-grimace::before {
        content: "\\f57f";
    }

    .fa-grimace::before,
    i.grimace::before {
        content: "\\f57f";
    }

    .fa-face-grin::before,
    i.face-grin::before {
        content: "\\f580";
    }

    .fa-grin::before,
    i.grin::before {
        content: "\\f580";
    }

    .fa-face-grin-beam::before,
    i.face-grin-beam::before {
        content: "\\f582";
    }

    .fa-grin-beam::before,
    i.grin-beam::before {
        content: "\\f582";
    }

    .fa-face-grin-beam-sweat::before,
    i.face-grin-beam-sweat::before {
        content: "\\f583";
    }

    .fa-grin-beam-sweat::before,
    i.grin-beam-sweat::before {
        content: "\\f583";
    }

    .fa-face-grin-hearts::before,
    i.face-grin-hearts::before {
        content: "\\f584";
    }

    .fa-grin-hearts::before,
    i.grin-hearts::before {
        content: "\\f584";
    }

    .fa-face-grin-squint::before,
    i.face-grin-squint::before {
        content: "\\f585";
    }

    .fa-grin-squint::before,
    i.grin-squint::before {
        content: "\\f585";
    }

    .fa-face-grin-squint-tears::before,
    i.face-grin-squint-tears::before {
        content: "\\f586";
    }

    .fa-grin-squint-tears::before,
    i.grin-squint-tears::before {
        content: "\\f586";
    }

    .fa-face-grin-stars::before,
    i.face-grin-stars::before {
        content: "\\f587";
    }

    .fa-grin-stars::before,
    i.grin-stars::before {
        content: "\\f587";
    }

    .fa-face-grin-tears::before,
    i.face-grin-tears::before {
        content: "\\f588";
    }

    .fa-grin-tears::before,
    i.grin-tears::before {
        content: "\\f588";
    }

    .fa-face-grin-tongue::before,
    i.face-grin-tongue::before {
        content: "\\f589";
    }

    .fa-grin-tongue::before,
    i.grin-tongue::before {
        content: "\\f589";
    }

    .fa-face-grin-tongue-squint::before,
    i.face-grin-tongue-squint::before {
        content: "\\f58a";
    }

    .fa-grin-tongue-squint::before,
    i.grin-tongue-squint::before {
        content: "\\f58a";
    }

    .fa-face-grin-tongue-wink::before,
    i.face-grin-tongue-wink::before {
        content: "\\f58b";
    }

    .fa-grin-tongue-wink::before,
    i.grin-tongue-wink::before {
        content: "\\f58b";
    }

    .fa-face-grin-wide::before,
    i.face-grin-wide::before {
        content: "\\f581";
    }

    .fa-grin-alt::before,
    i.grin-alt::before {
        content: "\\f581";
    }

    .fa-face-grin-wink::before,
    i.face-grin-wink::before {
        content: "\\f58c";
    }

    .fa-grin-wink::before,
    i.grin-wink::before {
        content: "\\f58c";
    }

    .fa-face-hand-over-mouth::before,
    i.face-hand-over-mouth::before {
        content: "\\e378";
    }

    .fa-face-hand-peeking::before,
    i.face-hand-peeking::before {
        content: "\\e481";
    }

    .fa-face-hand-yawn::before,
    i.face-hand-yawn::before {
        content: "\\e379";
    }

    .fa-face-head-bandage::before,
    i.face-head-bandage::before {
        content: "\\e37a";
    }

    .fa-face-holding-back-tears::before,
    i.face-holding-back-tears::before {
        content: "\\e482";
    }

    .fa-face-hushed::before,
    i.face-hushed::before {
        content: "\\e37b";
    }

    .fa-face-icicles::before,
    i.face-icicles::before {
        content: "\\e37c";
    }

    .fa-face-kiss::before,
    i.face-kiss::before {
        content: "\\f596";
    }

    .fa-kiss::before,
    i.kiss::before {
        content: "\\f596";
    }

    .fa-face-kiss-beam::before,
    i.face-kiss-beam::before {
        content: "\\f597";
    }

    .fa-kiss-beam::before,
    i.kiss-beam::before {
        content: "\\f597";
    }

    .fa-face-kiss-closed-eyes::before,
    i.face-kiss-closed-eyes::before {
        content: "\\e37d";
    }

    .fa-face-kiss-wink-heart::before,
    i.face-kiss-wink-heart::before {
        content: "\\f598";
    }

    .fa-kiss-wink-heart::before,
    i.kiss-wink-heart::before {
        content: "\\f598";
    }

    .fa-face-laugh::before,
    i.face-laugh::before {
        content: "\\f599";
    }

    .fa-laugh::before,
    i.laugh::before {
        content: "\\f599";
    }

    .fa-face-laugh-beam::before,
    i.face-laugh-beam::before {
        content: "\\f59a";
    }

    .fa-laugh-beam::before,
    i.laugh-beam::before {
        content: "\\f59a";
    }

    .fa-face-laugh-squint::before,
    i.face-laugh-squint::before {
        content: "\\f59b";
    }

    .fa-laugh-squint::before,
    i.laugh-squint::before {
        content: "\\f59b";
    }

    .fa-face-laugh-wink::before,
    i.face-laugh-wink::before {
        content: "\\f59c";
    }

    .fa-laugh-wink::before,
    i.laugh-wink::before {
        content: "\\f59c";
    }

    .fa-face-lying::before,
    i.face-lying::before {
        content: "\\e37e";
    }

    .fa-face-mask::before,
    i.face-mask::before {
        content: "\\e37f";
    }

    .fa-face-meh::before,
    i.face-meh::before {
        content: "\\f11a";
    }

    .fa-meh::before,
    i.meh::before {
        content: "\\f11a";
    }

    .fa-face-meh-blank::before,
    i.face-meh-blank::before {
        content: "\\f5a4";
    }

    .fa-meh-blank::before,
    i.meh-blank::before {
        content: "\\f5a4";
    }

    .fa-face-melting::before,
    i.face-melting::before {
        content: "\\e483";
    }

    .fa-face-monocle::before,
    i.face-monocle::before {
        content: "\\e380";
    }

    .fa-face-nauseated::before,
    i.face-nauseated::before {
        content: "\\e381";
    }

    .fa-face-nose-steam::before,
    i.face-nose-steam::before {
        content: "\\e382";
    }

    .fa-face-party::before,
    i.face-party::before {
        content: "\\e383";
    }

    .fa-face-pensive::before,
    i.face-pensive::before {
        content: "\\e384";
    }

    .fa-face-persevering::before,
    i.face-persevering::before {
        content: "\\e385";
    }

    .fa-face-pleading::before,
    i.face-pleading::before {
        content: "\\e386";
    }

    .fa-face-pouting::before,
    i.face-pouting::before {
        content: "\\e387";
    }

    .fa-face-raised-eyebrow::before,
    i.face-raised-eyebrow::before {
        content: "\\e388";
    }

    .fa-face-relieved::before,
    i.face-relieved::before {
        content: "\\e389";
    }

    .fa-face-rolling-eyes::before,
    i.face-rolling-eyes::before {
        content: "\\f5a5";
    }

    .fa-meh-rolling-eyes::before,
    i.meh-rolling-eyes::before {
        content: "\\f5a5";
    }

    .fa-face-sad-cry::before,
    i.face-sad-cry::before {
        content: "\\f5b3";
    }

    .fa-sad-cry::before,
    i.sad-cry::before {
        content: "\\f5b3";
    }

    .fa-face-sad-sweat::before,
    i.face-sad-sweat::before {
        content: "\\e38a";
    }

    .fa-face-sad-tear::before,
    i.face-sad-tear::before {
        content: "\\f5b4";
    }

    .fa-sad-tear::before,
    i.sad-tear::before {
        content: "\\f5b4";
    }

    .fa-face-saluting::before,
    i.face-saluting::before {
        content: "\\e484";
    }

    .fa-face-scream::before,
    i.face-scream::before {
        content: "\\e38b";
    }

    .fa-face-shush::before,
    i.face-shush::before {
        content: "\\e38c";
    }

    .fa-face-sleeping::before,
    i.face-sleeping::before {
        content: "\\e38d";
    }

    .fa-face-sleepy::before,
    i.face-sleepy::before {
        content: "\\e38e";
    }

    .fa-face-smile::before,
    i.face-smile::before {
        content: "\\f118";
    }

    .fa-smile::before,
    i.smile::before {
        content: "\\f118";
    }

    .fa-face-smile-beam::before,
    i.face-smile-beam::before {
        content: "\\f5b8";
    }

    .fa-smile-beam::before,
    i.smile-beam::before {
        content: "\\f5b8";
    }

    .fa-face-smile-halo::before,
    i.face-smile-halo::before {
        content: "\\e38f";
    }

    .fa-face-smile-hearts::before,
    i.face-smile-hearts::before {
        content: "\\e390";
    }

    .fa-face-smile-horns::before,
    i.face-smile-horns::before {
        content: "\\e391";
    }

    .fa-face-smile-plus::before,
    i.face-smile-plus::before {
        content: "\\f5b9";
    }

    .fa-smile-plus::before,
    i.smile-plus::before {
        content: "\\f5b9";
    }

    .fa-face-smile-relaxed::before,
    i.face-smile-relaxed::before {
        content: "\\e392";
    }

    .fa-face-smile-tear::before,
    i.face-smile-tear::before {
        content: "\\e393";
    }

    .fa-face-smile-tongue::before,
    i.face-smile-tongue::before {
        content: "\\e394";
    }

    .fa-face-smile-upside-down::before,
    i.face-smile-upside-down::before {
        content: "\\e395";
    }

    .fa-face-smile-wink::before,
    i.face-smile-wink::before {
        content: "\\f4da";
    }

    .fa-smile-wink::before,
    i.smile-wink::before {
        content: "\\f4da";
    }

    .fa-face-smiling-hands::before,
    i.face-smiling-hands::before {
        content: "\\e396";
    }

    .fa-face-smirking::before,
    i.face-smirking::before {
        content: "\\e397";
    }

    .fa-face-spiral-eyes::before,
    i.face-spiral-eyes::before {
        content: "\\e485";
    }

    .fa-face-sunglasses::before,
    i.face-sunglasses::before {
        content: "\\e398";
    }

    .fa-face-surprise::before,
    i.face-surprise::before {
        content: "\\f5c2";
    }

    .fa-surprise::before,
    i.surprise::before {
        content: "\\f5c2";
    }

    .fa-face-swear::before,
    i.face-swear::before {
        content: "\\e399";
    }

    .fa-face-thermometer::before,
    i.face-thermometer::before {
        content: "\\e39a";
    }

    .fa-face-thinking::before,
    i.face-thinking::before {
        content: "\\e39b";
    }

    .fa-face-tired::before,
    i.face-tired::before {
        content: "\\f5c8";
    }

    .fa-tired::before,
    i.tired::before {
        content: "\\f5c8";
    }

    .fa-face-tissue::before,
    i.face-tissue::before {
        content: "\\e39c";
    }

    .fa-face-tongue-money::before,
    i.face-tongue-money::before {
        content: "\\e39d";
    }

    .fa-face-tongue-sweat::before,
    i.face-tongue-sweat::before {
        content: "\\e39e";
    }

    .fa-face-unamused::before,
    i.face-unamused::before {
        content: "\\e39f";
    }

    .fa-face-viewfinder::before,
    i.face-viewfinder::before {
        content: "\\e2ff";
    }

    .fa-face-vomit::before,
    i.face-vomit::before {
        content: "\\e3a0";
    }

    .fa-face-weary::before,
    i.face-weary::before {
        content: "\\e3a1";
    }

    .fa-face-woozy::before,
    i.face-woozy::before {
        content: "\\e3a2";
    }

    .fa-face-worried::before,
    i.face-worried::before {
        content: "\\e3a3";
    }

    .fa-face-zany::before,
    i.face-zany::before {
        content: "\\e3a4";
    }

    .fa-face-zipper::before,
    i.face-zipper::before {
        content: "\\e3a5";
    }

    .fa-falafel::before,
    i.falafel::before {
        content: "\\e40a";
    }

    .fa-family::before,
    i.family::before {
        content: "\\e300";
    }

    .fa-family-dress::before,
    i.family-dress::before {
        content: "\\e301";
    }

    .fa-family-pants::before,
    i.family-pants::before {
        content: "\\e302";
    }

    .fa-fan::before,
    i.fan::before {
        content: "\\f863";
    }

    .fa-fan-table::before,
    i.fan-table::before {
        content: "\\e004";
    }

    .fa-farm::before,
    i.farm::before {
        content: "\\f864";
    }

    .fa-barn-silo::before,
    i.barn-silo::before {
        content: "\\f864";
    }

    .fa-faucet::before,
    i.faucet::before {
        content: "\\e005";
    }

    .fa-faucet-drip::before,
    i.faucet-drip::before {
        content: "\\e006";
    }

    .fa-fax::before,
    i.fax::before {
        content: "\\f1ac";
    }

    .fa-feather::before,
    i.feather::before {
        content: "\\f52d";
    }

    .fa-feather-pointed::before,
    i.feather-pointed::before {
        content: "\\f56b";
    }

    .fa-feather-alt::before,
    i.feather-alt::before {
        content: "\\f56b";
    }

    .fa-fence::before,
    i.fence::before {
        content: "\\e303";
    }

    .fa-ferris-wheel::before,
    i.ferris-wheel::before {
        content: "\\e174";
    }

    .fa-ferry::before,
    i.ferry::before {
        content: "\\e4ea";
    }

    .fa-field-hockey-stick-ball::before,
    i.field-hockey-stick-ball::before {
        content: "\\f44c";
    }

    .fa-field-hockey::before,
    i.field-hockey::before {
        content: "\\f44c";
    }

    .fa-file::before,
    i.file::before {
        content: "\\f15b";
    }

    .fa-file-arrow-down::before,
    i.file-arrow-down::before {
        content: "\\f56d";
    }

    .fa-file-download::before,
    i.file-download::before {
        content: "\\f56d";
    }

    .fa-file-arrow-up::before,
    i.file-arrow-up::before {
        content: "\\f574";
    }

    .fa-file-upload::before,
    i.file-upload::before {
        content: "\\f574";
    }

    .fa-file-audio::before,
    i.file-audio::before {
        content: "\\f1c7";
    }

    .fa-file-binary::before,
    i.file-binary::before {
        content: "\\e175";
    }

    .fa-file-certificate::before,
    i.file-certificate::before {
        content: "\\f5f3";
    }

    .fa-file-award::before,
    i.file-award::before {
        content: "\\f5f3";
    }

    .fa-file-chart-column::before,
    i.file-chart-column::before {
        content: "\\f659";
    }

    .fa-file-chart-line::before,
    i.file-chart-line::before {
        content: "\\f659";
    }

    .fa-file-chart-pie::before,
    i.file-chart-pie::before {
        content: "\\f65a";
    }

    .fa-file-check::before,
    i.file-check::before {
        content: "\\f316";
    }

    .fa-file-circle-check::before,
    i.file-circle-check::before {
        content: "\\e493";
    }

    .fa-file-circle-exclamation::before,
    i.file-circle-exclamation::before {
        content: "\\e4eb";
    }

    .fa-file-circle-info::before,
    i.file-circle-info::before {
        content: "\\e4ec";
    }

    .fa-file-circle-minus::before,
    i.file-circle-minus::before {
        content: "\\e4ed";
    }

    .fa-file-circle-plus::before,
    i.file-circle-plus::before {
        content: "\\e4ee";
    }

    .fa-file-circle-question::before,
    i.file-circle-question::before {
        content: "\\e4ef";
    }

    .fa-file-circle-xmark::before,
    i.file-circle-xmark::before {
        content: "\\e494";
    }

    .fa-file-code::before,
    i.file-code::before {
        content: "\\f1c9";
    }

    .fa-file-contract::before,
    i.file-contract::before {
        content: "\\f56c";
    }

    .fa-file-csv::before,
    i.file-csv::before {
        content: "\\f6dd";
    }

    .fa-file-dashed-line::before,
    i.file-dashed-line::before {
        content: "\\f877";
    }

    .fa-page-break::before,
    i.page-break::before {
        content: "\\f877";
    }

    .fa-file-excel::before,
    i.file-excel::before {
        content: "\\f1c3";
    }

    .fa-file-exclamation::before,
    i.file-exclamation::before {
        content: "\\f31a";
    }

    .fa-file-export::before,
    i.file-export::before {
        content: "\\f56e";
    }

    .fa-arrow-right-from-file::before,
    i.arrow-right-from-file::before {
        content: "\\f56e";
    }

    .fa-file-heart::before,
    i.file-heart::before {
        content: "\\e176";
    }

    .fa-file-image::before,
    i.file-image::before {
        content: "\\f1c5";
    }

    .fa-file-import::before,
    i.file-import::before {
        content: "\\f56f";
    }

    .fa-arrow-right-to-file::before,
    i.arrow-right-to-file::before {
        content: "\\f56f";
    }

    .fa-file-invoice::before,
    i.file-invoice::before {
        content: "\\f570";
    }

    .fa-file-invoice-dollar::before,
    i.file-invoice-dollar::before {
        content: "\\f571";
    }

    .fa-file-lines::before,
    i.file-lines::before {
        content: "\\f15c";
    }

    .fa-file-alt::before,
    i.file-alt::before {
        content: "\\f15c";
    }

    .fa-file-text::before,
    i.file-text::before {
        content: "\\f15c";
    }

    .fa-file-lock::before,
    i.file-lock::before {
        content: "\\e3a6";
    }

    .fa-file-magnifying-glass::before,
    i.file-magnifying-glass::before {
        content: "\\f865";
    }

    .fa-file-search::before,
    i.file-search::before {
        content: "\\f865";
    }

    .fa-file-medical::before,
    i.file-medical::before {
        content: "\\f477";
    }

    .fa-file-minus::before,
    i.file-minus::before {
        content: "\\f318";
    }

    .fa-file-music::before,
    i.file-music::before {
        content: "\\f8b6";
    }

    .fa-file-pdf::before,
    i.file-pdf::before {
        content: "\\f1c1";
    }

    .fa-file-pen::before,
    i.file-pen::before {
        content: "\\f31c";
    }

    .fa-file-edit::before,
    i.file-edit::before {
        content: "\\f31c";
    }

    .fa-file-plus::before,
    i.file-plus::before {
        content: "\\f319";
    }

    .fa-file-plus-minus::before,
    i.file-plus-minus::before {
        content: "\\e177";
    }

    .fa-file-powerpoint::before,
    i.file-powerpoint::before {
        content: "\\f1c4";
    }

    .fa-file-prescription::before,
    i.file-prescription::before {
        content: "\\f572";
    }

    .fa-file-shield::before,
    i.file-shield::before {
        content: "\\e4f0";
    }

    .fa-file-signature::before,
    i.file-signature::before {
        content: "\\f573";
    }

    .fa-file-slash::before,
    i.file-slash::before {
        content: "\\e3a7";
    }

    .fa-file-spreadsheet::before,
    i.file-spreadsheet::before {
        content: "\\f65b";
    }

    .fa-file-user::before,
    i.file-user::before {
        content: "\\f65c";
    }

    .fa-file-video::before,
    i.file-video::before {
        content: "\\f1c8";
    }

    .fa-file-waveform::before,
    i.file-waveform::before {
        content: "\\f478";
    }

    .fa-file-medical-alt::before,
    i.file-medical-alt::before {
        content: "\\f478";
    }

    .fa-file-word::before,
    i.file-word::before {
        content: "\\f1c2";
    }

    .fa-file-xmark::before,
    i.file-xmark::before {
        content: "\\f317";
    }

    .fa-file-times::before,
    i.file-times::before {
        content: "\\f317";
    }

    .fa-file-zipper::before,
    i.file-zipper::before {
        content: "\\f1c6";
    }

    .fa-file-archive::before,
    i.file-archive::before {
        content: "\\f1c6";
    }

    .fa-files::before,
    i.files::before {
        content: "\\e178";
    }

    .fa-files-medical::before,
    i.files-medical::before {
        content: "\\f7fd";
    }

    .fa-fill::before,
    i.fill::before {
        content: "\\f575";
    }

    .fa-fill-drip::before,
    i.fill-drip::before {
        content: "\\f576";
    }

    .fa-film::before,
    i.film::before {
        content: "\\f008";
    }

    .fa-film-canister::before,
    i.film-canister::before {
        content: "\\f8b7";
    }

    .fa-film-simple::before,
    i.film-simple::before {
        content: "\\f3a0";
    }

    .fa-film-alt::before,
    i.film-alt::before {
        content: "\\f3a0";
    }

    .fa-film-slash::before,
    i.film-slash::before {
        content: "\\e179";
    }

    .fa-films::before,
    i.films::before {
        content: "\\e17a";
    }

    .fa-filter::before,
    i.filter::before {
        content: "\\f0b0";
    }

    .fa-filter-circle-dollar::before,
    i.filter-circle-dollar::before {
        content: "\\f662";
    }

    .fa-funnel-dollar::before,
    i.funnel-dollar::before {
        content: "\\f662";
    }

    .fa-filter-circle-xmark::before,
    i.filter-circle-xmark::before {
        content: "\\e17b";
    }

    .fa-filter-list::before,
    i.filter-list::before {
        content: "\\e17c";
    }

    .fa-filter-slash::before,
    i.filter-slash::before {
        content: "\\e17d";
    }

    .fa-filters::before,
    i.filters::before {
        content: "\\e17e";
    }

    .fa-fingerprint::before,
    i.fingerprint::before {
        content: "\\f577";
    }

    .fa-fire::before,
    i.fire::before {
        content: "\\f06d";
    }

    .fa-fire-burner::before,
    i.fire-burner::before {
        content: "\\e4f1";
    }

    .fa-fire-extinguisher::before,
    i.fire-extinguisher::before {
        content: "\\f134";
    }

    .fa-fire-flame::before,
    i.fire-flame::before {
        content: "\\f6df";
    }

    .fa-flame::before,
    i.flame::before {
        content: "\\f6df";
    }

    .fa-fire-flame-curved::before,
    i.fire-flame-curved::before {
        content: "\\f7e4";
    }

    .fa-fire-alt::before,
    i.fire-alt::before {
        content: "\\f7e4";
    }

    .fa-fire-flame-simple::before,
    i.fire-flame-simple::before {
        content: "\\f46a";
    }

    .fa-burn::before,
    i.burn::before {
        content: "\\f46a";
    }

    .fa-fire-hydrant::before,
    i.fire-hydrant::before {
        content: "\\e17f";
    }

    .fa-fire-smoke::before,
    i.fire-smoke::before {
        content: "\\f74b";
    }

    .fa-fireplace::before,
    i.fireplace::before {
        content: "\\f79a";
    }

    .fa-fish::before,
    i.fish::before {
        content: "\\f578";
    }

    .fa-fish-bones::before,
    i.fish-bones::before {
        content: "\\e304";
    }

    .fa-fish-cooked::before,
    i.fish-cooked::before {
        content: "\\f7fe";
    }

    .fa-fish-fins::before,
    i.fish-fins::before {
        content: "\\e4f2";
    }

    .fa-fishing-rod::before,
    i.fishing-rod::before {
        content: "\\e3a8";
    }

    .fa-flag::before,
    i.flag::before {
        content: "\\f024";
    }

    .fa-flag-checkered::before,
    i.flag-checkered::before {
        content: "\\f11e";
    }

    .fa-flag-pennant::before,
    i.flag-pennant::before {
        content: "\\f456";
    }

    .fa-pennant::before,
    i.pennant::before {
        content: "\\f456";
    }

    .fa-flag-swallowtail::before,
    i.flag-swallowtail::before {
        content: "\\f74c";
    }

    .fa-flag-alt::before,
    i.flag-alt::before {
        content: "\\f74c";
    }

    .fa-flag-usa::before,
    i.flag-usa::before {
        content: "\\f74d";
    }

    .fa-flashlight::before,
    i.flashlight::before {
        content: "\\f8b8";
    }

    .fa-flask::before,
    i.flask::before {
        content: "\\f0c3";
    }

    .fa-flask-round-poison::before,
    i.flask-round-poison::before {
        content: "\\f6e0";
    }

    .fa-flask-poison::before,
    i.flask-poison::before {
        content: "\\f6e0";
    }

    .fa-flask-round-potion::before,
    i.flask-round-potion::before {
        content: "\\f6e1";
    }

    .fa-flask-potion::before,
    i.flask-potion::before {
        content: "\\f6e1";
    }

    .fa-flask-vial::before,
    i.flask-vial::before {
        content: "\\e4f3";
    }

    .fa-flatbread::before,
    i.flatbread::before {
        content: "\\e40b";
    }

    .fa-flatbread-stuffed::before,
    i.flatbread-stuffed::before {
        content: "\\e40c";
    }

    .fa-floppy-disk::before,
    i.floppy-disk::before {
        content: "\\f0c7";
    }

    .fa-save::before,
    i.save::before {
        content: "\\f0c7";
    }

    .fa-floppy-disk-circle-arrow-right::before,
    i.floppy-disk-circle-arrow-right::before {
        content: "\\e180";
    }

    .fa-save-circle-arrow-right::before,
    i.save-circle-arrow-right::before {
        content: "\\e180";
    }

    .fa-floppy-disk-circle-xmark::before,
    i.floppy-disk-circle-xmark::before {
        content: "\\e181";
    }

    .fa-floppy-disk-times::before,
    i.floppy-disk-times::before {
        content: "\\e181";
    }

    .fa-save-circle-xmark::before,
    i.save-circle-xmark::before {
        content: "\\e181";
    }

    .fa-save-times::before,
    i.save-times::before {
        content: "\\e181";
    }

    .fa-floppy-disk-pen::before,
    i.floppy-disk-pen::before {
        content: "\\e182";
    }

    .fa-floppy-disks::before,
    i.floppy-disks::before {
        content: "\\e183";
    }

    .fa-florin-sign::before,
    i.florin-sign::before {
        content: "\\e184";
    }

    .fa-flower::before,
    i.flower::before {
        content: "\\f7ff";
    }

    .fa-flower-daffodil::before,
    i.flower-daffodil::before {
        content: "\\f800";
    }

    .fa-flower-tulip::before,
    i.flower-tulip::before {
        content: "\\f801";
    }

    .fa-flute::before,
    i.flute::before {
        content: "\\f8b9";
    }

    .fa-flux-capacitor::before,
    i.flux-capacitor::before {
        content: "\\f8ba";
    }

    .fa-flying-disc::before,
    i.flying-disc::before {
        content: "\\e3a9";
    }

    .fa-folder::before,
    i.folder::before {
        content: "\\f07b";
    }

    .fa-folder-blank::before,
    i.folder-blank::before {
        content: "\\f07b";
    }

    .fa-folder-arrow-down::before,
    i.folder-arrow-down::before {
        content: "\\e053";
    }

    .fa-folder-download::before,
    i.folder-download::before {
        content: "\\e053";
    }

    .fa-folder-arrow-up::before,
    i.folder-arrow-up::before {
        content: "\\e054";
    }

    .fa-folder-upload::before,
    i.folder-upload::before {
        content: "\\e054";
    }

    .fa-folder-bookmark::before,
    i.folder-bookmark::before {
        content: "\\e186";
    }

    .fa-folder-closed::before,
    i.folder-closed::before {
        content: "\\e185";
    }

    .fa-folder-gear::before,
    i.folder-gear::before {
        content: "\\e187";
    }

    .fa-folder-cog::before,
    i.folder-cog::before {
        content: "\\e187";
    }

    .fa-folder-grid::before,
    i.folder-grid::before {
        content: "\\e188";
    }

    .fa-folder-heart::before,
    i.folder-heart::before {
        content: "\\e189";
    }

    .fa-folder-image::before,
    i.folder-image::before {
        content: "\\e18a";
    }

    .fa-folder-magnifying-glass::before,
    i.folder-magnifying-glass::before {
        content: "\\e18b";
    }

    .fa-folder-search::before,
    i.folder-search::before {
        content: "\\e18b";
    }

    .fa-folder-medical::before,
    i.folder-medical::before {
        content: "\\e18c";
    }

    .fa-folder-minus::before,
    i.folder-minus::before {
        content: "\\f65d";
    }

    .fa-folder-music::before,
    i.folder-music::before {
        content: "\\e18d";
    }

    .fa-folder-open::before,
    i.folder-open::before {
        content: "\\f07c";
    }

    .fa-folder-plus::before,
    i.folder-plus::before {
        content: "\\f65e";
    }

    .fa-folder-tree::before,
    i.folder-tree::before {
        content: "\\f802";
    }

    .fa-folder-user::before,
    i.folder-user::before {
        content: "\\e18e";
    }

    .fa-folder-xmark::before,
    i.folder-xmark::before {
        content: "\\f65f";
    }

    .fa-folder-times::before,
    i.folder-times::before {
        content: "\\f65f";
    }

    .fa-folders::before,
    i.folders::before {
        content: "\\f660";
    }

    .fa-fondue-pot::before,
    i.fondue-pot::before {
        content: "\\e40d";
    }

    .fa-font::before,
    i.font::before {
        content: "\\f031";
    }

    .fa-font-case::before,
    i.font-case::before {
        content: "\\f866";
    }

    .fa-football::before,
    i.football::before {
        content: "\\f44e";
    }

    .fa-football-ball::before,
    i.football-ball::before {
        content: "\\f44e";
    }

    .fa-football-helmet::before,
    i.football-helmet::before {
        content: "\\f44f";
    }

    .fa-fork::before,
    i.fork::before {
        content: "\\f2e3";
    }

    .fa-utensil-fork::before,
    i.utensil-fork::before {
        content: "\\f2e3";
    }

    .fa-fork-knife::before,
    i.fork-knife::before {
        content: "\\f2e6";
    }

    .fa-utensils-alt::before,
    i.utensils-alt::before {
        content: "\\f2e6";
    }

    .fa-forklift::before,
    i.forklift::before {
        content: "\\f47a";
    }

    .fa-fort::before,
    i.fort::before {
        content: "\\e486";
    }

    .fa-forward::before,
    i.forward::before {
        content: "\\f04e";
    }

    .fa-forward-fast::before,
    i.forward-fast::before {
        content: "\\f050";
    }

    .fa-fast-forward::before,
    i.fast-forward::before {
        content: "\\f050";
    }

    .fa-forward-step::before,
    i.forward-step::before {
        content: "\\f051";
    }

    .fa-step-forward::before,
    i.step-forward::before {
        content: "\\f051";
    }

    .fa-frame::before,
    i.frame::before {
        content: "\\e495";
    }

    .fa-franc-sign::before,
    i.franc-sign::before {
        content: "\\e18f";
    }

    .fa-french-fries::before,
    i.french-fries::before {
        content: "\\f803";
    }

    .fa-frog::before,
    i.frog::before {
        content: "\\f52e";
    }

    .fa-function::before,
    i.function::before {
        content: "\\f661";
    }

    .fa-futbol::before,
    i.futbol::before {
        content: "\\f1e3";
    }

    .fa-futbol-ball::before,
    i.futbol-ball::before {
        content: "\\f1e3";
    }

    .fa-soccer-ball::before,
    i.soccer-ball::before {
        content: "\\f1e3";
    }

    .fa-g::before,
    i.g::before {
        content: "\\47";
    }

    .fa-galaxy::before,
    i.galaxy::before {
        content: "\\e008";
    }

    .fa-gallery-thumbnails::before,
    i.gallery-thumbnails::before {
        content: "\\e3aa";
    }

    .fa-game-board::before,
    i.game-board::before {
        content: "\\f867";
    }

    .fa-game-board-simple::before,
    i.game-board-simple::before {
        content: "\\f868";
    }

    .fa-game-board-alt::before,
    i.game-board-alt::before {
        content: "\\f868";
    }

    .fa-game-console-handheld::before,
    i.game-console-handheld::before {
        content: "\\f8bb";
    }

    .fa-gamepad::before,
    i.gamepad::before {
        content: "\\f11b";
    }

    .fa-gamepad-modern::before,
    i.gamepad-modern::before {
        content: "\\f8bc";
    }

    .fa-gamepad-alt::before,
    i.gamepad-alt::before {
        content: "\\f8bc";
    }

    .fa-garage::before,
    i.garage::before {
        content: "\\e009";
    }

    .fa-garage-car::before,
    i.garage-car::before {
        content: "\\e00a";
    }

    .fa-garage-open::before,
    i.garage-open::before {
        content: "\\e00b";
    }

    .fa-garlic::before,
    i.garlic::before {
        content: "\\e40e";
    }

    .fa-gas-pump::before,
    i.gas-pump::before {
        content: "\\f52f";
    }

    .fa-gas-pump-slash::before,
    i.gas-pump-slash::before {
        content: "\\f5f4";
    }

    .fa-gauge::before,
    i.gauge::before {
        content: "\\f624";
    }

    .fa-dashboard::before,
    i.dashboard::before {
        content: "\\f624";
    }

    .fa-gauge-med::before,
    i.gauge-med::before {
        content: "\\f624";
    }

    .fa-tachometer-alt-average::before,
    i.tachometer-alt-average::before {
        content: "\\f624";
    }

    .fa-gauge-circle-bolt::before,
    i.gauge-circle-bolt::before {
        content: "\\e496";
    }

    .fa-gauge-circle-minus::before,
    i.gauge-circle-minus::before {
        content: "\\e497";
    }

    .fa-gauge-circle-plus::before,
    i.gauge-circle-plus::before {
        content: "\\e498";
    }

    .fa-gauge-high::before,
    i.gauge-high::before {
        content: "\\f625";
    }

    .fa-tachometer-alt::before,
    i.tachometer-alt::before {
        content: "\\f625";
    }

    .fa-tachometer-alt-fast::before,
    i.tachometer-alt-fast::before {
        content: "\\f625";
    }

    .fa-gauge-low::before,
    i.gauge-low::before {
        content: "\\f627";
    }

    .fa-tachometer-alt-slow::before,
    i.tachometer-alt-slow::before {
        content: "\\f627";
    }

    .fa-gauge-max::before,
    i.gauge-max::before {
        content: "\\f626";
    }

    .fa-tachometer-alt-fastest::before,
    i.tachometer-alt-fastest::before {
        content: "\\f626";
    }

    .fa-gauge-min::before,
    i.gauge-min::before {
        content: "\\f628";
    }

    .fa-tachometer-alt-slowest::before,
    i.tachometer-alt-slowest::before {
        content: "\\f628";
    }

    .fa-gauge-simple::before,
    i.gauge-simple::before {
        content: "\\f629";
    }

    .fa-gauge-simple-med::before,
    i.gauge-simple-med::before {
        content: "\\f629";
    }

    .fa-tachometer-average::before,
    i.tachometer-average::before {
        content: "\\f629";
    }

    .fa-gauge-simple-high::before,
    i.gauge-simple-high::before {
        content: "\\f62a";
    }

    .fa-tachometer::before,
    i.tachometer::before {
        content: "\\f62a";
    }

    .fa-tachometer-fast::before,
    i.tachometer-fast::before {
        content: "\\f62a";
    }

    .fa-gauge-simple-low::before,
    i.gauge-simple-low::before {
        content: "\\f62c";
    }

    .fa-tachometer-slow::before,
    i.tachometer-slow::before {
        content: "\\f62c";
    }

    .fa-gauge-simple-max::before,
    i.gauge-simple-max::before {
        content: "\\f62b";
    }

    .fa-tachometer-fastest::before,
    i.tachometer-fastest::before {
        content: "\\f62b";
    }

    .fa-gauge-simple-min::before,
    i.gauge-simple-min::before {
        content: "\\f62d";
    }

    .fa-tachometer-slowest::before,
    i.tachometer-slowest::before {
        content: "\\f62d";
    }

    .fa-gavel::before,
    i.gavel::before {
        content: "\\f0e3";
    }

    .fa-legal::before,
    i.legal::before {
        content: "\\f0e3";
    }

    .fa-gear::before,
    i.gear::before {
        content: "\\f013";
    }

    .fa-cog::before,
    i.cog::before {
        content: "\\f013";
    }

    .fa-gears::before,
    i.gears::before {
        content: "\\f085";
    }

    .fa-cogs::before,
    i.cogs::before {
        content: "\\f085";
    }

    .fa-gem::before,
    i.gem::before {
        content: "\\f3a5";
    }

    .fa-genderless::before,
    i.genderless::before {
        content: "\\f22d";
    }

    .fa-ghost::before,
    i.ghost::before {
        content: "\\f6e2";
    }

    .fa-gif::before,
    i.gif::before {
        content: "\\e190";
    }

    .fa-gift::before,
    i.gift::before {
        content: "\\f06b";
    }

    .fa-gift-card::before,
    i.gift-card::before {
        content: "\\f663";
    }

    .fa-gifts::before,
    i.gifts::before {
        content: "\\f79c";
    }

    .fa-gingerbread-man::before,
    i.gingerbread-man::before {
        content: "\\f79d";
    }

    .fa-glass::before,
    i.glass::before {
        content: "\\f804";
    }

    .fa-glass-citrus::before,
    i.glass-citrus::before {
        content: "\\f869";
    }

    .fa-glass-empty::before,
    i.glass-empty::before {
        content: "\\e191";
    }

    .fa-glass-half::before,
    i.glass-half::before {
        content: "\\e192";
    }

    .fa-glass-half-empty::before,
    i.glass-half-empty::before {
        content: "\\e192";
    }

    .fa-glass-half-full::before,
    i.glass-half-full::before {
        content: "\\e192";
    }

    .fa-glass-water::before,
    i.glass-water::before {
        content: "\\e4f4";
    }

    .fa-glass-water-droplet::before,
    i.glass-water-droplet::before {
        content: "\\e4f5";
    }

    .fa-glasses::before,
    i.glasses::before {
        content: "\\f530";
    }

    .fa-glasses-round::before,
    i.glasses-round::before {
        content: "\\f5f5";
    }

    .fa-glasses-alt::before,
    i.glasses-alt::before {
        content: "\\f5f5";
    }

    .fa-globe::before,
    i.globe::before {
        content: "\\f0ac";
    }

    .fa-globe-snow::before,
    i.globe-snow::before {
        content: "\\f7a3";
    }

    .fa-globe-stand::before,
    i.globe-stand::before {
        content: "\\f5f6";
    }

    .fa-goal-net::before,
    i.goal-net::before {
        content: "\\e3ab";
    }

    .fa-golf-ball-tee::before,
    i.golf-ball-tee::before {
        content: "\\f450";
    }

    .fa-golf-ball::before,
    i.golf-ball::before {
        content: "\\f450";
    }

    .fa-golf-club::before,
    i.golf-club::before {
        content: "\\f451";
    }

    .fa-golf-flag-hole::before,
    i.golf-flag-hole::before {
        content: "\\e3ac";
    }

    .fa-gopuram::before,
    i.gopuram::before {
        content: "\\f664";
    }

    .fa-graduation-cap::before,
    i.graduation-cap::before {
        content: "\\f19d";
    }

    .fa-mortar-board::before,
    i.mortar-board::before {
        content: "\\f19d";
    }

    .fa-gramophone::before,
    i.gramophone::before {
        content: "\\f8bd";
    }

    .fa-grapes::before,
    i.grapes::before {
        content: "\\e306";
    }

    .fa-grate::before,
    i.grate::before {
        content: "\\e193";
    }

    .fa-grate-droplet::before,
    i.grate-droplet::before {
        content: "\\e194";
    }

    .fa-greater-than::before,
    i.greater-than::before {
        content: "\\3e";
    }

    .fa-greater-than-equal::before,
    i.greater-than-equal::before {
        content: "\\f532";
    }

    .fa-grid::before,
    i.grid::before {
        content: "\\e195";
    }

    .fa-grid-3::before,
    i.grid-3::before {
        content: "\\e195";
    }

    .fa-grid-2::before,
    i.grid-2::before {
        content: "\\e196";
    }

    .fa-grid-2-plus::before,
    i.grid-2-plus::before {
        content: "\\e197";
    }

    .fa-grid-4::before,
    i.grid-4::before {
        content: "\\e198";
    }

    .fa-grid-5::before,
    i.grid-5::before {
        content: "\\e199";
    }

    .fa-grid-dividers::before,
    i.grid-dividers::before {
        content: "\\e3ad";
    }

    .fa-grid-horizontal::before,
    i.grid-horizontal::before {
        content: "\\e307";
    }

    .fa-grip::before,
    i.grip::before {
        content: "\\f58d";
    }

    .fa-grip-horizontal::before,
    i.grip-horizontal::before {
        content: "\\f58d";
    }

    .fa-grip-dots::before,
    i.grip-dots::before {
        content: "\\e410";
    }

    .fa-grip-dots-vertical::before,
    i.grip-dots-vertical::before {
        content: "\\e411";
    }

    .fa-grip-lines::before,
    i.grip-lines::before {
        content: "\\f7a4";
    }

    .fa-grip-lines-vertical::before,
    i.grip-lines-vertical::before {
        content: "\\f7a5";
    }

    .fa-grip-vertical::before,
    i.grip-vertical::before {
        content: "\\f58e";
    }

    .fa-group-arrows-rotate::before,
    i.group-arrows-rotate::before {
        content: "\\e4f6";
    }

    .fa-guarani-sign::before,
    i.guarani-sign::before {
        content: "\\e19a";
    }

    .fa-guitar::before,
    i.guitar::before {
        content: "\\f7a6";
    }

    .fa-guitar-electric::before,
    i.guitar-electric::before {
        content: "\\f8be";
    }

    .fa-guitars::before,
    i.guitars::before {
        content: "\\f8bf";
    }

    .fa-gun::before,
    i.gun::before {
        content: "\\e19b";
    }

    .fa-gun-slash::before,
    i.gun-slash::before {
        content: "\\e19c";
    }

    .fa-gun-squirt::before,
    i.gun-squirt::before {
        content: "\\e19d";
    }

    .fa-h::before,
    i.h::before {
        content: "\\48";
    }

    .fa-h1::before,
    i.h1::before {
        content: "\\f313";
    }

    .fa-h2::before,
    i.h2::before {
        content: "\\f314";
    }

    .fa-h3::before,
    i.h3::before {
        content: "\\f315";
    }

    .fa-h4::before,
    i.h4::before {
        content: "\\f86a";
    }

    .fa-h5::before,
    i.h5::before {
        content: "\\e412";
    }

    .fa-h6::before,
    i.h6::before {
        content: "\\e413";
    }

    .fa-hammer::before,
    i.hammer::before {
        content: "\\f6e3";
    }

    .fa-hammer-crash::before,
    i.hammer-crash::before {
        content: "\\e414";
    }

    .fa-hammer-war::before,
    i.hammer-war::before {
        content: "\\f6e4";
    }

    .fa-hamsa::before,
    i.hamsa::before {
        content: "\\f665";
    }

    .fa-hand::before,
    i.hand::before {
        content: "\\f256";
    }

    .fa-hand-paper::before,
    i.hand-paper::before {
        content: "\\f256";
    }

    .fa-hand-back-fist::before,
    i.hand-back-fist::before {
        content: "\\f255";
    }

    .fa-hand-rock::before,
    i.hand-rock::before {
        content: "\\f255";
    }

    .fa-hand-back-point-down::before,
    i.hand-back-point-down::before {
        content: "\\e19e";
    }

    .fa-hand-back-point-left::before,
    i.hand-back-point-left::before {
        content: "\\e19f";
    }

    .fa-hand-back-point-ribbon::before,
    i.hand-back-point-ribbon::before {
        content: "\\e1a0";
    }

    .fa-hand-back-point-right::before,
    i.hand-back-point-right::before {
        content: "\\e1a1";
    }

    .fa-hand-back-point-up::before,
    i.hand-back-point-up::before {
        content: "\\e1a2";
    }

    .fa-hand-dots::before,
    i.hand-dots::before {
        content: "\\f461";
    }

    .fa-allergies::before,
    i.allergies::before {
        content: "\\f461";
    }

    .fa-hand-fingers-crossed::before,
    i.hand-fingers-crossed::before {
        content: "\\e1a3";
    }

    .fa-hand-fist::before,
    i.hand-fist::before {
        content: "\\f6de";
    }

    .fa-fist-raised::before,
    i.fist-raised::before {
        content: "\\f6de";
    }

    .fa-hand-heart::before,
    i.hand-heart::before {
        content: "\\f4bc";
    }

    .fa-hand-holding::before,
    i.hand-holding::before {
        content: "\\f4bd";
    }

    .fa-hand-holding-box::before,
    i.hand-holding-box::before {
        content: "\\f47b";
    }

    .fa-hand-holding-dollar::before,
    i.hand-holding-dollar::before {
        content: "\\f4c0";
    }

    .fa-hand-holding-usd::before,
    i.hand-holding-usd::before {
        content: "\\f4c0";
    }

    .fa-hand-holding-droplet::before,
    i.hand-holding-droplet::before {
        content: "\\f4c1";
    }

    .fa-hand-holding-water::before,
    i.hand-holding-water::before {
        content: "\\f4c1";
    }

    .fa-hand-holding-hand::before,
    i.hand-holding-hand::before {
        content: "\\e4f7";
    }

    .fa-hand-holding-heart::before,
    i.hand-holding-heart::before {
        content: "\\f4be";
    }

    .fa-hand-holding-magic::before,
    i.hand-holding-magic::before {
        content: "\\f6e5";
    }

    .fa-hand-holding-medical::before,
    i.hand-holding-medical::before {
        content: "\\e05c";
    }

    .fa-hand-holding-seedling::before,
    i.hand-holding-seedling::before {
        content: "\\f4bf";
    }

    .fa-hand-holding-skull::before,
    i.hand-holding-skull::before {
        content: "\\e1a4";
    }

    .fa-hand-horns::before,
    i.hand-horns::before {
        content: "\\e1a9";
    }

    .fa-hand-lizard::before,
    i.hand-lizard::before {
        content: "\\f258";
    }

    .fa-hand-love::before,
    i.hand-love::before {
        content: "\\e1a5";
    }

    .fa-hand-middle-finger::before,
    i.hand-middle-finger::before {
        content: "\\f806";
    }

    .fa-hand-peace::before,
    i.hand-peace::before {
        content: "\\f25b";
    }

    .fa-hand-point-down::before,
    i.hand-point-down::before {
        content: "\\f0a7";
    }

    .fa-hand-point-left::before,
    i.hand-point-left::before {
        content: "\\f0a5";
    }

    .fa-hand-point-ribbon::before,
    i.hand-point-ribbon::before {
        content: "\\e1a6";
    }

    .fa-hand-point-right::before,
    i.hand-point-right::before {
        content: "\\f0a4";
    }

    .fa-hand-point-up::before,
    i.hand-point-up::before {
        content: "\\f0a6";
    }

    .fa-hand-pointer::before,
    i.hand-pointer::before {
        content: "\\f25a";
    }

    .fa-hand-scissors::before,
    i.hand-scissors::before {
        content: "\\f257";
    }

    .fa-hand-sparkles::before,
    i.hand-sparkles::before {
        content: "\\e05d";
    }

    .fa-hand-spock::before,
    i.hand-spock::before {
        content: "\\f259";
    }

    .fa-hand-wave::before,
    i.hand-wave::before {
        content: "\\e1a7";
    }

    .fa-handcuffs::before,
    i.handcuffs::before {
        content: "\\e4f8";
    }

    .fa-hands::before,
    i.hands::before {
        content: "\\f2a7";
    }

    .fa-sign-language::before,
    i.sign-language::before {
        content: "\\f2a7";
    }

    .fa-signing::before,
    i.signing::before {
        content: "\\f2a7";
    }

    .fa-hands-asl-interpreting::before,
    i.hands-asl-interpreting::before {
        content: "\\f2a3";
    }

    .fa-american-sign-language-interpreting::before,
    i.american-sign-language-interpreting::before {
        content: "\\f2a3";
    }

    .fa-asl-interpreting::before,
    i.asl-interpreting::before {
        content: "\\f2a3";
    }

    .fa-hands-american-sign-language-interpreting::before,
    i.hands-american-sign-language-interpreting::before {
        content: "\\f2a3";
    }

    .fa-hands-bound::before,
    i.hands-bound::before {
        content: "\\e4f9";
    }

    .fa-hands-bubbles::before,
    i.hands-bubbles::before {
        content: "\\e05e";
    }

    .fa-hands-wash::before,
    i.hands-wash::before {
        content: "\\e05e";
    }

    .fa-hands-clapping::before,
    i.hands-clapping::before {
        content: "\\e1a8";
    }

    .fa-hands-holding::before,
    i.hands-holding::before {
        content: "\\f4c2";
    }

    .fa-hands-holding-child::before,
    i.hands-holding-child::before {
        content: "\\e4fa";
    }

    .fa-hands-holding-circle::before,
    i.hands-holding-circle::before {
        content: "\\e4fb";
    }

    .fa-hands-holding-diamond::before,
    i.hands-holding-diamond::before {
        content: "\\f47c";
    }

    .fa-hand-receiving::before,
    i.hand-receiving::before {
        content: "\\f47c";
    }

    .fa-hands-holding-dollar::before,
    i.hands-holding-dollar::before {
        content: "\\f4c5";
    }

    .fa-hands-usd::before,
    i.hands-usd::before {
        content: "\\f4c5";
    }

    .fa-hands-holding-heart::before,
    i.hands-holding-heart::before {
        content: "\\f4c3";
    }

    .fa-hands-heart::before,
    i.hands-heart::before {
        content: "\\f4c3";
    }

    .fa-hands-praying::before,
    i.hands-praying::before {
        content: "\\f684";
    }

    .fa-praying-hands::before,
    i.praying-hands::before {
        content: "\\f684";
    }

    .fa-handshake::before,
    i.handshake::before {
        content: "\\f2b5";
    }

    .fa-handshake-angle::before,
    i.handshake-angle::before {
        content: "\\f4c4";
    }

    .fa-hands-helping::before,
    i.hands-helping::before {
        content: "\\f4c4";
    }

    .fa-handshake-simple::before,
    i.handshake-simple::before {
        content: "\\f4c6";
    }

    .fa-handshake-alt::before,
    i.handshake-alt::before {
        content: "\\f4c6";
    }

    .fa-handshake-simple-slash::before,
    i.handshake-simple-slash::before {
        content: "\\e05f";
    }

    .fa-handshake-alt-slash::before,
    i.handshake-alt-slash::before {
        content: "\\e05f";
    }

    .fa-handshake-slash::before,
    i.handshake-slash::before {
        content: "\\e060";
    }

    .fa-hanukiah::before,
    i.hanukiah::before {
        content: "\\f6e6";
    }

    .fa-hard-drive::before,
    i.hard-drive::before {
        content: "\\f0a0";
    }

    .fa-hdd::before,
    i.hdd::before {
        content: "\\f0a0";
    }

    .fa-hashtag::before,
    i.hashtag::before {
        content: "\\23";
    }

    .fa-hashtag-lock::before,
    i.hashtag-lock::before {
        content: "\\e415";
    }

    .fa-hat-chef::before,
    i.hat-chef::before {
        content: "\\f86b";
    }

    .fa-hat-cowboy::before,
    i.hat-cowboy::before {
        content: "\\f8c0";
    }

    .fa-hat-cowboy-side::before,
    i.hat-cowboy-side::before {
        content: "\\f8c1";
    }

    .fa-hat-santa::before,
    i.hat-santa::before {
        content: "\\f7a7";
    }

    .fa-hat-winter::before,
    i.hat-winter::before {
        content: "\\f7a8";
    }

    .fa-hat-witch::before,
    i.hat-witch::before {
        content: "\\f6e7";
    }

    .fa-hat-wizard::before,
    i.hat-wizard::before {
        content: "\\f6e8";
    }

    .fa-head-side::before,
    i.head-side::before {
        content: "\\f6e9";
    }

    .fa-head-side-brain::before,
    i.head-side-brain::before {
        content: "\\f808";
    }

    .fa-head-side-cough::before,
    i.head-side-cough::before {
        content: "\\e061";
    }

    .fa-head-side-cough-slash::before,
    i.head-side-cough-slash::before {
        content: "\\e062";
    }

    .fa-head-side-goggles::before,
    i.head-side-goggles::before {
        content: "\\f6ea";
    }

    .fa-head-vr::before,
    i.head-vr::before {
        content: "\\f6ea";
    }

    .fa-head-side-headphones::before,
    i.head-side-headphones::before {
        content: "\\f8c2";
    }

    .fa-head-side-heart::before,
    i.head-side-heart::before {
        content: "\\e1aa";
    }

    .fa-head-side-mask::before,
    i.head-side-mask::before {
        content: "\\e063";
    }

    .fa-head-side-medical::before,
    i.head-side-medical::before {
        content: "\\f809";
    }

    .fa-head-side-virus::before,
    i.head-side-virus::before {
        content: "\\e064";
    }

    .fa-heading::before,
    i.heading::before {
        content: "\\f1dc";
    }

    .fa-header::before,
    i.header::before {
        content: "\\f1dc";
    }

    .fa-headphones::before,
    i.headphones::before {
        content: "\\f025";
    }

    .fa-headphones-simple::before,
    i.headphones-simple::before {
        content: "\\f58f";
    }

    .fa-headphones-alt::before,
    i.headphones-alt::before {
        content: "\\f58f";
    }

    .fa-headset::before,
    i.headset::before {
        content: "\\f590";
    }

    .fa-heart::before,
    i.heart::before {
        content: "\\f004";
    }

    .fa-heart-circle-bolt::before,
    i.heart-circle-bolt::before {
        content: "\\e4fc";
    }

    .fa-heart-circle-check::before,
    i.heart-circle-check::before {
        content: "\\e4fd";
    }

    .fa-heart-circle-exclamation::before,
    i.heart-circle-exclamation::before {
        content: "\\e4fe";
    }

    .fa-heart-circle-minus::before,
    i.heart-circle-minus::before {
        content: "\\e4ff";
    }

    .fa-heart-circle-plus::before,
    i.heart-circle-plus::before {
        content: "\\e500";
    }

    .fa-heart-circle-xmark::before,
    i.heart-circle-xmark::before {
        content: "\\e501";
    }

    .fa-heart-crack::before,
    i.heart-crack::before {
        content: "\\f7a9";
    }

    .fa-heart-broken::before,
    i.heart-broken::before {
        content: "\\f7a9";
    }

    .fa-heart-half::before,
    i.heart-half::before {
        content: "\\e1ab";
    }

    .fa-heart-half-stroke::before,
    i.heart-half-stroke::before {
        content: "\\e1ac";
    }

    .fa-heart-half-alt::before,
    i.heart-half-alt::before {
        content: "\\e1ac";
    }

    .fa-heart-pulse::before,
    i.heart-pulse::before {
        content: "\\f21e";
    }

    .fa-heartbeat::before,
    i.heartbeat::before {
        content: "\\f21e";
    }

    .fa-heat::before,
    i.heat::before {
        content: "\\e00c";
    }

    .fa-helicopter::before,
    i.helicopter::before {
        content: "\\f533";
    }

    .fa-helicopter-symbol::before,
    i.helicopter-symbol::before {
        content: "\\e502";
    }

    .fa-helmet-battle::before,
    i.helmet-battle::before {
        content: "\\f6eb";
    }

    .fa-helmet-safety::before,
    i.helmet-safety::before {
        content: "\\f807";
    }

    .fa-hard-hat::before,
    i.hard-hat::before {
        content: "\\f807";
    }

    .fa-hat-hard::before,
    i.hat-hard::before {
        content: "\\f807";
    }

    .fa-helmet-un::before,
    i.helmet-un::before {
        content: "\\e503";
    }

    .fa-hexagon::before,
    i.hexagon::before {
        content: "\\f312";
    }

    .fa-hexagon-check::before,
    i.hexagon-check::before {
        content: "\\e416";
    }

    .fa-hexagon-divide::before,
    i.hexagon-divide::before {
        content: "\\e1ad";
    }

    .fa-hexagon-exclamation::before,
    i.hexagon-exclamation::before {
        content: "\\e417";
    }

    .fa-hexagon-image::before,
    i.hexagon-image::before {
        content: "\\e504";
    }

    .fa-hexagon-minus::before,
    i.hexagon-minus::before {
        content: "\\f307";
    }

    .fa-minus-hexagon::before,
    i.minus-hexagon::before {
        content: "\\f307";
    }

    .fa-hexagon-plus::before,
    i.hexagon-plus::before {
        content: "\\f300";
    }

    .fa-plus-hexagon::before,
    i.plus-hexagon::before {
        content: "\\f300";
    }

    .fa-hexagon-vertical-nft::before,
    i.hexagon-vertical-nft::before {
        content: "\\e505";
    }

    .fa-hexagon-vertical-nft-slanted::before,
    i.hexagon-vertical-nft-slanted::before {
        content: "\\e506";
    }

    .fa-hexagon-xmark::before,
    i.hexagon-xmark::before {
        content: "\\f2ee";
    }

    .fa-times-hexagon::before,
    i.times-hexagon::before {
        content: "\\f2ee";
    }

    .fa-xmark-hexagon::before,
    i.xmark-hexagon::before {
        content: "\\f2ee";
    }

    .fa-high-definition::before,
    i.high-definition::before {
        content: "\\e1ae";
    }

    .fa-rectangle-hd::before,
    i.rectangle-hd::before {
        content: "\\e1ae";
    }

    .fa-highlighter::before,
    i.highlighter::before {
        content: "\\f591";
    }

    .fa-highlighter-line::before,
    i.highlighter-line::before {
        content: "\\e1af";
    }

    .fa-hill-avalanche::before,
    i.hill-avalanche::before {
        content: "\\e507";
    }

    .fa-hill-rockslide::before,
    i.hill-rockslide::before {
        content: "\\e508";
    }

    .fa-hippo::before,
    i.hippo::before {
        content: "\\f6ed";
    }

    .fa-hockey-mask::before,
    i.hockey-mask::before {
        content: "\\f6ee";
    }

    .fa-hockey-puck::before,
    i.hockey-puck::before {
        content: "\\f453";
    }

    .fa-hockey-stick-puck::before,
    i.hockey-stick-puck::before {
        content: "\\e3ae";
    }

    .fa-hockey-sticks::before,
    i.hockey-sticks::before {
        content: "\\f454";
    }

    .fa-holly-berry::before,
    i.holly-berry::before {
        content: "\\f7aa";
    }

    .fa-honey-pot::before,
    i.honey-pot::before {
        content: "\\e418";
    }

    .fa-hood-cloak::before,
    i.hood-cloak::before {
        content: "\\f6ef";
    }

    .fa-horizontal-rule::before,
    i.horizontal-rule::before {
        content: "\\f86c";
    }

    .fa-horse::before,
    i.horse::before {
        content: "\\f6f0";
    }

    .fa-horse-head::before,
    i.horse-head::before {
        content: "\\f7ab";
    }

    .fa-horse-saddle::before,
    i.horse-saddle::before {
        content: "\\f8c3";
    }

    .fa-hose::before,
    i.hose::before {
        content: "\\e419";
    }

    .fa-hose-reel::before,
    i.hose-reel::before {
        content: "\\e41a";
    }

    .fa-hospital::before,
    i.hospital::before {
        content: "\\f0f8";
    }

    .fa-hospital-alt::before,
    i.hospital-alt::before {
        content: "\\f0f8";
    }

    .fa-hospital-wide::before,
    i.hospital-wide::before {
        content: "\\f0f8";
    }

    .fa-hospital-user::before,
    i.hospital-user::before {
        content: "\\f80d";
    }

    .fa-hospitals::before,
    i.hospitals::before {
        content: "\\f80e";
    }

    .fa-hot-tub-person::before,
    i.hot-tub-person::before {
        content: "\\f593";
    }

    .fa-hot-tub::before,
    i.hot-tub::before {
        content: "\\f593";
    }

    .fa-hotdog::before,
    i.hotdog::before {
        content: "\\f80f";
    }

    .fa-hotel::before,
    i.hotel::before {
        content: "\\f594";
    }

    .fa-hourglass::before,
    i.hourglass::before {
        content: "\\f254";
    }

    .fa-hourglass-2::before,
    i.hourglass-2::before {
        content: "\\f254";
    }

    .fa-hourglass-half::before,
    i.hourglass-half::before {
        content: "\\f254";
    }

    .fa-hourglass-clock::before,
    i.hourglass-clock::before {
        content: "\\e41b";
    }

    .fa-hourglass-empty::before,
    i.hourglass-empty::before {
        content: "\\f252";
    }

    .fa-hourglass-end::before,
    i.hourglass-end::before {
        content: "\\f253";
    }

    .fa-hourglass-3::before,
    i.hourglass-3::before {
        content: "\\f253";
    }

    .fa-hourglass-start::before,
    i.hourglass-start::before {
        content: "\\f251";
    }

    .fa-hourglass-1::before,
    i.hourglass-1::before {
        content: "\\f251";
    }

    .fa-house::before,
    i.house::before {
        content: "\\f015";
    }

    .fa-home::before,
    i.home::before {
        content: "\\f015";
    }

    .fa-home-alt::before,
    i.home-alt::before {
        content: "\\f015";
    }

    .fa-home-lg-alt::before,
    i.home-lg-alt::before {
        content: "\\f015";
    }

    .fa-house-blank::before,
    i.house-blank::before {
        content: "\\e487";
    }

    .fa-home-blank::before,
    i.home-blank::before {
        content: "\\e487";
    }

    .fa-house-building::before,
    i.house-building::before {
        content: "\\e1b1";
    }

    .fa-house-chimney::before,
    i.house-chimney::before {
        content: "\\e3af";
    }

    .fa-home-lg::before,
    i.home-lg::before {
        content: "\\e3af";
    }

    .fa-house-chimney-blank::before,
    i.house-chimney-blank::before {
        content: "\\e3b0";
    }

    .fa-house-chimney-crack::before,
    i.house-chimney-crack::before {
        content: "\\f6f1";
    }

    .fa-house-damage::before,
    i.house-damage::before {
        content: "\\f6f1";
    }

    .fa-house-chimney-heart::before,
    i.house-chimney-heart::before {
        content: "\\e1b2";
    }

    .fa-house-chimney-medical::before,
    i.house-chimney-medical::before {
        content: "\\f7f2";
    }

    .fa-clinic-medical::before,
    i.clinic-medical::before {
        content: "\\f7f2";
    }

    .fa-house-chimney-user::before,
    i.house-chimney-user::before {
        content: "\\e065";
    }

    .fa-house-chimney-window::before,
    i.house-chimney-window::before {
        content: "\\e00d";
    }

    .fa-house-circle-check::before,
    i.house-circle-check::before {
        content: "\\e509";
    }

    .fa-house-circle-exclamation::before,
    i.house-circle-exclamation::before {
        content: "\\e50a";
    }

    .fa-house-circle-xmark::before,
    i.house-circle-xmark::before {
        content: "\\e50b";
    }

    .fa-house-crack::before,
    i.house-crack::before {
        content: "\\e3b1";
    }

    .fa-house-day::before,
    i.house-day::before {
        content: "\\e00e";
    }

    .fa-house-fire::before,
    i.house-fire::before {
        content: "\\e50c";
    }

    .fa-house-flag::before,
    i.house-flag::before {
        content: "\\e50d";
    }

    .fa-house-flood-water::before,
    i.house-flood-water::before {
        content: "\\e50e";
    }

    .fa-house-flood-water-circle-arrow-right::before,
    i.house-flood-water-circle-arrow-right::before {
        content: "\\e50f";
    }

    .fa-house-heart::before,
    i.house-heart::before {
        content: "\\f4c9";
    }

    .fa-home-heart::before,
    i.home-heart::before {
        content: "\\f4c9";
    }

    .fa-house-laptop::before,
    i.house-laptop::before {
        content: "\\e066";
    }

    .fa-laptop-house::before,
    i.laptop-house::before {
        content: "\\e066";
    }

    .fa-house-lock::before,
    i.house-lock::before {
        content: "\\e510";
    }

    .fa-house-medical::before,
    i.house-medical::before {
        content: "\\e3b2";
    }

    .fa-house-medical-circle-check::before,
    i.house-medical-circle-check::before {
        content: "\\e511";
    }

    .fa-house-medical-circle-exclamation::before,
    i.house-medical-circle-exclamation::before {
        content: "\\e512";
    }

    .fa-house-medical-circle-xmark::before,
    i.house-medical-circle-xmark::before {
        content: "\\e513";
    }

    .fa-house-medical-flag::before,
    i.house-medical-flag::before {
        content: "\\e514";
    }

    .fa-house-night::before,
    i.house-night::before {
        content: "\\e010";
    }

    .fa-house-person-leave::before,
    i.house-person-leave::before {
        content: "\\e00f";
    }

    .fa-house-leave::before,
    i.house-leave::before {
        content: "\\e00f";
    }

    .fa-house-person-depart::before,
    i.house-person-depart::before {
        content: "\\e00f";
    }

    .fa-house-person-return::before,
    i.house-person-return::before {
        content: "\\e011";
    }

    .fa-house-person-arrive::before,
    i.house-person-arrive::before {
        content: "\\e011";
    }

    .fa-house-return::before,
    i.house-return::before {
        content: "\\e011";
    }

    .fa-house-signal::before,
    i.house-signal::before {
        content: "\\e012";
    }

    .fa-house-tree::before,
    i.house-tree::before {
        content: "\\e1b3";
    }

    .fa-house-tsunami::before,
    i.house-tsunami::before {
        content: "\\e515";
    }

    .fa-house-turret::before,
    i.house-turret::before {
        content: "\\e1b4";
    }

    .fa-house-user::before,
    i.house-user::before {
        content: "\\e1b0";
    }

    .fa-home-user::before,
    i.home-user::before {
        content: "\\e1b0";
    }

    .fa-house-water::before,
    i.house-water::before {
        content: "\\f74f";
    }

    .fa-house-flood::before,
    i.house-flood::before {
        content: "\\f74f";
    }

    .fa-house-window::before,
    i.house-window::before {
        content: "\\e3b3";
    }

    .fa-hryvnia-sign::before,
    i.hryvnia-sign::before {
        content: "\\f6f2";
    }

    .fa-hryvnia::before,
    i.hryvnia::before {
        content: "\\f6f2";
    }

    .fa-hundred-points::before,
    i.hundred-points::before {
        content: "\\e41c";
    }

    .fa-100::before {
        content: "\\e41c";
    }

    .fa-hurricane::before,
    i.hurricane::before {
        content: "\\f751";
    }

    .fa-hyphen::before,
    i.hyphen::before {
        content: "\\2d";
    }

    .fa-i::before,
    i.i::before {
        content: "\\49";
    }

    .fa-i-cursor::before,
    i.i-cursor::before {
        content: "\\f246";
    }

    .fa-ice-cream::before,
    i.ice-cream::before {
        content: "\\f810";
    }

    .fa-ice-skate::before,
    i.ice-skate::before {
        content: "\\f7ac";
    }

    .fa-icicles::before,
    i.icicles::before {
        content: "\\f7ad";
    }

    .fa-icons::before,
    i.icons::before {
        content: "\\f86d";
    }

    .fa-heart-music-camera-bolt::before,
    i.heart-music-camera-bolt::before {
        content: "\\f86d";
    }

    .fa-id-badge::before,
    i.id-badge::before {
        content: "\\f2c1";
    }

    .fa-id-card::before,
    i.id-card::before {
        content: "\\f2c2";
    }

    .fa-drivers-license::before,
    i.drivers-license::before {
        content: "\\f2c2";
    }

    .fa-id-card-clip::before,
    i.id-card-clip::before {
        content: "\\f47f";
    }

    .fa-id-card-alt::before,
    i.id-card-alt::before {
        content: "\\f47f";
    }

    .fa-igloo::before,
    i.igloo::before {
        content: "\\f7ae";
    }

    .fa-image::before,
    i.image::before {
        content: "\\f03e";
    }

    .fa-image-landscape::before,
    i.image-landscape::before {
        content: "\\e1b5";
    }

    .fa-landscape::before,
    i.landscape::before {
        content: "\\e1b5";
    }

    .fa-image-polaroid::before,
    i.image-polaroid::before {
        content: "\\f8c4";
    }

    .fa-image-polaroid-user::before,
    i.image-polaroid-user::before {
        content: "\\e1b6";
    }

    .fa-image-portrait::before,
    i.image-portrait::before {
        content: "\\f3e0";
    }

    .fa-portrait::before,
    i.portrait::before {
        content: "\\f3e0";
    }

    .fa-image-slash::before,
    i.image-slash::before {
        content: "\\e1b7";
    }

    .fa-image-user::before,
    i.image-user::before {
        content: "\\e1b8";
    }

    .fa-images::before,
    i.images::before {
        content: "\\f302";
    }

    .fa-images-user::before,
    i.images-user::before {
        content: "\\e1b9";
    }

    .fa-inbox::before,
    i.inbox::before {
        content: "\\f01c";
    }

    .fa-inbox-full::before,
    i.inbox-full::before {
        content: "\\e1ba";
    }

    .fa-inbox-in::before,
    i.inbox-in::before {
        content: "\\f310";
    }

    .fa-inbox-arrow-down::before,
    i.inbox-arrow-down::before {
        content: "\\f310";
    }

    .fa-inbox-out::before,
    i.inbox-out::before {
        content: "\\f311";
    }

    .fa-inbox-arrow-up::before,
    i.inbox-arrow-up::before {
        content: "\\f311";
    }

    .fa-inboxes::before,
    i.inboxes::before {
        content: "\\e1bb";
    }

    .fa-indent::before,
    i.indent::before {
        content: "\\f03c";
    }

    .fa-indian-rupee-sign::before,
    i.indian-rupee-sign::before {
        content: "\\e1bc";
    }

    .fa-indian-rupee::before,
    i.indian-rupee::before {
        content: "\\e1bc";
    }

    .fa-inr::before,
    i.inr::before {
        content: "\\e1bc";
    }

    .fa-industry::before,
    i.industry::before {
        content: "\\f275";
    }

    .fa-industry-windows::before,
    i.industry-windows::before {
        content: "\\f3b3";
    }

    .fa-industry-alt::before,
    i.industry-alt::before {
        content: "\\f3b3";
    }

    .fa-infinity::before,
    i.infinity::before {
        content: "\\f534";
    }

    .fa-info::before,
    i.info::before {
        content: "\\f129";
    }

    .fa-inhaler::before,
    i.inhaler::before {
        content: "\\f5f9";
    }

    .fa-input-numeric::before,
    i.input-numeric::before {
        content: "\\e1bd";
    }

    .fa-input-pipe::before,
    i.input-pipe::before {
        content: "\\e1be";
    }

    .fa-input-text::before,
    i.input-text::before {
        content: "\\e1bf";
    }

    .fa-integral::before,
    i.integral::before {
        content: "\\f667";
    }

    .fa-intersection::before,
    i.intersection::before {
        content: "\\f668";
    }

    .fa-island-tropical::before,
    i.island-tropical::before {
        content: "\\f811";
    }

    .fa-island-tree-palm::before,
    i.island-tree-palm::before {
        content: "\\f811";
    }

    .fa-italic::before,
    i.italic::before {
        content: "\\f033";
    }

    .fa-j::before,
    i.j::before {
        content: "\\4a";
    }

    .fa-jack-o-lantern::before,
    i.jack-o-lantern::before {
        content: "\\f30e";
    }

    .fa-jar::before,
    i.jar::before {
        content: "\\e516";
    }

    .fa-jar-wheat::before,
    i.jar-wheat::before {
        content: "\\e517";
    }

    .fa-jedi::before,
    i.jedi::before {
        content: "\\f669";
    }

    .fa-jet-fighter::before,
    i.jet-fighter::before {
        content: "\\f0fb";
    }

    .fa-fighter-jet::before,
    i.fighter-jet::before {
        content: "\\f0fb";
    }

    .fa-jet-fighter-up::before,
    i.jet-fighter-up::before {
        content: "\\e518";
    }

    .fa-joint::before,
    i.joint::before {
        content: "\\f595";
    }

    .fa-joystick::before,
    i.joystick::before {
        content: "\\f8c5";
    }

    .fa-jug::before,
    i.jug::before {
        content: "\\f8c6";
    }

    .fa-jug-detergent::before,
    i.jug-detergent::before {
        content: "\\e519";
    }

    .fa-k::before,
    i.k::before {
        content: "\\4b";
    }

    .fa-kaaba::before,
    i.kaaba::before {
        content: "\\f66b";
    }

    .fa-kazoo::before,
    i.kazoo::before {
        content: "\\f8c7";
    }

    .fa-kerning::before,
    i.kerning::before {
        content: "\\f86f";
    }

    .fa-key::before,
    i.key::before {
        content: "\\f084";
    }

    .fa-key-skeleton::before,
    i.key-skeleton::before {
        content: "\\f6f3";
    }

    .fa-key-skeleton-left-right::before,
    i.key-skeleton-left-right::before {
        content: "\\e3b4";
    }

    .fa-keyboard::before,
    i.keyboard::before {
        content: "\\f11c";
    }

    .fa-keyboard-brightness::before,
    i.keyboard-brightness::before {
        content: "\\e1c0";
    }

    .fa-keyboard-brightness-low::before,
    i.keyboard-brightness-low::before {
        content: "\\e1c1";
    }

    .fa-keyboard-down::before,
    i.keyboard-down::before {
        content: "\\e1c2";
    }

    .fa-keyboard-left::before,
    i.keyboard-left::before {
        content: "\\e1c3";
    }

    .fa-keynote::before,
    i.keynote::before {
        content: "\\f66c";
    }

    .fa-khanda::before,
    i.khanda::before {
        content: "\\f66d";
    }

    .fa-kidneys::before,
    i.kidneys::before {
        content: "\\f5fb";
    }

    .fa-kip-sign::before,
    i.kip-sign::before {
        content: "\\e1c4";
    }

    .fa-kit-medical::before,
    i.kit-medical::before {
        content: "\\f479";
    }

    .fa-first-aid::before,
    i.first-aid::before {
        content: "\\f479";
    }

    .fa-kitchen-set::before,
    i.kitchen-set::before {
        content: "\\e51a";
    }

    .fa-kite::before,
    i.kite::before {
        content: "\\f6f4";
    }

    .fa-kiwi-bird::before,
    i.kiwi-bird::before {
        content: "\\f535";
    }

    .fa-kiwi-fruit::before,
    i.kiwi-fruit::before {
        content: "\\e30c";
    }

    .fa-knife::before,
    i.knife::before {
        content: "\\f2e4";
    }

    .fa-utensil-knife::before,
    i.utensil-knife::before {
        content: "\\f2e4";
    }

    .fa-knife-kitchen::before,
    i.knife-kitchen::before {
        content: "\\f6f5";
    }

    .fa-l::before,
    i.l::before {
        content: "\\4c";
    }

    .fa-lacrosse-stick::before,
    i.lacrosse-stick::before {
        content: "\\e3b5";
    }

    .fa-lacrosse-stick-ball::before,
    i.lacrosse-stick-ball::before {
        content: "\\e3b6";
    }

    .fa-lambda::before,
    i.lambda::before {
        content: "\\f66e";
    }

    .fa-lamp::before,
    i.lamp::before {
        content: "\\f4ca";
    }

    .fa-lamp-desk::before,
    i.lamp-desk::before {
        content: "\\e014";
    }

    .fa-lamp-floor::before,
    i.lamp-floor::before {
        content: "\\e015";
    }

    .fa-lamp-street::before,
    i.lamp-street::before {
        content: "\\e1c5";
    }

    .fa-land-mine-on::before,
    i.land-mine-on::before {
        content: "\\e51b";
    }

    .fa-landmark::before,
    i.landmark::before {
        content: "\\f66f";
    }

    .fa-landmark-dome::before,
    i.landmark-dome::before {
        content: "\\f752";
    }

    .fa-landmark-alt::before,
    i.landmark-alt::before {
        content: "\\f752";
    }

    .fa-landmark-flag::before,
    i.landmark-flag::before {
        content: "\\e51c";
    }

    .fa-language::before,
    i.language::before {
        content: "\\f1ab";
    }

    .fa-laptop::before,
    i.laptop::before {
        content: "\\f109";
    }

    .fa-laptop-arrow-down::before,
    i.laptop-arrow-down::before {
        content: "\\e1c6";
    }

    .fa-laptop-code::before,
    i.laptop-code::before {
        content: "\\f5fc";
    }

    .fa-laptop-file::before,
    i.laptop-file::before {
        content: "\\e51d";
    }

    .fa-laptop-medical::before,
    i.laptop-medical::before {
        content: "\\f812";
    }

    .fa-laptop-mobile::before,
    i.laptop-mobile::before {
        content: "\\f87a";
    }

    .fa-phone-laptop::before,
    i.phone-laptop::before {
        content: "\\f87a";
    }

    .fa-laptop-slash::before,
    i.laptop-slash::before {
        content: "\\e1c7";
    }

    .fa-lari-sign::before,
    i.lari-sign::before {
        content: "\\e1c8";
    }

    .fa-lasso::before,
    i.lasso::before {
        content: "\\f8c8";
    }

    .fa-lasso-sparkles::before,
    i.lasso-sparkles::before {
        content: "\\e1c9";
    }

    .fa-layer-group::before,
    i.layer-group::before {
        content: "\\f5fd";
    }

    .fa-layer-minus::before,
    i.layer-minus::before {
        content: "\\f5fe";
    }

    .fa-layer-group-minus::before,
    i.layer-group-minus::before {
        content: "\\f5fe";
    }

    .fa-layer-plus::before,
    i.layer-plus::before {
        content: "\\f5ff";
    }

    .fa-layer-group-plus::before,
    i.layer-group-plus::before {
        content: "\\f5ff";
    }

    .fa-leaf::before,
    i.leaf::before {
        content: "\\f06c";
    }

    .fa-leaf-heart::before,
    i.leaf-heart::before {
        content: "\\f4cb";
    }

    .fa-leaf-maple::before,
    i.leaf-maple::before {
        content: "\\f6f6";
    }

    .fa-leaf-oak::before,
    i.leaf-oak::before {
        content: "\\f6f7";
    }

    .fa-leafy-green::before,
    i.leafy-green::before {
        content: "\\e41d";
    }

    .fa-left::before,
    i.left::before {
        content: "\\f355";
    }

    .fa-arrow-alt-left::before,
    i.arrow-alt-left::before {
        content: "\\f355";
    }

    .fa-left-from-line::before,
    i.left-from-line::before {
        content: "\\f348";
    }

    .fa-arrow-alt-from-right::before,
    i.arrow-alt-from-right::before {
        content: "\\f348";
    }

    .fa-left-long::before,
    i.left-long::before {
        content: "\\f30a";
    }

    .fa-long-arrow-alt-left::before,
    i.long-arrow-alt-left::before {
        content: "\\f30a";
    }

    .fa-left-long-to-line::before,
    i.left-long-to-line::before {
        content: "\\e41e";
    }

    .fa-left-right::before,
    i.left-right::before {
        content: "\\f337";
    }

    .fa-arrows-alt-h::before,
    i.arrows-alt-h::before {
        content: "\\f337";
    }

    .fa-left-to-line::before,
    i.left-to-line::before {
        content: "\\f34b";
    }

    .fa-arrow-alt-to-left::before,
    i.arrow-alt-to-left::before {
        content: "\\f34b";
    }

    .fa-lemon::before,
    i.lemon::before {
        content: "\\f094";
    }

    .fa-less-than::before,
    i.less-than::before {
        content: "\\3c";
    }

    .fa-less-than-equal::before,
    i.less-than-equal::before {
        content: "\\f537";
    }

    .fa-life-ring::before,
    i.life-ring::before {
        content: "\\f1cd";
    }

    .fa-light-ceiling::before,
    i.light-ceiling::before {
        content: "\\e016";
    }

    .fa-light-emergency::before,
    i.light-emergency::before {
        content: "\\e41f";
    }

    .fa-light-emergency-on::before,
    i.light-emergency-on::before {
        content: "\\e420";
    }

    .fa-light-switch::before,
    i.light-switch::before {
        content: "\\e017";
    }

    .fa-light-switch-off::before,
    i.light-switch-off::before {
        content: "\\e018";
    }

    .fa-light-switch-on::before,
    i.light-switch-on::before {
        content: "\\e019";
    }

    .fa-lightbulb::before,
    i.lightbulb::before {
        content: "\\f0eb";
    }

    .fa-lightbulb-dollar::before,
    i.lightbulb-dollar::before {
        content: "\\f670";
    }

    .fa-lightbulb-exclamation::before,
    i.lightbulb-exclamation::before {
        content: "\\f671";
    }

    .fa-lightbulb-exclamation-on::before,
    i.lightbulb-exclamation-on::before {
        content: "\\e1ca";
    }

    .fa-lightbulb-on::before,
    i.lightbulb-on::before {
        content: "\\f672";
    }

    .fa-lightbulb-slash::before,
    i.lightbulb-slash::before {
        content: "\\f673";
    }

    .fa-lights-holiday::before,
    i.lights-holiday::before {
        content: "\\f7b2";
    }

    .fa-line-columns::before,
    i.line-columns::before {
        content: "\\f870";
    }

    .fa-line-height::before,
    i.line-height::before {
        content: "\\f871";
    }

    .fa-lines-leaning::before,
    i.lines-leaning::before {
        content: "\\e51e";
    }

    .fa-link::before,
    i.link::before {
        content: "\\f0c1";
    }

    .fa-chain::before,
    i.chain::before {
        content: "\\f0c1";
    }

    .fa-link-horizontal::before,
    i.link-horizontal::before {
        content: "\\e1cb";
    }

    .fa-chain-horizontal::before,
    i.chain-horizontal::before {
        content: "\\e1cb";
    }

    .fa-link-horizontal-slash::before,
    i.link-horizontal-slash::before {
        content: "\\e1cc";
    }

    .fa-chain-horizontal-slash::before,
    i.chain-horizontal-slash::before {
        content: "\\e1cc";
    }

    .fa-link-simple::before,
    i.link-simple::before {
        content: "\\e1cd";
    }

    .fa-link-simple-slash::before,
    i.link-simple-slash::before {
        content: "\\e1ce";
    }

    .fa-link-slash::before,
    i.link-slash::before {
        content: "\\f127";
    }

    .fa-chain-broken::before,
    i.chain-broken::before {
        content: "\\f127";
    }

    .fa-chain-slash::before,
    i.chain-slash::before {
        content: "\\f127";
    }

    .fa-unlink::before,
    i.unlink::before {
        content: "\\f127";
    }

    .fa-lips::before,
    i.lips::before {
        content: "\\f600";
    }

    .fa-lira-sign::before,
    i.lira-sign::before {
        content: "\\f195";
    }

    .fa-list::before,
    i.list::before {
        content: "\\f03a";
    }

    .fa-list-squares::before,
    i.list-squares::before {
        content: "\\f03a";
    }

    .fa-list-check::before,
    i.list-check::before {
        content: "\\f0ae";
    }

    .fa-tasks::before,
    i.tasks::before {
        content: "\\f0ae";
    }

    .fa-list-dropdown::before,
    i.list-dropdown::before {
        content: "\\e1cf";
    }

    .fa-list-music::before,
    i.list-music::before {
        content: "\\f8c9";
    }

    .fa-list-ol::before,
    i.list-ol::before {
        content: "\\f0cb";
    }

    .fa-list-1-2::before,
    i.list-1-2::before {
        content: "\\f0cb";
    }

    .fa-list-numeric::before,
    i.list-numeric::before {
        content: "\\f0cb";
    }

    .fa-list-radio::before,
    i.list-radio::before {
        content: "\\e1d0";
    }

    .fa-list-timeline::before,
    i.list-timeline::before {
        content: "\\e1d1";
    }

    .fa-list-tree::before,
    i.list-tree::before {
        content: "\\e1d2";
    }

    .fa-list-ul::before,
    i.list-ul::before {
        content: "\\f0ca";
    }

    .fa-list-dots::before,
    i.list-dots::before {
        content: "\\f0ca";
    }

    .fa-litecoin-sign::before,
    i.litecoin-sign::before {
        content: "\\e1d3";
    }

    .fa-loader::before,
    i.loader::before {
        content: "\\e1d4";
    }

    .fa-lobster::before,
    i.lobster::before {
        content: "\\e421";
    }

    .fa-location-arrow::before,
    i.location-arrow::before {
        content: "\\f124";
    }

    .fa-location-check::before,
    i.location-check::before {
        content: "\\f606";
    }

    .fa-map-marker-check::before,
    i.map-marker-check::before {
        content: "\\f606";
    }

    .fa-location-crosshairs::before,
    i.location-crosshairs::before {
        content: "\\f601";
    }

    .fa-location::before,
    i.location::before {
        content: "\\f601";
    }

    .fa-location-crosshairs-slash::before,
    i.location-crosshairs-slash::before {
        content: "\\f603";
    }

    .fa-location-slash::before,
    i.location-slash::before {
        content: "\\f603";
    }

    .fa-location-dot::before,
    i.location-dot::before {
        content: "\\f3c5";
    }

    .fa-map-marker-alt::before,
    i.map-marker-alt::before {
        content: "\\f3c5";
    }

    .fa-location-dot-slash::before,
    i.location-dot-slash::before {
        content: "\\f605";
    }

    .fa-map-marker-alt-slash::before,
    i.map-marker-alt-slash::before {
        content: "\\f605";
    }

    .fa-location-exclamation::before,
    i.location-exclamation::before {
        content: "\\f608";
    }

    .fa-map-marker-exclamation::before,
    i.map-marker-exclamation::before {
        content: "\\f608";
    }

    .fa-location-minus::before,
    i.location-minus::before {
        content: "\\f609";
    }

    .fa-map-marker-minus::before,
    i.map-marker-minus::before {
        content: "\\f609";
    }

    .fa-location-pen::before,
    i.location-pen::before {
        content: "\\f607";
    }

    .fa-map-marker-edit::before,
    i.map-marker-edit::before {
        content: "\\f607";
    }

    .fa-location-pin::before,
    i.location-pin::before {
        content: "\\f041";
    }

    .fa-map-marker::before,
    i.map-marker::before {
        content: "\\f041";
    }

    .fa-location-pin-lock::before,
    i.location-pin-lock::before {
        content: "\\e51f";
    }

    .fa-location-pin-slash::before,
    i.location-pin-slash::before {
        content: "\\f60c";
    }

    .fa-map-marker-slash::before,
    i.map-marker-slash::before {
        content: "\\f60c";
    }

    .fa-location-plus::before,
    i.location-plus::before {
        content: "\\f60a";
    }

    .fa-map-marker-plus::before,
    i.map-marker-plus::before {
        content: "\\f60a";
    }

    .fa-location-question::before,
    i.location-question::before {
        content: "\\f60b";
    }

    .fa-map-marker-question::before,
    i.map-marker-question::before {
        content: "\\f60b";
    }

    .fa-location-smile::before,
    i.location-smile::before {
        content: "\\f60d";
    }

    .fa-map-marker-smile::before,
    i.map-marker-smile::before {
        content: "\\f60d";
    }

    .fa-location-xmark::before,
    i.location-xmark::before {
        content: "\\f60e";
    }

    .fa-map-marker-times::before,
    i.map-marker-times::before {
        content: "\\f60e";
    }

    .fa-map-marker-xmark::before,
    i.map-marker-xmark::before {
        content: "\\f60e";
    }

    .fa-lock::before,
    i.lock::before {
        content: "\\f023";
    }

    .fa-lock-a::before,
    i.lock-a::before {
        content: "\\e422";
    }

    .fa-lock-hashtag::before,
    i.lock-hashtag::before {
        content: "\\e423";
    }

    .fa-lock-keyhole::before,
    i.lock-keyhole::before {
        content: "\\f30d";
    }

    .fa-lock-alt::before,
    i.lock-alt::before {
        content: "\\f30d";
    }

    .fa-lock-keyhole-open::before,
    i.lock-keyhole-open::before {
        content: "\\f3c2";
    }

    .fa-lock-open-alt::before,
    i.lock-open-alt::before {
        content: "\\f3c2";
    }

    .fa-lock-open::before,
    i.lock-open::before {
        content: "\\f3c1";
    }

    .fa-locust::before,
    i.locust::before {
        content: "\\e520";
    }

    .fa-lollipop::before,
    i.lollipop::before {
        content: "\\e424";
    }

    .fa-lollypop::before,
    i.lollypop::before {
        content: "\\e424";
    }

    .fa-loveseat::before,
    i.loveseat::before {
        content: "\\f4cc";
    }

    .fa-couch-small::before,
    i.couch-small::before {
        content: "\\f4cc";
    }

    .fa-luchador-mask::before,
    i.luchador-mask::before {
        content: "\\f455";
    }

    .fa-luchador::before,
    i.luchador::before {
        content: "\\f455";
    }

    .fa-mask-luchador::before,
    i.mask-luchador::before {
        content: "\\f455";
    }

    .fa-lungs::before,
    i.lungs::before {
        content: "\\f604";
    }

    .fa-lungs-virus::before,
    i.lungs-virus::before {
        content: "\\e067";
    }

    .fa-m::before,
    i.m::before {
        content: "\\4d";
    }

    .fa-mace::before,
    i.mace::before {
        content: "\\f6f8";
    }

    .fa-magnet::before,
    i.magnet::before {
        content: "\\f076";
    }

    .fa-magnifying-glass::before,
    i.magnifying-glass::before {
        content: "\\f002";
    }

    .fa-search::before,
    i.search::before {
        content: "\\f002";
    }

    .fa-magnifying-glass-arrow-right::before,
    i.magnifying-glass-arrow-right::before {
        content: "\\e521";
    }

    .fa-magnifying-glass-chart::before,
    i.magnifying-glass-chart::before {
        content: "\\e522";
    }

    .fa-magnifying-glass-dollar::before,
    i.magnifying-glass-dollar::before {
        content: "\\f688";
    }

    .fa-search-dollar::before,
    i.search-dollar::before {
        content: "\\f688";
    }

    .fa-magnifying-glass-location::before,
    i.magnifying-glass-location::before {
        content: "\\f689";
    }

    .fa-search-location::before,
    i.search-location::before {
        content: "\\f689";
    }

    .fa-magnifying-glass-minus::before,
    i.magnifying-glass-minus::before {
        content: "\\f010";
    }

    .fa-search-minus::before,
    i.search-minus::before {
        content: "\\f010";
    }

    .fa-magnifying-glass-plus::before,
    i.magnifying-glass-plus::before {
        content: "\\f00e";
    }

    .fa-search-plus::before,
    i.search-plus::before {
        content: "\\f00e";
    }

    .fa-mailbox::before,
    i.mailbox::before {
        content: "\\f813";
    }

    .fa-manat-sign::before,
    i.manat-sign::before {
        content: "\\e1d5";
    }

    .fa-mandolin::before,
    i.mandolin::before {
        content: "\\f6f9";
    }

    .fa-mango::before,
    i.mango::before {
        content: "\\e30f";
    }

    .fa-manhole::before,
    i.manhole::before {
        content: "\\e1d6";
    }

    .fa-map::before,
    i.map::before {
        content: "\\f279";
    }

    .fa-map-location::before,
    i.map-location::before {
        content: "\\f59f";
    }

    .fa-map-marked::before,
    i.map-marked::before {
        content: "\\f59f";
    }

    .fa-map-location-dot::before,
    i.map-location-dot::before {
        content: "\\f5a0";
    }

    .fa-map-marked-alt::before,
    i.map-marked-alt::before {
        content: "\\f5a0";
    }

    .fa-map-pin::before,
    i.map-pin::before {
        content: "\\f276";
    }

    .fa-marker::before,
    i.marker::before {
        content: "\\f5a1";
    }

    .fa-mars::before,
    i.mars::before {
        content: "\\f222";
    }

    .fa-mars-and-venus::before,
    i.mars-and-venus::before {
        content: "\\f224";
    }

    .fa-mars-and-venus-burst::before,
    i.mars-and-venus-burst::before {
        content: "\\e523";
    }

    .fa-mars-double::before,
    i.mars-double::before {
        content: "\\f227";
    }

    .fa-mars-stroke::before,
    i.mars-stroke::before {
        content: "\\f229";
    }

    .fa-mars-stroke-right::before,
    i.mars-stroke-right::before {
        content: "\\f22b";
    }

    .fa-mars-stroke-h::before,
    i.mars-stroke-h::before {
        content: "\\f22b";
    }

    .fa-mars-stroke-up::before,
    i.mars-stroke-up::before {
        content: "\\f22a";
    }

    .fa-mars-stroke-v::before,
    i.mars-stroke-v::before {
        content: "\\f22a";
    }

    .fa-martini-glass::before,
    i.martini-glass::before {
        content: "\\f57b";
    }

    .fa-glass-martini-alt::before,
    i.glass-martini-alt::before {
        content: "\\f57b";
    }

    .fa-martini-glass-citrus::before,
    i.martini-glass-citrus::before {
        content: "\\f561";
    }

    .fa-cocktail::before,
    i.cocktail::before {
        content: "\\f561";
    }

    .fa-martini-glass-empty::before,
    i.martini-glass-empty::before {
        content: "\\f000";
    }

    .fa-glass-martini::before,
    i.glass-martini::before {
        content: "\\f000";
    }

    .fa-mask::before,
    i.mask::before {
        content: "\\f6fa";
    }

    .fa-mask-face::before,
    i.mask-face::before {
        content: "\\e1d7";
    }

    .fa-mask-snorkel::before,
    i.mask-snorkel::before {
        content: "\\e3b7";
    }

    .fa-mask-ventilator::before,
    i.mask-ventilator::before {
        content: "\\e524";
    }

    .fa-masks-theater::before,
    i.masks-theater::before {
        content: "\\f630";
    }

    .fa-theater-masks::before,
    i.theater-masks::before {
        content: "\\f630";
    }

    .fa-mattress-pillow::before,
    i.mattress-pillow::before {
        content: "\\e525";
    }

    .fa-maximize::before,
    i.maximize::before {
        content: "\\f31e";
    }

    .fa-expand-arrows-alt::before,
    i.expand-arrows-alt::before {
        content: "\\f31e";
    }

    .fa-meat::before,
    i.meat::before {
        content: "\\f814";
    }

    .fa-medal::before,
    i.medal::before {
        content: "\\f5a2";
    }

    .fa-megaphone::before,
    i.megaphone::before {
        content: "\\f675";
    }

    .fa-melon::before,
    i.melon::before {
        content: "\\e310";
    }

    .fa-melon-slice::before,
    i.melon-slice::before {
        content: "\\e311";
    }

    .fa-memo::before,
    i.memo::before {
        content: "\\e1d8";
    }

    .fa-memo-circle-check::before,
    i.memo-circle-check::before {
        content: "\\e1d9";
    }

    .fa-memo-circle-info::before,
    i.memo-circle-info::before {
        content: "\\e49a";
    }

    .fa-memo-pad::before,
    i.memo-pad::before {
        content: "\\e1da";
    }

    .fa-memory::before,
    i.memory::before {
        content: "\\f538";
    }

    .fa-menorah::before,
    i.menorah::before {
        content: "\\f676";
    }

    .fa-mercury::before,
    i.mercury::before {
        content: "\\f223";
    }

    .fa-merge::before,
    i.merge::before {
        content: "\\e526";
    }

    .fa-message::before,
    i.message::before {
        content: "\\f27a";
    }

    .fa-comment-alt::before,
    i.comment-alt::before {
        content: "\\f27a";
    }

    .fa-message-arrow-down::before,
    i.message-arrow-down::before {
        content: "\\e1db";
    }

    .fa-comment-alt-arrow-down::before,
    i.comment-alt-arrow-down::before {
        content: "\\e1db";
    }

    .fa-message-arrow-up::before,
    i.message-arrow-up::before {
        content: "\\e1dc";
    }

    .fa-comment-alt-arrow-up::before,
    i.comment-alt-arrow-up::before {
        content: "\\e1dc";
    }

    .fa-message-arrow-up-right::before,
    i.message-arrow-up-right::before {
        content: "\\e1dd";
    }

    .fa-message-bot::before,
    i.message-bot::before {
        content: "\\e3b8";
    }

    .fa-message-captions::before,
    i.message-captions::before {
        content: "\\e1de";
    }

    .fa-comment-alt-captions::before,
    i.comment-alt-captions::before {
        content: "\\e1de";
    }

    .fa-message-check::before,
    i.message-check::before {
        content: "\\f4a2";
    }

    .fa-comment-alt-check::before,
    i.comment-alt-check::before {
        content: "\\f4a2";
    }

    .fa-message-code::before,
    i.message-code::before {
        content: "\\e1df";
    }

    .fa-message-dollar::before,
    i.message-dollar::before {
        content: "\\f650";
    }

    .fa-comment-alt-dollar::before,
    i.comment-alt-dollar::before {
        content: "\\f650";
    }

    .fa-message-dots::before,
    i.message-dots::before {
        content: "\\f4a3";
    }

    .fa-comment-alt-dots::before,
    i.comment-alt-dots::before {
        content: "\\f4a3";
    }

    .fa-messaging::before,
    i.messaging::before {
        content: "\\f4a3";
    }

    .fa-message-exclamation::before,
    i.message-exclamation::before {
        content: "\\f4a5";
    }

    .fa-comment-alt-exclamation::before,
    i.comment-alt-exclamation::before {
        content: "\\f4a5";
    }

    .fa-message-image::before,
    i.message-image::before {
        content: "\\e1e0";
    }

    .fa-comment-alt-image::before,
    i.comment-alt-image::before {
        content: "\\e1e0";
    }

    .fa-message-lines::before,
    i.message-lines::before {
        content: "\\f4a6";
    }

    .fa-comment-alt-lines::before,
    i.comment-alt-lines::before {
        content: "\\f4a6";
    }

    .fa-message-medical::before,
    i.message-medical::before {
        content: "\\f7f4";
    }

    .fa-comment-alt-medical::before,
    i.comment-alt-medical::before {
        content: "\\f7f4";
    }

    .fa-message-middle::before,
    i.message-middle::before {
        content: "\\e1e1";
    }

    .fa-comment-middle-alt::before,
    i.comment-middle-alt::before {
        content: "\\e1e1";
    }

    .fa-message-middle-top::before,
    i.message-middle-top::before {
        content: "\\e1e2";
    }

    .fa-comment-middle-top-alt::before,
    i.comment-middle-top-alt::before {
        content: "\\e1e2";
    }

    .fa-message-minus::before,
    i.message-minus::before {
        content: "\\f4a7";
    }

    .fa-comment-alt-minus::before,
    i.comment-alt-minus::before {
        content: "\\f4a7";
    }

    .fa-message-music::before,
    i.message-music::before {
        content: "\\f8af";
    }

    .fa-comment-alt-music::before,
    i.comment-alt-music::before {
        content: "\\f8af";
    }

    .fa-message-pen::before,
    i.message-pen::before {
        content: "\\f4a4";
    }

    .fa-comment-alt-edit::before,
    i.comment-alt-edit::before {
        content: "\\f4a4";
    }

    .fa-message-edit::before,
    i.message-edit::before {
        content: "\\f4a4";
    }

    .fa-message-plus::before,
    i.message-plus::before {
        content: "\\f4a8";
    }

    .fa-comment-alt-plus::before,
    i.comment-alt-plus::before {
        content: "\\f4a8";
    }

    .fa-message-question::before,
    i.message-question::before {
        content: "\\e1e3";
    }

    .fa-message-quote::before,
    i.message-quote::before {
        content: "\\e1e4";
    }

    .fa-comment-alt-quote::before,
    i.comment-alt-quote::before {
        content: "\\e1e4";
    }

    .fa-message-slash::before,
    i.message-slash::before {
        content: "\\f4a9";
    }

    .fa-comment-alt-slash::before,
    i.comment-alt-slash::before {
        content: "\\f4a9";
    }

    .fa-message-smile::before,
    i.message-smile::before {
        content: "\\f4aa";
    }

    .fa-comment-alt-smile::before,
    i.comment-alt-smile::before {
        content: "\\f4aa";
    }

    .fa-message-sms::before,
    i.message-sms::before {
        content: "\\e1e5";
    }

    .fa-message-text::before,
    i.message-text::before {
        content: "\\e1e6";
    }

    .fa-comment-alt-text::before,
    i.comment-alt-text::before {
        content: "\\e1e6";
    }

    .fa-message-xmark::before,
    i.message-xmark::before {
        content: "\\f4ab";
    }

    .fa-comment-alt-times::before,
    i.comment-alt-times::before {
        content: "\\f4ab";
    }

    .fa-message-times::before,
    i.message-times::before {
        content: "\\f4ab";
    }

    .fa-messages::before,
    i.messages::before {
        content: "\\f4b6";
    }

    .fa-comments-alt::before,
    i.comments-alt::before {
        content: "\\f4b6";
    }

    .fa-messages-dollar::before,
    i.messages-dollar::before {
        content: "\\f652";
    }

    .fa-comments-alt-dollar::before,
    i.comments-alt-dollar::before {
        content: "\\f652";
    }

    .fa-messages-question::before,
    i.messages-question::before {
        content: "\\e1e7";
    }

    .fa-meteor::before,
    i.meteor::before {
        content: "\\f753";
    }

    .fa-meter::before,
    i.meter::before {
        content: "\\e1e8";
    }

    .fa-meter-bolt::before,
    i.meter-bolt::before {
        content: "\\e1e9";
    }

    .fa-meter-droplet::before,
    i.meter-droplet::before {
        content: "\\e1ea";
    }

    .fa-meter-fire::before,
    i.meter-fire::before {
        content: "\\e1eb";
    }

    .fa-microchip::before,
    i.microchip::before {
        content: "\\f2db";
    }

    .fa-microchip-ai::before,
    i.microchip-ai::before {
        content: "\\e1ec";
    }

    .fa-microphone::before,
    i.microphone::before {
        content: "\\f130";
    }

    .fa-microphone-lines::before,
    i.microphone-lines::before {
        content: "\\f3c9";
    }

    .fa-microphone-alt::before,
    i.microphone-alt::before {
        content: "\\f3c9";
    }

    .fa-microphone-lines-slash::before,
    i.microphone-lines-slash::before {
        content: "\\f539";
    }

    .fa-microphone-alt-slash::before,
    i.microphone-alt-slash::before {
        content: "\\f539";
    }

    .fa-microphone-slash::before,
    i.microphone-slash::before {
        content: "\\f131";
    }

    .fa-microphone-stand::before,
    i.microphone-stand::before {
        content: "\\f8cb";
    }

    .fa-microscope::before,
    i.microscope::before {
        content: "\\f610";
    }

    .fa-microwave::before,
    i.microwave::before {
        content: "\\e01b";
    }

    .fa-mill-sign::before,
    i.mill-sign::before {
        content: "\\e1ed";
    }

    .fa-minimize::before,
    i.minimize::before {
        content: "\\f78c";
    }

    .fa-compress-arrows-alt::before,
    i.compress-arrows-alt::before {
        content: "\\f78c";
    }

    .fa-minus::before,
    i.minus::before {
        content: "\\f068";
    }

    .fa-subtract::before,
    i.subtract::before {
        content: "\\f068";
    }

    .fa-mistletoe::before,
    i.mistletoe::before {
        content: "\\f7b4";
    }

    .fa-mitten::before,
    i.mitten::before {
        content: "\\f7b5";
    }

    .fa-mobile::before,
    i.mobile::before {
        content: "\\f3ce";
    }

    .fa-mobile-android::before,
    i.mobile-android::before {
        content: "\\f3ce";
    }

    .fa-mobile-phone::before,
    i.mobile-phone::before {
        content: "\\f3ce";
    }

    .fa-mobile-button::before,
    i.mobile-button::before {
        content: "\\f10b";
    }

    .fa-mobile-notch::before,
    i.mobile-notch::before {
        content: "\\e1ee";
    }

    .fa-mobile-iphone::before,
    i.mobile-iphone::before {
        content: "\\e1ee";
    }

    .fa-mobile-retro::before,
    i.mobile-retro::before {
        content: "\\e527";
    }

    .fa-mobile-screen::before,
    i.mobile-screen::before {
        content: "\\f3cf";
    }

    .fa-mobile-android-alt::before,
    i.mobile-android-alt::before {
        content: "\\f3cf";
    }

    .fa-mobile-screen-button::before,
    i.mobile-screen-button::before {
        content: "\\f3cd";
    }

    .fa-mobile-alt::before,
    i.mobile-alt::before {
        content: "\\f3cd";
    }

    .fa-mobile-signal::before,
    i.mobile-signal::before {
        content: "\\e1ef";
    }

    .fa-mobile-signal-out::before,
    i.mobile-signal-out::before {
        content: "\\e1f0";
    }

    .fa-money-bill::before,
    i.money-bill::before {
        content: "\\f0d6";
    }

    .fa-money-bill-1::before,
    i.money-bill-1::before {
        content: "\\f3d1";
    }

    .fa-money-bill-alt::before,
    i.money-bill-alt::before {
        content: "\\f3d1";
    }

    .fa-money-bill-1-wave::before,
    i.money-bill-1-wave::before {
        content: "\\f53b";
    }

    .fa-money-bill-wave-alt::before,
    i.money-bill-wave-alt::before {
        content: "\\f53b";
    }

    .fa-money-bill-simple::before,
    i.money-bill-simple::before {
        content: "\\e1f1";
    }

    .fa-money-bill-simple-wave::before,
    i.money-bill-simple-wave::before {
        content: "\\e1f2";
    }

    .fa-money-bill-transfer::before,
    i.money-bill-transfer::before {
        content: "\\e528";
    }

    .fa-money-bill-trend-up::before,
    i.money-bill-trend-up::before {
        content: "\\e529";
    }

    .fa-money-bill-wave::before,
    i.money-bill-wave::before {
        content: "\\f53a";
    }

    .fa-money-bill-wheat::before,
    i.money-bill-wheat::before {
        content: "\\e52a";
    }

    .fa-money-bills::before,
    i.money-bills::before {
        content: "\\e1f3";
    }

    .fa-money-bills-simple::before,
    i.money-bills-simple::before {
        content: "\\e1f4";
    }

    .fa-money-bills-alt::before,
    i.money-bills-alt::before {
        content: "\\e1f4";
    }

    .fa-money-check::before,
    i.money-check::before {
        content: "\\f53c";
    }

    .fa-money-check-dollar::before,
    i.money-check-dollar::before {
        content: "\\f53d";
    }

    .fa-money-check-alt::before,
    i.money-check-alt::before {
        content: "\\f53d";
    }

    .fa-money-check-dollar-pen::before,
    i.money-check-dollar-pen::before {
        content: "\\f873";
    }

    .fa-money-check-edit-alt::before,
    i.money-check-edit-alt::before {
        content: "\\f873";
    }

    .fa-money-check-pen::before,
    i.money-check-pen::before {
        content: "\\f872";
    }

    .fa-money-check-edit::before,
    i.money-check-edit::before {
        content: "\\f872";
    }

    .fa-money-from-bracket::before,
    i.money-from-bracket::before {
        content: "\\e312";
    }

    .fa-money-simple-from-bracket::before,
    i.money-simple-from-bracket::before {
        content: "\\e313";
    }

    .fa-monitor-waveform::before,
    i.monitor-waveform::before {
        content: "\\f611";
    }

    .fa-monitor-heart-rate::before,
    i.monitor-heart-rate::before {
        content: "\\f611";
    }

    .fa-monkey::before,
    i.monkey::before {
        content: "\\f6fb";
    }

    .fa-monument::before,
    i.monument::before {
        content: "\\f5a6";
    }

    .fa-moon::before,
    i.moon::before {
        content: "\\f186";
    }

    .fa-moon-cloud::before,
    i.moon-cloud::before {
        content: "\\f754";
    }

    .fa-moon-over-sun::before,
    i.moon-over-sun::before {
        content: "\\f74a";
    }

    .fa-eclipse-alt::before,
    i.eclipse-alt::before {
        content: "\\f74a";
    }

    .fa-moon-stars::before,
    i.moon-stars::before {
        content: "\\f755";
    }

    .fa-moped::before,
    i.moped::before {
        content: "\\e3b9";
    }

    .fa-mortar-pestle::before,
    i.mortar-pestle::before {
        content: "\\f5a7";
    }

    .fa-mosque::before,
    i.mosque::before {
        content: "\\f678";
    }

    .fa-mosquito::before,
    i.mosquito::before {
        content: "\\e52b";
    }

    .fa-mosquito-net::before,
    i.mosquito-net::before {
        content: "\\e52c";
    }

    .fa-motorcycle::before,
    i.motorcycle::before {
        content: "\\f21c";
    }

    .fa-mound::before,
    i.mound::before {
        content: "\\e52d";
    }

    .fa-mountain::before,
    i.mountain::before {
        content: "\\f6fc";
    }

    .fa-mountain-city::before,
    i.mountain-city::before {
        content: "\\e52e";
    }

    .fa-mountain-sun::before,
    i.mountain-sun::before {
        content: "\\e52f";
    }

    .fa-mountains::before,
    i.mountains::before {
        content: "\\f6fd";
    }

    .fa-mp3-player::before,
    i.mp3-player::before {
        content: "\\f8ce";
    }

    .fa-mug::before,
    i.mug::before {
        content: "\\f874";
    }

    .fa-mug-hot::before,
    i.mug-hot::before {
        content: "\\f7b6";
    }

    .fa-mug-marshmallows::before,
    i.mug-marshmallows::before {
        content: "\\f7b7";
    }

    .fa-mug-saucer::before,
    i.mug-saucer::before {
        content: "\\f0f4";
    }

    .fa-coffee::before,
    i.coffee::before {
        content: "\\f0f4";
    }

    .fa-mug-tea::before,
    i.mug-tea::before {
        content: "\\f875";
    }

    .fa-mug-tea-saucer::before,
    i.mug-tea-saucer::before {
        content: "\\e1f5";
    }

    .fa-mushroom::before,
    i.mushroom::before {
        content: "\\e425";
    }

    .fa-music::before,
    i.music::before {
        content: "\\f001";
    }

    .fa-music-note::before,
    i.music-note::before {
        content: "\\f8cf";
    }

    .fa-music-alt::before,
    i.music-alt::before {
        content: "\\f8cf";
    }

    .fa-music-note-slash::before,
    i.music-note-slash::before {
        content: "\\f8d0";
    }

    .fa-music-alt-slash::before,
    i.music-alt-slash::before {
        content: "\\f8d0";
    }

    .fa-music-slash::before,
    i.music-slash::before {
        content: "\\f8d1";
    }

    .fa-n::before,
    i.n::before {
        content: "\\4e";
    }

    .fa-naira-sign::before,
    i.naira-sign::before {
        content: "\\e1f6";
    }

    .fa-narwhal::before,
    i.narwhal::before {
        content: "\\f6fe";
    }

    .fa-nesting-dolls::before,
    i.nesting-dolls::before {
        content: "\\e3ba";
    }

    .fa-network-wired::before,
    i.network-wired::before {
        content: "\\f6ff";
    }

    .fa-neuter::before,
    i.neuter::before {
        content: "\\f22c";
    }

    .fa-newspaper::before,
    i.newspaper::before {
        content: "\\f1ea";
    }

    .fa-nfc::before,
    i.nfc::before {
        content: "\\e1f7";
    }

    .fa-nfc-lock::before,
    i.nfc-lock::before {
        content: "\\e1f8";
    }

    .fa-nfc-magnifying-glass::before,
    i.nfc-magnifying-glass::before {
        content: "\\e1f9";
    }

    .fa-nfc-pen::before,
    i.nfc-pen::before {
        content: "\\e1fa";
    }

    .fa-nfc-signal::before,
    i.nfc-signal::before {
        content: "\\e1fb";
    }

    .fa-nfc-slash::before,
    i.nfc-slash::before {
        content: "\\e1fc";
    }

    .fa-nfc-trash::before,
    i.nfc-trash::before {
        content: "\\e1fd";
    }

    .fa-not-equal::before,
    i.not-equal::before {
        content: "\\f53e";
    }

    .fa-notdef::before,
    i.notdef::before {
        content: "\\e1fe";
    }

    .fa-note::before,
    i.note::before {
        content: "\\e1ff";
    }

    .fa-note-medical::before,
    i.note-medical::before {
        content: "\\e200";
    }

    .fa-note-sticky::before,
    i.note-sticky::before {
        content: "\\f249";
    }

    .fa-sticky-note::before,
    i.sticky-note::before {
        content: "\\f249";
    }

    .fa-notebook::before,
    i.notebook::before {
        content: "\\e201";
    }

    .fa-notes::before,
    i.notes::before {
        content: "\\e202";
    }

    .fa-notes-medical::before,
    i.notes-medical::before {
        content: "\\f481";
    }

    .fa-o::before,
    i.o::before {
        content: "\\4f";
    }

    .fa-object-exclude::before,
    i.object-exclude::before {
        content: "\\e49c";
    }

    .fa-object-group::before,
    i.object-group::before {
        content: "\\f247";
    }

    .fa-object-intersect::before,
    i.object-intersect::before {
        content: "\\e49d";
    }

    .fa-object-subtract::before,
    i.object-subtract::before {
        content: "\\e49e";
    }

    .fa-object-ungroup::before,
    i.object-ungroup::before {
        content: "\\f248";
    }

    .fa-object-union::before,
    i.object-union::before {
        content: "\\e49f";
    }

    .fa-objects-align-bottom::before,
    i.objects-align-bottom::before {
        content: "\\e3bb";
    }

    .fa-objects-align-center-horizontal::before,
    i.objects-align-center-horizontal::before {
        content: "\\e3bc";
    }

    .fa-objects-align-center-vertical::before,
    i.objects-align-center-vertical::before {
        content: "\\e3bd";
    }

    .fa-objects-align-left::before,
    i.objects-align-left::before {
        content: "\\e3be";
    }

    .fa-objects-align-right::before,
    i.objects-align-right::before {
        content: "\\e3bf";
    }

    .fa-objects-align-top::before,
    i.objects-align-top::before {
        content: "\\e3c0";
    }

    .fa-objects-column::before,
    i.objects-column::before {
        content: "\\e3c1";
    }

    .fa-octagon::before,
    i.octagon::before {
        content: "\\f306";
    }

    .fa-octagon-check::before,
    i.octagon-check::before {
        content: "\\e426";
    }

    .fa-octagon-divide::before,
    i.octagon-divide::before {
        content: "\\e203";
    }

    .fa-octagon-exclamation::before,
    i.octagon-exclamation::before {
        content: "\\e204";
    }

    .fa-octagon-minus::before,
    i.octagon-minus::before {
        content: "\\f308";
    }

    .fa-minus-octagon::before,
    i.minus-octagon::before {
        content: "\\f308";
    }

    .fa-octagon-plus::before,
    i.octagon-plus::before {
        content: "\\f301";
    }

    .fa-plus-octagon::before,
    i.plus-octagon::before {
        content: "\\f301";
    }

    .fa-octagon-xmark::before,
    i.octagon-xmark::before {
        content: "\\f2f0";
    }

    .fa-times-octagon::before,
    i.times-octagon::before {
        content: "\\f2f0";
    }

    .fa-xmark-octagon::before,
    i.xmark-octagon::before {
        content: "\\f2f0";
    }

    .fa-oil-can::before,
    i.oil-can::before {
        content: "\\f613";
    }

    .fa-oil-can-drip::before,
    i.oil-can-drip::before {
        content: "\\e205";
    }

    .fa-oil-temperature::before,
    i.oil-temperature::before {
        content: "\\f614";
    }

    .fa-oil-temp::before,
    i.oil-temp::before {
        content: "\\f614";
    }

    .fa-oil-well::before,
    i.oil-well::before {
        content: "\\e532";
    }

    .fa-olive::before,
    i.olive::before {
        content: "\\e316";
    }

    .fa-olive-branch::before,
    i.olive-branch::before {
        content: "\\e317";
    }

    .fa-om::before,
    i.om::before {
        content: "\\f679";
    }

    .fa-omega::before,
    i.omega::before {
        content: "\\f67a";
    }

    .fa-onion::before,
    i.onion::before {
        content: "\\e427";
    }

    .fa-option::before,
    i.option::before {
        content: "\\e318";
    }

    .fa-ornament::before,
    i.ornament::before {
        content: "\\f7b8";
    }

    .fa-otter::before,
    i.otter::before {
        content: "\\f700";
    }

    .fa-outdent::before,
    i.outdent::before {
        content: "\\f03b";
    }

    .fa-dedent::before,
    i.dedent::before {
        content: "\\f03b";
    }

    .fa-outlet::before,
    i.outlet::before {
        content: "\\e01c";
    }

    .fa-oven::before,
    i.oven::before {
        content: "\\e01d";
    }

    .fa-overline::before,
    i.overline::before {
        content: "\\f876";
    }

    .fa-p::before,
    i.p::before {
        content: "\\50";
    }

    .fa-page::before,
    i.page::before {
        content: "\\e428";
    }

    .fa-page-caret-down::before,
    i.page-caret-down::before {
        content: "\\e429";
    }

    .fa-file-caret-down::before,
    i.file-caret-down::before {
        content: "\\e429";
    }

    .fa-page-caret-up::before,
    i.page-caret-up::before {
        content: "\\e42a";
    }

    .fa-file-caret-up::before,
    i.file-caret-up::before {
        content: "\\e42a";
    }

    .fa-pager::before,
    i.pager::before {
        content: "\\f815";
    }

    .fa-paint-roller::before,
    i.paint-roller::before {
        content: "\\f5aa";
    }

    .fa-paintbrush::before,
    i.paintbrush::before {
        content: "\\f1fc";
    }

    .fa-paint-brush::before,
    i.paint-brush::before {
        content: "\\f1fc";
    }

    .fa-paintbrush-fine::before,
    i.paintbrush-fine::before {
        content: "\\f5a9";
    }

    .fa-paint-brush-alt::before,
    i.paint-brush-alt::before {
        content: "\\f5a9";
    }

    .fa-paint-brush-fine::before,
    i.paint-brush-fine::before {
        content: "\\f5a9";
    }

    .fa-paintbrush-alt::before,
    i.paintbrush-alt::before {
        content: "\\f5a9";
    }

    .fa-paintbrush-pencil::before,
    i.paintbrush-pencil::before {
        content: "\\e206";
    }

    .fa-palette::before,
    i.palette::before {
        content: "\\f53f";
    }

    .fa-pallet::before,
    i.pallet::before {
        content: "\\f482";
    }

    .fa-pallet-box::before,
    i.pallet-box::before {
        content: "\\e208";
    }

    .fa-pallet-boxes::before,
    i.pallet-boxes::before {
        content: "\\f483";
    }

    .fa-palette-boxes::before,
    i.palette-boxes::before {
        content: "\\f483";
    }

    .fa-pallet-alt::before,
    i.pallet-alt::before {
        content: "\\f483";
    }

    .fa-pan-food::before,
    i.pan-food::before {
        content: "\\e42b";
    }

    .fa-pan-frying::before,
    i.pan-frying::before {
        content: "\\e42c";
    }

    .fa-pancakes::before,
    i.pancakes::before {
        content: "\\e42d";
    }

    .fa-panel-ews::before,
    i.panel-ews::before {
        content: "\\e42e";
    }

    .fa-panel-fire::before,
    i.panel-fire::before {
        content: "\\e42f";
    }

    .fa-panorama::before,
    i.panorama::before {
        content: "\\e209";
    }

    .fa-paper-plane::before,
    i.paper-plane::before {
        content: "\\f1d8";
    }

    .fa-paper-plane-top::before,
    i.paper-plane-top::before {
        content: "\\e20a";
    }

    .fa-paper-plane-alt::before,
    i.paper-plane-alt::before {
        content: "\\e20a";
    }

    .fa-send::before,
    i.send::before {
        content: "\\e20a";
    }

    .fa-paperclip::before,
    i.paperclip::before {
        content: "\\f0c6";
    }

    .fa-paperclip-vertical::before,
    i.paperclip-vertical::before {
        content: "\\e3c2";
    }

    .fa-parachute-box::before,
    i.parachute-box::before {
        content: "\\f4cd";
    }

    .fa-paragraph::before,
    i.paragraph::before {
        content: "\\f1dd";
    }

    .fa-paragraph-left::before,
    i.paragraph-left::before {
        content: "\\f878";
    }

    .fa-paragraph-rtl::before,
    i.paragraph-rtl::before {
        content: "\\f878";
    }

    .fa-party-bell::before,
    i.party-bell::before {
        content: "\\e31a";
    }

    .fa-party-horn::before,
    i.party-horn::before {
        content: "\\e31b";
    }

    .fa-passport::before,
    i.passport::before {
        content: "\\f5ab";
    }

    .fa-paste::before,
    i.paste::before {
        content: "\\f0ea";
    }

    .fa-file-clipboard::before,
    i.file-clipboard::before {
        content: "\\f0ea";
    }

    .fa-pause::before,
    i.pause::before {
        content: "\\f04c";
    }

    .fa-paw::before,
    i.paw::before {
        content: "\\f1b0";
    }

    .fa-paw-claws::before,
    i.paw-claws::before {
        content: "\\f702";
    }

    .fa-paw-simple::before,
    i.paw-simple::before {
        content: "\\f701";
    }

    .fa-paw-alt::before,
    i.paw-alt::before {
        content: "\\f701";
    }

    .fa-peace::before,
    i.peace::before {
        content: "\\f67c";
    }

    .fa-peach::before,
    i.peach::before {
        content: "\\e20b";
    }

    .fa-peanut::before,
    i.peanut::before {
        content: "\\e430";
    }

    .fa-peanuts::before,
    i.peanuts::before {
        content: "\\e431";
    }

    .fa-peapod::before,
    i.peapod::before {
        content: "\\e31c";
    }

    .fa-pear::before,
    i.pear::before {
        content: "\\e20c";
    }

    .fa-pedestal::before,
    i.pedestal::before {
        content: "\\e20d";
    }

    .fa-pegasus::before,
    i.pegasus::before {
        content: "\\f703";
    }

    .fa-pen::before,
    i.pen::before {
        content: "\\f304";
    }

    .fa-pen-circle::before,
    i.pen-circle::before {
        content: "\\e20e";
    }

    .fa-pen-clip::before,
    i.pen-clip::before {
        content: "\\f305";
    }

    .fa-pen-alt::before,
    i.pen-alt::before {
        content: "\\f305";
    }

    .fa-pen-clip-slash::before,
    i.pen-clip-slash::before {
        content: "\\e20f";
    }

    .fa-pen-alt-slash::before,
    i.pen-alt-slash::before {
        content: "\\e20f";
    }

    .fa-pen-fancy::before,
    i.pen-fancy::before {
        content: "\\f5ac";
    }

    .fa-pen-fancy-slash::before,
    i.pen-fancy-slash::before {
        content: "\\e210";
    }

    .fa-pen-field::before,
    i.pen-field::before {
        content: "\\e211";
    }

    .fa-pen-line::before,
    i.pen-line::before {
        content: "\\e212";
    }

    .fa-pen-nib::before,
    i.pen-nib::before {
        content: "\\f5ad";
    }

    .fa-pen-nib-slash::before,
    i.pen-nib-slash::before {
        content: "\\e4a1";
    }

    .fa-pen-paintbrush::before,
    i.pen-paintbrush::before {
        content: "\\f618";
    }

    .fa-pencil-paintbrush::before,
    i.pencil-paintbrush::before {
        content: "\\f618";
    }

    .fa-pen-ruler::before,
    i.pen-ruler::before {
        content: "\\f5ae";
    }

    .fa-pencil-ruler::before,
    i.pencil-ruler::before {
        content: "\\f5ae";
    }

    .fa-pen-slash::before,
    i.pen-slash::before {
        content: "\\e213";
    }

    .fa-pen-swirl::before,
    i.pen-swirl::before {
        content: "\\e214";
    }

    .fa-pen-to-square::before,
    i.pen-to-square::before {
        content: "\\f044";
    }

    .fa-edit::before,
    i.edit::before {
        content: "\\f044";
    }

    .fa-pencil::before,
    i.pencil::before {
        content: "\\f303";
    }

    .fa-pencil-alt::before,
    i.pencil-alt::before {
        content: "\\f303";
    }

    .fa-pencil-slash::before,
    i.pencil-slash::before {
        content: "\\e215";
    }

    .fa-people::before,
    i.people::before {
        content: "\\e216";
    }

    .fa-people-arrows-left-right::before,
    i.people-arrows-left-right::before {
        content: "\\e068";
    }

    .fa-people-arrows::before,
    i.people-arrows::before {
        content: "\\e068";
    }

    .fa-people-carry-box::before,
    i.people-carry-box::before {
        content: "\\f4ce";
    }

    .fa-people-carry::before,
    i.people-carry::before {
        content: "\\f4ce";
    }

    .fa-people-dress::before,
    i.people-dress::before {
        content: "\\e217";
    }

    .fa-people-dress-simple::before,
    i.people-dress-simple::before {
        content: "\\e218";
    }

    .fa-people-group::before,
    i.people-group::before {
        content: "\\e533";
    }

    .fa-people-line::before,
    i.people-line::before {
        content: "\\e534";
    }

    .fa-people-pants::before,
    i.people-pants::before {
        content: "\\e219";
    }

    .fa-people-pants-simple::before,
    i.people-pants-simple::before {
        content: "\\e21a";
    }

    .fa-people-pulling::before,
    i.people-pulling::before {
        content: "\\e535";
    }

    .fa-people-robbery::before,
    i.people-robbery::before {
        content: "\\e536";
    }

    .fa-people-roof::before,
    i.people-roof::before {
        content: "\\e537";
    }

    .fa-people-simple::before,
    i.people-simple::before {
        content: "\\e21b";
    }

    .fa-pepper::before,
    i.pepper::before {
        content: "\\e432";
    }

    .fa-pepper-hot::before,
    i.pepper-hot::before {
        content: "\\f816";
    }

    .fa-percent::before,
    i.percent::before {
        content: "\\25";
    }

    .fa-percentage::before,
    i.percentage::before {
        content: "\\25";
    }

    .fa-period::before,
    i.period::before {
        content: "\\2e";
    }

    .fa-person::before,
    i.person::before {
        content: "\\f183";
    }

    .fa-male::before,
    i.male::before {
        content: "\\f183";
    }

    .fa-person-arrow-down-to-line::before,
    i.person-arrow-down-to-line::before {
        content: "\\e538";
    }

    .fa-person-arrow-up-from-line::before,
    i.person-arrow-up-from-line::before {
        content: "\\e539";
    }

    .fa-person-biking::before,
    i.person-biking::before {
        content: "\\f84a";
    }

    .fa-biking::before,
    i.biking::before {
        content: "\\f84a";
    }

    .fa-person-biking-mountain::before,
    i.person-biking-mountain::before {
        content: "\\f84b";
    }

    .fa-biking-mountain::before,
    i.biking-mountain::before {
        content: "\\f84b";
    }

    .fa-person-booth::before,
    i.person-booth::before {
        content: "\\f756";
    }

    .fa-person-breastfeeding::before,
    i.person-breastfeeding::before {
        content: "\\e53a";
    }

    .fa-person-burst::before,
    i.person-burst::before {
        content: "\\e53b";
    }

    .fa-person-cane::before,
    i.person-cane::before {
        content: "\\e53c";
    }

    .fa-person-carry-box::before,
    i.person-carry-box::before {
        content: "\\f4cf";
    }

    .fa-person-carry::before,
    i.person-carry::before {
        content: "\\f4cf";
    }

    .fa-person-chalkboard::before,
    i.person-chalkboard::before {
        content: "\\e53d";
    }

    .fa-person-circle-check::before,
    i.person-circle-check::before {
        content: "\\e53e";
    }

    .fa-person-circle-exclamation::before,
    i.person-circle-exclamation::before {
        content: "\\e53f";
    }

    .fa-person-circle-minus::before,
    i.person-circle-minus::before {
        content: "\\e540";
    }

    .fa-person-circle-plus::before,
    i.person-circle-plus::before {
        content: "\\e541";
    }

    .fa-person-circle-question::before,
    i.person-circle-question::before {
        content: "\\e542";
    }

    .fa-person-circle-xmark::before,
    i.person-circle-xmark::before {
        content: "\\e543";
    }

    .fa-person-digging::before,
    i.person-digging::before {
        content: "\\f85e";
    }

    .fa-digging::before,
    i.digging::before {
        content: "\\f85e";
    }

    .fa-person-dolly::before,
    i.person-dolly::before {
        content: "\\f4d0";
    }

    .fa-person-dolly-empty::before,
    i.person-dolly-empty::before {
        content: "\\f4d1";
    }

    .fa-person-dots-from-line::before,
    i.person-dots-from-line::before {
        content: "\\f470";
    }

    .fa-diagnoses::before,
    i.diagnoses::before {
        content: "\\f470";
    }

    .fa-person-dress::before,
    i.person-dress::before {
        content: "\\f182";
    }

    .fa-female::before,
    i.female::before {
        content: "\\f182";
    }

    .fa-person-dress-burst::before,
    i.person-dress-burst::before {
        content: "\\e544";
    }

    .fa-person-dress-simple::before,
    i.person-dress-simple::before {
        content: "\\e21c";
    }

    .fa-person-drowning::before,
    i.person-drowning::before {
        content: "\\e545";
    }

    .fa-person-falling::before,
    i.person-falling::before {
        content: "\\e546";
    }

    .fa-person-falling-burst::before,
    i.person-falling-burst::before {
        content: "\\e547";
    }

    .fa-person-from-portal::before,
    i.person-from-portal::before {
        content: "\\e023";
    }

    .fa-portal-exit::before,
    i.portal-exit::before {
        content: "\\e023";
    }

    .fa-person-half-dress::before,
    i.person-half-dress::before {
        content: "\\e548";
    }

    .fa-person-harassing::before,
    i.person-harassing::before {
        content: "\\e549";
    }

    .fa-person-hiking::before,
    i.person-hiking::before {
        content: "\\f6ec";
    }

    .fa-hiking::before,
    i.hiking::before {
        content: "\\f6ec";
    }

    .fa-person-military-pointing::before,
    i.person-military-pointing::before {
        content: "\\e54a";
    }

    .fa-person-military-rifle::before,
    i.person-military-rifle::before {
        content: "\\e54b";
    }

    .fa-person-military-to-person::before,
    i.person-military-to-person::before {
        content: "\\e54c";
    }

    .fa-person-pinball::before,
    i.person-pinball::before {
        content: "\\e21d";
    }

    .fa-person-praying::before,
    i.person-praying::before {
        content: "\\f683";
    }

    .fa-pray::before,
    i.pray::before {
        content: "\\f683";
    }

    .fa-person-pregnant::before,
    i.person-pregnant::before {
        content: "\\e31e";
    }

    .fa-person-rays::before,
    i.person-rays::before {
        content: "\\e54d";
    }

    .fa-person-rifle::before,
    i.person-rifle::before {
        content: "\\e54e";
    }

    .fa-person-running::before,
    i.person-running::before {
        content: "\\f70c";
    }

    .fa-running::before,
    i.running::before {
        content: "\\f70c";
    }

    .fa-person-seat::before,
    i.person-seat::before {
        content: "\\e21e";
    }

    .fa-person-seat-reclined::before,
    i.person-seat-reclined::before {
        content: "\\e21f";
    }

    .fa-person-shelter::before,
    i.person-shelter::before {
        content: "\\e54f";
    }

    .fa-person-sign::before,
    i.person-sign::before {
        content: "\\f757";
    }

    .fa-person-simple::before,
    i.person-simple::before {
        content: "\\e220";
    }

    .fa-person-skating::before,
    i.person-skating::before {
        content: "\\f7c5";
    }

    .fa-skating::before,
    i.skating::before {
        content: "\\f7c5";
    }

    .fa-person-ski-jumping::before,
    i.person-ski-jumping::before {
        content: "\\f7c7";
    }

    .fa-ski-jump::before,
    i.ski-jump::before {
        content: "\\f7c7";
    }

    .fa-person-ski-lift::before,
    i.person-ski-lift::before {
        content: "\\f7c8";
    }

    .fa-ski-lift::before,
    i.ski-lift::before {
        content: "\\f7c8";
    }

    .fa-person-skiing::before,
    i.person-skiing::before {
        content: "\\f7c9";
    }

    .fa-skiing::before,
    i.skiing::before {
        content: "\\f7c9";
    }

    .fa-person-skiing-nordic::before,
    i.person-skiing-nordic::before {
        content: "\\f7ca";
    }

    .fa-skiing-nordic::before,
    i.skiing-nordic::before {
        content: "\\f7ca";
    }

    .fa-person-sledding::before,
    i.person-sledding::before {
        content: "\\f7cb";
    }

    .fa-sledding::before,
    i.sledding::before {
        content: "\\f7cb";
    }

    .fa-person-snowboarding::before,
    i.person-snowboarding::before {
        content: "\\f7ce";
    }

    .fa-snowboarding::before,
    i.snowboarding::before {
        content: "\\f7ce";
    }

    .fa-person-snowmobiling::before,
    i.person-snowmobiling::before {
        content: "\\f7d1";
    }

    .fa-snowmobile::before,
    i.snowmobile::before {
        content: "\\f7d1";
    }

    .fa-person-swimming::before,
    i.person-swimming::before {
        content: "\\f5c4";
    }

    .fa-swimmer::before,
    i.swimmer::before {
        content: "\\f5c4";
    }

    .fa-person-through-window::before,
    i.person-through-window::before {
        content: "\\e433";
    }

    .fa-person-to-door::before,
    i.person-to-door::before {
        content: "\\e550";
    }

    .fa-person-to-portal::before,
    i.person-to-portal::before {
        content: "\\e022";
    }

    .fa-portal-enter::before,
    i.portal-enter::before {
        content: "\\e022";
    }

    .fa-person-walking::before,
    i.person-walking::before {
        content: "\\f554";
    }

    .fa-walking::before,
    i.walking::before {
        content: "\\f554";
    }

    .fa-person-walking-arrow-loop-left::before,
    i.person-walking-arrow-loop-left::before {
        content: "\\e551";
    }

    .fa-person-walking-arrow-right::before,
    i.person-walking-arrow-right::before {
        content: "\\e552";
    }

    .fa-person-walking-dashed-line-arrow-right::before,
    i.person-walking-dashed-line-arrow-right::before {
        content: "\\e553";
    }

    .fa-person-walking-luggage::before,
    i.person-walking-luggage::before {
        content: "\\e554";
    }

    .fa-person-walking-with-cane::before,
    i.person-walking-with-cane::before {
        content: "\\f29d";
    }

    .fa-blind::before,
    i.blind::before {
        content: "\\f29d";
    }

    .fa-peseta-sign::before,
    i.peseta-sign::before {
        content: "\\e221";
    }

    .fa-peso-sign::before,
    i.peso-sign::before {
        content: "\\e222";
    }

    .fa-phone::before,
    i.phone::before {
        content: "\\f095";
    }

    .fa-phone-arrow-down-left::before,
    i.phone-arrow-down-left::before {
        content: "\\e223";
    }

    .fa-phone-arrow-down::before,
    i.phone-arrow-down::before {
        content: "\\e223";
    }

    .fa-phone-incoming::before,
    i.phone-incoming::before {
        content: "\\e223";
    }

    .fa-phone-arrow-up-right::before,
    i.phone-arrow-up-right::before {
        content: "\\e224";
    }

    .fa-phone-arrow-up::before,
    i.phone-arrow-up::before {
        content: "\\e224";
    }

    .fa-phone-outgoing::before,
    i.phone-outgoing::before {
        content: "\\e224";
    }

    .fa-phone-flip::before,
    i.phone-flip::before {
        content: "\\f879";
    }

    .fa-phone-alt::before,
    i.phone-alt::before {
        content: "\\f879";
    }

    .fa-phone-hangup::before,
    i.phone-hangup::before {
        content: "\\e225";
    }

    .fa-phone-intercom::before,
    i.phone-intercom::before {
        content: "\\e434";
    }

    .fa-phone-missed::before,
    i.phone-missed::before {
        content: "\\e226";
    }

    .fa-phone-office::before,
    i.phone-office::before {
        content: "\\f67d";
    }

    .fa-phone-plus::before,
    i.phone-plus::before {
        content: "\\f4d2";
    }

    .fa-phone-rotary::before,
    i.phone-rotary::before {
        content: "\\f8d3";
    }

    .fa-phone-slash::before,
    i.phone-slash::before {
        content: "\\f3dd";
    }

    .fa-phone-volume::before,
    i.phone-volume::before {
        content: "\\f2a0";
    }

    .fa-volume-control-phone::before,
    i.volume-control-phone::before {
        content: "\\f2a0";
    }

    .fa-phone-xmark::before,
    i.phone-xmark::before {
        content: "\\e227";
    }

    .fa-photo-film::before,
    i.photo-film::before {
        content: "\\f87c";
    }

    .fa-photo-video::before,
    i.photo-video::before {
        content: "\\f87c";
    }

    .fa-photo-film-music::before,
    i.photo-film-music::before {
        content: "\\e228";
    }

    .fa-pi::before,
    i.pi::before {
        content: "\\f67e";
    }

    .fa-piano::before,
    i.piano::before {
        content: "\\f8d4";
    }

    .fa-piano-keyboard::before,
    i.piano-keyboard::before {
        content: "\\f8d5";
    }

    .fa-pickleball::before,
    i.pickleball::before {
        content: "\\e435";
    }

    .fa-pie::before,
    i.pie::before {
        content: "\\f705";
    }

    .fa-pig::before,
    i.pig::before {
        content: "\\f706";
    }

    .fa-piggy-bank::before,
    i.piggy-bank::before {
        content: "\\f4d3";
    }

    .fa-pills::before,
    i.pills::before {
        content: "\\f484";
    }

    .fa-pinata::before,
    i.pinata::before {
        content: "\\e3c3";
    }

    .fa-pinball::before,
    i.pinball::before {
        content: "\\e229";
    }

    .fa-pineapple::before,
    i.pineapple::before {
        content: "\\e31f";
    }

    .fa-pipe::before,
    i.pipe::before {
        content: "\\7c";
    }

    .fa-pipe-circle-check::before,
    i.pipe-circle-check::before {
        content: "\\e436";
    }

    .fa-pipe-collar::before,
    i.pipe-collar::before {
        content: "\\e437";
    }

    .fa-pipe-section::before,
    i.pipe-section::before {
        content: "\\e438";
    }

    .fa-pipe-smoking::before,
    i.pipe-smoking::before {
        content: "\\e3c4";
    }

    .fa-pipe-valve::before,
    i.pipe-valve::before {
        content: "\\e439";
    }

    .fa-pizza::before,
    i.pizza::before {
        content: "\\f817";
    }

    .fa-pizza-slice::before,
    i.pizza-slice::before {
        content: "\\f818";
    }

    .fa-place-of-worship::before,
    i.place-of-worship::before {
        content: "\\f67f";
    }

    .fa-plane::before,
    i.plane::before {
        content: "\\f072";
    }

    .fa-plane-arrival::before,
    i.plane-arrival::before {
        content: "\\f5af";
    }

    .fa-plane-circle-check::before,
    i.plane-circle-check::before {
        content: "\\e555";
    }

    .fa-plane-circle-exclamation::before,
    i.plane-circle-exclamation::before {
        content: "\\e556";
    }

    .fa-plane-circle-xmark::before,
    i.plane-circle-xmark::before {
        content: "\\e557";
    }

    .fa-plane-departure::before,
    i.plane-departure::before {
        content: "\\f5b0";
    }

    .fa-plane-engines::before,
    i.plane-engines::before {
        content: "\\f3de";
    }

    .fa-plane-alt::before,
    i.plane-alt::before {
        content: "\\f3de";
    }

    .fa-plane-lock::before,
    i.plane-lock::before {
        content: "\\e558";
    }

    .fa-plane-prop::before,
    i.plane-prop::before {
        content: "\\e22b";
    }

    .fa-plane-slash::before,
    i.plane-slash::before {
        content: "\\e069";
    }

    .fa-plane-tail::before,
    i.plane-tail::before {
        content: "\\e22c";
    }

    .fa-plane-up::before,
    i.plane-up::before {
        content: "\\e22d";
    }

    .fa-plane-up-slash::before,
    i.plane-up-slash::before {
        content: "\\e22e";
    }

    .fa-planet-moon::before,
    i.planet-moon::before {
        content: "\\e01f";
    }

    .fa-planet-ringed::before,
    i.planet-ringed::before {
        content: "\\e020";
    }

    .fa-plant-wilt::before,
    i.plant-wilt::before {
        content: "\\e43b";
    }

    .fa-plate-utensils::before,
    i.plate-utensils::before {
        content: "\\e559";
    }

    .fa-plate-wheat::before,
    i.plate-wheat::before {
        content: "\\e55a";
    }

    .fa-play::before,
    i.play::before {
        content: "\\f04b";
    }

    .fa-play-pause::before,
    i.play-pause::before {
        content: "\\e22f";
    }

    .fa-plug::before,
    i.plug::before {
        content: "\\f1e6";
    }

    .fa-plug-circle-bolt::before,
    i.plug-circle-bolt::before {
        content: "\\e55b";
    }

    .fa-plug-circle-check::before,
    i.plug-circle-check::before {
        content: "\\e55c";
    }

    .fa-plug-circle-exclamation::before,
    i.plug-circle-exclamation::before {
        content: "\\e55d";
    }

    .fa-plug-circle-minus::before,
    i.plug-circle-minus::before {
        content: "\\e55e";
    }

    .fa-plug-circle-plus::before,
    i.plug-circle-plus::before {
        content: "\\e55f";
    }

    .fa-plug-circle-xmark::before,
    i.plug-circle-xmark::before {
        content: "\\e560";
    }

    .fa-plus::before,
    i.plus::before {
        content: "\\2b";
    }

    .fa-add::before,
    i.add::before {
        content: "\\2b";
    }

    .fa-plus-minus::before,
    i.plus-minus::before {
        content: "\\e43c";
    }

    .fa-podcast::before,
    i.podcast::before {
        content: "\\f2ce";
    }

    .fa-podium::before,
    i.podium::before {
        content: "\\f680";
    }

    .fa-podium-star::before,
    i.podium-star::before {
        content: "\\f758";
    }

    .fa-police-box::before,
    i.police-box::before {
        content: "\\e021";
    }

    .fa-poll-people::before,
    i.poll-people::before {
        content: "\\f759";
    }

    .fa-pompebled::before,
    i.pompebled::before {
        content: "\\e43d";
    }

    .fa-poo::before,
    i.poo::before {
        content: "\\f2fe";
    }

    .fa-poo-storm::before,
    i.poo-storm::before {
        content: "\\f75a";
    }

    .fa-poo-bolt::before,
    i.poo-bolt::before {
        content: "\\f75a";
    }

    .fa-pool-8-ball::before,
    i.pool-8-ball::before {
        content: "\\e3c5";
    }

    .fa-poop::before,
    i.poop::before {
        content: "\\f619";
    }

    .fa-popcorn::before,
    i.popcorn::before {
        content: "\\f819";
    }

    .fa-popsicle::before,
    i.popsicle::before {
        content: "\\e43e";
    }

    .fa-pot-food::before,
    i.pot-food::before {
        content: "\\e43f";
    }

    .fa-potato::before,
    i.potato::before {
        content: "\\e440";
    }

    .fa-power-off::before,
    i.power-off::before {
        content: "\\f011";
    }

    .fa-prescription::before,
    i.prescription::before {
        content: "\\f5b1";
    }

    .fa-prescription-bottle::before,
    i.prescription-bottle::before {
        content: "\\f485";
    }

    .fa-prescription-bottle-medical::before,
    i.prescription-bottle-medical::before {
        content: "\\f486";
    }

    .fa-prescription-bottle-alt::before,
    i.prescription-bottle-alt::before {
        content: "\\f486";
    }

    .fa-presentation-screen::before,
    i.presentation-screen::before {
        content: "\\f685";
    }

    .fa-presentation::before,
    i.presentation::before {
        content: "\\f685";
    }

    .fa-pretzel::before,
    i.pretzel::before {
        content: "\\e441";
    }

    .fa-print::before,
    i.print::before {
        content: "\\f02f";
    }

    .fa-print-magnifying-glass::before,
    i.print-magnifying-glass::before {
        content: "\\f81a";
    }

    .fa-print-search::before,
    i.print-search::before {
        content: "\\f81a";
    }

    .fa-print-slash::before,
    i.print-slash::before {
        content: "\\f686";
    }

    .fa-projector::before,
    i.projector::before {
        content: "\\f8d6";
    }

    .fa-pump::before,
    i.pump::before {
        content: "\\e442";
    }

    .fa-pump-medical::before,
    i.pump-medical::before {
        content: "\\e06a";
    }

    .fa-pump-soap::before,
    i.pump-soap::before {
        content: "\\e06b";
    }

    .fa-pumpkin::before,
    i.pumpkin::before {
        content: "\\f707";
    }

    .fa-puzzle::before,
    i.puzzle::before {
        content: "\\e443";
    }

    .fa-puzzle-piece::before,
    i.puzzle-piece::before {
        content: "\\f12e";
    }

    .fa-puzzle-piece-simple::before,
    i.puzzle-piece-simple::before {
        content: "\\e231";
    }

    .fa-puzzle-piece-alt::before,
    i.puzzle-piece-alt::before {
        content: "\\e231";
    }

    .fa-q::before,
    i.q::before {
        content: "\\51";
    }

    .fa-qrcode::before,
    i.qrcode::before {
        content: "\\f029";
    }

    .fa-question::before,
    i.question::before {
        content: "\\3f";
    }

    .fa-quote-left::before,
    i.quote-left::before {
        content: "\\f10d";
    }

    .fa-quote-left-alt::before,
    i.quote-left-alt::before {
        content: "\\f10d";
    }

    .fa-quote-right::before,
    i.quote-right::before {
        content: "\\f10e";
    }

    .fa-quote-right-alt::before,
    i.quote-right-alt::before {
        content: "\\f10e";
    }

    .fa-quotes::before,
    i.quotes::before {
        content: "\\e234";
    }

    .fa-r::before,
    i.r::before {
        content: "\\52";
    }

    .fa-rabbit::before,
    i.rabbit::before {
        content: "\\f708";
    }

    .fa-rabbit-running::before,
    i.rabbit-running::before {
        content: "\\f709";
    }

    .fa-rabbit-fast::before,
    i.rabbit-fast::before {
        content: "\\f709";
    }

    .fa-racquet::before,
    i.racquet::before {
        content: "\\f45a";
    }

    .fa-radar::before,
    i.radar::before {
        content: "\\e024";
    }

    .fa-radiation::before,
    i.radiation::before {
        content: "\\f7b9";
    }

    .fa-radio::before,
    i.radio::before {
        content: "\\f8d7";
    }

    .fa-radio-tuner::before,
    i.radio-tuner::before {
        content: "\\f8d8";
    }

    .fa-radio-alt::before,
    i.radio-alt::before {
        content: "\\f8d8";
    }

    .fa-rainbow::before,
    i.rainbow::before {
        content: "\\f75b";
    }

    .fa-raindrops::before,
    i.raindrops::before {
        content: "\\f75c";
    }

    .fa-ram::before,
    i.ram::before {
        content: "\\f70a";
    }

    .fa-ramp-loading::before,
    i.ramp-loading::before {
        content: "\\f4d4";
    }

    .fa-ranking-star::before,
    i.ranking-star::before {
        content: "\\e561";
    }

    .fa-raygun::before,
    i.raygun::before {
        content: "\\e025";
    }

    .fa-receipt::before,
    i.receipt::before {
        content: "\\f543";
    }

    .fa-record-vinyl::before,
    i.record-vinyl::before {
        content: "\\f8d9";
    }

    .fa-rectangle::before,
    i.rectangle::before {
        content: "\\f2fa";
    }

    .fa-rectangle-landscape::before,
    i.rectangle-landscape::before {
        content: "\\f2fa";
    }

    .fa-rectangle-ad::before,
    i.rectangle-ad::before {
        content: "\\f641";
    }

    .fa-ad::before,
    i.ad::before {
        content: "\\f641";
    }

    .fa-rectangle-barcode::before,
    i.rectangle-barcode::before {
        content: "\\f463";
    }

    .fa-barcode-alt::before,
    i.barcode-alt::before {
        content: "\\f463";
    }

    .fa-rectangle-code::before,
    i.rectangle-code::before {
        content: "\\e322";
    }

    .fa-rectangle-history::before,
    i.rectangle-history::before {
        content: "\\e4a2";
    }

    .fa-rectangle-history-circle-plus::before,
    i.rectangle-history-circle-plus::before {
        content: "\\e4a3";
    }

    .fa-rectangle-history-circle-user::before,
    i.rectangle-history-circle-user::before {
        content: "\\e4a4";
    }

    .fa-rectangle-list::before,
    i.rectangle-list::before {
        content: "\\f022";
    }

    .fa-list-alt::before,
    i.list-alt::before {
        content: "\\f022";
    }

    .fa-rectangle-pro::before,
    i.rectangle-pro::before {
        content: "\\e235";
    }

    .fa-pro::before,
    i.pro::before {
        content: "\\e235";
    }

    .fa-rectangle-terminal::before,
    i.rectangle-terminal::before {
        content: "\\e236";
    }

    .fa-rectangle-vertical::before,
    i.rectangle-vertical::before {
        content: "\\f2fb";
    }

    .fa-rectangle-portrait::before,
    i.rectangle-portrait::before {
        content: "\\f2fb";
    }

    .fa-rectangle-vertical-history::before,
    i.rectangle-vertical-history::before {
        content: "\\e237";
    }

    .fa-rectangle-wide::before,
    i.rectangle-wide::before {
        content: "\\f2fc";
    }

    .fa-rectangle-xmark::before,
    i.rectangle-xmark::before {
        content: "\\f410";
    }

    .fa-rectangle-times::before,
    i.rectangle-times::before {
        content: "\\f410";
    }

    .fa-times-rectangle::before,
    i.times-rectangle::before {
        content: "\\f410";
    }

    .fa-window-close::before,
    i.window-close::before {
        content: "\\f410";
    }

    .fa-rectangles-mixed::before,
    i.rectangles-mixed::before {
        content: "\\e323";
    }

    .fa-recycle::before,
    i.recycle::before {
        content: "\\f1b8";
    }

    .fa-reel::before,
    i.reel::before {
        content: "\\e238";
    }

    .fa-refrigerator::before,
    i.refrigerator::before {
        content: "\\e026";
    }

    .fa-registered::before,
    i.registered::before {
        content: "\\f25d";
    }

    .fa-repeat::before,
    i.repeat::before {
        content: "\\f363";
    }

    .fa-repeat-1::before,
    i.repeat-1::before {
        content: "\\f365";
    }

    .fa-reply::before,
    i.reply::before {
        content: "\\f3e5";
    }

    .fa-mail-reply::before,
    i.mail-reply::before {
        content: "\\f3e5";
    }

    .fa-reply-all::before,
    i.reply-all::before {
        content: "\\f122";
    }

    .fa-mail-reply-all::before,
    i.mail-reply-all::before {
        content: "\\f122";
    }

    .fa-reply-clock::before,
    i.reply-clock::before {
        content: "\\e239";
    }

    .fa-reply-time::before,
    i.reply-time::before {
        content: "\\e239";
    }

    .fa-republican::before,
    i.republican::before {
        content: "\\f75e";
    }

    .fa-restroom::before,
    i.restroom::before {
        content: "\\f7bd";
    }

    .fa-restroom-simple::before,
    i.restroom-simple::before {
        content: "\\e23a";
    }

    .fa-retweet::before,
    i.retweet::before {
        content: "\\f079";
    }

    .fa-rhombus::before,
    i.rhombus::before {
        content: "\\e23b";
    }

    .fa-ribbon::before,
    i.ribbon::before {
        content: "\\f4d6";
    }

    .fa-right::before,
    i.right::before {
        content: "\\f356";
    }

    .fa-arrow-alt-right::before,
    i.arrow-alt-right::before {
        content: "\\f356";
    }

    .fa-right-from-bracket::before,
    i.right-from-bracket::before {
        content: "\\f2f5";
    }

    .fa-sign-out-alt::before,
    i.sign-out-alt::before {
        content: "\\f2f5";
    }

    .fa-right-from-line::before,
    i.right-from-line::before {
        content: "\\f347";
    }

    .fa-arrow-alt-from-left::before,
    i.arrow-alt-from-left::before {
        content: "\\f347";
    }

    .fa-right-left::before,
    i.right-left::before {
        content: "\\f362";
    }

    .fa-exchange-alt::before,
    i.exchange-alt::before {
        content: "\\f362";
    }

    .fa-right-long::before,
    i.right-long::before {
        content: "\\f30b";
    }

    .fa-long-arrow-alt-right::before,
    i.long-arrow-alt-right::before {
        content: "\\f30b";
    }

    .fa-right-long-to-line::before,
    i.right-long-to-line::before {
        content: "\\e444";
    }

    .fa-right-to-bracket::before,
    i.right-to-bracket::before {
        content: "\\f2f6";
    }

    .fa-sign-in-alt::before,
    i.sign-in-alt::before {
        content: "\\f2f6";
    }

    .fa-right-to-line::before,
    i.right-to-line::before {
        content: "\\f34c";
    }

    .fa-arrow-alt-to-right::before,
    i.arrow-alt-to-right::before {
        content: "\\f34c";
    }

    .fa-ring::before,
    i.ring::before {
        content: "\\f70b";
    }

    .fa-rings-wedding::before,
    i.rings-wedding::before {
        content: "\\f81b";
    }

    .fa-road::before,
    i.road::before {
        content: "\\f018";
    }

    .fa-road-barrier::before,
    i.road-barrier::before {
        content: "\\e562";
    }

    .fa-road-bridge::before,
    i.road-bridge::before {
        content: "\\e563";
    }

    .fa-road-circle-check::before,
    i.road-circle-check::before {
        content: "\\e564";
    }

    .fa-road-circle-exclamation::before,
    i.road-circle-exclamation::before {
        content: "\\e565";
    }

    .fa-road-circle-xmark::before,
    i.road-circle-xmark::before {
        content: "\\e566";
    }

    .fa-road-lock::before,
    i.road-lock::before {
        content: "\\e567";
    }

    .fa-road-spikes::before,
    i.road-spikes::before {
        content: "\\e568";
    }

    .fa-robot::before,
    i.robot::before {
        content: "\\f544";
    }

    .fa-robot-astromech::before,
    i.robot-astromech::before {
        content: "\\e2d2";
    }

    .fa-rocket::before,
    i.rocket::before {
        content: "\\f135";
    }

    .fa-rocket-launch::before,
    i.rocket-launch::before {
        content: "\\e027";
    }

    .fa-roller-coaster::before,
    i.roller-coaster::before {
        content: "\\e324";
    }

    .fa-rotate::before,
    i.rotate::before {
        content: "\\f2f1";
    }

    .fa-sync-alt::before,
    i.sync-alt::before {
        content: "\\f2f1";
    }

    .fa-rotate-exclamation::before,
    i.rotate-exclamation::before {
        content: "\\e23c";
    }

    .fa-rotate-left::before,
    i.rotate-left::before {
        content: "\\f2ea";
    }

    .fa-rotate-back::before,
    i.rotate-back::before {
        content: "\\f2ea";
    }

    .fa-rotate-backward::before,
    i.rotate-backward::before {
        content: "\\f2ea";
    }

    .fa-undo-alt::before,
    i.undo-alt::before {
        content: "\\f2ea";
    }

    .fa-rotate-right::before,
    i.rotate-right::before {
        content: "\\f2f9";
    }

    .fa-redo-alt::before,
    i.redo-alt::before {
        content: "\\f2f9";
    }

    .fa-rotate-forward::before,
    i.rotate-forward::before {
        content: "\\f2f9";
    }

    .fa-route::before,
    i.route::before {
        content: "\\f4d7";
    }

    .fa-route-highway::before,
    i.route-highway::before {
        content: "\\f61a";
    }

    .fa-route-interstate::before,
    i.route-interstate::before {
        content: "\\f61b";
    }

    .fa-router::before,
    i.router::before {
        content: "\\f8da";
    }

    .fa-rss::before,
    i.rss::before {
        content: "\\f09e";
    }

    .fa-feed::before,
    i.feed::before {
        content: "\\f09e";
    }

    .fa-ruble-sign::before,
    i.ruble-sign::before {
        content: "\\f158";
    }

    .fa-rouble::before,
    i.rouble::before {
        content: "\\f158";
    }

    .fa-rub::before,
    i.rub::before {
        content: "\\f158";
    }

    .fa-ruble::before,
    i.ruble::before {
        content: "\\f158";
    }

    .fa-rug::before,
    i.rug::before {
        content: "\\e569";
    }

    .fa-rugby-ball::before,
    i.rugby-ball::before {
        content: "\\e3c6";
    }

    .fa-ruler::before,
    i.ruler::before {
        content: "\\f545";
    }

    .fa-ruler-combined::before,
    i.ruler-combined::before {
        content: "\\f546";
    }

    .fa-ruler-horizontal::before,
    i.ruler-horizontal::before {
        content: "\\f547";
    }

    .fa-ruler-triangle::before,
    i.ruler-triangle::before {
        content: "\\f61c";
    }

    .fa-ruler-vertical::before,
    i.ruler-vertical::before {
        content: "\\f548";
    }

    .fa-rupee-sign::before,
    i.rupee-sign::before {
        content: "\\f156";
    }

    .fa-rupee::before,
    i.rupee::before {
        content: "\\f156";
    }

    .fa-rupiah-sign::before,
    i.rupiah-sign::before {
        content: "\\e23d";
    }

    .fa-rv::before,
    i.rv::before {
        content: "\\f7be";
    }

    .fa-s::before,
    i.s::before {
        content: "\\53";
    }

    .fa-sack::before,
    i.sack::before {
        content: "\\f81c";
    }

    .fa-sack-dollar::before,
    i.sack-dollar::before {
        content: "\\f81d";
    }

    .fa-sack-xmark::before,
    i.sack-xmark::before {
        content: "\\e56a";
    }

    .fa-sailboat::before,
    i.sailboat::before {
        content: "\\e445";
    }

    .fa-salad::before,
    i.salad::before {
        content: "\\f81e";
    }

    .fa-bowl-salad::before,
    i.bowl-salad::before {
        content: "\\f81e";
    }

    .fa-salt-shaker::before,
    i.salt-shaker::before {
        content: "\\e446";
    }

    .fa-sandwich::before,
    i.sandwich::before {
        content: "\\f81f";
    }

    .fa-satellite::before,
    i.satellite::before {
        content: "\\f7bf";
    }

    .fa-satellite-dish::before,
    i.satellite-dish::before {
        content: "\\f7c0";
    }

    .fa-sausage::before,
    i.sausage::before {
        content: "\\f820";
    }

    .fa-saxophone::before,
    i.saxophone::before {
        content: "\\f8dc";
    }

    .fa-saxophone-fire::before,
    i.saxophone-fire::before {
        content: "\\f8db";
    }

    .fa-sax-hot::before,
    i.sax-hot::before {
        content: "\\f8db";
    }

    .fa-scale-balanced::before,
    i.scale-balanced::before {
        content: "\\f24e";
    }

    .fa-balance-scale::before,
    i.balance-scale::before {
        content: "\\f24e";
    }

    .fa-scale-unbalanced::before,
    i.scale-unbalanced::before {
        content: "\\f515";
    }

    .fa-balance-scale-left::before,
    i.balance-scale-left::before {
        content: "\\f515";
    }

    .fa-scale-unbalanced-flip::before,
    i.scale-unbalanced-flip::before {
        content: "\\f516";
    }

    .fa-balance-scale-right::before,
    i.balance-scale-right::before {
        content: "\\f516";
    }

    .fa-scalpel::before,
    i.scalpel::before {
        content: "\\f61d";
    }

    .fa-scalpel-line-dashed::before,
    i.scalpel-line-dashed::before {
        content: "\\f61e";
    }

    .fa-scalpel-path::before,
    i.scalpel-path::before {
        content: "\\f61e";
    }

    .fa-scanner::before,
    i.scanner::before {
        content: "\\f8f3";
    }

    .fa-scanner-image::before,
    i.scanner-image::before {
        content: "\\f8f3";
    }

    .fa-scanner-gun::before,
    i.scanner-gun::before {
        content: "\\f488";
    }

    .fa-scanner-keyboard::before,
    i.scanner-keyboard::before {
        content: "\\f489";
    }

    .fa-scanner-touchscreen::before,
    i.scanner-touchscreen::before {
        content: "\\f48a";
    }

    .fa-scarecrow::before,
    i.scarecrow::before {
        content: "\\f70d";
    }

    .fa-scarf::before,
    i.scarf::before {
        content: "\\f7c1";
    }

    .fa-school::before,
    i.school::before {
        content: "\\f549";
    }

    .fa-school-circle-check::before,
    i.school-circle-check::before {
        content: "\\e56b";
    }

    .fa-school-circle-exclamation::before,
    i.school-circle-exclamation::before {
        content: "\\e56c";
    }

    .fa-school-circle-xmark::before,
    i.school-circle-xmark::before {
        content: "\\e56d";
    }

    .fa-school-flag::before,
    i.school-flag::before {
        content: "\\e56e";
    }

    .fa-school-lock::before,
    i.school-lock::before {
        content: "\\e56f";
    }

    .fa-scissors::before,
    i.scissors::before {
        content: "\\f0c4";
    }

    .fa-cut::before,
    i.cut::before {
        content: "\\f0c4";
    }

    .fa-screen-users::before,
    i.screen-users::before {
        content: "\\f63d";
    }

    .fa-users-class::before,
    i.users-class::before {
        content: "\\f63d";
    }

    .fa-screencast::before,
    i.screencast::before {
        content: "\\e23e";
    }

    .fa-screwdriver::before,
    i.screwdriver::before {
        content: "\\f54a";
    }

    .fa-screwdriver-wrench::before,
    i.screwdriver-wrench::before {
        content: "\\f7d9";
    }

    .fa-tools::before,
    i.tools::before {
        content: "\\f7d9";
    }

    .fa-scribble::before,
    i.scribble::before {
        content: "\\e23f";
    }

    .fa-scroll::before,
    i.scroll::before {
        content: "\\f70e";
    }

    .fa-scroll-old::before,
    i.scroll-old::before {
        content: "\\f70f";
    }

    .fa-scroll-torah::before,
    i.scroll-torah::before {
        content: "\\f6a0";
    }

    .fa-torah::before,
    i.torah::before {
        content: "\\f6a0";
    }

    .fa-scrubber::before,
    i.scrubber::before {
        content: "\\f2f8";
    }

    .fa-scythe::before,
    i.scythe::before {
        content: "\\f710";
    }

    .fa-sd-card::before,
    i.sd-card::before {
        content: "\\f7c2";
    }

    .fa-sd-cards::before,
    i.sd-cards::before {
        content: "\\e240";
    }

    .fa-seal::before,
    i.seal::before {
        content: "\\e241";
    }

    .fa-seal-exclamation::before,
    i.seal-exclamation::before {
        content: "\\e242";
    }

    .fa-seal-question::before,
    i.seal-question::before {
        content: "\\e243";
    }

    .fa-seat-airline::before,
    i.seat-airline::before {
        content: "\\e244";
    }

    .fa-section::before,
    i.section::before {
        content: "\\e447";
    }

    .fa-seedling::before,
    i.seedling::before {
        content: "\\f4d8";
    }

    .fa-sprout::before,
    i.sprout::before {
        content: "\\f4d8";
    }

    .fa-semicolon::before,
    i.semicolon::before {
        content: "\\3b";
    }

    .fa-send-back::before,
    i.send-back::before {
        content: "\\f87e";
    }

    .fa-send-backward::before,
    i.send-backward::before {
        content: "\\f87f";
    }

    .fa-sensor::before,
    i.sensor::before {
        content: "\\e028";
    }

    .fa-sensor-cloud::before,
    i.sensor-cloud::before {
        content: "\\e02c";
    }

    .fa-sensor-smoke::before,
    i.sensor-smoke::before {
        content: "\\e02c";
    }

    .fa-sensor-fire::before,
    i.sensor-fire::before {
        content: "\\e02a";
    }

    .fa-sensor-on::before,
    i.sensor-on::before {
        content: "\\e02b";
    }

    .fa-sensor-triangle-exclamation::before,
    i.sensor-triangle-exclamation::before {
        content: "\\e029";
    }

    .fa-sensor-alert::before,
    i.sensor-alert::before {
        content: "\\e029";
    }

    .fa-server::before,
    i.server::before {
        content: "\\f233";
    }

    .fa-shapes::before,
    i.shapes::before {
        content: "\\f61f";
    }

    .fa-triangle-circle-square::before,
    i.triangle-circle-square::before {
        content: "\\f61f";
    }

    .fa-share::before,
    i.share::before {
        content: "\\f064";
    }

    .fa-arrow-turn-right::before,
    i.arrow-turn-right::before {
        content: "\\f064";
    }

    .fa-mail-forward::before,
    i.mail-forward::before {
        content: "\\f064";
    }

    .fa-share-all::before,
    i.share-all::before {
        content: "\\f367";
    }

    .fa-share-from-square::before,
    i.share-from-square::before {
        content: "\\f14d";
    }

    .fa-share-square::before,
    i.share-square::before {
        content: "\\f14d";
    }

    .fa-share-nodes::before,
    i.share-nodes::before {
        content: "\\f1e0";
    }

    .fa-share-alt::before,
    i.share-alt::before {
        content: "\\f1e0";
    }

    .fa-sheep::before,
    i.sheep::before {
        content: "\\f711";
    }

    .fa-sheet-plastic::before,
    i.sheet-plastic::before {
        content: "\\e571";
    }

    .fa-shekel-sign::before,
    i.shekel-sign::before {
        content: "\\f20b";
    }

    .fa-ils::before,
    i.ils::before {
        content: "\\f20b";
    }

    .fa-shekel::before,
    i.shekel::before {
        content: "\\f20b";
    }

    .fa-sheqel::before,
    i.sheqel::before {
        content: "\\f20b";
    }

    .fa-sheqel-sign::before,
    i.sheqel-sign::before {
        content: "\\f20b";
    }

    .fa-shelves::before,
    i.shelves::before {
        content: "\\f480";
    }

    .fa-inventory::before,
    i.inventory::before {
        content: "\\f480";
    }

    .fa-shelves-empty::before,
    i.shelves-empty::before {
        content: "\\e246";
    }

    .fa-shield::before,
    i.shield::before {
        content: "\\f132";
    }

    .fa-shield-blank::before,
    i.shield-blank::before {
        content: "\\f132";
    }

    .fa-shield-cat::before,
    i.shield-cat::before {
        content: "\\e572";
    }

    .fa-shield-check::before,
    i.shield-check::before {
        content: "\\f2f7";
    }

    .fa-shield-cross::before,
    i.shield-cross::before {
        content: "\\f712";
    }

    .fa-shield-dog::before,
    i.shield-dog::before {
        content: "\\e573";
    }

    .fa-shield-exclamation::before,
    i.shield-exclamation::before {
        content: "\\e247";
    }

    .fa-shield-halved::before,
    i.shield-halved::before {
        content: "\\f3ed";
    }

    .fa-shield-alt::before,
    i.shield-alt::before {
        content: "\\f3ed";
    }

    .fa-shield-heart::before,
    i.shield-heart::before {
        content: "\\e574";
    }

    .fa-shield-keyhole::before,
    i.shield-keyhole::before {
        content: "\\e248";
    }

    .fa-shield-minus::before,
    i.shield-minus::before {
        content: "\\e249";
    }

    .fa-shield-plus::before,
    i.shield-plus::before {
        content: "\\e24a";
    }

    .fa-shield-quartered::before,
    i.shield-quartered::before {
        content: "\\e575";
    }

    .fa-shield-slash::before,
    i.shield-slash::before {
        content: "\\e24b";
    }

    .fa-shield-virus::before,
    i.shield-virus::before {
        content: "\\e06c";
    }

    .fa-shield-xmark::before,
    i.shield-xmark::before {
        content: "\\e24c";
    }

    .fa-shield-times::before,
    i.shield-times::before {
        content: "\\e24c";
    }

    .fa-ship::before,
    i.ship::before {
        content: "\\f21a";
    }

    .fa-shirt::before,
    i.shirt::before {
        content: "\\f553";
    }

    .fa-t-shirt::before,
    i.t-shirt::before {
        content: "\\f553";
    }

    .fa-tshirt::before,
    i.tshirt::before {
        content: "\\f553";
    }

    .fa-shirt-long-sleeve::before,
    i.shirt-long-sleeve::before {
        content: "\\e3c7";
    }

    .fa-shirt-running::before,
    i.shirt-running::before {
        content: "\\e3c8";
    }

    .fa-shirt-tank-top::before,
    i.shirt-tank-top::before {
        content: "\\e3c9";
    }

    .fa-shish-kebab::before,
    i.shish-kebab::before {
        content: "\\f821";
    }

    .fa-shoe-prints::before,
    i.shoe-prints::before {
        content: "\\f54b";
    }

    .fa-shop::before,
    i.shop::before {
        content: "\\f54f";
    }

    .fa-store-alt::before,
    i.store-alt::before {
        content: "\\f54f";
    }

    .fa-shop-lock::before,
    i.shop-lock::before {
        content: "\\e4a5";
    }

    .fa-shop-slash::before,
    i.shop-slash::before {
        content: "\\e070";
    }

    .fa-store-alt-slash::before,
    i.store-alt-slash::before {
        content: "\\e070";
    }

    .fa-shovel::before,
    i.shovel::before {
        content: "\\f713";
    }

    .fa-shovel-snow::before,
    i.shovel-snow::before {
        content: "\\f7c3";
    }

    .fa-shower::before,
    i.shower::before {
        content: "\\f2cc";
    }

    .fa-shower-down::before,
    i.shower-down::before {
        content: "\\e24d";
    }

    .fa-shower-alt::before,
    i.shower-alt::before {
        content: "\\e24d";
    }

    .fa-shredder::before,
    i.shredder::before {
        content: "\\f68a";
    }

    .fa-shrimp::before,
    i.shrimp::before {
        content: "\\e448";
    }

    .fa-shuffle::before,
    i.shuffle::before {
        content: "\\f074";
    }

    .fa-random::before,
    i.random::before {
        content: "\\f074";
    }

    .fa-shutters::before,
    i.shutters::before {
        content: "\\e449";
    }

    .fa-shuttle-space::before,
    i.shuttle-space::before {
        content: "\\f197";
    }

    .fa-space-shuttle::before,
    i.space-shuttle::before {
        content: "\\f197";
    }

    .fa-shuttlecock::before,
    i.shuttlecock::before {
        content: "\\f45b";
    }

    .fa-sickle::before,
    i.sickle::before {
        content: "\\f822";
    }

    .fa-sidebar::before,
    i.sidebar::before {
        content: "\\e24e";
    }

    .fa-sidebar-flip::before,
    i.sidebar-flip::before {
        content: "\\e24f";
    }

    .fa-sigma::before,
    i.sigma::before {
        content: "\\f68b";
    }

    .fa-sign-hanging::before,
    i.sign-hanging::before {
        content: "\\f4d9";
    }

    .fa-sign::before,
    i.sign::before {
        content: "\\f4d9";
    }

    .fa-signal::before,
    i.signal::before {
        content: "\\f012";
    }

    .fa-signal-5::before,
    i.signal-5::before {
        content: "\\f012";
    }

    .fa-signal-perfect::before,
    i.signal-perfect::before {
        content: "\\f012";
    }

    .fa-signal-bars::before,
    i.signal-bars::before {
        content: "\\f690";
    }

    .fa-signal-alt::before,
    i.signal-alt::before {
        content: "\\f690";
    }

    .fa-signal-alt-4::before,
    i.signal-alt-4::before {
        content: "\\f690";
    }

    .fa-signal-bars-strong::before,
    i.signal-bars-strong::before {
        content: "\\f690";
    }

    .fa-signal-bars-fair::before,
    i.signal-bars-fair::before {
        content: "\\f692";
    }

    .fa-signal-alt-2::before,
    i.signal-alt-2::before {
        content: "\\f692";
    }

    .fa-signal-bars-good::before,
    i.signal-bars-good::before {
        content: "\\f693";
    }

    .fa-signal-alt-3::before,
    i.signal-alt-3::before {
        content: "\\f693";
    }

    .fa-signal-bars-slash::before,
    i.signal-bars-slash::before {
        content: "\\f694";
    }

    .fa-signal-alt-slash::before,
    i.signal-alt-slash::before {
        content: "\\f694";
    }

    .fa-signal-bars-weak::before,
    i.signal-bars-weak::before {
        content: "\\f691";
    }

    .fa-signal-alt-1::before,
    i.signal-alt-1::before {
        content: "\\f691";
    }

    .fa-signal-fair::before,
    i.signal-fair::before {
        content: "\\f68d";
    }

    .fa-signal-2::before,
    i.signal-2::before {
        content: "\\f68d";
    }

    .fa-signal-good::before,
    i.signal-good::before {
        content: "\\f68e";
    }

    .fa-signal-3::before,
    i.signal-3::before {
        content: "\\f68e";
    }

    .fa-signal-slash::before,
    i.signal-slash::before {
        content: "\\f695";
    }

    .fa-signal-stream::before,
    i.signal-stream::before {
        content: "\\f8dd";
    }

    .fa-signal-stream-slash::before,
    i.signal-stream-slash::before {
        content: "\\e250";
    }

    .fa-signal-strong::before,
    i.signal-strong::before {
        content: "\\f68f";
    }

    .fa-signal-4::before,
    i.signal-4::before {
        content: "\\f68f";
    }

    .fa-signal-weak::before,
    i.signal-weak::before {
        content: "\\f68c";
    }

    .fa-signal-1::before,
    i.signal-1::before {
        content: "\\f68c";
    }

    .fa-signature::before,
    i.signature::before {
        content: "\\f5b7";
    }

    .fa-signature-lock::before,
    i.signature-lock::before {
        content: "\\e3ca";
    }

    .fa-signature-slash::before,
    i.signature-slash::before {
        content: "\\e3cb";
    }

    .fa-signs-post::before,
    i.signs-post::before {
        content: "\\f277";
    }

    .fa-map-signs::before,
    i.map-signs::before {
        content: "\\f277";
    }

    .fa-sim-card::before,
    i.sim-card::before {
        content: "\\f7c4";
    }

    .fa-sim-cards::before,
    i.sim-cards::before {
        content: "\\e251";
    }

    .fa-sink::before,
    i.sink::before {
        content: "\\e06d";
    }

    .fa-siren::before,
    i.siren::before {
        content: "\\e02d";
    }

    .fa-siren-on::before,
    i.siren-on::before {
        content: "\\e02e";
    }

    .fa-sitemap::before,
    i.sitemap::before {
        content: "\\f0e8";
    }

    .fa-skeleton::before,
    i.skeleton::before {
        content: "\\f620";
    }

    .fa-ski-boot::before,
    i.ski-boot::before {
        content: "\\e3cc";
    }

    .fa-ski-boot-ski::before,
    i.ski-boot-ski::before {
        content: "\\e3cd";
    }

    .fa-skull::before,
    i.skull::before {
        content: "\\f54c";
    }

    .fa-skull-cow::before,
    i.skull-cow::before {
        content: "\\f8de";
    }

    .fa-skull-crossbones::before,
    i.skull-crossbones::before {
        content: "\\f714";
    }

    .fa-slash::before,
    i.slash::before {
        content: "\\f715";
    }

    .fa-slash-back::before,
    i.slash-back::before {
        content: "\\5c";
    }

    .fa-slash-forward::before,
    i.slash-forward::before {
        content: "\\2f";
    }

    .fa-sleigh::before,
    i.sleigh::before {
        content: "\\f7cc";
    }

    .fa-slider::before,
    i.slider::before {
        content: "\\e252";
    }

    .fa-sliders::before,
    i.sliders::before {
        content: "\\f1de";
    }

    .fa-sliders-h::before,
    i.sliders-h::before {
        content: "\\f1de";
    }

    .fa-sliders-simple::before,
    i.sliders-simple::before {
        content: "\\e253";
    }

    .fa-sliders-up::before,
    i.sliders-up::before {
        content: "\\f3f1";
    }

    .fa-sliders-v::before,
    i.sliders-v::before {
        content: "\\f3f1";
    }

    .fa-slot-machine::before,
    i.slot-machine::before {
        content: "\\e3ce";
    }

    .fa-smog::before,
    i.smog::before {
        content: "\\f75f";
    }

    .fa-smoke::before,
    i.smoke::before {
        content: "\\f760";
    }

    .fa-smoking::before,
    i.smoking::before {
        content: "\\f48d";
    }

    .fa-snake::before,
    i.snake::before {
        content: "\\f716";
    }

    .fa-snooze::before,
    i.snooze::before {
        content: "\\f880";
    }

    .fa-zzz::before,
    i.zzz::before {
        content: "\\f880";
    }

    .fa-snow-blowing::before,
    i.snow-blowing::before {
        content: "\\f761";
    }

    .fa-snowflake::before,
    i.snowflake::before {
        content: "\\f2dc";
    }

    .fa-snowflakes::before,
    i.snowflakes::before {
        content: "\\f7cf";
    }

    .fa-snowman::before,
    i.snowman::before {
        content: "\\f7d0";
    }

    .fa-snowman-head::before,
    i.snowman-head::before {
        content: "\\f79b";
    }

    .fa-frosty-head::before,
    i.frosty-head::before {
        content: "\\f79b";
    }

    .fa-snowplow::before,
    i.snowplow::before {
        content: "\\f7d2";
    }

    .fa-soap::before,
    i.soap::before {
        content: "\\e06e";
    }

    .fa-socks::before,
    i.socks::before {
        content: "\\f696";
    }

    .fa-soft-serve::before,
    i.soft-serve::before {
        content: "\\e400";
    }

    .fa-creemee::before,
    i.creemee::before {
        content: "\\e400";
    }

    .fa-solar-panel::before,
    i.solar-panel::before {
        content: "\\f5ba";
    }

    .fa-solar-system::before,
    i.solar-system::before {
        content: "\\e02f";
    }

    .fa-sort::before,
    i.sort::before {
        content: "\\f0dc";
    }

    .fa-unsorted::before,
    i.unsorted::before {
        content: "\\f0dc";
    }

    .fa-sort-down::before,
    i.sort-down::before {
        content: "\\f0dd";
    }

    .fa-sort-desc::before,
    i.sort-desc::before {
        content: "\\f0dd";
    }

    .fa-sort-up::before,
    i.sort-up::before {
        content: "\\f0de";
    }

    .fa-sort-asc::before,
    i.sort-asc::before {
        content: "\\f0de";
    }

    .fa-spa::before,
    i.spa::before {
        content: "\\f5bb";
    }

    .fa-space-station-moon::before,
    i.space-station-moon::before {
        content: "\\e033";
    }

    .fa-space-station-moon-construction::before,
    i.space-station-moon-construction::before {
        content: "\\e034";
    }

    .fa-space-station-moon-alt::before,
    i.space-station-moon-alt::before {
        content: "\\e034";
    }

    .fa-spade::before,
    i.spade::before {
        content: "\\f2f4";
    }

    .fa-spaghetti-monster-flying::before,
    i.spaghetti-monster-flying::before {
        content: "\\f67b";
    }

    .fa-pastafarianism::before,
    i.pastafarianism::before {
        content: "\\f67b";
    }

    .fa-sparkles::before,
    i.sparkles::before {
        content: "\\f890";
    }

    .fa-speaker::before,
    i.speaker::before {
        content: "\\f8df";
    }

    .fa-speakers::before,
    i.speakers::before {
        content: "\\f8e0";
    }

    .fa-spell-check::before,
    i.spell-check::before {
        content: "\\f891";
    }

    .fa-spider::before,
    i.spider::before {
        content: "\\f717";
    }

    .fa-spider-black-widow::before,
    i.spider-black-widow::before {
        content: "\\f718";
    }

    .fa-spider-web::before,
    i.spider-web::before {
        content: "\\f719";
    }

    .fa-spinner::before,
    i.spinner::before {
        content: "\\f110";
    }

    .fa-spinner-third::before,
    i.spinner-third::before {
        content: "\\f3f4";
    }

    .fa-split::before,
    i.split::before {
        content: "\\e254";
    }

    .fa-splotch::before,
    i.splotch::before {
        content: "\\f5bc";
    }

    .fa-spoon::before,
    i.spoon::before {
        content: "\\f2e5";
    }

    .fa-utensil-spoon::before,
    i.utensil-spoon::before {
        content: "\\f2e5";
    }

    .fa-sportsball::before,
    i.sportsball::before {
        content: "\\e44b";
    }

    .fa-spray-can::before,
    i.spray-can::before {
        content: "\\f5bd";
    }

    .fa-spray-can-sparkles::before,
    i.spray-can-sparkles::before {
        content: "\\f5d0";
    }

    .fa-air-freshener::before,
    i.air-freshener::before {
        content: "\\f5d0";
    }

    .fa-sprinkler::before,
    i.sprinkler::before {
        content: "\\e035";
    }

    .fa-sprinkler-ceiling::before,
    i.sprinkler-ceiling::before {
        content: "\\e44c";
    }

    .fa-square::before,
    i.square::before {
        content: "\\f0c8";
    }

    .fa-square-0::before,
    i.square-0::before {
        content: "\\e255";
    }

    .fa-square-1::before,
    i.square-1::before {
        content: "\\e256";
    }

    .fa-square-2::before,
    i.square-2::before {
        content: "\\e257";
    }

    .fa-square-3::before,
    i.square-3::before {
        content: "\\e258";
    }

    .fa-square-4::before,
    i.square-4::before {
        content: "\\e259";
    }

    .fa-square-5::before,
    i.square-5::before {
        content: "\\e25a";
    }

    .fa-square-6::before,
    i.square-6::before {
        content: "\\e25b";
    }

    .fa-square-7::before,
    i.square-7::before {
        content: "\\e25c";
    }

    .fa-square-8::before,
    i.square-8::before {
        content: "\\e25d";
    }

    .fa-square-9::before,
    i.square-9::before {
        content: "\\e25e";
    }

    .fa-square-a::before,
    i.square-a::before {
        content: "\\e25f";
    }

    .fa-square-a-lock::before,
    i.square-a-lock::before {
        content: "\\e44d";
    }

    .fa-square-ampersand::before,
    i.square-ampersand::before {
        content: "\\e260";
    }

    .fa-square-arrow-down::before,
    i.square-arrow-down::before {
        content: "\\f339";
    }

    .fa-arrow-square-down::before,
    i.arrow-square-down::before {
        content: "\\f339";
    }

    .fa-square-arrow-down-left::before,
    i.square-arrow-down-left::before {
        content: "\\e261";
    }

    .fa-square-arrow-down-right::before,
    i.square-arrow-down-right::before {
        content: "\\e262";
    }

    .fa-square-arrow-left::before,
    i.square-arrow-left::before {
        content: "\\f33a";
    }

    .fa-arrow-square-left::before,
    i.arrow-square-left::before {
        content: "\\f33a";
    }

    .fa-square-arrow-right::before,
    i.square-arrow-right::before {
        content: "\\f33b";
    }

    .fa-arrow-square-right::before,
    i.arrow-square-right::before {
        content: "\\f33b";
    }

    .fa-square-arrow-up::before,
    i.square-arrow-up::before {
        content: "\\f33c";
    }

    .fa-arrow-square-up::before,
    i.arrow-square-up::before {
        content: "\\f33c";
    }

    .fa-square-arrow-up-left::before,
    i.square-arrow-up-left::before {
        content: "\\e263";
    }

    .fa-square-arrow-up-right::before,
    i.square-arrow-up-right::before {
        content: "\\f14c";
    }

    .fa-external-link-square::before,
    i.external-link-square::before {
        content: "\\f14c";
    }

    .fa-square-b::before,
    i.square-b::before {
        content: "\\e264";
    }

    .fa-square-bolt::before,
    i.square-bolt::before {
        content: "\\e265";
    }

    .fa-square-c::before,
    i.square-c::before {
        content: "\\e266";
    }

    .fa-square-caret-down::before,
    i.square-caret-down::before {
        content: "\\f150";
    }

    .fa-caret-square-down::before,
    i.caret-square-down::before {
        content: "\\f150";
    }

    .fa-square-caret-left::before,
    i.square-caret-left::before {
        content: "\\f191";
    }

    .fa-caret-square-left::before,
    i.caret-square-left::before {
        content: "\\f191";
    }

    .fa-square-caret-right::before,
    i.square-caret-right::before {
        content: "\\f152";
    }

    .fa-caret-square-right::before,
    i.caret-square-right::before {
        content: "\\f152";
    }

    .fa-square-caret-up::before,
    i.square-caret-up::before {
        content: "\\f151";
    }

    .fa-caret-square-up::before,
    i.caret-square-up::before {
        content: "\\f151";
    }

    .fa-square-check::before,
    i.square-check::before {
        content: "\\f14a";
    }

    .fa-check-square::before,
    i.check-square::before {
        content: "\\f14a";
    }

    .fa-square-chevron-down::before,
    i.square-chevron-down::before {
        content: "\\f329";
    }

    .fa-chevron-square-down::before,
    i.chevron-square-down::before {
        content: "\\f329";
    }

    .fa-square-chevron-left::before,
    i.square-chevron-left::before {
        content: "\\f32a";
    }

    .fa-chevron-square-left::before,
    i.chevron-square-left::before {
        content: "\\f32a";
    }

    .fa-square-chevron-right::before,
    i.square-chevron-right::before {
        content: "\\f32b";
    }

    .fa-chevron-square-right::before,
    i.chevron-square-right::before {
        content: "\\f32b";
    }

    .fa-square-chevron-up::before,
    i.square-chevron-up::before {
        content: "\\f32c";
    }

    .fa-chevron-square-up::before,
    i.chevron-square-up::before {
        content: "\\f32c";
    }

    .fa-square-code::before,
    i.square-code::before {
        content: "\\e267";
    }

    .fa-square-d::before,
    i.square-d::before {
        content: "\\e268";
    }

    .fa-square-dashed::before,
    i.square-dashed::before {
        content: "\\e269";
    }

    .fa-square-divide::before,
    i.square-divide::before {
        content: "\\e26a";
    }

    .fa-square-dollar::before,
    i.square-dollar::before {
        content: "\\f2e9";
    }

    .fa-dollar-square::before,
    i.dollar-square::before {
        content: "\\f2e9";
    }

    .fa-usd-square::before,
    i.usd-square::before {
        content: "\\f2e9";
    }

    .fa-square-down::before,
    i.square-down::before {
        content: "\\f350";
    }

    .fa-arrow-alt-square-down::before,
    i.arrow-alt-square-down::before {
        content: "\\f350";
    }

    .fa-square-down-left::before,
    i.square-down-left::before {
        content: "\\e26b";
    }

    .fa-square-down-right::before,
    i.square-down-right::before {
        content: "\\e26c";
    }

    .fa-square-e::before,
    i.square-e::before {
        content: "\\e26d";
    }

    .fa-square-ellipsis::before,
    i.square-ellipsis::before {
        content: "\\e26e";
    }

    .fa-square-ellipsis-vertical::before,
    i.square-ellipsis-vertical::before {
        content: "\\e26f";
    }

    .fa-square-envelope::before,
    i.square-envelope::before {
        content: "\\f199";
    }

    .fa-envelope-square::before,
    i.envelope-square::before {
        content: "\\f199";
    }

    .fa-square-exclamation::before,
    i.square-exclamation::before {
        content: "\\f321";
    }

    .fa-exclamation-square::before,
    i.exclamation-square::before {
        content: "\\f321";
    }

    .fa-square-f::before,
    i.square-f::before {
        content: "\\e270";
    }

    .fa-square-fragile::before,
    i.square-fragile::before {
        content: "\\f49b";
    }

    .fa-box-fragile::before,
    i.box-fragile::before {
        content: "\\f49b";
    }

    .fa-square-wine-glass-crack::before,
    i.square-wine-glass-crack::before {
        content: "\\f49b";
    }

    .fa-square-full::before,
    i.square-full::before {
        content: "\\f45c";
    }

    .fa-square-g::before,
    i.square-g::before {
        content: "\\e271";
    }

    .fa-square-h::before,
    i.square-h::before {
        content: "\\f0fd";
    }

    .fa-h-square::before,
    i.h-square::before {
        content: "\\f0fd";
    }

    .fa-square-heart::before,
    i.square-heart::before {
        content: "\\f4c8";
    }

    .fa-heart-square::before,
    i.heart-square::before {
        content: "\\f4c8";
    }

    .fa-square-i::before,
    i.square-i::before {
        content: "\\e272";
    }

    .fa-square-info::before,
    i.square-info::before {
        content: "\\f30f";
    }

    .fa-info-square::before,
    i.info-square::before {
        content: "\\f30f";
    }

    .fa-square-j::before,
    i.square-j::before {
        content: "\\e273";
    }

    .fa-square-k::before,
    i.square-k::before {
        content: "\\e274";
    }

    .fa-square-kanban::before,
    i.square-kanban::before {
        content: "\\e488";
    }

    .fa-square-l::before,
    i.square-l::before {
        content: "\\e275";
    }

    .fa-square-left::before,
    i.square-left::before {
        content: "\\f351";
    }

    .fa-arrow-alt-square-left::before,
    i.arrow-alt-square-left::before {
        content: "\\f351";
    }

    .fa-square-list::before,
    i.square-list::before {
        content: "\\e489";
    }

    .fa-square-m::before,
    i.square-m::before {
        content: "\\e276";
    }

    .fa-square-minus::before,
    i.square-minus::before {
        content: "\\f146";
    }

    .fa-minus-square::before,
    i.minus-square::before {
        content: "\\f146";
    }

    .fa-square-n::before,
    i.square-n::before {
        content: "\\e277";
    }

    .fa-square-nfi::before,
    i.square-nfi::before {
        content: "\\e576";
    }

    .fa-square-o::before,
    i.square-o::before {
        content: "\\e278";
    }

    .fa-square-p::before,
    i.square-p::before {
        content: "\\e279";
    }

    .fa-square-parking::before,
    i.square-parking::before {
        content: "\\f540";
    }

    .fa-parking::before,
    i.parking::before {
        content: "\\f540";
    }

    .fa-square-parking-slash::before,
    i.square-parking-slash::before {
        content: "\\f617";
    }

    .fa-parking-slash::before,
    i.parking-slash::before {
        content: "\\f617";
    }

    .fa-square-pen::before,
    i.square-pen::before {
        content: "\\f14b";
    }

    .fa-pen-square::before,
    i.pen-square::before {
        content: "\\f14b";
    }

    .fa-pencil-square::before,
    i.pencil-square::before {
        content: "\\f14b";
    }

    .fa-square-person-confined::before,
    i.square-person-confined::before {
        content: "\\e577";
    }

    .fa-square-phone::before,
    i.square-phone::before {
        content: "\\f098";
    }

    .fa-phone-square::before,
    i.phone-square::before {
        content: "\\f098";
    }

    .fa-square-phone-flip::before,
    i.square-phone-flip::before {
        content: "\\f87b";
    }

    .fa-phone-square-alt::before,
    i.phone-square-alt::before {
        content: "\\f87b";
    }

    .fa-square-phone-hangup::before,
    i.square-phone-hangup::before {
        content: "\\e27a";
    }

    .fa-phone-square-down::before,
    i.phone-square-down::before {
        content: "\\e27a";
    }

    .fa-square-plus::before,
    i.square-plus::before {
        content: "\\f0fe";
    }

    .fa-plus-square::before,
    i.plus-square::before {
        content: "\\f0fe";
    }

    .fa-square-poll-horizontal::before,
    i.square-poll-horizontal::before {
        content: "\\f682";
    }

    .fa-poll-h::before,
    i.poll-h::before {
        content: "\\f682";
    }

    .fa-square-poll-vertical::before,
    i.square-poll-vertical::before {
        content: "\\f681";
    }

    .fa-poll::before,
    i.poll::before {
        content: "\\f681";
    }

    .fa-square-q::before,
    i.square-q::before {
        content: "\\e27b";
    }

    .fa-square-quarters::before,
    i.square-quarters::before {
        content: "\\e44e";
    }

    .fa-square-question::before,
    i.square-question::before {
        content: "\\f2fd";
    }

    .fa-question-square::before,
    i.question-square::before {
        content: "\\f2fd";
    }

    .fa-square-quote::before,
    i.square-quote::before {
        content: "\\e329";
    }

    .fa-square-r::before,
    i.square-r::before {
        content: "\\e27c";
    }

    .fa-square-right::before,
    i.square-right::before {
        content: "\\f352";
    }

    .fa-arrow-alt-square-right::before,
    i.arrow-alt-square-right::before {
        content: "\\f352";
    }

    .fa-square-ring::before,
    i.square-ring::before {
        content: "\\e44f";
    }

    .fa-square-root::before,
    i.square-root::before {
        content: "\\f697";
    }

    .fa-square-root-variable::before,
    i.square-root-variable::before {
        content: "\\f698";
    }

    .fa-square-root-alt::before,
    i.square-root-alt::before {
        content: "\\f698";
    }

    .fa-square-rss::before,
    i.square-rss::before {
        content: "\\f143";
    }

    .fa-rss-square::before,
    i.rss-square::before {
        content: "\\f143";
    }

    .fa-square-s::before,
    i.square-s::before {
        content: "\\e27d";
    }

    .fa-square-share-nodes::before,
    i.square-share-nodes::before {
        content: "\\f1e1";
    }

    .fa-share-alt-square::before,
    i.share-alt-square::before {
        content: "\\f1e1";
    }

    .fa-square-sliders::before,
    i.square-sliders::before {
        content: "\\f3f0";
    }

    .fa-sliders-h-square::before,
    i.sliders-h-square::before {
        content: "\\f3f0";
    }

    .fa-square-sliders-vertical::before,
    i.square-sliders-vertical::before {
        content: "\\f3f2";
    }

    .fa-sliders-v-square::before,
    i.sliders-v-square::before {
        content: "\\f3f2";
    }

    .fa-square-small::before,
    i.square-small::before {
        content: "\\e27e";
    }

    .fa-square-star::before,
    i.square-star::before {
        content: "\\e27f";
    }

    .fa-square-t::before,
    i.square-t::before {
        content: "\\e280";
    }

    .fa-square-terminal::before,
    i.square-terminal::before {
        content: "\\e32a";
    }

    .fa-square-this-way-up::before,
    i.square-this-way-up::before {
        content: "\\f49f";
    }

    .fa-box-up::before,
    i.box-up::before {
        content: "\\f49f";
    }

    .fa-square-u::before,
    i.square-u::before {
        content: "\\e281";
    }

    .fa-square-up::before,
    i.square-up::before {
        content: "\\f353";
    }

    .fa-arrow-alt-square-up::before,
    i.arrow-alt-square-up::before {
        content: "\\f353";
    }

    .fa-square-up-left::before,
    i.square-up-left::before {
        content: "\\e282";
    }

    .fa-square-up-right::before,
    i.square-up-right::before {
        content: "\\f360";
    }

    .fa-external-link-square-alt::before,
    i.external-link-square-alt::before {
        content: "\\f360";
    }

    .fa-square-user::before,
    i.square-user::before {
        content: "\\e283";
    }

    .fa-square-v::before,
    i.square-v::before {
        content: "\\e284";
    }

    .fa-square-virus::before,
    i.square-virus::before {
        content: "\\e578";
    }

    .fa-square-w::before,
    i.square-w::before {
        content: "\\e285";
    }

    .fa-square-x::before,
    i.square-x::before {
        content: "\\e286";
    }

    .fa-square-xmark::before,
    i.square-xmark::before {
        content: "\\f2d3";
    }

    .fa-times-square::before,
    i.times-square::before {
        content: "\\f2d3";
    }

    .fa-xmark-square::before,
    i.xmark-square::before {
        content: "\\f2d3";
    }

    .fa-square-y::before,
    i.square-y::before {
        content: "\\e287";
    }

    .fa-square-z::before,
    i.square-z::before {
        content: "\\e288";
    }

    .fa-squid::before,
    i.squid::before {
        content: "\\e450";
    }

    .fa-squirrel::before,
    i.squirrel::before {
        content: "\\f71a";
    }

    .fa-staff::before,
    i.staff::before {
        content: "\\f71b";
    }

    .fa-staff-aesculapius::before,
    i.staff-aesculapius::before {
        content: "\\e579";
    }

    .fa-rod-asclepius::before,
    i.rod-asclepius::before {
        content: "\\e579";
    }

    .fa-rod-snake::before,
    i.rod-snake::before {
        content: "\\e579";
    }

    .fa-staff-snake::before,
    i.staff-snake::before {
        content: "\\e579";
    }

    .fa-stairs::before,
    i.stairs::before {
        content: "\\e289";
    }

    .fa-stamp::before,
    i.stamp::before {
        content: "\\f5bf";
    }

    .fa-standard-definition::before,
    i.standard-definition::before {
        content: "\\e28a";
    }

    .fa-rectangle-sd::before,
    i.rectangle-sd::before {
        content: "\\e28a";
    }

    .fa-star::before,
    i.star::before {
        content: "\\f005";
    }

    .fa-star-and-crescent::before,
    i.star-and-crescent::before {
        content: "\\f699";
    }

    .fa-star-christmas::before,
    i.star-christmas::before {
        content: "\\f7d4";
    }

    .fa-star-exclamation::before,
    i.star-exclamation::before {
        content: "\\f2f3";
    }

    .fa-star-half::before,
    i.star-half::before {
        content: "\\f089";
    }

    .fa-star-half-stroke::before,
    i.star-half-stroke::before {
        content: "\\f5c0";
    }

    .fa-star-half-alt::before,
    i.star-half-alt::before {
        content: "\\f5c0";
    }

    .fa-star-of-david::before,
    i.star-of-david::before {
        content: "\\f69a";
    }

    .fa-star-of-life::before,
    i.star-of-life::before {
        content: "\\f621";
    }

    .fa-star-sharp::before,
    i.star-sharp::before {
        content: "\\e28b";
    }

    .fa-star-sharp-half::before,
    i.star-sharp-half::before {
        content: "\\e28c";
    }

    .fa-star-sharp-half-stroke::before,
    i.star-sharp-half-stroke::before {
        content: "\\e28d";
    }

    .fa-star-sharp-half-alt::before,
    i.star-sharp-half-alt::before {
        content: "\\e28d";
    }

    .fa-star-shooting::before,
    i.star-shooting::before {
        content: "\\e036";
    }

    .fa-starfighter::before,
    i.starfighter::before {
        content: "\\e037";
    }

    .fa-starfighter-twin-ion-engine::before,
    i.starfighter-twin-ion-engine::before {
        content: "\\e038";
    }

    .fa-starfighter-alt::before,
    i.starfighter-alt::before {
        content: "\\e038";
    }

    .fa-starfighter-twin-ion-engine-advanced::before,
    i.starfighter-twin-ion-engine-advanced::before {
        content: "\\e28e";
    }

    .fa-starfighter-alt-advanced::before,
    i.starfighter-alt-advanced::before {
        content: "\\e28e";
    }

    .fa-stars::before,
    i.stars::before {
        content: "\\f762";
    }

    .fa-starship::before,
    i.starship::before {
        content: "\\e039";
    }

    .fa-starship-freighter::before,
    i.starship-freighter::before {
        content: "\\e03a";
    }

    .fa-steak::before,
    i.steak::before {
        content: "\\f824";
    }

    .fa-steering-wheel::before,
    i.steering-wheel::before {
        content: "\\f622";
    }

    .fa-sterling-sign::before,
    i.sterling-sign::before {
        content: "\\f154";
    }

    .fa-gbp::before,
    i.gbp::before {
        content: "\\f154";
    }

    .fa-pound-sign::before,
    i.pound-sign::before {
        content: "\\f154";
    }

    .fa-stethoscope::before,
    i.stethoscope::before {
        content: "\\f0f1";
    }

    .fa-stocking::before,
    i.stocking::before {
        content: "\\f7d5";
    }

    .fa-stomach::before,
    i.stomach::before {
        content: "\\f623";
    }

    .fa-stop::before,
    i.stop::before {
        content: "\\f04d";
    }

    .fa-stopwatch::before,
    i.stopwatch::before {
        content: "\\f2f2";
    }

    .fa-stopwatch-20::before,
    i.stopwatch-20::before {
        content: "\\e06f";
    }

    .fa-store::before,
    i.store::before {
        content: "\\f54e";
    }

    .fa-store-lock::before,
    i.store-lock::before {
        content: "\\e4a6";
    }

    .fa-store-slash::before,
    i.store-slash::before {
        content: "\\e071";
    }

    .fa-strawberry::before,
    i.strawberry::before {
        content: "\\e32b";
    }

    .fa-street-view::before,
    i.street-view::before {
        content: "\\f21d";
    }

    .fa-stretcher::before,
    i.stretcher::before {
        content: "\\f825";
    }

    .fa-strikethrough::before,
    i.strikethrough::before {
        content: "\\f0cc";
    }

    .fa-stroopwafel::before,
    i.stroopwafel::before {
        content: "\\f551";
    }

    .fa-subscript::before,
    i.subscript::before {
        content: "\\f12c";
    }

    .fa-suitcase::before,
    i.suitcase::before {
        content: "\\f0f2";
    }

    .fa-suitcase-medical::before,
    i.suitcase-medical::before {
        content: "\\f0fa";
    }

    .fa-medkit::before,
    i.medkit::before {
        content: "\\f0fa";
    }

    .fa-suitcase-rolling::before,
    i.suitcase-rolling::before {
        content: "\\f5c1";
    }

    .fa-sun::before,
    i.sun::before {
        content: "\\f185";
    }

    .fa-sun-bright::before,
    i.sun-bright::before {
        content: "\\e28f";
    }

    .fa-sun-alt::before,
    i.sun-alt::before {
        content: "\\e28f";
    }

    .fa-sun-cloud::before,
    i.sun-cloud::before {
        content: "\\f763";
    }

    .fa-sun-dust::before,
    i.sun-dust::before {
        content: "\\f764";
    }

    .fa-sun-haze::before,
    i.sun-haze::before {
        content: "\\f765";
    }

    .fa-sun-plant-wilt::before,
    i.sun-plant-wilt::before {
        content: "\\e57a";
    }

    .fa-sunglasses::before,
    i.sunglasses::before {
        content: "\\f892";
    }

    .fa-sunrise::before,
    i.sunrise::before {
        content: "\\f766";
    }

    .fa-sunset::before,
    i.sunset::before {
        content: "\\f767";
    }

    .fa-superscript::before,
    i.superscript::before {
        content: "\\f12b";
    }

    .fa-sushi::before,
    i.sushi::before {
        content: "\\e48a";
    }

    .fa-nigiri::before,
    i.nigiri::before {
        content: "\\e48a";
    }

    .fa-sushi-roll::before,
    i.sushi-roll::before {
        content: "\\e48b";
    }

    .fa-maki-roll::before,
    i.maki-roll::before {
        content: "\\e48b";
    }

    .fa-makizushi::before,
    i.makizushi::before {
        content: "\\e48b";
    }

    .fa-swatchbook::before,
    i.swatchbook::before {
        content: "\\f5c3";
    }

    .fa-sword::before,
    i.sword::before {
        content: "\\f71c";
    }

    .fa-sword-laser::before,
    i.sword-laser::before {
        content: "\\e03b";
    }

    .fa-sword-laser-alt::before,
    i.sword-laser-alt::before {
        content: "\\e03c";
    }

    .fa-swords::before,
    i.swords::before {
        content: "\\f71d";
    }

    .fa-swords-laser::before,
    i.swords-laser::before {
        content: "\\e03d";
    }

    .fa-symbols::before,
    i.symbols::before {
        content: "\\f86e";
    }

    .fa-icons-alt::before,
    i.icons-alt::before {
        content: "\\f86e";
    }

    .fa-synagogue::before,
    i.synagogue::before {
        content: "\\f69b";
    }

    .fa-syringe::before,
    i.syringe::before {
        content: "\\f48e";
    }

    .fa-t::before,
    i.t::before {
        content: "\\54";
    }

    .fa-table::before,
    i.table::before {
        content: "\\f0ce";
    }

    .fa-table-cells::before,
    i.table-cells::before {
        content: "\\f00a";
    }

    .fa-th::before,
    i.th::before {
        content: "\\f00a";
    }

    .fa-table-cells-large::before,
    i.table-cells-large::before {
        content: "\\f009";
    }

    .fa-th-large::before,
    i.th-large::before {
        content: "\\f009";
    }

    .fa-table-columns::before,
    i.table-columns::before {
        content: "\\f0db";
    }

    .fa-columns::before,
    i.columns::before {
        content: "\\f0db";
    }

    .fa-table-layout::before,
    i.table-layout::before {
        content: "\\e290";
    }

    .fa-table-list::before,
    i.table-list::before {
        content: "\\f00b";
    }

    .fa-th-list::before,
    i.th-list::before {
        content: "\\f00b";
    }

    .fa-table-picnic::before,
    i.table-picnic::before {
        content: "\\e32d";
    }

    .fa-table-pivot::before,
    i.table-pivot::before {
        content: "\\e291";
    }

    .fa-table-rows::before,
    i.table-rows::before {
        content: "\\e292";
    }

    .fa-rows::before,
    i.rows::before {
        content: "\\e292";
    }

    .fa-table-tennis-paddle-ball::before,
    i.table-tennis-paddle-ball::before {
        content: "\\f45d";
    }

    .fa-ping-pong-paddle-ball::before,
    i.ping-pong-paddle-ball::before {
        content: "\\f45d";
    }

    .fa-table-tennis::before,
    i.table-tennis::before {
        content: "\\f45d";
    }

    .fa-table-tree::before,
    i.table-tree::before {
        content: "\\e293";
    }

    .fa-tablet::before,
    i.tablet::before {
        content: "\\f3fb";
    }

    .fa-tablet-android::before,
    i.tablet-android::before {
        content: "\\f3fb";
    }

    .fa-tablet-button::before,
    i.tablet-button::before {
        content: "\\f10a";
    }

    .fa-tablet-rugged::before,
    i.tablet-rugged::before {
        content: "\\f48f";
    }

    .fa-tablet-screen::before,
    i.tablet-screen::before {
        content: "\\f3fc";
    }

    .fa-tablet-android-alt::before,
    i.tablet-android-alt::before {
        content: "\\f3fc";
    }

    .fa-tablet-screen-button::before,
    i.tablet-screen-button::before {
        content: "\\f3fa";
    }

    .fa-tablet-alt::before,
    i.tablet-alt::before {
        content: "\\f3fa";
    }

    .fa-tablets::before,
    i.tablets::before {
        content: "\\f490";
    }

    .fa-tachograph-digital::before,
    i.tachograph-digital::before {
        content: "\\f566";
    }

    .fa-digital-tachograph::before,
    i.digital-tachograph::before {
        content: "\\f566";
    }

    .fa-taco::before,
    i.taco::before {
        content: "\\f826";
    }

    .fa-tag::before,
    i.tag::before {
        content: "\\f02b";
    }

    .fa-tags::before,
    i.tags::before {
        content: "\\f02c";
    }

    .fa-tally::before,
    i.tally::before {
        content: "\\f69c";
    }

    .fa-tally-5::before,
    i.tally-5::before {
        content: "\\f69c";
    }

    .fa-tally-1::before,
    i.tally-1::before {
        content: "\\e294";
    }

    .fa-tally-2::before,
    i.tally-2::before {
        content: "\\e295";
    }

    .fa-tally-3::before,
    i.tally-3::before {
        content: "\\e296";
    }

    .fa-tally-4::before,
    i.tally-4::before {
        content: "\\e297";
    }

    .fa-tamale::before,
    i.tamale::before {
        content: "\\e451";
    }

    .fa-tank-water::before,
    i.tank-water::before {
        content: "\\e452";
    }

    .fa-tape::before,
    i.tape::before {
        content: "\\f4db";
    }

    .fa-tarp::before,
    i.tarp::before {
        content: "\\e57b";
    }

    .fa-tarp-droplet::before,
    i.tarp-droplet::before {
        content: "\\e57c";
    }

    .fa-taxi::before,
    i.taxi::before {
        content: "\\f1ba";
    }

    .fa-cab::before,
    i.cab::before {
        content: "\\f1ba";
    }

    .fa-taxi-bus::before,
    i.taxi-bus::before {
        content: "\\e298";
    }

    .fa-teddy-bear::before,
    i.teddy-bear::before {
        content: "\\e3cf";
    }

    .fa-teeth::before,
    i.teeth::before {
        content: "\\f62e";
    }

    .fa-teeth-open::before,
    i.teeth-open::before {
        content: "\\f62f";
    }

    .fa-telescope::before,
    i.telescope::before {
        content: "\\e03e";
    }

    .fa-temperature-arrow-down::before,
    i.temperature-arrow-down::before {
        content: "\\e03f";
    }

    .fa-temperature-down::before,
    i.temperature-down::before {
        content: "\\e03f";
    }

    .fa-temperature-arrow-up::before,
    i.temperature-arrow-up::before {
        content: "\\e040";
    }

    .fa-temperature-up::before,
    i.temperature-up::before {
        content: "\\e040";
    }

    .fa-temperature-empty::before,
    i.temperature-empty::before {
        content: "\\f2cb";
    }

    .fa-temperature-0::before,
    i.temperature-0::before {
        content: "\\f2cb";
    }

    .fa-thermometer-0::before,
    i.thermometer-0::before {
        content: "\\f2cb";
    }

    .fa-thermometer-empty::before,
    i.thermometer-empty::before {
        content: "\\f2cb";
    }

    .fa-temperature-full::before,
    i.temperature-full::before {
        content: "\\f2c7";
    }

    .fa-temperature-4::before,
    i.temperature-4::before {
        content: "\\f2c7";
    }

    .fa-thermometer-4::before,
    i.thermometer-4::before {
        content: "\\f2c7";
    }

    .fa-thermometer-full::before,
    i.thermometer-full::before {
        content: "\\f2c7";
    }

    .fa-temperature-half::before,
    i.temperature-half::before {
        content: "\\f2c9";
    }

    .fa-temperature-2::before,
    i.temperature-2::before {
        content: "\\f2c9";
    }

    .fa-thermometer-2::before,
    i.thermometer-2::before {
        content: "\\f2c9";
    }

    .fa-thermometer-half::before,
    i.thermometer-half::before {
        content: "\\f2c9";
    }

    .fa-temperature-high::before,
    i.temperature-high::before {
        content: "\\f769";
    }

    .fa-temperature-list::before,
    i.temperature-list::before {
        content: "\\e299";
    }

    .fa-temperature-low::before,
    i.temperature-low::before {
        content: "\\f76b";
    }

    .fa-temperature-quarter::before,
    i.temperature-quarter::before {
        content: "\\f2ca";
    }

    .fa-temperature-1::before,
    i.temperature-1::before {
        content: "\\f2ca";
    }

    .fa-thermometer-1::before,
    i.thermometer-1::before {
        content: "\\f2ca";
    }

    .fa-thermometer-quarter::before,
    i.thermometer-quarter::before {
        content: "\\f2ca";
    }

    .fa-temperature-snow::before,
    i.temperature-snow::before {
        content: "\\f768";
    }

    .fa-temperature-frigid::before,
    i.temperature-frigid::before {
        content: "\\f768";
    }

    .fa-temperature-sun::before,
    i.temperature-sun::before {
        content: "\\f76a";
    }

    .fa-temperature-hot::before,
    i.temperature-hot::before {
        content: "\\f76a";
    }

    .fa-temperature-three-quarters::before,
    i.temperature-three-quarters::before {
        content: "\\f2c8";
    }

    .fa-temperature-3::before,
    i.temperature-3::before {
        content: "\\f2c8";
    }

    .fa-thermometer-3::before,
    i.thermometer-3::before {
        content: "\\f2c8";
    }

    .fa-thermometer-three-quarters::before,
    i.thermometer-three-quarters::before {
        content: "\\f2c8";
    }

    .fa-tenge-sign::before,
    i.tenge-sign::before {
        content: "\\f7d7";
    }

    .fa-tenge::before,
    i.tenge::before {
        content: "\\f7d7";
    }

    .fa-tennis-ball::before,
    i.tennis-ball::before {
        content: "\\f45e";
    }

    .fa-tent::before,
    i.tent::before {
        content: "\\e57d";
    }

    .fa-tent-arrow-down-to-line::before,
    i.tent-arrow-down-to-line::before {
        content: "\\e57e";
    }

    .fa-tent-arrow-left-right::before,
    i.tent-arrow-left-right::before {
        content: "\\e57f";
    }

    .fa-tent-arrow-turn-left::before,
    i.tent-arrow-turn-left::before {
        content: "\\e580";
    }

    .fa-tent-arrows-down::before,
    i.tent-arrows-down::before {
        content: "\\e581";
    }

    .fa-tents::before,
    i.tents::before {
        content: "\\e582";
    }

    .fa-terminal::before,
    i.terminal::before {
        content: "\\f120";
    }

    .fa-text::before,
    i.text::before {
        content: "\\f893";
    }

    .fa-text-height::before,
    i.text-height::before {
        content: "\\f034";
    }

    .fa-text-size::before,
    i.text-size::before {
        content: "\\f894";
    }

    .fa-text-slash::before,
    i.text-slash::before {
        content: "\\f87d";
    }

    .fa-remove-format::before,
    i.remove-format::before {
        content: "\\f87d";
    }

    .fa-text-width::before,
    i.text-width::before {
        content: "\\f035";
    }

    .fa-thermometer::before,
    i.thermometer::before {
        content: "\\f491";
    }

    .fa-theta::before,
    i.theta::before {
        content: "\\f69e";
    }

    .fa-thought-bubble::before,
    i.thought-bubble::before {
        content: "\\e32e";
    }

    .fa-thumbs-down::before,
    i.thumbs-down::before {
        content: "\\f165";
    }

    .fa-thumbs-up::before,
    i.thumbs-up::before {
        content: "\\f164";
    }

    .fa-thumbtack::before,
    i.thumbtack::before {
        content: "\\f08d";
    }

    .fa-thumb-tack::before,
    i.thumb-tack::before {
        content: "\\f08d";
    }

    .fa-tick::before,
    i.tick::before {
        content: "\\e32f";
    }

    .fa-ticket::before,
    i.ticket::before {
        content: "\\f145";
    }

    .fa-ticket-airline::before,
    i.ticket-airline::before {
        content: "\\e29a";
    }

    .fa-ticket-simple::before,
    i.ticket-simple::before {
        content: "\\f3ff";
    }

    .fa-ticket-alt::before,
    i.ticket-alt::before {
        content: "\\f3ff";
    }

    .fa-tickets-airline::before,
    i.tickets-airline::before {
        content: "\\e29b";
    }

    .fa-tilde::before,
    i.tilde::before {
        content: "\\7e";
    }

    .fa-timeline::before,
    i.timeline::before {
        content: "\\e29c";
    }

    .fa-timeline-arrow::before,
    i.timeline-arrow::before {
        content: "\\e29d";
    }

    .fa-timer::before,
    i.timer::before {
        content: "\\e29e";
    }

    .fa-tire::before,
    i.tire::before {
        content: "\\f631";
    }

    .fa-tire-flat::before,
    i.tire-flat::before {
        content: "\\f632";
    }

    .fa-tire-pressure-warning::before,
    i.tire-pressure-warning::before {
        content: "\\f633";
    }

    .fa-tire-rugged::before,
    i.tire-rugged::before {
        content: "\\f634";
    }

    .fa-toggle-off::before,
    i.toggle-off::before {
        content: "\\f204";
    }

    .fa-toggle-on::before,
    i.toggle-on::before {
        content: "\\f205";
    }

    .fa-toilet::before,
    i.toilet::before {
        content: "\\f7d8";
    }

    .fa-toilet-paper::before,
    i.toilet-paper::before {
        content: "\\f71e";
    }

    .fa-toilet-paper-blank::before,
    i.toilet-paper-blank::before {
        content: "\\f71f";
    }

    .fa-toilet-paper-alt::before,
    i.toilet-paper-alt::before {
        content: "\\f71f";
    }

    .fa-toilet-paper-blank-under::before,
    i.toilet-paper-blank-under::before {
        content: "\\e29f";
    }

    .fa-toilet-paper-reverse-alt::before,
    i.toilet-paper-reverse-alt::before {
        content: "\\e29f";
    }

    .fa-toilet-paper-slash::before,
    i.toilet-paper-slash::before {
        content: "\\e072";
    }

    .fa-toilet-paper-under::before,
    i.toilet-paper-under::before {
        content: "\\e2a0";
    }

    .fa-toilet-paper-reverse::before,
    i.toilet-paper-reverse::before {
        content: "\\e2a0";
    }

    .fa-toilet-paper-under-slash::before,
    i.toilet-paper-under-slash::before {
        content: "\\e2a1";
    }

    .fa-toilet-paper-reverse-slash::before,
    i.toilet-paper-reverse-slash::before {
        content: "\\e2a1";
    }

    .fa-toilet-portable::before,
    i.toilet-portable::before {
        content: "\\e583";
    }

    .fa-toilets-portable::before,
    i.toilets-portable::before {
        content: "\\e584";
    }

    .fa-tomato::before,
    i.tomato::before {
        content: "\\e330";
    }

    .fa-tombstone::before,
    i.tombstone::before {
        content: "\\f720";
    }

    .fa-tombstone-blank::before,
    i.tombstone-blank::before {
        content: "\\f721";
    }

    .fa-tombstone-alt::before,
    i.tombstone-alt::before {
        content: "\\f721";
    }

    .fa-toolbox::before,
    i.toolbox::before {
        content: "\\f552";
    }

    .fa-tooth::before,
    i.tooth::before {
        content: "\\f5c9";
    }

    .fa-toothbrush::before,
    i.toothbrush::before {
        content: "\\f635";
    }

    .fa-torii-gate::before,
    i.torii-gate::before {
        content: "\\f6a1";
    }

    .fa-tornado::before,
    i.tornado::before {
        content: "\\f76f";
    }

    .fa-tower-broadcast::before,
    i.tower-broadcast::before {
        content: "\\f519";
    }

    .fa-broadcast-tower::before,
    i.broadcast-tower::before {
        content: "\\f519";
    }

    .fa-tower-cell::before,
    i.tower-cell::before {
        content: "\\e585";
    }

    .fa-tower-control::before,
    i.tower-control::before {
        content: "\\e2a2";
    }

    .fa-tower-observation::before,
    i.tower-observation::before {
        content: "\\e586";
    }

    .fa-tractor::before,
    i.tractor::before {
        content: "\\f722";
    }

    .fa-trademark::before,
    i.trademark::before {
        content: "\\f25c";
    }

    .fa-traffic-cone::before,
    i.traffic-cone::before {
        content: "\\f636";
    }

    .fa-traffic-light::before,
    i.traffic-light::before {
        content: "\\f637";
    }

    .fa-traffic-light-go::before,
    i.traffic-light-go::before {
        content: "\\f638";
    }

    .fa-traffic-light-slow::before,
    i.traffic-light-slow::before {
        content: "\\f639";
    }

    .fa-traffic-light-stop::before,
    i.traffic-light-stop::before {
        content: "\\f63a";
    }

    .fa-trailer::before,
    i.trailer::before {
        content: "\\e041";
    }

    .fa-train::before,
    i.train::before {
        content: "\\f238";
    }

    .fa-train-subway::before,
    i.train-subway::before {
        content: "\\f239";
    }

    .fa-subway::before,
    i.subway::before {
        content: "\\f239";
    }

    .fa-train-subway-tunnel::before,
    i.train-subway-tunnel::before {
        content: "\\e2a3";
    }

    .fa-subway-tunnel::before,
    i.subway-tunnel::before {
        content: "\\e2a3";
    }

    .fa-train-track::before,
    i.train-track::before {
        content: "\\e453";
    }

    .fa-train-tram::before,
    i.train-tram::before {
        content: "\\f7da";
    }

    .fa-tram::before,
    i.tram::before {
        content: "\\f7da";
    }

    .fa-train-tunnel::before,
    i.train-tunnel::before {
        content: "\\e454";
    }

    .fa-transformer-bolt::before,
    i.transformer-bolt::before {
        content: "\\e2a4";
    }

    .fa-transgender::before,
    i.transgender::before {
        content: "\\f225";
    }

    .fa-transgender-alt::before,
    i.transgender-alt::before {
        content: "\\f225";
    }

    .fa-transporter::before,
    i.transporter::before {
        content: "\\e042";
    }

    .fa-transporter-1::before,
    i.transporter-1::before {
        content: "\\e043";
    }

    .fa-transporter-2::before,
    i.transporter-2::before {
        content: "\\e044";
    }

    .fa-transporter-3::before,
    i.transporter-3::before {
        content: "\\e045";
    }

    .fa-transporter-4::before,
    i.transporter-4::before {
        content: "\\e2a5";
    }

    .fa-transporter-5::before,
    i.transporter-5::before {
        content: "\\e2a6";
    }

    .fa-transporter-6::before,
    i.transporter-6::before {
        content: "\\e2a7";
    }

    .fa-transporter-7::before,
    i.transporter-7::before {
        content: "\\e2a8";
    }

    .fa-transporter-empty::before,
    i.transporter-empty::before {
        content: "\\e046";
    }

    .fa-trash::before,
    i.trash::before {
        content: "\\f1f8";
    }

    .fa-trash-arrow-up::before,
    i.trash-arrow-up::before {
        content: "\\f829";
    }

    .fa-trash-restore::before,
    i.trash-restore::before {
        content: "\\f829";
    }

    .fa-trash-can::before,
    i.trash-can::before {
        content: "\\f2ed";
    }

    .fa-trash-alt::before,
    i.trash-alt::before {
        content: "\\f2ed";
    }

    .fa-trash-can-arrow-up::before,
    i.trash-can-arrow-up::before {
        content: "\\f82a";
    }

    .fa-trash-restore-alt::before,
    i.trash-restore-alt::before {
        content: "\\f82a";
    }

    .fa-trash-can-check::before,
    i.trash-can-check::before {
        content: "\\e2a9";
    }

    .fa-trash-can-clock::before,
    i.trash-can-clock::before {
        content: "\\e2aa";
    }

    .fa-trash-can-list::before,
    i.trash-can-list::before {
        content: "\\e2ab";
    }

    .fa-trash-can-plus::before,
    i.trash-can-plus::before {
        content: "\\e2ac";
    }

    .fa-trash-can-slash::before,
    i.trash-can-slash::before {
        content: "\\e2ad";
    }

    .fa-trash-alt-slash::before,
    i.trash-alt-slash::before {
        content: "\\e2ad";
    }

    .fa-trash-can-undo::before,
    i.trash-can-undo::before {
        content: "\\f896";
    }

    .fa-trash-can-arrow-turn-left::before,
    i.trash-can-arrow-turn-left::before {
        content: "\\f896";
    }

    .fa-trash-undo-alt::before,
    i.trash-undo-alt::before {
        content: "\\f896";
    }

    .fa-trash-can-xmark::before,
    i.trash-can-xmark::before {
        content: "\\e2ae";
    }

    .fa-trash-check::before,
    i.trash-check::before {
        content: "\\e2af";
    }

    .fa-trash-clock::before,
    i.trash-clock::before {
        content: "\\e2b0";
    }

    .fa-trash-list::before,
    i.trash-list::before {
        content: "\\e2b1";
    }

    .fa-trash-plus::before,
    i.trash-plus::before {
        content: "\\e2b2";
    }

    .fa-trash-slash::before,
    i.trash-slash::before {
        content: "\\e2b3";
    }

    .fa-trash-undo::before,
    i.trash-undo::before {
        content: "\\f895";
    }

    .fa-trash-arrow-turn-left::before,
    i.trash-arrow-turn-left::before {
        content: "\\f895";
    }

    .fa-trash-xmark::before,
    i.trash-xmark::before {
        content: "\\e2b4";
    }

    .fa-treasure-chest::before,
    i.treasure-chest::before {
        content: "\\f723";
    }

    .fa-tree::before,
    i.tree::before {
        content: "\\f1bb";
    }

    .fa-tree-christmas::before,
    i.tree-christmas::before {
        content: "\\f7db";
    }

    .fa-tree-city::before,
    i.tree-city::before {
        content: "\\e587";
    }

    .fa-tree-deciduous::before,
    i.tree-deciduous::before {
        content: "\\f400";
    }

    .fa-tree-alt::before,
    i.tree-alt::before {
        content: "\\f400";
    }

    .fa-tree-decorated::before,
    i.tree-decorated::before {
        content: "\\f7dc";
    }

    .fa-tree-large::before,
    i.tree-large::before {
        content: "\\f7dd";
    }

    .fa-tree-palm::before,
    i.tree-palm::before {
        content: "\\f82b";
    }

    .fa-trees::before,
    i.trees::before {
        content: "\\f724";
    }

    .fa-triangle::before,
    i.triangle::before {
        content: "\\f2ec";
    }

    .fa-triangle-exclamation::before,
    i.triangle-exclamation::before {
        content: "\\f071";
    }

    .fa-exclamation-triangle::before,
    i.exclamation-triangle::before {
        content: "\\f071";
    }

    .fa-warning::before,
    i.warning::before {
        content: "\\f071";
    }

    .fa-triangle-instrument::before,
    i.triangle-instrument::before {
        content: "\\f8e2";
    }

    .fa-triangle-music::before,
    i.triangle-music::before {
        content: "\\f8e2";
    }

    .fa-triangle-person-digging::before,
    i.triangle-person-digging::before {
        content: "\\f85d";
    }

    .fa-construction::before,
    i.construction::before {
        content: "\\f85d";
    }

    .fa-trillium::before,
    i.trillium::before {
        content: "\\e588";
    }

    .fa-trophy::before,
    i.trophy::before {
        content: "\\f091";
    }

    .fa-trophy-star::before,
    i.trophy-star::before {
        content: "\\f2eb";
    }

    .fa-trophy-alt::before,
    i.trophy-alt::before {
        content: "\\f2eb";
    }

    .fa-trowel::before,
    i.trowel::before {
        content: "\\e589";
    }

    .fa-trowel-bricks::before,
    i.trowel-bricks::before {
        content: "\\e58a";
    }

    .fa-truck::before,
    i.truck::before {
        content: "\\f0d1";
    }

    .fa-truck-arrow-right::before,
    i.truck-arrow-right::before {
        content: "\\e58b";
    }

    .fa-truck-bolt::before,
    i.truck-bolt::before {
        content: "\\e3d0";
    }

    .fa-truck-clock::before,
    i.truck-clock::before {
        content: "\\f48c";
    }

    .fa-shipping-timed::before,
    i.shipping-timed::before {
        content: "\\f48c";
    }

    .fa-truck-container::before,
    i.truck-container::before {
        content: "\\f4dc";
    }

    .fa-truck-container-empty::before,
    i.truck-container-empty::before {
        content: "\\e2b5";
    }

    .fa-truck-droplet::before,
    i.truck-droplet::before {
        content: "\\e58c";
    }

    .fa-truck-fast::before,
    i.truck-fast::before {
        content: "\\f48b";
    }

    .fa-shipping-fast::before,
    i.shipping-fast::before {
        content: "\\f48b";
    }

    .fa-truck-field::before,
    i.truck-field::before {
        content: "\\e58d";
    }

    .fa-truck-field-un::before,
    i.truck-field-un::before {
        content: "\\e58e";
    }

    .fa-truck-flatbed::before,
    i.truck-flatbed::before {
        content: "\\e2b6";
    }

    .fa-truck-front::before,
    i.truck-front::before {
        content: "\\e2b7";
    }

    .fa-truck-medical::before,
    i.truck-medical::before {
        content: "\\f0f9";
    }

    .fa-ambulance::before,
    i.ambulance::before {
        content: "\\f0f9";
    }

    .fa-truck-monster::before,
    i.truck-monster::before {
        content: "\\f63b";
    }

    .fa-truck-moving::before,
    i.truck-moving::before {
        content: "\\f4df";
    }

    .fa-truck-pickup::before,
    i.truck-pickup::before {
        content: "\\f63c";
    }

    .fa-truck-plane::before,
    i.truck-plane::before {
        content: "\\e58f";
    }

    .fa-truck-plow::before,
    i.truck-plow::before {
        content: "\\f7de";
    }

    .fa-truck-ramp::before,
    i.truck-ramp::before {
        content: "\\f4e0";
    }

    .fa-truck-ramp-box::before,
    i.truck-ramp-box::before {
        content: "\\f4de";
    }

    .fa-truck-loading::before,
    i.truck-loading::before {
        content: "\\f4de";
    }

    .fa-truck-ramp-couch::before,
    i.truck-ramp-couch::before {
        content: "\\f4dd";
    }

    .fa-truck-couch::before,
    i.truck-couch::before {
        content: "\\f4dd";
    }

    .fa-truck-tow::before,
    i.truck-tow::before {
        content: "\\e2b8";
    }

    .fa-trumpet::before,
    i.trumpet::before {
        content: "\\f8e3";
    }

    .fa-tty::before,
    i.tty::before {
        content: "\\f1e4";
    }

    .fa-teletype::before,
    i.teletype::before {
        content: "\\f1e4";
    }

    .fa-tty-answer::before,
    i.tty-answer::before {
        content: "\\e2b9";
    }

    .fa-teletype-answer::before,
    i.teletype-answer::before {
        content: "\\e2b9";
    }

    .fa-tugrik-sign::before,
    i.tugrik-sign::before {
        content: "\\e2ba";
    }

    .fa-turkey::before,
    i.turkey::before {
        content: "\\f725";
    }

    .fa-turkish-lira-sign::before,
    i.turkish-lira-sign::before {
        content: "\\e2bb";
    }

    .fa-try::before,
    i.try::before {
        content: "\\e2bb";
    }

    .fa-turkish-lira::before,
    i.turkish-lira::before {
        content: "\\e2bb";
    }

    .fa-turn-down::before,
    i.turn-down::before {
        content: "\\f3be";
    }

    .fa-level-down-alt::before,
    i.level-down-alt::before {
        content: "\\f3be";
    }

    .fa-turn-down-left::before,
    i.turn-down-left::before {
        content: "\\e331";
    }

    .fa-turn-down-right::before,
    i.turn-down-right::before {
        content: "\\e455";
    }

    .fa-turn-up::before,
    i.turn-up::before {
        content: "\\f3bf";
    }

    .fa-level-up-alt::before,
    i.level-up-alt::before {
        content: "\\f3bf";
    }

    .fa-turntable::before,
    i.turntable::before {
        content: "\\f8e4";
    }

    .fa-turtle::before,
    i.turtle::before {
        content: "\\f726";
    }

    .fa-tv::before,
    i.tv::before {
        content: "\\f26c";
    }

    .fa-television::before,
    i.television::before {
        content: "\\f26c";
    }

    .fa-tv-alt::before,
    i.tv-alt::before {
        content: "\\f26c";
    }

    .fa-tv-music::before,
    i.tv-music::before {
        content: "\\f8e6";
    }

    .fa-tv-retro::before,
    i.tv-retro::before {
        content: "\\f401";
    }

    .fa-typewriter::before,
    i.typewriter::before {
        content: "\\f8e7";
    }

    .fa-u::before,
    i.u::before {
        content: "\\55";
    }

    .fa-ufo::before,
    i.ufo::before {
        content: "\\e047";
    }

    .fa-ufo-beam::before,
    i.ufo-beam::before {
        content: "\\e048";
    }

    .fa-umbrella::before,
    i.umbrella::before {
        content: "\\f0e9";
    }

    .fa-umbrella-beach::before,
    i.umbrella-beach::before {
        content: "\\f5ca";
    }

    .fa-umbrella-simple::before,
    i.umbrella-simple::before {
        content: "\\e2bc";
    }

    .fa-umbrella-alt::before,
    i.umbrella-alt::before {
        content: "\\e2bc";
    }

    .fa-underline::before,
    i.underline::before {
        content: "\\f0cd";
    }

    .fa-unicorn::before,
    i.unicorn::before {
        content: "\\f727";
    }

    .fa-uniform-martial-arts::before,
    i.uniform-martial-arts::before {
        content: "\\e3d1";
    }

    .fa-union::before,
    i.union::before {
        content: "\\f6a2";
    }

    .fa-universal-access::before,
    i.universal-access::before {
        content: "\\f29a";
    }

    .fa-unlock::before,
    i.unlock::before {
        content: "\\f09c";
    }

    .fa-unlock-keyhole::before,
    i.unlock-keyhole::before {
        content: "\\f13e";
    }

    .fa-unlock-alt::before,
    i.unlock-alt::before {
        content: "\\f13e";
    }

    .fa-up::before,
    i.up::before {
        content: "\\f357";
    }

    .fa-arrow-alt-up::before,
    i.arrow-alt-up::before {
        content: "\\f357";
    }

    .fa-up-down::before,
    i.up-down::before {
        content: "\\f338";
    }

    .fa-arrows-alt-v::before,
    i.arrows-alt-v::before {
        content: "\\f338";
    }

    .fa-up-down-left-right::before,
    i.up-down-left-right::before {
        content: "\\f0b2";
    }

    .fa-arrows-alt::before,
    i.arrows-alt::before {
        content: "\\f0b2";
    }

    .fa-up-from-bracket::before,
    i.up-from-bracket::before {
        content: "\\e590";
    }

    .fa-up-from-dotted-line::before,
    i.up-from-dotted-line::before {
        content: "\\e456";
    }

    .fa-up-from-line::before,
    i.up-from-line::before {
        content: "\\f346";
    }

    .fa-arrow-alt-from-bottom::before,
    i.arrow-alt-from-bottom::before {
        content: "\\f346";
    }

    .fa-up-left::before,
    i.up-left::before {
        content: "\\e2bd";
    }

    .fa-up-long::before,
    i.up-long::before {
        content: "\\f30c";
    }

    .fa-long-arrow-alt-up::before,
    i.long-arrow-alt-up::before {
        content: "\\f30c";
    }

    .fa-up-right::before,
    i.up-right::before {
        content: "\\e2be";
    }

    .fa-up-right-and-down-left-from-center::before,
    i.up-right-and-down-left-from-center::before {
        content: "\\f424";
    }

    .fa-expand-alt::before,
    i.expand-alt::before {
        content: "\\f424";
    }

    .fa-up-right-from-square::before,
    i.up-right-from-square::before {
        content: "\\f35d";
    }

    .fa-external-link-alt::before,
    i.external-link-alt::before {
        content: "\\f35d";
    }

    .fa-up-to-dotted-line::before,
    i.up-to-dotted-line::before {
        content: "\\e457";
    }

    .fa-up-to-line::before,
    i.up-to-line::before {
        content: "\\f34d";
    }

    .fa-arrow-alt-to-top::before,
    i.arrow-alt-to-top::before {
        content: "\\f34d";
    }

    .fa-upload::before,
    i.upload::before {
        content: "\\f093";
    }

    .fa-usb-drive::before,
    i.usb-drive::before {
        content: "\\f8e9";
    }

    .fa-user::before,
    i.user::before {
        content: "\\f007";
    }

    .fa-user-alien::before,
    i.user-alien::before {
        content: "\\e04a";
    }

    .fa-user-astronaut::before,
    i.user-astronaut::before {
        content: "\\f4fb";
    }

    .fa-user-bounty-hunter::before,
    i.user-bounty-hunter::before {
        content: "\\e2bf";
    }

    .fa-user-check::before,
    i.user-check::before {
        content: "\\f4fc";
    }

    .fa-user-chef::before,
    i.user-chef::before {
        content: "\\e3d2";
    }

    .fa-user-clock::before,
    i.user-clock::before {
        content: "\\f4fd";
    }

    .fa-user-cowboy::before,
    i.user-cowboy::before {
        content: "\\f8ea";
    }

    .fa-user-crown::before,
    i.user-crown::before {
        content: "\\f6a4";
    }

    .fa-user-doctor::before,
    i.user-doctor::before {
        content: "\\f0f0";
    }

    .fa-user-md::before,
    i.user-md::before {
        content: "\\f0f0";
    }

    .fa-user-doctor-hair::before,
    i.user-doctor-hair::before {
        content: "\\e458";
    }

    .fa-user-doctor-hair-long::before,
    i.user-doctor-hair-long::before {
        content: "\\e459";
    }

    .fa-user-doctor-message::before,
    i.user-doctor-message::before {
        content: "\\f82e";
    }

    .fa-user-md-chat::before,
    i.user-md-chat::before {
        content: "\\f82e";
    }

    .fa-user-gear::before,
    i.user-gear::before {
        content: "\\f4fe";
    }

    .fa-user-cog::before,
    i.user-cog::before {
        content: "\\f4fe";
    }

    .fa-user-graduate::before,
    i.user-graduate::before {
        content: "\\f501";
    }

    .fa-user-group::before,
    i.user-group::before {
        content: "\\f500";
    }

    .fa-user-friends::before,
    i.user-friends::before {
        content: "\\f500";
    }

    .fa-user-group-crown::before,
    i.user-group-crown::before {
        content: "\\f6a5";
    }

    .fa-users-crown::before,
    i.users-crown::before {
        content: "\\f6a5";
    }

    .fa-user-hair::before,
    i.user-hair::before {
        content: "\\e45a";
    }

    .fa-user-hair-buns::before,
    i.user-hair-buns::before {
        content: "\\e3d3";
    }

    .fa-user-hair-long::before,
    i.user-hair-long::before {
        content: "\\e45b";
    }

    .fa-user-hair-mullet::before,
    i.user-hair-mullet::before {
        content: "\\e45c";
    }

    .fa-business-front::before,
    i.business-front::before {
        content: "\\e45c";
    }

    .fa-party-back::before,
    i.party-back::before {
        content: "\\e45c";
    }

    .fa-trian-balbot::before,
    i.trian-balbot::before {
        content: "\\e45c";
    }

    .fa-user-headset::before,
    i.user-headset::before {
        content: "\\f82d";
    }

    .fa-user-helmet-safety::before,
    i.user-helmet-safety::before {
        content: "\\f82c";
    }

    .fa-user-construction::before,
    i.user-construction::before {
        content: "\\f82c";
    }

    .fa-user-hard-hat::before,
    i.user-hard-hat::before {
        content: "\\f82c";
    }

    .fa-user-injured::before,
    i.user-injured::before {
        content: "\\f728";
    }

    .fa-user-large::before,
    i.user-large::before {
        content: "\\f406";
    }

    .fa-user-alt::before,
    i.user-alt::before {
        content: "\\f406";
    }

    .fa-user-large-slash::before,
    i.user-large-slash::before {
        content: "\\f4fa";
    }

    .fa-user-alt-slash::before,
    i.user-alt-slash::before {
        content: "\\f4fa";
    }

    .fa-user-lock::before,
    i.user-lock::before {
        content: "\\f502";
    }

    .fa-user-minus::before,
    i.user-minus::before {
        content: "\\f503";
    }

    .fa-user-music::before,
    i.user-music::before {
        content: "\\f8eb";
    }

    .fa-user-ninja::before,
    i.user-ninja::before {
        content: "\\f504";
    }

    .fa-user-nurse::before,
    i.user-nurse::before {
        content: "\\f82f";
    }

    .fa-user-nurse-hair::before,
    i.user-nurse-hair::before {
        content: "\\e45d";
    }

    .fa-user-nurse-hair-long::before,
    i.user-nurse-hair-long::before {
        content: "\\e45e";
    }

    .fa-user-pen::before,
    i.user-pen::before {
        content: "\\f4ff";
    }

    .fa-user-edit::before,
    i.user-edit::before {
        content: "\\f4ff";
    }

    .fa-user-pilot::before,
    i.user-pilot::before {
        content: "\\e2c0";
    }

    .fa-user-pilot-tie::before,
    i.user-pilot-tie::before {
        content: "\\e2c1";
    }

    .fa-user-plus::before,
    i.user-plus::before {
        content: "\\f234";
    }

    .fa-user-police::before,
    i.user-police::before {
        content: "\\e333";
    }

    .fa-user-police-tie::before,
    i.user-police-tie::before {
        content: "\\e334";
    }

    .fa-user-robot::before,
    i.user-robot::before {
        content: "\\e04b";
    }

    .fa-user-robot-xmarks::before,
    i.user-robot-xmarks::before {
        content: "\\e4a7";
    }

    .fa-user-secret::before,
    i.user-secret::before {
        content: "\\f21b";
    }

    .fa-user-shakespeare::before,
    i.user-shakespeare::before {
        content: "\\e2c2";
    }

    .fa-user-shield::before,
    i.user-shield::before {
        content: "\\f505";
    }

    .fa-user-slash::before,
    i.user-slash::before {
        content: "\\f506";
    }

    .fa-user-tag::before,
    i.user-tag::before {
        content: "\\f507";
    }

    .fa-user-tie::before,
    i.user-tie::before {
        content: "\\f508";
    }

    .fa-user-tie-hair::before,
    i.user-tie-hair::before {
        content: "\\e45f";
    }

    .fa-user-tie-hair-long::before,
    i.user-tie-hair-long::before {
        content: "\\e460";
    }

    .fa-user-unlock::before,
    i.user-unlock::before {
        content: "\\e058";
    }

    .fa-user-visor::before,
    i.user-visor::before {
        content: "\\e04c";
    }

    .fa-user-vneck::before,
    i.user-vneck::before {
        content: "\\e461";
    }

    .fa-user-vneck-hair::before,
    i.user-vneck-hair::before {
        content: "\\e462";
    }

    .fa-user-vneck-hair-long::before,
    i.user-vneck-hair-long::before {
        content: "\\e463";
    }

    .fa-user-xmark::before,
    i.user-xmark::before {
        content: "\\f235";
    }

    .fa-user-times::before,
    i.user-times::before {
        content: "\\f235";
    }

    .fa-users::before,
    i.users::before {
        content: "\\f0c0";
    }

    .fa-users-between-lines::before,
    i.users-between-lines::before {
        content: "\\e591";
    }

    .fa-users-gear::before,
    i.users-gear::before {
        content: "\\f509";
    }

    .fa-users-cog::before,
    i.users-cog::before {
        content: "\\f509";
    }

    .fa-users-line::before,
    i.users-line::before {
        content: "\\e592";
    }

    .fa-users-medical::before,
    i.users-medical::before {
        content: "\\f830";
    }

    .fa-users-rays::before,
    i.users-rays::before {
        content: "\\e593";
    }

    .fa-users-rectangle::before,
    i.users-rectangle::before {
        content: "\\e594";
    }

    .fa-users-slash::before,
    i.users-slash::before {
        content: "\\e073";
    }

    .fa-users-viewfinder::before,
    i.users-viewfinder::before {
        content: "\\e595";
    }

    .fa-utensils::before,
    i.utensils::before {
        content: "\\f2e7";
    }

    .fa-cutlery::before,
    i.cutlery::before {
        content: "\\f2e7";
    }

    .fa-utensils-slash::before,
    i.utensils-slash::before {
        content: "\\e464";
    }

    .fa-utility-pole::before,
    i.utility-pole::before {
        content: "\\e2c3";
    }

    .fa-utility-pole-double::before,
    i.utility-pole-double::before {
        content: "\\e2c4";
    }

    .fa-v::before,
    i.v::before {
        content: "\\56";
    }

    .fa-vacuum::before,
    i.vacuum::before {
        content: "\\e04d";
    }

    .fa-vacuum-robot::before,
    i.vacuum-robot::before {
        content: "\\e04e";
    }

    .fa-value-absolute::before,
    i.value-absolute::before {
        content: "\\f6a6";
    }

    .fa-van-shuttle::before,
    i.van-shuttle::before {
        content: "\\f5b6";
    }

    .fa-shuttle-van::before,
    i.shuttle-van::before {
        content: "\\f5b6";
    }

    .fa-vault::before,
    i.vault::before {
        content: "\\e2c5";
    }

    .fa-vector-circle::before,
    i.vector-circle::before {
        content: "\\e2c6";
    }

    .fa-vector-polygon::before,
    i.vector-polygon::before {
        content: "\\e2c7";
    }

    .fa-vector-square::before,
    i.vector-square::before {
        content: "\\f5cb";
    }

    .fa-vent-damper::before,
    i.vent-damper::before {
        content: "\\e465";
    }

    .fa-venus::before,
    i.venus::before {
        content: "\\f221";
    }

    .fa-venus-double::before,
    i.venus-double::before {
        content: "\\f226";
    }

    .fa-venus-mars::before,
    i.venus-mars::before {
        content: "\\f228";
    }

    .fa-vest::before,
    i.vest::before {
        content: "\\e085";
    }

    .fa-vest-patches::before,
    i.vest-patches::before {
        content: "\\e086";
    }

    .fa-vial::before,
    i.vial::before {
        content: "\\f492";
    }

    .fa-vial-circle-check::before,
    i.vial-circle-check::before {
        content: "\\e596";
    }

    .fa-vial-virus::before,
    i.vial-virus::before {
        content: "\\e597";
    }

    .fa-vials::before,
    i.vials::before {
        content: "\\f493";
    }

    .fa-video::before,
    i.video::before {
        content: "\\f03d";
    }

    .fa-video-camera::before,
    i.video-camera::before {
        content: "\\f03d";
    }

    .fa-video-arrow-down-left::before,
    i.video-arrow-down-left::before {
        content: "\\e2c8";
    }

    .fa-video-arrow-up-right::before,
    i.video-arrow-up-right::before {
        content: "\\e2c9";
    }

    .fa-video-plus::before,
    i.video-plus::before {
        content: "\\f4e1";
    }

    .fa-video-slash::before,
    i.video-slash::before {
        content: "\\f4e2";
    }

    .fa-vihara::before,
    i.vihara::before {
        content: "\\f6a7";
    }

    .fa-violin::before,
    i.violin::before {
        content: "\\f8ed";
    }

    .fa-virus::before,
    i.virus::before {
        content: "\\e074";
    }

    .fa-virus-covid::before,
    i.virus-covid::before {
        content: "\\e4a8";
    }

    .fa-virus-covid-slash::before,
    i.virus-covid-slash::before {
        content: "\\e4a9";
    }

    .fa-virus-slash::before,
    i.virus-slash::before {
        content: "\\e075";
    }

    .fa-viruses::before,
    i.viruses::before {
        content: "\\e076";
    }

    .fa-voicemail::before,
    i.voicemail::before {
        content: "\\f897";
    }

    .fa-volcano::before,
    i.volcano::before {
        content: "\\f770";
    }

    .fa-volleyball::before,
    i.volleyball::before {
        content: "\\f45f";
    }

    .fa-volleyball-ball::before,
    i.volleyball-ball::before {
        content: "\\f45f";
    }

    .fa-volume::before,
    i.volume::before {
        content: "\\f6a8";
    }

    .fa-volume-medium::before,
    i.volume-medium::before {
        content: "\\f6a8";
    }

    .fa-volume-high::before,
    i.volume-high::before {
        content: "\\f028";
    }

    .fa-volume-up::before,
    i.volume-up::before {
        content: "\\f028";
    }

    .fa-volume-low::before,
    i.volume-low::before {
        content: "\\f027";
    }

    .fa-volume-down::before,
    i.volume-down::before {
        content: "\\f027";
    }

    .fa-volume-off::before,
    i.volume-off::before {
        content: "\\f026";
    }

    .fa-volume-slash::before,
    i.volume-slash::before {
        content: "\\f2e2";
    }

    .fa-volume-xmark::before,
    i.volume-xmark::before {
        content: "\\f6a9";
    }

    .fa-volume-mute::before,
    i.volume-mute::before {
        content: "\\f6a9";
    }

    .fa-volume-times::before,
    i.volume-times::before {
        content: "\\f6a9";
    }

    .fa-vr-cardboard::before,
    i.vr-cardboard::before {
        content: "\\f729";
    }

    .fa-w::before,
    i.w::before {
        content: "\\57";
    }

    .fa-waffle::before,
    i.waffle::before {
        content: "\\e466";
    }

    .fa-wagon-covered::before,
    i.wagon-covered::before {
        content: "\\f8ee";
    }

    .fa-walker::before,
    i.walker::before {
        content: "\\f831";
    }

    .fa-walkie-talkie::before,
    i.walkie-talkie::before {
        content: "\\f8ef";
    }

    .fa-wallet::before,
    i.wallet::before {
        content: "\\f555";
    }

    .fa-wand::before,
    i.wand::before {
        content: "\\f72a";
    }

    .fa-wand-magic::before,
    i.wand-magic::before {
        content: "\\f0d0";
    }

    .fa-magic::before,
    i.magic::before {
        content: "\\f0d0";
    }

    .fa-wand-magic-sparkles::before,
    i.wand-magic-sparkles::before {
        content: "\\e2ca";
    }

    .fa-magic-wand-sparkles::before,
    i.magic-wand-sparkles::before {
        content: "\\e2ca";
    }

    .fa-wand-sparkles::before,
    i.wand-sparkles::before {
        content: "\\f72b";
    }

    .fa-warehouse::before,
    i.warehouse::before {
        content: "\\f494";
    }

    .fa-warehouse-full::before,
    i.warehouse-full::before {
        content: "\\f495";
    }

    .fa-warehouse-alt::before,
    i.warehouse-alt::before {
        content: "\\f495";
    }

    .fa-washing-machine::before,
    i.washing-machine::before {
        content: "\\f898";
    }

    .fa-washer::before,
    i.washer::before {
        content: "\\f898";
    }

    .fa-watch::before,
    i.watch::before {
        content: "\\f2e1";
    }

    .fa-watch-apple::before,
    i.watch-apple::before {
        content: "\\e2cb";
    }

    .fa-watch-calculator::before,
    i.watch-calculator::before {
        content: "\\f8f0";
    }

    .fa-watch-fitness::before,
    i.watch-fitness::before {
        content: "\\f63e";
    }

    .fa-watch-smart::before,
    i.watch-smart::before {
        content: "\\e2cc";
    }

    .fa-water::before,
    i.water::before {
        content: "\\f773";
    }

    .fa-water-arrow-down::before,
    i.water-arrow-down::before {
        content: "\\f774";
    }

    .fa-water-lower::before,
    i.water-lower::before {
        content: "\\f774";
    }

    .fa-water-arrow-up::before,
    i.water-arrow-up::before {
        content: "\\f775";
    }

    .fa-water-rise::before,
    i.water-rise::before {
        content: "\\f775";
    }

    .fa-water-ladder::before,
    i.water-ladder::before {
        content: "\\f5c5";
    }

    .fa-ladder-water::before,
    i.ladder-water::before {
        content: "\\f5c5";
    }

    .fa-swimming-pool::before,
    i.swimming-pool::before {
        content: "\\f5c5";
    }

    .fa-watermelon-slice::before,
    i.watermelon-slice::before {
        content: "\\e337";
    }

    .fa-wave-pulse::before,
    i.wave-pulse::before {
        content: "\\f5f8";
    }

    .fa-heart-rate::before,
    i.heart-rate::before {
        content: "\\f5f8";
    }

    .fa-wave-sine::before,
    i.wave-sine::before {
        content: "\\f899";
    }

    .fa-wave-square::before,
    i.wave-square::before {
        content: "\\f83e";
    }

    .fa-wave-triangle::before,
    i.wave-triangle::before {
        content: "\\f89a";
    }

    .fa-waveform::before,
    i.waveform::before {
        content: "\\f8f1";
    }

    .fa-waveform-lines::before,
    i.waveform-lines::before {
        content: "\\f8f2";
    }

    .fa-waveform-path::before,
    i.waveform-path::before {
        content: "\\f8f2";
    }

    .fa-weight-hanging::before,
    i.weight-hanging::before {
        content: "\\f5cd";
    }

    .fa-weight-scale::before,
    i.weight-scale::before {
        content: "\\f496";
    }

    .fa-weight::before,
    i.weight::before {
        content: "\\f496";
    }

    .fa-whale::before,
    i.whale::before {
        content: "\\f72c";
    }

    .fa-wheat::before,
    i.wheat::before {
        content: "\\f72d";
    }

    .fa-wheat-awn::before,
    i.wheat-awn::before {
        content: "\\e2cd";
    }

    .fa-wheat-alt::before,
    i.wheat-alt::before {
        content: "\\e2cd";
    }

    .fa-wheat-awn-circle-exclamation::before,
    i.wheat-awn-circle-exclamation::before {
        content: "\\e598";
    }

    .fa-wheat-awn-slash::before,
    i.wheat-awn-slash::before {
        content: "\\e338";
    }

    .fa-wheat-slash::before,
    i.wheat-slash::before {
        content: "\\e339";
    }

    .fa-wheelchair::before,
    i.wheelchair::before {
        content: "\\f193";
    }

    .fa-wheelchair-move::before,
    i.wheelchair-move::before {
        content: "\\e2ce";
    }

    .fa-wheelchair-alt::before,
    i.wheelchair-alt::before {
        content: "\\e2ce";
    }

    .fa-whiskey-glass::before,
    i.whiskey-glass::before {
        content: "\\f7a0";
    }

    .fa-glass-whiskey::before,
    i.glass-whiskey::before {
        content: "\\f7a0";
    }

    .fa-whiskey-glass-ice::before,
    i.whiskey-glass-ice::before {
        content: "\\f7a1";
    }

    .fa-glass-whiskey-rocks::before,
    i.glass-whiskey-rocks::before {
        content: "\\f7a1";
    }

    .fa-whistle::before,
    i.whistle::before {
        content: "\\f460";
    }

    .fa-wifi::before,
    i.wifi::before {
        content: "\\f1eb";
    }

    .fa-wifi-3::before,
    i.wifi-3::before {
        content: "\\f1eb";
    }

    .fa-wifi-strong::before,
    i.wifi-strong::before {
        content: "\\f1eb";
    }

    .fa-wifi-exclamation::before,
    i.wifi-exclamation::before {
        content: "\\e2cf";
    }

    .fa-wifi-fair::before,
    i.wifi-fair::before {
        content: "\\f6ab";
    }

    .fa-wifi-2::before,
    i.wifi-2::before {
        content: "\\f6ab";
    }

    .fa-wifi-slash::before,
    i.wifi-slash::before {
        content: "\\f6ac";
    }

    .fa-wifi-weak::before,
    i.wifi-weak::before {
        content: "\\f6aa";
    }

    .fa-wifi-1::before,
    i.wifi-1::before {
        content: "\\f6aa";
    }

    .fa-wind::before,
    i.wind::before {
        content: "\\f72e";
    }

    .fa-wind-turbine::before,
    i.wind-turbine::before {
        content: "\\f89b";
    }

    .fa-wind-warning::before,
    i.wind-warning::before {
        content: "\\f776";
    }

    .fa-wind-circle-exclamation::before,
    i.wind-circle-exclamation::before {
        content: "\\f776";
    }

    .fa-window::before,
    i.window::before {
        content: "\\f40e";
    }

    .fa-window-flip::before,
    i.window-flip::before {
        content: "\\f40f";
    }

    .fa-window-alt::before,
    i.window-alt::before {
        content: "\\f40f";
    }

    .fa-window-frame::before,
    i.window-frame::before {
        content: "\\e04f";
    }

    .fa-window-frame-open::before,
    i.window-frame-open::before {
        content: "\\e050";
    }

    .fa-window-maximize::before,
    i.window-maximize::before {
        content: "\\f2d0";
    }

    .fa-window-minimize::before,
    i.window-minimize::before {
        content: "\\f2d1";
    }

    .fa-window-restore::before,
    i.window-restore::before {
        content: "\\f2d2";
    }

    .fa-windsock::before,
    i.windsock::before {
        content: "\\f777";
    }

    .fa-wine-bottle::before,
    i.wine-bottle::before {
        content: "\\f72f";
    }

    .fa-wine-glass::before,
    i.wine-glass::before {
        content: "\\f4e3";
    }

    .fa-wine-glass-crack::before,
    i.wine-glass-crack::before {
        content: "\\f4bb";
    }

    .fa-fragile::before,
    i.fragile::before {
        content: "\\f4bb";
    }

    .fa-wine-glass-empty::before,
    i.wine-glass-empty::before {
        content: "\\f5ce";
    }

    .fa-wine-glass-alt::before,
    i.wine-glass-alt::before {
        content: "\\f5ce";
    }

    .fa-won-sign::before,
    i.won-sign::before {
        content: "\\f159";
    }

    .fa-krw::before,
    i.krw::before {
        content: "\\f159";
    }

    .fa-won::before,
    i.won::before {
        content: "\\f159";
    }

    .fa-worm::before,
    i.worm::before {
        content: "\\e599";
    }

    .fa-wreath::before,
    i.wreath::before {
        content: "\\f7e2";
    }

    .fa-wrench::before,
    i.wrench::before {
        content: "\\f0ad";
    }

    .fa-wrench-simple::before,
    i.wrench-simple::before {
        content: "\\e2d1";
    }

    .fa-x::before,
    i.x::before {
        content: "\\58";
    }

    .fa-x-ray::before,
    i.x-ray::before {
        content: "\\f497";
    }

    .fa-xmark::before,
    i.xmark::before {
        content: "\\f00d";
    }

    .fa-close::before,
    i.close::before {
        content: "\\f00d";
    }

    .fa-multiply::before,
    i.multiply::before {
        content: "\\f00d";
    }

    .fa-remove::before,
    i.remove::before {
        content: "\\f00d";
    }

    .fa-times::before,
    i.times::before {
        content: "\\f00d";
    }

    .fa-xmark-large::before,
    i.xmark-large::before {
        content: "\\e59b";
    }

    .fa-xmark-to-slot::before,
    i.xmark-to-slot::before {
        content: "\\f771";
    }

    .fa-times-to-slot::before,
    i.times-to-slot::before {
        content: "\\f771";
    }

    .fa-vote-nay::before,
    i.vote-nay::before {
        content: "\\f771";
    }

    .fa-xmarks-lines::before,
    i.xmarks-lines::before {
        content: "\\e59a";
    }

    .fa-y::before,
    i.y::before {
        content: "\\59";
    }

    .fa-yen-sign::before,
    i.yen-sign::before {
        content: "\\f157";
    }

    .fa-cny::before,
    i.cny::before {
        content: "\\f157";
    }

    .fa-jpy::before,
    i.jpy::before {
        content: "\\f157";
    }

    .fa-rmb::before,
    i.rmb::before {
        content: "\\f157";
    }

    .fa-yen::before,
    i.yen::before {
        content: "\\f157";
    }

    .fa-yin-yang::before,
    i.yin-yang::before {
        content: "\\f6ad";
    }

    .fa-z::before,
    i.z::before {
        content: "\\5a";
    }
`;
