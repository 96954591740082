import { App } from "@pentacode/core/src/app";
import { AjaxSender } from "./lib/ajax";
import { LocalStorage } from "./lib/storage";
import { Router } from "./lib/router";
import { WebCryptoProvider } from "./lib/crypto";

// @ts-ignore
export const app = (window.app = new App(
    new LocalStorage(),
    new AjaxSender(process.env.PTC_SERVER_URL!),
    new WebCryptoProvider()
));

// @ts-ignore
export const router = (window.router = new Router());
