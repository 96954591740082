import { DateString, Hours } from "@pentacode/openapi/src/units";
import { DateRange } from "./time";
import { Company, Department, Employee, Position } from "./model";
import { PentacodeAPIModels } from "./rest/api";

export function wait(dt: number): Promise<void> {
    return new Promise<void>((resolve) => setTimeout(resolve, dt));
}

// taken from https://github.com/EFForg/OpenWireless/blob/master/app/js/diceware.js
export async function randomNumber(
    min: number = 0,
    max: number = 10,
    rand: (n: number) => Promise<Uint8Array>
): Promise<number> {
    let rval = 0;
    const range = max - min + 1;
    const bitsNeeded = Math.ceil(Math.log2(range));
    if (bitsNeeded > 53) {
        throw new Error("We cannot generate numbers larger than 53 bits.");
    }

    const bytesNeeded = Math.ceil(bitsNeeded / 8);
    const mask = Math.pow(2, bitsNeeded) - 1;

    // Fill a byte array with N random numbers
    const byteArray = await rand(bytesNeeded);

    let p = (bytesNeeded - 1) * 8;
    for (let i = 0; i < bytesNeeded; i++) {
        rval += byteArray[i] * Math.pow(2, p);
        p -= 8;
    }

    // Use & to apply the mask and reduce the number of recursive lookups
    // tslint:disable-next-line
    rval = rval & mask;

    if (rval >= range) {
        // Integer out of acceptable range
        return randomNumber(min, max, rand);
    }

    // Return an integer that falls within the range
    return min + rval;
}

export function parseTime(date: Date | string | null, time: string | null | undefined): Date | null {
    if (!date || !time) {
        return null;
    }
    if (typeof date === "string") {
        date = parseDateString(date);
    }
    if (!date) {
        return null;
    }
    const [hourString, minuteString] = time.split(":");
    const hours = parseInt(hourString);
    const minutes = parseInt(minuteString);

    date = new Date(date);
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
}

export function parseTimes(
    date: DateString,
    start: string | null | undefined,
    end: string | null | undefined
): [Date | null, Date | null] {
    const startDate = parseTime(date, start);
    const endDate = parseTime(
        end && start && start.slice(0, 5) > end.slice(0, 5) ? dateAdd(date, { days: 1 }) : date,
        end
    );
    return [startDate, endDate];
}

export function toTimeString(date: Date | string | null | undefined) {
    if (!date) {
        return "";
    }

    if (typeof date === "string") {
        date = new Date(date);
    }

    return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
}

export function toDurationString(dur?: number | Hours, trimLeadingZero = false) {
    if (!dur) {
        return trimLeadingZero ? "0:00" : "00:00";
    }

    const absDur = Math.abs(dur);

    const minutes = Math.round(absDur * 60);

    let hours = Math.floor(minutes / 60).toString();

    if (!trimLeadingZero) {
        hours = hours.padStart(2, "0");
    }

    const min = (minutes % 60).toString().padStart(2, "0");
    return `${dur < 0 ? "-" : ""}${hours}:${min}`;
}

export function parseDurationString(str: string): Hours {
    if (!str) {
        return 0 as Hours;
    }
    const [hours, minutes] = str.split(":");
    return (parseInt(hours) + parseInt(minutes) / 60) as Hours;
}

export function toDateString(date: Date): DateString {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}` as DateString;
}

export function parseDateString(str: string) {
    const date = new Date(str);

    // invalid date
    if (isNaN(date.getTime())) {
        return null;
    }

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    return date;
}

export function getMonday(inp: DateString): DateString {
    const date = parseDateString(inp)!;
    date.setDate(date.getDate() - ((date.getDay() + 6) % 7));
    return toDateString(date);
}

export function getSunday(inp: DateString): DateString {
    const date = parseDateString(inp)!;
    date.setDate(date.getDate() + ((7 - date.getDay()) % 7));
    return toDateString(date);
}

export function getYearRange(inp: DateString): DateRange {
    const date = parseDateString(inp)!;
    const from = new Date(date.getFullYear(), 0, 1);
    const to = new Date(date.getFullYear() + 1, 0, 1);
    return { from: toDateString(from), to: toDateString(to) };
}

export function getMonthRange(inp: DateString): DateRange {
    const date = parseDateString(inp)!;
    const from = new Date(date.getFullYear(), date.getMonth(), 1);
    const to = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    return { from: toDateString(from), to: toDateString(to) };
}

export function getWeekRange(inp: DateString): DateRange {
    const from = getMonday(inp);
    return {
        from,
        to: dateAdd(from, { days: 7 }),
    };
}

export function getDayRange(inp: DateString): DateRange {
    return { from: inp, to: dateAdd(inp, { days: 1 }) };
}

export function getRange(inp: DateString | Date, period: "year" | "month" | "week" | "day"): DateRange {
    if (inp instanceof Date) {
        inp = toDateString(inp);
    }

    switch (period) {
        case "year":
            return getYearRange(inp);
        case "month":
            return getMonthRange(inp);
        case "week":
            return getWeekRange(inp);
        case "day":
            return getDayRange(inp);
    }
}

export function getCurrentMonthRange() {
    return getRange(new Date(), "month");
}

export function dateAdd(
    inp: DateString,
    { years = 0, months = 0, days = 0, weeks = 0 }: { years?: number; months?: number; days?: number; weeks?: number }
): DateString {
    const date = parseDateString(inp)!;
    if (!date) {
        throw new Error(`${inp} is not a valid date string!`);
    }

    if (weeks) {
        days += weeks * 7;
    }

    // Check number of days in the target month
    const daysInTargetMonth = new Date(date.getFullYear() + years, date.getMonth() + months + 1, 0).getDate();

    date.setFullYear(date.getFullYear() + years);
    // If target month has fewer days that current date, set the date to the maximum date in that month
    if (date.getDate() > daysInTargetMonth) {
        date.setDate(daysInTargetMonth);
    }
    date.setMonth(date.getMonth() + months);
    date.setDate(date.getDate() + days);
    return toDateString(date);
}

// Get difference in days
export function dateSub(start: DateString, end: DateString) {
    return Math.round((parseDateString(end)!.getTime() - parseDateString(start)!.getTime()) / 24 / 60 / 60 / 1000);
}

export function getDateArray(from: DateString, to: DateString) {
    const res: DateString[] = [];
    while (from < to) {
        res.push(from);
        from = dateAdd(from, { days: 1 });
    }
    return res;
}

export const monthNames = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
];

/**
 * "Debounces" a function, making sure it is only called once within a certain
 * time window
 */
export function debounce(
    fn: (...args: any[]) => any,
    delay: number,
    keyFn: (...args: any[]) => string | number = () => 0
) {
    const timeouts = new Map<string | number, number>();

    return function (...args: any[]) {
        const key = keyFn(...args);
        const timeout = timeouts.get(key);
        clearTimeout(timeout);
        timeouts.set(
            key,
            window.setTimeout(() => fn(...args), delay)
        );
    };
}

export function compare(a: any, b: any, desc = false) {
    const sign = desc ? -1 : 1;
    return sign * (a < b ? -1 : a > b ? 1 : 0);
}

export function compareProps(a: Record<string, any>, b: Record<string, any>, props: string | string[], desc = false) {
    props = Array.isArray(props) ? props : [props];

    let result = 0;

    while (!result && props.length) {
        const p = props.shift()!;
        result = compare(a[p], b[p], desc);
    }

    return result;
}

export function comparePropsFn(props: string | string[], desc = false) {
    return (a: object, b: object) => compareProps(a, b, props, desc);
}

export function formatDate(date: Date | string, fullYear = true) {
    const d = typeof date === "string" ? parseDateString(date)! : date;
    if (!d) {
        return "Fehlerhaftes Datum";
    }
    return `${d.getDate().toString().padStart(2, "0")}.${(d.getMonth() + 1).toString().padStart(2, "0")}.${
        fullYear ? d.getFullYear() : d.getFullYear().toString().slice(-2)
    }`;
}

export function formatDateShort(date: Date | string, numeric = false, includeYear = false) {
    const d = typeof date === "string" ? parseDateString(date)! : date;
    if (!d) {
        return "Fehlerhaftes Datum";
    }
    let str = numeric
        ? `${d.getDate()}.${d.getMonth() + 1}.`
        : `${d.getDate()}. ${monthNames[d.getMonth()].slice(0, 3)}`;
    if (includeYear) {
        str += " " + d.getFullYear().toString().slice(-2);
    }

    return str;
}

export function formatWeekDayShort(date: Date | string) {
    const d = typeof date === "string" ? parseDateString(date)! : date;
    return ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"][d.getDay()];
}

export function formatWeekDay(date: Date | string) {
    const d = typeof date === "string" ? parseDateString(date)! : date;
    return ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"][d.getDay()];
}

export function formatMonth(date: Date | string) {
    const d = typeof date === "string" ? parseDateString(date)! : date;
    return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
}

export function formatMonthShort(date: Date | string) {
    const d = typeof date === "string" ? parseDateString(date)! : date;
    return `${monthNames[d.getMonth()].slice(0, 3)} ${d.getFullYear()}`;
}

export function formatNumber(val: number, decimals = 2, thousandsSeparator = true): string {
    if (typeof val !== "number") {
        return formatNumber(0, decimals);
    }

    let res = val.toFixed(decimals).replace(".", ",");

    if (thousandsSeparator) {
        res = res.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    return res;
}

export function throttle(fn: (...args: any[]) => any, delay: number) {
    let lastCall: any;
    let lastRan: number;
    return (...args: any[]) => {
        if (!lastRan) {
            fn(...args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastCall);
            lastCall = setTimeout(
                () => {
                    if (Date.now() - lastRan >= delay) {
                        fn(...args);
                        lastRan = Date.now();
                    }
                },
                delay - (Date.now() - lastRan)
            );
        }
    };
}

export function sum(items: any, prop?: string): number {
    return items.reduce((total: number, item: any) => (total + (prop ? item[prop] : item)) as number, 0);
}

export function round(val: number, decimals = 2) {
    const factor = Math.pow(10, decimals);
    return Math.round(val * factor) / factor;
}

export function getPath(obj: any, path: string): any {
    const [prop, ...rest] = path.split(".");
    const sub = obj[prop];
    return sub && rest.length ? getPath(sub, rest.join(".")) : sub;
}

export function setPath(obj: any, path: string, value: any) {
    const [prop, ...rest] = path.split(".");
    let sub = obj[prop];

    if (rest.length) {
        if (!sub) {
            sub = obj[prop] = {};
        }
        setPath(sub, rest.join("."), value);
    } else {
        obj[path] = value;
    }
}

export function formatTimeDistance(date: string | Date) {
    date = new Date(date);
    const seconds = (Date.now() - date.getTime()) / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const prefix = seconds < 0 ? "in" : "vor";

    if (Math.abs(days) > 2) {
        return `${prefix} ${Math.abs(Math.floor(days))} Tagen`;
    } else if (Math.abs(hours) > 2) {
        return `${prefix} ${Math.abs(Math.floor(hours))} Std`;
    } else {
        return `${prefix} ${Math.abs(Math.floor(minutes))} Min`;
    }
}

export function intersect<T>(...arrs: T[][]): T[] {
    let intersection = arrs.pop() || [];

    while (arrs.length) {
        const compare = arrs.pop()!;
        intersection = intersection.filter((each) => compare.includes(each));
    }

    return intersection;
}

/**
 *
 * Get week number based on the [ISO week date system](https://en.wikipedia.org/wiki/ISO_week_date),
 * where each week starts with monday and the first week of the year
 * is the week that contains the first Thursday of the year.
 */
export function getWeekNumber(date: Date | DateString): number {
    const weekRange = getRange(date, "week");
    const thursday = dateAdd(weekRange.from, { days: 3 });
    const d = new Date(thursday);

    const firstDayOfYear = new Date(d.getFullYear(), 0, 1);
    const pastDaysOfYear = (d.valueOf() - firstDayOfYear.valueOf()) / 86400000;

    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

export function getDayOfWeek(date: string | Date): number {
    const d = date instanceof Date ? date : parseDateString(date);
    return d?.getDay() || 0;
}

export function truncate(str: string, len: number) {
    return str.length > len ? str.slice(0, len) + "…" : str;
}

export function toDOSLineEndings(val: string) {
    return val.replace(/\n/g, "\r\n");
}

export function switchDate(d: Date, date: string) {
    d = new Date(d);
    const target = new Date(date);
    d.setFullYear(target.getFullYear());
    d.setMonth(target.getMonth());
    d.setDate(target.getDate());
    return d;
}

/**
 * Generates a random UUID v4
 * NOT CRYPTOGRAPHICALLY SAFE!
 */
export function unsafeUUID(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function capitalize(str: string) {
    return str
        .split(" ")
        .map((s) => `${s[0]?.toUpperCase() || ""}${s.slice(1)}`)
        .join(" ");
}

export function pathToPascalCase(path: string) {
    return path
        .split("/")
        .filter(Boolean) // remove empty strings from consecutive '/'
        .map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1))
        .join("");
}

export function isValidDateString(str: string) {
    try {
        return toDateString(new Date(str)) === str;
    } catch {
        return false;
    }
}

export function stripPropertiesRecursive(obj: any, properties: string[]) {
    for (const [key, value] of Object.entries(obj)) {
        if (properties.includes(key)) {
            delete obj[key];
            continue;
        }
        if (typeof value === "object") {
            stripPropertiesRecursive(value, properties);
        }
    }
    return obj;
}

export function inferRangeType({ from, to }: DateRange) {
    for (const type of ["month", "year", "week", "day"] as const) {
        const range = getRange(from, type);
        if (range.from === from && range.to === to) {
            return type;
        }
    }

    return "custom";
}

export function formatRange(
    range: DateRange,
    rangeType: "year" | "month" | "week" | "day" | "custom" = inferRangeType(range)
) {
    const date = parseDateString(range.from)!;
    switch (rangeType) {
        case "year":
            return date.getFullYear().toString();
        case "month":
            return formatMonth(date);
        case "day":
            return formatDate(date);
        case "week":
            return `KW ${getWeekNumber(range.from)}, ${new Date(range.from).getFullYear()} (${formatDateShort(
                range.from,
                false
            )} - ${formatDateShort(dateAdd(range.to, { days: -1 }), false)})`;
        default:
            const from = range.from;
            const to = dateAdd(range.to, { days: -1 });
            return `${formatWeekDayShort(from)}, ${formatDate(from)} - ${formatWeekDayShort(to)}, ${formatDate(to)}`;
    }
}

export function displayDistinctWorkarea(company: Company, employee: Employee, position: Position) {
    const { venue, department } = company.getDepartment(position.departmentId);
    if (!venue || !department) {
        return position.name;
    }

    const isPositionNameUnique = !employee.positions.some(
        (p) => p.id !== position.id && p.name.trim() === position.name.trim()
    );

    if (isPositionNameUnique) {
        return position.name;
    }

    const isDepartmentNameUnique = !employee.positions.some(
        (pos) =>
            pos.id !== position.id &&
            company.getDepartment(pos.departmentId).department?.name.trim() === department.name.trim()
    );

    if (isDepartmentNameUnique || company.venues.length < 2) {
        return `${department.name} / ${position.name}`;
    }

    return `${venue.name} / ${department.name} / ${position.name}`;
}

export function hasConflictingEmployee(
    company: Company,
    employee: Pick<PentacodeAPIModels["Employee"], "email" | "timeLogPin" | "staffNumber">
) {
    const { email, timeLogPin, staffNumber } = employee;
    if (email || timeLogPin || staffNumber) {
        return company.employees.some(
            (emp) =>
                (email && email === emp.email) ||
                (staffNumber && Number(staffNumber) === emp.staffNumber) ||
                (timeLogPin && timeLogPin === emp.timePin)
        );
    }
    return false;
}

export function getEmployeeOrderInDepartment(emp: Employee, dep: Department) {
    const posOrder = Math.min(...emp.positions.filter((p) => p.departmentId === dep.id).map((p) => p.order));
    const order = dep.rosterOrder.indexOf(emp.id.toString());
    return order === -1 ? dep.rosterOrder.length + posOrder : order;
}

export function isAdult(dateOfBirth: Date, now: Date) {
    const eighteenYearsAgo = new Date(now.toUTCString());
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

    return dateOfBirth <= eighteenYearsAgo;
}

export function escapeXML(str: string) {
    return str.replace(/&/g, '&amp;')
              .replace(/</g, '&lt;')
              .replace(/>/g, '&gt;')
              .replace(/"/g, '&quot;')
              .replace(/'/g, '&apos;');
}