import {
    IsString,
    IsInt,
    IsNumber,
    IsNotEmpty,
    IsEnum,
    IsOptional,
    IsBoolean,
    ValidateNested,
    IsDateString,
} from "class-validator";
import { Type } from "class-transformer";
import {
    Account,
    Company,
    Session,
    Venue,
    Department,
    Employee,
    Contract,
    TimeEntry,
    TimeEntryType,
    PublishedRoster,
    CompanySettings,
    RosterTemplate,
    RevenueEntry,
    RevenueType,
    RevenueGroup,
    RevenuePrediction,
    Feature,
    TimeSettings,
    RosterTargets,
    RosterTab,
    NotificationSettings,
    Absence,
    AbsenceStatus,
    Role,
    Invite,
    RosterNote,
    CompanyLifeCycleEvent,
    CompanyStatus,
    Availability,
    Document,
    DocumentTag,
    JobPosting,
    EmploymentType,
    EmployeeStatus,
    Form,
    MimeType,
    FormStatus,
    BonusType,
    BenefitType,
    CostCenter,
    EntityFilter,
    TimeLogDevice,
    TimeLogEvent,
    Access,
    Balances,
    ShiftTemplate,
    DailyResults,
    TimeBalance,
    BonusesBalance,
    VacationBalance,
    WageType,
    EmployeeTag,
    BillingMode,
    CompanyListing,
} from "./model";
import { Issue } from "./issues";
import { MonthlyStatement } from "./statement";
import { Invoice } from "./invoice";
import { EmployeeSortDirection, EmployeeSortProperty } from "./filters";
import { DateString, Days, Euros, Hours } from "@pentacode/openapi/src/units";
import { parseTimes, toTimeString } from "./util";
import { HogastUnionId } from "./hogast";
import { Country, StateCode } from "./localized";

abstract class DTO<T> {
    constructor(props?: Partial<T>) {
        if (props) {
            Object.assign(this, props);
        }
    }
}

export type Constructor = new (...args: any[]) => any;

interface HandlerDefinition<In extends Constructor = Constructor, Out extends Constructor = Constructor> {
    method: string;
    input?: In;
    output?: Out;
    transforms?: {
        clientVersionBefore: string;
        minVersion?: string;
        transformInput?: (val: any) => any;
        transformOutput?: (val: any) => any;
    }[];
}

/**
 * Decorator for defining request handler methods
 */
function Handler<In extends Constructor = Constructor, Out extends Constructor = Constructor>(
    input: In | undefined,
    output: Out | undefined,
    { transforms }: { transforms?: HandlerDefinition<In, Out>["transforms"] } = {}
) {
    return (proto: API, method: string) => {
        if (!proto.handlerDefinitions) {
            proto.handlerDefinitions = [];
        }
        proto.handlerDefinitions.push({
            method,
            input,
            output,
            transforms,
        });
    };
}

export class UpdateAccountParams extends DTO<UpdateAccountParams> {
    id?: number;

    @IsString()
    @IsOptional()
    email?: string;

    @IsString()
    @IsOptional()
    password?: string;

    @IsString()
    @IsNotEmpty()
    @IsOptional()
    firstName?: string;

    @IsString()
    @IsNotEmpty()
    @IsOptional()
    lastName?: string;

    profile?: {
        avatar?: string;

        shiftTemplates?: ShiftTemplate[];

        rosterTabs?: RosterTab[];

        notifications?: NotificationSettings;
    };
}

export class LoginParams extends DTO<LoginParams> {
    scope: string;

    email?: string;
    password?: string;

    company?: number;
    tempAuthToken?: string;
    pin?: string;

    employee?: number;
    loginToken?: string;
    timestamp?: number;
}

export class RevokeSessionParams extends DTO<RevokeSessionParams> {
    @IsInt()
    id: number;
}

export class UpdateBillingParams extends DTO<UpdateBillingParams> {
    addPaymentMethod?: string;
    removePaymentMethod?: string;
    defaultPaymentMethod?: string;

    @IsString()
    @IsOptional()
    name?: string;

    @IsString()
    @IsOptional()
    addressLine1?: string;

    @IsString()
    @IsOptional()
    addressLine2?: string;

    @IsString()
    @IsOptional()
    postalCode?: string;

    @IsString()
    @IsOptional()
    city?: string;

    @IsString()
    @IsOptional()
    phone?: string;

    @IsString()
    @IsOptional()
    email?: string;

    @IsString()
    @IsOptional()
    comments?: string;

    vatId?: string;

    billingMode?: BillingMode;

    hogastUnionId?: HogastUnionId | null;

    hogastMemberId?: string | null;
}

export class GetCompanyParams extends DTO<GetCompanyParams> {
    id: number;
    includeEvents: boolean;
    forceReload?: boolean;
}

export class UpdateCompanyParams extends DTO<UpdateCompanyParams> {
    id?: number;

    @IsString()
    @IsOptional()
    name?: string;

    @IsString()
    @IsOptional()
    address?: string;

    @IsString()
    @IsOptional()
    postalCode?: string;

    @IsString()
    @IsOptional()
    city?: string;

    @IsString()
    @IsOptional()
    phone?: string;

    @IsString()
    @IsOptional()
    email?: string;

    settings?: CompanySettings;

    @IsString({ each: true })
    @IsOptional()
    features?: Feature[];

    @Type(() => UpdateBillingParams)
    billing?: UpdateBillingParams;

    @IsString()
    @IsOptional()
    comments?: string;

    clearCache?: boolean;

    @Type(() => CompanyLifeCycleEvent)
    newLifeCycleEvent?: CompanyLifeCycleEvent;

    @Type(() => DocumentTag)
    documentTags?: DocumentTag[];

    @Type(() => EmployeeTag)
    employeeTags?: EmployeeTag[];

    @Type(() => BonusType)
    bonusTypes?: BonusType[];

    @Type(() => BenefitType)
    benefitTypes?: BenefitType[];

    costCenters?: CostCenter[];

    wageTypes?: WageType[];

    deleteLifeCycleEvent?: number;
}

export class CreateVenueParams extends DTO<CreateVenueParams> {
    @IsString()
    @IsNotEmpty()
    name: string;

    @IsString()
    @IsNotEmpty()
    address: string;

    @IsString()
    @IsNotEmpty()
    city: string;

    @IsString()
    @IsNotEmpty()
    postalCode: string;

    @IsString()
    @IsNotEmpty()
    state: StateCode;

    timeSettingsId: number | null;

    @IsOptional()
    @IsNumber()
    venueNumber: number | null;
}

export class UpdateVenueParams extends DTO<UpdateVenueParams> {
    @IsInt()
    id: number;

    @IsString()
    @IsOptional()
    name?: string;

    @IsString()
    @IsOptional()
    address?: string;

    @IsString()
    @IsOptional()
    city?: string;

    @IsString()
    @IsOptional()
    postalCode?: string;

    @IsString()
    @IsOptional()
    state?: StateCode;

    order?: number;

    rosterTemplates?: RosterTemplate[];

    timeSettingsId?: number | null;

    company?: number;

    @IsOptional()
    @IsNumber()
    venueNumber?: number | null;
}

export class CreateCompanyParams extends DTO<CreateCompanyParams> {
    @IsString()
    name: string;

    @IsString()
    country: Country;

    @IsString()
    address: string;

    @IsString()
    city: string;

    @IsString()
    postalCode: string;

    @IsString()
    email: string;

    @IsString()
    phone: string;

    status?: CompanyStatus;

    owner: {
        email: string;
        password: string;
        firstName: string;
        lastName: string;
    };

    @Type(() => CreateVenueParams)
    venue: CreateVenueParams;
}

export class CreateDepartmentParams extends DTO<CreateDepartmentParams> {
    @IsInt()
    venue: number;

    @IsString()
    @IsNotEmpty()
    name: string;

    color: string;

    timeSettingsId: number | null;

    positions: { name: string; color: string }[];

    bonusesAreTaxed: boolean;
}

export class UpdateDepartmentParams extends DTO<UpdateDepartmentParams> {
    @IsInt()
    id: number;

    @IsString()
    @IsOptional()
    name?: string;

    color?: string;

    positions?: {
        id?: number;
        name: string;
        color: string;
    }[];

    @IsInt()
    @IsOptional()
    order?: number;

    @IsString({ each: true })
    @IsOptional()
    rosterOrder?: string[];

    timeSettingsId?: number | null;

    bonusesAreTaxed?: boolean;
}

export class CreateEmployeeParams {
    constructor(vals: Partial<CreateEmployeeParams>) {
        Object.assign(this, vals);
    }

    @IsString()
    @IsNotEmpty()
    firstName: string = "";

    @IsString()
    @IsNotEmpty()
    lastName: string = "";

    @IsString()
    callName: string = "";

    @IsDateString()
    @IsOptional()
    birthday: DateString | null;

    @IsString()
    address: string = "";

    @IsString()
    city: string = "";

    @IsString()
    postalCode: string = "";

    @IsString()
    email: string = "";

    @IsString()
    phone: string = "";

    @IsString()
    avatar: string = "";

    @IsString()
    pin: string = "";

    staffNumber: number | null = null;

    @IsNumber()
    autoMeal: number = 0;

    @IsInt({ each: true })
    positions: number[] = [];

    @ValidateNested()
    @Type(() => Contract)
    contract: Contract;

    @IsOptional()
    access?: Access;

    @IsNumber()
    initialHours?: Hours;

    @IsNumber()
    initialVacationDays?: Days;

    timeSettingsId: number | null;

    status: EmployeeStatus;

    @Type(() => EmployeeTag)
    tags?: EmployeeTag[];

    invite?: {
        message: string;
    };
}

export class UpdateEmployeeParams extends DTO<UpdateEmployeeParams> {
    @IsInt()
    id: number;

    @IsString()
    @IsOptional()
    firstName?: string;

    @IsString()
    @IsOptional()
    lastName?: string;

    @IsString()
    @IsOptional()
    callName?: string;

    @IsDateString()
    @IsOptional()
    birthday?: DateString | null;

    @IsString()
    @IsOptional()
    address?: string;

    @IsString()
    @IsOptional()
    city?: string;

    @IsString()
    @IsOptional()
    postalCode?: string;

    @IsString()
    @IsOptional()
    email?: string;

    @IsString()
    @IsOptional()
    phone?: string;

    @IsString()
    @IsOptional()
    avatar?: string;

    @IsOptional()
    staffNumber?: number | null;

    @IsNumber()
    @IsOptional()
    autoMeal?: number;

    @IsInt({ each: true })
    @IsOptional()
    positions?: number[];

    @ValidateNested()
    @Type(() => Contract)
    contract?: Contract;

    @IsBoolean()
    @IsOptional()
    deleteContract?: boolean;

    @IsOptional()
    access?: Access;

    @IsOptional()
    @IsEnum(Role)
    role?: Role;

    timeSettingsId?: number | null;

    status?: EmployeeStatus;

    scheduledStatusChange?: Employee["scheduledStatusChange"];

    @IsBoolean()
    @IsOptional()
    deleteDanglingEntries?: boolean;

    @IsOptional()
    shiftTemplates?: {
        venue: number;
        start: string;
        end: string;
    }[];

    rosterTabs?: RosterTab[];

    @Type(() => EmployeeTag)
    tags?: EmployeeTag[];

    notifications?: NotificationSettings;

    revokeAccess?: boolean;

    isContactPerson?: boolean;
}

export class UpdateEmployeesParams extends DTO<UpdateEmployeesParams> {
    update: {
        id: number;
        email?: string;
        positions?: number[];
        access?: Access;
        role?: Role;
    }[];
}

export class DeleteEmployeeParams extends DTO<DeleteEmployeeParams> {
    @IsInt()
    id: number;

    @IsBoolean()
    @IsOptional()
    deleteDanglingEntries: boolean;
}

export class GetTimeEntriesParams extends DTO<GetTimeEntriesParams> {
    from: DateString;
    to: DateString;

    @IsOptional()
    employee?: number | number[];

    @IsInt()
    @IsOptional()
    department?: number;

    @IsInt()
    @IsOptional()
    venue?: number;

    @IsEnum(TimeEntryType, { each: true })
    @IsOptional()
    type?: TimeEntryType[];

    includeDeleted?: boolean;

    includeUnassigned?: boolean;

    includeOffered?: boolean;

    companyId?: number;
}

export enum UpdateTimeEntriesContext {
    OfferSwap = "offer_swap",
    TakeUnassigned = "take_unassigned",
    TakeOffered = "take_offered",
}

export class UpdateTimeEntriesParams extends DTO<UpdateTimeEntriesParams> {
    @Type(() => TimeEntry)
    updated?: TimeEntry[];

    @Type(() => TimeEntry)
    removed?: TimeEntry[];

    context?: UpdateTimeEntriesContext;
}

export class RestoreDeletedTimeEntriesParams extends DTO<RestoreDeletedTimeEntriesParams> {
    @IsNumber()
    employeeId: number;

    @IsDateString()
    from: DateString;

    @IsDateString()
    to: DateString;
}

export class RequestSetPasswordParams extends DTO<RequestSetPasswordParams> {
    @IsString()
    @IsNotEmpty()
    email: string;

    scope?: string;
}

export class SetPasswordParams extends DTO<SetPasswordParams> {
    @IsNumber()
    @IsNotEmpty()
    id: number;

    @IsString()
    @IsNotEmpty()
    password: string;

    @IsString()
    @IsOptional()
    token?: string;

    @IsString()
    @IsOptional()
    oldPassword?: string;
}

export class PublishRosterParams extends DTO<PublishRosterParams> {
    @IsNumber()
    venue: number;

    from: DateString;
    to: DateString;

    entries?: string[];

    notify?: boolean | number[];
}

export class GetPublishedRosterParams extends DTO<GetPublishedRosterParams> {
    id?: string;
    venue?: number;
    token?: string;
    from?: DateString;
    to?: DateString;
    employee?: number;
    departments?: number[];
}

export class GetPublishedRosterResponse extends DTO<GetPublishedRosterResponse> {
    @Type(() => PublishedRoster)
    roster: PublishedRoster;

    @Type(() => TimeEntry)
    timeEntries: TimeEntry[];

    @Type(() => Employee)
    employees: Employee[];

    departments?: number[];
    employee?: number;
}

export class SendMessageParams extends DTO<SendMessageParams> {
    employees: number[];
    message: string;
}

export class GetMonthlyStatementsParams extends DTO<GetMonthlyStatementsParams> {
    employee: number | number[];
    from?: DateString;
    to?: DateString;
    exclude?: (keyof MonthlyStatement)[];
    minVersion?: string;
}

export class GetIssuesParams extends DTO<GetIssuesParams> {
    from?: DateString;
    to?: DateString;
    includeIgnored?: boolean;
}

export class GetRevenueEntriesCountParams extends DTO<GetRevenueEntriesCountParams> {
    from: DateString;
    companyId: number;
}

export class GetRevenueEntriesParams extends DTO<GetRevenueEntriesParams> {
    from?: DateString;
    to?: DateString;

    venue?: number;

    @IsEnum(RevenueType, { each: true })
    @IsOptional()
    type?: RevenueType[];

    employee?: number[] | number;

    draft?: boolean;
    reporting?: boolean;
    cashbook?: boolean;

    limit?: number;
    reverse?: boolean;
}

export class UpdateRevenueEntriesParams extends DTO<UpdateRevenueEntriesParams> {
    @Type(() => RevenueEntry)
    updated?: RevenueEntry[];

    @Type(() => RevenueEntry)
    removed?: RevenueEntry[];

    force?: boolean;

    skipBalancing?: boolean;
}

export class GetRevenueGroupsParams extends DTO<GetRevenueGroupsParams> {
    @IsInt()
    venue: number;

    @IsEnum(RevenueType, { each: true })
    @IsOptional()
    type?: RevenueType[];

    reporting?: boolean;
    cashbook?: boolean;

    excludeSuggestions?: boolean;
}

export class UpdateRevenueGroupParams extends DTO<UpdateRevenueGroupParams> {
    @Type(() => RevenueGroup)
    group?: RevenueGroup;

    @Type(() => RevenueGroup)
    merge?: RevenueGroup;

    order?: number[];

    updateEntries?: boolean;
}

export class GetRevenuePredictionsParams extends DTO<GetRevenuePredictionsParams> {
    from?: DateString;
    to?: DateString;

    @IsInt()
    venue: number;
}

export class UpdateRevenuePredictionsParams extends DTO<UpdateRevenuePredictionsParams> {
    @Type(() => RevenuePrediction)
    updated?: RevenuePrediction[];

    @Type(() => RevenuePrediction)
    removed?: RevenuePrediction[];
}

export class UnlockRevenueEntriesParams extends DTO<UnlockRevenueEntriesParams> {
    @IsNumber()
    companyId: number;

    @IsDateString()
    from: DateString;
}

export class CommitCashbookParams extends DTO<CommitCashbookParams> {
    @IsInt()
    venue: number;

    @IsString()
    @IsOptional()
    until?: string;
}

export class ImportVenueParams extends DTO<ImportVenueParams> {
    company: number;
    name: string;
    address: string;
    state: StateCode;
    postalCode: string;
    city: string;
    departments: string;
    employees: string;
    employeesHistory: string;
    timeEntries: string;
    salaries: string;
    salariesHistory: string;
    timeBookings: string;
    carryOver: string;
}

export class StartSetupPaymentParams extends DTO<StartSetupPaymentParams> {
    company?: number;
}

export class StartSetupPaymentResponse extends DTO<StartSetupPaymentResponse> {
    secret: string;
}

export type TimeTrackerLogEventType =
    | "attend"
    | "checkin"
    | "checkout"
    | "startbreak"
    | "endbreak"
    | "error"
    | "start"
    | "login"
    | "logout"
    | "sync";

export class TimeTrackerLogEvent {
    constructor(vals: Partial<TimeTrackerLogEvent> = {}) {
        Object.assign(this, vals);
    }

    type: TimeTrackerLogEventType;

    employee?: number;

    @Type(() => Date)
    time: Date;

    @Type(() => Date)
    lastSync: Date;

    @Type(() => TimeSettings)
    timeSettings?: TimeSettings;

    @Type(() => TimeEntry)
    entries?: TimeEntry[];

    @Type(() => TimeEntry)
    available?: TimeEntry[];

    @Type(() => TimeEntry)
    entry?: TimeEntry;

    offline = false;

    version: string;

    venue?: number;

    error?: string;

    departments?: number[];
}

export class TimeTrackerLog {
    @Type(() => TimeTrackerLogEvent)
    events: TimeTrackerLogEvent[] = [];

    add(...evt: Partial<TimeTrackerLogEvent>[]) {
        const newEvents = evt.map((e) => new TimeTrackerLogEvent(e));
        this.events = [...this.events, ...newEvents].sort((a, b) => b.time.getTime() - a.time.getTime()).slice(0, 500);
    }
}

export class GetRosterTargetsParams extends DTO<GetRosterTargetsParams> {
    date: DateString;
    venue: number;
}

export class UpdateRosterTargetsParams extends DTO<UpdateRosterTargetsParams> {
    @Type(() => RosterTargets)
    updated: RosterTargets[];
}

export class GetAbsencesParams extends DTO<GetAbsencesParams> {
    employee?: number | number[];
    from?: DateString;
    to?: DateString;
    includeEntries?: boolean;
    status?: AbsenceStatus[];
}

export class RequestAbsenceParams extends DTO<RequestAbsenceParams> {
    start: DateString;
    end: DateString;
    type: TimeEntryType.Vacation | TimeEntryType.Sick;
    message: string;
}

export class CreateInvitesParams extends DTO<CreateInvitesParams> {
    recipients: {
        id: number;
        email: string;
    }[];

    role: Role;
    message: string;
}

export class GetInviteResponse extends DTO<GetInviteResponse> {
    company: {
        id: number;
        name: string;
    };

    inviter: {
        name: string;
    };

    invite: Invite;

    profile: {
        email: string;
        firstName: string;
        lastName: string;
        avatar: string;
        role: Role;
    };

    hasAccount: boolean;
}

export class JoinCompanyParams extends DTO<JoinCompanyParams> {
    inviteId: string;
    password: string;
    scope: string;
}

export class GetRosterNotesParams extends DTO<GetRosterNotesParams> {
    venue?: number;
    from: DateString;
    to: DateString;
}

export class GetPublicRosterUrlParams extends DTO<GetPublicRosterUrlParams> {
    venue: number;
    // departments?: number[];
    from: DateString;
    to: DateString;
}

export class GetPublicRosterUrlResponse extends DTO<GetPublicRosterUrlResponse> {
    url: string;
}

export class GetPublicRosterParams extends DTO<GetPublicRosterParams> {
    venue: number;
    departments?: number[];
    from: DateString;
    to: DateString;
    token: string;
}

export class GetAvailabilitesParams extends DTO<GetAvailabilitesParams> {
    employees: number[];
    from: DateString;
    to: DateString;
}

export class CreateOrUpdateDocumentParams extends DTO<CreateOrUpdateDocumentParams> {
    id?: number;
    employeeId: number;
    name: string;
    content: string | null;
    @Type(() => DocumentTag)
    tags: DocumentTag[];
    date: DateString;
    comment: string;
    type?: MimeType;
    @Type(() => Form)
    form: Form | null;
}

export class GetDocumentsParams extends DTO<GetDocumentsParams> {
    employeeId?: number;
    formStatus?: FormStatus;
}

export class CreateOrUpdateJobPostingParams extends DTO<CreateOrUpdateJobPostingParams> {
    id?: number;
    title: string;
    description: string;
    positions: number[];
    payType: "monthly" | "hourly";
    payAmount: Euros;
    start: DateString | null;
    end: DateString | null;
    venueId: number;
    employmentType: EmploymentType;
    hoursPerWeek: Hours | null;
    images: string[];
}

export class CreateJobApplicationParams extends DTO<CreateJobApplicationParams> {
    postingUuid: string;
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    postalCode: string;
    email: string;
    phone: string;
    birthday: DateString | null;
    notes: string;
    headShot: string;
    availableFrom: DateString | null;
    message: string;
}

export class SocialSecurityRegistrationParams extends DTO<SocialSecurityRegistrationParams> {
    employeeId: number;
    date: DateString;
    group: string;
}

export class SocialSecurityRegistrationResponse extends DTO<SocialSecurityRegistrationResponse> {
    document: Document;
}

export class ExportCompanyParams extends DTO<ExportCompanyParams> {
    companyId: number;
}

export class ImportCoreDataParams extends DTO<ImportCoreDataParams> {
    fileContent: string;
    companyId: number;
}

export class ImportCoreDataResponse extends DTO<ImportCompanyResponse> {
    status: "success" | "failure";
    errors: string[];
}

export class ImportCompanyResponse extends DTO<ImportCompanyResponse> {
    companyId: number;
}

export class CompanyExport extends DTO<CompanyExport> {
    @Type(() => Company)
    company: Company;

    @Type(() => TimeEntry)
    timeEntries: TimeEntry[];

    @Type(() => RosterTargets)
    rosterTargets: RosterTargets[];

    @Type(() => RosterNote)
    rosterNotes: RosterNote[];

    @Type(() => Absence)
    absences: Absence[];

    @Type(() => Availability)
    availabilities: Availability[];

    @Type(() => Document)
    documents: Document[];

    @Type(() => JobPosting)
    jobPostings: JobPosting[];

    @Type(() => RevenueGroup)
    revenueGroups: RevenueGroup[];

    @Type(() => RevenueEntry)
    revenueEntries: RevenueEntry[];
}

export enum ExportFormat {
    PayrollReportDetailed = "payroll_report_detailed",
    PayrollReportOverview = "payroll_report_overview",
    PayrollDatevLodas = "payroll_datev_lodas",
    PayrollDatevLG = "payroll_datev_lg",
    PayrollAgenda = "payroll_agenda",
    PayrollLexware = "payroll_lexware",
    PayrollAddison = "payroll_addison",
    PayrollLohnAG = "payroll_lohn_ag",
    PayrollBMDWages = "payroll_bmd",
    PayrollBMDAbsences = "payroll_bmd_absences",

    TimeSheet = "time_sheet",

    RosterExcel = "roster_excel",

    /** @deprecated Use `BalancesTimeExcel`, `BalancesVacationExcel`, `BalancesBonusesExcel` instead */
    BalancesExcel = "balances_excel",
    OvertimeExcel = "balances_overtime_excel",
    AverageWorkTimeExcel = "balances_average_work_time_excel",

    BalancesTimeExcel = "balances_time_excel",
    BalancesVacationExcel = "balances_vacation_excel",
    BalancesBonusesExcel = "balances_bonuses_excel",

    CashbookDatev = "cashbook_datev",
    CashbookDatevOnline = "cashbook_datev_online",
    CashbookLexware = "cashbook_lexware",
    CashbookGeneric = "cashbook_generic",
    CashbookPDF = "cashbook_pdf",

    EmployeeDataPDF = "employee_data_pdf",
    EmployeeDataCSV = "employee_data_csv",
}

export class ExportParams extends DTO<ExportParams> {
    format: ExportFormat;
    filters: EntityFilter[];
    sortProperty: EmployeeSortProperty;
    sortDirection: EmployeeSortDirection;
    from: DateString;
    to: DateString;
    resolution?: "month" | "week" | "year";
    saveToDocuments?: boolean;
    sendAsEmail?: string[];
    requireEmployeeSignature?: boolean;
    venueId?: number;
    @Type(() => DocumentTag)
    tags?: DocumentTag[];
}

export class ExportResponse extends DTO<ExportResponse> {
    type: MimeType;
    size: number;
    url: string;
    fileName: string;
    format: ExportFormat;
    messages: { type: "info" | "warning" | "error"; content: string }[];
}

export class CreateOrUpdateTimeLogDeviceParams extends DTO<CreateOrUpdateTimeLogDeviceParams> {
    id?: string;
    filters: EntityFilter[];
    description: string;
    image?: string;
    displaySchedule?: boolean;
    displayUpcoming?: boolean;
    displayActive?: boolean;
}

export class GetTimeLogEventsParams extends DTO<GetTimeLogEventsParams> {
    timeEntryId?: string;
    deviceId?: string;
    @Type(() => Date)
    fromTime: Date;
    @Type(() => Date)
    toTime: Date;
}

export class GetBalancesParams extends DTO<GetBalancesParams> {
    from: DateString;
    to: DateString;
    filters: EntityFilter[];
    resolution: "day" | "week" | "month" | "year" | "none";
    projected?: boolean;
}

export class GetDailyResultsParams extends DTO<GetDailyResultsParams> {
    from: DateString;
    to: DateString;
    filters: EntityFilter[];
    recalculate?: boolean;
    planned?: boolean;
}

export class ResetBalancesParams extends DTO<ResetBalancesParams> {
    date: DateString;
    balances: {
        employeeId: number;
        time: Hours | null;
        vacation: Days | null;
        bonuses: Euros | null;
    }[];
}

/**
 * Transport-agnostic interface defining communication
 * between [[Client]] and [[Server]] instances.
 */
export class API {
    handlerDefinitions!: HandlerDefinition[];

    @Handler(UpdateAccountParams, Account)
    updateAccount(_params: UpdateAccountParams): Promise<Account> {
        throw "not implemented";
    }

    @Handler(LoginParams, Session)
    login(_params: LoginParams): Promise<Session> {
        throw "not implemented";
    }

    @Handler(undefined, Account)
    me(): Promise<Account> {
        throw "not implemented";
    }

    @Handler(GetCompanyParams, Company)
    getCompany(_params: GetCompanyParams): Promise<Company> {
        throw "not implemented";
    }

    @Handler(CreateCompanyParams, Company)
    createCompany(_params: CreateCompanyParams): Promise<Company> {
        throw "not implemented";
    }

    @Handler(UpdateCompanyParams, undefined)
    updateCompany(_params: UpdateCompanyParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(Number, undefined)
    deleteCompany(_id: number): Promise<void> {
        throw "not implemented";
    }

    @Handler(undefined, Company)
    listCompanies(): Promise<CompanyListing[]> {
        throw "not implemented";
    }

    @Handler(Number, Session)
    selectCompany(_id: number): Promise<Session> {
        throw "not implemented";
    }

    @Handler(Number, Invoice)
    getInvoices(_id?: number): Promise<Invoice[]> {
        throw "not implemented";
    }

    @Handler(RevokeSessionParams, undefined)
    revokeSession(_params: RevokeSessionParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(RequestSetPasswordParams, undefined)
    requestSetPassword(_params: RequestSetPasswordParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(SetPasswordParams, Account)
    setPassword(_params: SetPasswordParams): Promise<Account> {
        throw "not implemented";
    }

    @Handler(CreateVenueParams, Venue)
    createVenue(_params: CreateVenueParams): Promise<Venue> {
        throw "not implemented";
    }

    @Handler(UpdateVenueParams, Venue)
    updateVenue(_params: UpdateVenueParams): Promise<Venue> {
        throw "not implemented";
    }

    @Handler(Number, undefined)
    archiveVenue(_id: number): Promise<void> {
        throw "not implemented";
    }

    @Handler(CreateDepartmentParams, Department)
    createDepartment(_params: CreateDepartmentParams): Promise<Department> {
        throw "not implemented";
    }

    @Handler(UpdateDepartmentParams, Department)
    updateDepartment(_params: UpdateDepartmentParams): Promise<Department> {
        throw "not implemented";
    }

    @Handler(Number, undefined)
    archiveDepartment(_id: number): Promise<void> {
        throw "not implemented";
    }

    @Handler(CreateEmployeeParams, Employee)
    createEmployee(_params: CreateEmployeeParams): Promise<Employee> {
        throw "not implemented";
    }

    @Handler(UpdateEmployeeParams, Employee)
    updateEmployee(_params: UpdateEmployeeParams): Promise<Employee> {
        throw "not implemented";
    }

    @Handler(UpdateEmployeesParams, undefined)
    updateEmployees(_params: UpdateEmployeesParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(DeleteEmployeeParams, undefined)
    deleteEmployee(_params: DeleteEmployeeParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(GetTimeEntriesParams, TimeEntry, {
        transforms: [
            {
                clientVersionBefore: "1.25.0",
                transformOutput: ({
                    startPlanned,
                    endPlanned,
                    startLogged,
                    endLogged,
                    startFinal,
                    endFinal,
                    published,
                    seen,
                    mealsBreakfast,
                    mealsDinner,
                    mealsLunch,
                    ...rest
                }) => ({
                    ...rest,
                    startPlanned: toTimeString(startPlanned),
                    endPlanned: toTimeString(endPlanned),
                    startLogged: toTimeString(startLogged),
                    endLogged: toTimeString(endLogged),
                    startFinal: toTimeString(startFinal),
                    endFinal: toTimeString(endFinal),
                    published: published && {
                        ...published,
                        startPlanned: published.startPlanned && toTimeString(published.startPlanned),
                        endPlanned: published.endPlanned && toTimeString(published.endPlanned),
                    },
                    seen: seen && {
                        ...seen,
                        startPlanned: seen.startPlanned && toTimeString(seen.startPlanned),
                        endPlanned: seen.endPlanned && toTimeString(seen.endPlanned),
                    },
                    meals: mealsBreakfast || 0 + mealsDinner || 0 + mealsLunch || 0,
                }),
            },
        ],
    })
    getTimeEntries(_params: GetTimeEntriesParams): Promise<TimeEntry[]> {
        throw "not implemented";
    }

    @Handler(UpdateTimeEntriesParams, undefined, {
        transforms: [
            {
                clientVersionBefore: "1.25.0",
                transformInput: ({ updated, removed, ...rest }: any) => {
                    const transform = ({
                        date,
                        startPlanned,
                        endPlanned,
                        startLogged,
                        endLogged,
                        startFinal,
                        endFinal,
                        published,
                        seen,
                        meals,
                        ...rest
                    }: any) => {
                        [startPlanned, endPlanned] = parseTimes(date, startPlanned, endPlanned);
                        [startLogged, endLogged] = parseTimes(date, startLogged, endLogged);
                        [startFinal, endFinal] = parseTimes(date, startFinal, endFinal);

                        const publishedTimes =
                            published && parseTimes(published.date, published.startPlanned, published.endPlanned);
                        const seenTimes = seen && parseTimes(seen.date, seen.startPlanned, seen.endPlanned);

                        return {
                            ...rest,
                            date,
                            startPlanned,
                            endPlanned,
                            startLogged,
                            endLogged,
                            startFinal,
                            endFinal,
                            published: published && {
                                ...published,
                                startPlanned: publishedTimes[0],
                                endPlanned: publishedTimes[1],
                            },
                            seen: seen && {
                                ...seen,
                                startPlanned: seenTimes[0],
                                endPlanned: seenTimes[1],
                            },
                            mealsLunch: meals,
                            mealsBreakfast: 0,
                            mealsDinner: 0,
                        };
                    };

                    return {
                        updated: updated.map(transform),
                        removed: removed.map(transform),
                        ...rest,
                    };
                },
            },
        ],
    })
    updateTimeEntries(_params: UpdateTimeEntriesParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(RestoreDeletedTimeEntriesParams, undefined)
    restoreDeletedTimeEntries(_params: RestoreDeletedTimeEntriesParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(PublishRosterParams, PublishedRoster)
    publishRoster(_params: PublishRosterParams): Promise<PublishedRoster> {
        throw "not implemented";
    }

    @Handler(GetPublishedRosterParams, GetPublishedRosterResponse)
    getPublishedRoster(_params: GetPublishedRosterParams): Promise<GetPublishedRosterResponse> {
        throw "not implemented";
    }

    @Handler(SendMessageParams, undefined)
    sendMessage(_params: SendMessageParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(GetPublicRosterUrlParams, GetPublicRosterUrlResponse)
    getPublicRosterUrl(_params: GetPublicRosterUrlParams): Promise<GetPublicRosterUrlResponse> {
        throw "not implemented";
    }

    @Handler(GetMonthlyStatementsParams, MonthlyStatement)
    getMonthlyStatements(_params: GetMonthlyStatementsParams): Promise<MonthlyStatement[]> {
        throw "not implemented";
    }

    @Handler(GetIssuesParams, Issue)
    getIssues(_params: GetIssuesParams): Promise<Issue[]> {
        throw "not implemented";
    }

    @Handler(GetRevenueEntriesCountParams, Promise<number>)
    getRevenueEntriesCount(_params: GetRevenueEntriesCountParams): Promise<number> {
        throw "not implemented";
    }

    @Handler(GetRevenueEntriesParams, RevenueEntry)
    getRevenueEntries(_params: GetRevenueEntriesParams): Promise<RevenueEntry[]> {
        throw "not implemented";
    }

    @Handler(UpdateRevenueEntriesParams, undefined)
    updateRevenueEntries(_params: UpdateRevenueEntriesParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(UnlockRevenueEntriesParams, undefined)
    unlockRevenueEntries(_params: UnlockRevenueEntriesParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(CommitCashbookParams, undefined)
    commitCashbook(_params: CommitCashbookParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(GetRevenueGroupsParams, RevenueGroup)
    getRevenueGroups(_params: GetRevenueGroupsParams): Promise<RevenueGroup[]> {
        throw "not implemented";
    }

    @Handler(UpdateRevenueGroupParams, undefined)
    updateRevenueGroup(_params: UpdateRevenueGroupParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(Number, undefined)
    deleteRevenueGroup(_id: number): Promise<void> {
        throw "not implemented";
    }

    @Handler(GetRevenuePredictionsParams, RevenuePrediction)
    getRevenuePredictions(_params: GetRevenuePredictionsParams): Promise<RevenuePrediction[]> {
        throw "not implemented";
    }

    @Handler(UpdateRevenuePredictionsParams, undefined)
    updateRevenuePredictions(_params: UpdateRevenuePredictionsParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(GetRosterTargetsParams, RosterTargets)
    getRosterTargets(_params: GetRosterTargetsParams): Promise<RosterTargets[]> {
        throw "not implemented";
    }

    @Handler(UpdateRosterTargetsParams, undefined)
    updateRosterTargets(_params: UpdateRosterTargetsParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(StartSetupPaymentParams, StartSetupPaymentResponse)
    startSetupPayment(_params: StartSetupPaymentParams): Promise<StartSetupPaymentResponse> {
        throw "not implemented";
    }

    @Handler(TimeTrackerLog, undefined)
    sendTimeTrackerLog(_log: TimeTrackerLog): Promise<void> {
        throw "not implemented";
    }

    @Handler(ImportVenueParams, undefined)
    importVenue(_params: ImportVenueParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(Absence, undefined)
    createAbsence(_params: Absence): Promise<Absence> {
        throw "not implemented";
    }

    @Handler(Absence, undefined)
    updateAbsence(_params: Absence): Promise<Absence> {
        throw "not implemented";
    }

    @Handler(Number, undefined)
    deleteAbsence(_params: number): Promise<void> {
        throw "not implemented";
    }

    @Handler(GetAbsencesParams, Absence)
    getAbsences(_params: GetAbsencesParams): Promise<Absence[]> {
        throw "not implemented";
    }

    @Handler(RequestAbsenceParams, undefined)
    requestAbsence(_params: RequestAbsenceParams): Promise<Absence> {
        throw "not implemented";
    }

    @Handler(CreateInvitesParams, undefined)
    createInvites(_params: CreateInvitesParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(String, GetInviteResponse)
    getInvite(_params: string): Promise<GetInviteResponse> {
        throw "not implemented";
    }

    @Handler(JoinCompanyParams, undefined)
    joinCompany(_params: JoinCompanyParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(TimeSettings, undefined)
    createOrUpdateTimeSettings(_params: TimeSettings): Promise<void> {
        throw "not implemented";
    }

    @Handler(Number, undefined)
    deleteTimeSettings(_id: number): Promise<void> {
        throw "not implemented";
    }

    @Handler(RosterNote, RosterNote)
    createOrUpdateRosterNote(_params: RosterNote): Promise<RosterNote> {
        throw "not implemented";
    }

    @Handler(Number, undefined)
    deleteRosterNote(_id: number): Promise<void> {
        throw "not implemented";
    }

    @Handler(GetRosterNotesParams, RosterNote)
    getRosterNotes(_params: GetRosterNotesParams): Promise<RosterNote[]> {
        throw "not implemented";
    }

    @Handler(GetAvailabilitesParams, Availability)
    getAvailabilities(_params: GetAvailabilitesParams): Promise<Availability[]> {
        throw "not implemented";
    }

    @Handler(Availability, undefined)
    createAvailability(_params: Availability): Promise<Availability> {
        throw "not implemented";
    }

    @Handler(Availability, undefined)
    updateAvailability(_params: Availability): Promise<Availability> {
        throw "not implemented";
    }

    @Handler(Number, undefined)
    deleteAvailability(_params: number): Promise<void> {
        throw "not implemented";
    }

    @Handler(CreateOrUpdateDocumentParams, Document)
    createOrUpdateDocument(_params: CreateOrUpdateDocumentParams): Promise<Document> {
        throw "not implemented";
    }

    @Handler(GetDocumentsParams, Document)
    getDocuments(_params: GetDocumentsParams): Promise<Document[]> {
        throw "not implemented";
    }

    @Handler(Number, undefined)
    deleteDocument(_id: number): Promise<void> {
        throw "not implemented";
    }

    @Handler(CreateOrUpdateJobPostingParams, JobPosting)
    createOrUpdateJobPosting(_params: CreateOrUpdateJobPostingParams): Promise<JobPosting> {
        throw "not implemented";
    }

    @Handler(undefined, JobPosting)
    getJobPostings(): Promise<JobPosting[]> {
        throw "not implemented";
    }

    @Handler(String, JobPosting)
    getJobPosting(_uuid: string): Promise<JobPosting> {
        throw "not implemented";
    }

    @Handler(Number, undefined)
    deleteJobPosting(_id: number): Promise<void> {
        throw "not implemented";
    }

    @Handler(CreateJobApplicationParams, undefined)
    createJobApplication(_params: CreateJobApplicationParams): Promise<void> {
        throw "not implemented";
    }

    @Handler(Number, undefined)
    deleteJobApplication(_id: number): Promise<void> {
        throw "not implemented";
    }

    @Handler(SocialSecurityRegistrationParams, SocialSecurityRegistrationResponse)
    socialSecurityRegistration(_params: SocialSecurityRegistrationParams): Promise<SocialSecurityRegistrationResponse> {
        throw "not implemented";
    }

    @Handler(ExportCompanyParams, CompanyExport)
    exportCompany(_params: ExportCompanyParams): Promise<CompanyExport> {
        throw "not implemented";
    }

    @Handler(ImportCoreDataParams, ImportCoreDataResponse)
    importCoreData(_params: ImportCoreDataParams): Promise<ImportCoreDataResponse> {
        throw "not implemented";
    }

    @Handler(CompanyExport, ImportCompanyResponse)
    importCompany(_params: CompanyExport): Promise<ImportCompanyResponse> {
        throw "not implemented";
    }

    @Handler(ExportParams, ExportResponse)
    export(_params: ExportParams): Promise<ExportResponse> {
        throw "not implemented";
    }

    @Handler(CreateOrUpdateTimeLogDeviceParams, TimeLogDevice)
    createOrUpdateTimeLogDevice(_params: CreateOrUpdateTimeLogDeviceParams): Promise<TimeLogDevice> {
        throw "not implemented";
    }

    @Handler(undefined, TimeLogDevice)
    getTimeLogDevices(): Promise<TimeLogDevice[]> {
        throw "not implemented";
    }

    @Handler(String, undefined)
    deleteTimeLogDevice(_id: string): Promise<void> {
        throw "not implemented";
    }

    @Handler(GetTimeLogEventsParams, TimeLogEvent)
    getTimeLogEvents(_params: GetTimeLogEventsParams): Promise<TimeLogEvent[]> {
        throw "not implemented";
    }

    @Handler(GetDailyResultsParams, undefined)
    getDailyResults(_params: GetDailyResultsParams): Promise<DailyResults[]> {
        throw "not implemented";
    }

    /** @deprecated Use `getTimeBalances`, `getVacationBalances`, `getBonusesBalances` instead */
    @Handler(GetBalancesParams, undefined)
    getBalances(_params: GetBalancesParams): Promise<Balances[]> {
        throw "not implemented";
    }

    @Handler(GetBalancesParams, undefined)
    getTimeBalances(_params: GetBalancesParams): Promise<TimeBalance[]> {
        throw "not implemented";
    }

    @Handler(GetBalancesParams, undefined)
    getVacationBalances(_params: GetBalancesParams): Promise<VacationBalance[]> {
        throw "not implemented";
    }

    @Handler(GetBalancesParams, undefined)
    getBonusesBalances(_params: GetBalancesParams): Promise<BonusesBalance[]> {
        throw "not implemented";
    }

    @Handler(ResetBalancesParams, undefined)
    resetBalances(_params: ResetBalancesParams): Promise<void> {
        throw "not implemented";
    }
}
