import {
    TimeEntry,
    TimeEntryType,
    Employee,
    Company,
    TimeSettings,
    NominalHoursMode,
    BreakMode,
    AbsenceMode,
    BreakTiming,
    BonusType,
    Bonus,
    Contract,
    Position,
    BonusTypeMode,
} from "./model";
import { isHoliday, isSpecial, getHolidaysInRange, isChristmasEve, isNewYearsEve, HolidayName } from "./holidays";
import { getRange, dateAdd, dateSub, parseTimes, parseDateString, parseTime, round } from "./util";
import { calcPay } from "./salary";
import { DateRange, getAutomaticBreak, getStatutoryBreak } from "./time";
import { Hours } from "@pentacode/openapi/src/units";

export type Interval = [Date, Date];

export const second = 1000;
export const minute = 60 * second;
export const hour = 60 * minute;
export const day = 24 * hour;
export const week = 7 * day;
// export const weekFactor = 4.35;
export const nullInterval: Interval = [new Date(0), new Date(0)];

export function union(...intervals: Interval[]): Interval[] {
    const itvs = [...intervals].sort(([a], [b]) => Number(a) - Number(b));

    for (let i = 0; i < itvs.length - 1; ) {
        if (itvs[i][1] >= itvs[i + 1][1]) {
            itvs.splice(i + 1, 1);
        } else if (itvs[i][1] >= itvs[i + 1][0]) {
            itvs.splice(i, 2, [itvs[i][0], itvs[i + 1][1]]);
        } else {
            i++;
        }
    }

    return itvs;
}

export function intersect(...intervals: Interval[]): Interval {
    const start = new Date(Math.max(...intervals.map((i) => Number(i[0]))));
    const end = new Date(Math.max(Number(start), Math.min(...intervals.map((i) => Number(i[1])))));
    return [start, end];
}

export function intersectAll(...all: Interval[][]): Interval[] {
    const [one, two, ...rest] = all;

    if (!one) {
        return [];
    }

    if (!two) {
        return one;
    }

    const intersects: Interval[] = [];

    for (const a of one) {
        for (const b of two) {
            intersects.push(intersect(a, b));
        }
    }
    const result = union(...intersects);

    return intersectAll(result, ...rest);
}

export function sub(a: Interval, b: Interval): Interval[] {
    return (
        /*
         * |---|
         *       |---|
         */
        a[0] >= b[1] || a[1] <= b[0]
            ? [a]
            : /*
               *   |---|
               * |-------|
               */
              a[0] >= b[0] && a[1] <= b[1]
              ? []
              : /*
                 * |-------|
                 *   |---|
                 */
                a[0] < b[0] && a[1] > b[1]
                ? [
                      [a[0], b[0]],
                      [b[1], a[1]],
                  ]
                : /*
                   * |-----|
                   *    |-----|
                   */
                  a[0] < b[0] && a[1] <= b[1]
                  ? [[a[0], b[0]]]
                  : /*
                     *    |-----|
                     * |-----|
                     */
                    a[0] >= b[0] && a[1] > b[1]
                    ? [[b[1], a[1]]]
                    : []
    );
}

export function subtract(a: Interval[], b: Interval[]): Interval[] {
    if (!b.length) {
        return a;
    }

    const result: Interval[] = [];

    for (const aInt of a) {
        const tempRes = [];
        for (const bInt of b) {
            tempRes.push(sub(aInt, bInt));
        }
        result.push(...intersectAll(...tempRes));
    }

    return union(...result);
}

function isSunday(year: number, month: number, date: number) {
    return new Date(year, month, date).getDay() === 0;
}

export function overlap(a: Interval, b: Interval[]) {
    let result = 0;
    for (const itv of b) {
        result += Math.max(0, Math.min(Number(a[1]), Number(itv[1])) - Math.max(Number(a[0]), Number(itv[0])));
    }
    return result / hour;
}

export type LegacyBonusResult = {
    /* @deprecated as of v1.24.0 */
    bonusesAreTaxed?: boolean;
    /* @deprecated as of v1.24.0 */
    night1: number;
    /* @deprecated as of v1.24.0 */
    night2: number;
    /* @deprecated as of v1.24.0 */
    sunday: number;
    /* @deprecated as of v1.24.0 */
    holiday: number;
    /* @deprecated as of v1.24.0 */
    special: number;
};

export type LegacyCostResult = {
    /* @deprecated as of v1.24.0 */
    salaryGross: number;
    /* @deprecated as of v1.24.0 */
    salaryNet: number;
    /* @deprecated as of v1.24.0 */
    salaryCost: number;

    /* @deprecated as of v1.24.0 */
    workGross: number;
    /* @deprecated as of v1.24.0 */
    workNet: number;
    /* @deprecated as of v1.24.0 */
    workCost: number;

    /* @deprecated as of v1.24.0 */
    breaksGross: number;
    /* @deprecated as of v1.24.0 */
    breaksNet: number;
    /* @deprecated as of v1.24.0 */
    breaksCost: number;

    /* @deprecated as of v1.24.0 */
    commissionGross: number;
    /* @deprecated as of v1.24.0 */
    commissionNet: number;
    /* @deprecated as of v1.24.0 */
    commissionCost: number;

    /* @deprecated as of v1.24.0 */
    night1Gross: number;
    /* @deprecated as of v1.24.0 */
    night1Net: number;
    /* @deprecated as of v1.24.0 */
    night1Cost: number;

    /* @deprecated as of v1.24.0 */
    night2Gross: number;
    /* @deprecated as of v1.24.0 */
    night2Net: number;
    /* @deprecated as of v1.24.0 */
    night2Cost: number;

    /* @deprecated as of v1.24.0 */
    sundayGross: number;
    /* @deprecated as of v1.24.0 */
    sundayNet: number;
    /* @deprecated as of v1.24.0 */
    sundayCost: number;

    /* @deprecated as of v1.24.0 */
    holidayGross: number;
    /* @deprecated as of v1.24.0 */
    holidayNet: number;
    /* @deprecated as of v1.24.0 */
    holidayCost: number;

    /* @deprecated as of v1.24.0 */
    specialGross: number;
    /* @deprecated as of v1.24.0 */
    specialNet: number;
    /* @deprecated as of v1.24.0 */
    specialCost: number;

    /* @deprecated as of v1.24.0 */
    monthlyGross: number;
    /* @deprecated as of v1.24.0 */
    monthlyNet: number;
    /* @deprecated as of v1.24.0 */
    monthlyCost: number;

    /* @deprecated as of v1.24.0 */
    hourlyGross: number;
    /* @deprecated as of v1.24.0 */
    hourlyNet: number;
    /* @deprecated as of v1.24.0 */
    hourlyCost: number;

    /* @deprecated as of v1.24.0 */
    bonusGross: number;
    /* @deprecated as of v1.24.0 */
    bonusNet: number;
    /* @deprecated as of v1.24.0 */
    bonusCost: number;
};

export type BonusResult = {
    bonusId: number;
    bonusTypeId: number;
    duration: number;
    percent: number;
    hourlyRate: number;
    taxFree: boolean;
    costCenter?: string;
};

export type TimeResult = {
    position?: number;
    type?: TimeEntryType;

    comment?: string;

    full: number;
    paid: number;

    meals: number;
    mealsBreakfast: number;
    mealsDinner: number;

    revenue: number;

    days?: number;

    bonuses: BonusResult[];
} & LegacyBonusResult &
    LegacyCostResult;

export function newTimeResult(vals: Partial<TimeResult> = {}): TimeResult {
    return {
        full: 0,
        paid: 0,

        meals: 0,
        mealsBreakfast: 0,
        mealsDinner: 0,

        revenue: 0,

        bonuses: [],

        night1: 0,
        night2: 0,
        sunday: 0,
        holiday: 0,
        special: 0,

        salaryGross: 0,
        salaryNet: 0,
        salaryCost: 0,

        workGross: 0,
        workNet: 0,
        workCost: 0,

        breaksGross: 0,
        breaksNet: 0,
        breaksCost: 0,

        commissionGross: 0,
        commissionNet: 0,
        commissionCost: 0,

        night1Gross: 0,
        night1Net: 0,
        night1Cost: 0,

        night2Gross: 0,
        night2Net: 0,
        night2Cost: 0,

        sundayGross: 0,
        sundayNet: 0,
        sundayCost: 0,

        holidayGross: 0,
        holidayNet: 0,
        holidayCost: 0,

        specialGross: 0,
        specialNet: 0,
        specialCost: 0,

        monthlyGross: 0,
        monthlyNet: 0,
        monthlyCost: 0,

        hourlyGross: 0,
        hourlyNet: 0,
        hourlyCost: 0,

        bonusGross: 0,
        bonusNet: 0,
        bonusCost: 0,

        ...vals,
    } as TimeResult;
}

export interface TimeResultItem {
    entry: TimeEntry;
    result: TimeResult;
}

export function calcAverageDailyHours(entries: TimeResultItem[]): TimeResult {
    const total = calcTotalHours(entries);
    const average = newTimeResult();
    const days = new Set(entries.map((e) => e.entry.date));
    for (const prop of ["full", "paid", "night1", "night2", "sunday", "holiday", "special"] as const) {
        average[prop] = total[prop] / (days.size || 1);
    }

    const bonusesByType = entries.reduce((bbt, { result }) => {
        for (const bonus of result.bonuses || []) {
            if (!bbt.has(bonus.bonusTypeId)) {
                bbt.set(bonus.bonusTypeId, []);
            }
            bbt.get(bonus.bonusTypeId)!.push(bonus);
        }
        return bbt;
    }, new Map<number, BonusResult[]>());

    average.bonuses = [...bonusesByType.values()].map((bonuses) => ({
        ...bonuses[0],
        duration: bonuses.reduce((total, bonus) => total + bonus.duration, 0) / days.size,
    }));

    return average;
}

export function calcAverageHours(entries: TimeResult[]): TimeResult {
    const average = calcTotalResult(entries);
    for (const prop of ["full", "paid", "night1", "night2", "sunday", "holiday", "special"] as const) {
        average[prop] = average[prop] / (entries.length || 1);
    }

    const bonusesByType = entries.reduce((bbt, result) => {
        for (const bonus of result.bonuses || []) {
            if (!bbt.has(bonus.bonusTypeId)) {
                bbt.set(bonus.bonusTypeId, []);
            }
            bbt.get(bonus.bonusTypeId)!.push(bonus);
        }
        return bbt;
    }, new Map<number, BonusResult[]>());

    average.bonuses = [...bonusesByType.values()].map((bonuses) => ({
        ...bonuses[0],
        duration: bonuses.reduce((total, bonus) => total + bonus.duration, 0) / (entries.length || 1),
    }));

    return average;
}

export function calcTotalResult(results: TimeResult[], type?: TimeEntryType, position?: number, comment?: string) {
    if (!results.length) {
        return newTimeResult({ type, position });
    }
    return results.reduce(
        (total: TimeResult, entry) => {
            for (const prop of [
                "full",
                "paid",
                "days",
                "night1",
                "night2",
                "sunday",
                "holiday",
                "special",
                "revenue",
                "salaryGross",
                "salaryNet",
                "salaryCost",
                "workGross",
                "workNet",
                "workCost",
                "breaksGross",
                "breaksNet",
                "breaksCost",
                "commissionGross",
                "commissionNet",
                "commissionCost",
                "night1Gross",
                "night1Net",
                "night1Cost",
                "night2Gross",
                "night2Net",
                "night2Cost",
                "sundayGross",
                "sundayNet",
                "sundayCost",
                "holidayGross",
                "holidayNet",
                "holidayCost",
                "specialGross",
                "specialNet",
                "specialCost",
                "bonusGross",
                "bonusNet",
                "bonusCost",
                "meals",
                "mealsBreakfast",
                "mealsDinner",
            ] as const) {
                total[prop] = (total[prop] || 0) + (entry[prop] || 0);
            }

            for (const bonus of entry.bonuses || []) {
                let existing = total.bonuses.find(
                    (b) =>
                        b.bonusId === bonus.bonusId &&
                        b.hourlyRate === bonus.hourlyRate &&
                        b.taxFree === bonus.taxFree &&
                        b.costCenter === bonus.costCenter
                );
                if (!existing) {
                    existing = { ...bonus, duration: 0 };
                    total.bonuses.push(existing);
                }
                existing.duration += bonus.duration;
            }

            return total;
        },
        newTimeResult({
            type,
            position,
            monthlyGross: results[0].monthlyGross,
            monthlyNet: results[0].monthlyNet,
            monthlyCost: results[0].monthlyCost,
            hourlyGross: results[0].hourlyGross,
            hourlyNet: results[0].hourlyNet,
            hourlyCost: results[0].hourlyCost,
            comment,
            bonuses: [],
        })
    );
}

export function calcTotalHours(items: TimeResultItem[], type?: TimeEntryType, position?: number, comment?: string) {
    if (typeof type !== "undefined") {
        items = items.filter((e) => e.entry.type === type);
    }
    if (typeof position !== "undefined") {
        items = items.filter((e) => e.entry.position?.id === position);
    }
    if (typeof comment !== "undefined") {
        items = items.filter((e) => e.entry.comment === comment);
    }
    if (!items.length) {
        return newTimeResult({ type, position });
    }

    const results = items.map((r) => r.result);

    const result = calcTotalResult(results, type, position, comment);

    return result;
}

export type CalcHoursMode = "final" | "planned" | "logged" | "mixed" | "max";

function calcLegacyBonuses(
    a: TimeEntry,
    work: Interval,
    employee: Employee,
    {
        holidays,
        timeSettings,
        country,
    }: {
        holidays: HolidayName[];
        iterativeBreaks: boolean;
        timeSettings: TimeSettings;
        country: "DE" | "AT";
    }
): LegacyBonusResult {
    const year = a.start.getFullYear();
    const month = a.start.getMonth();
    const date = a.start.getDate();
    const contract = employee.getContractForDate(a.date);
    const {
        bonusNight1Start,
        bonusNight1End,
        bonusNight2Start,
        bonusNight2End,
        bonusMinDuration,
        bonusSundayNextDayUntil = "04:00",
        bonusHolidayNextDayUntil = "04:00",
        bonusChristmasEveFrom = "14:00",
        bonusNewYearsEveFrom = "14:00",
    } = timeSettings;

    if (!contract) {
        // console.error(`no contract found for employee ${employee.name} on date ${a.date}`);
        return newTimeResult();
    }

    const { stackBonuses, bonusNight1, bonusNight2, bonusSunday, bonusHoliday, bonusSpecial } = contract;
    let night1: Interval[] = [nullInterval];
    let night2: Interval[] = [nullInterval];
    let sunday: Interval[] = [nullInterval];
    let holiday: Interval[] = [nullInterval];
    let special: Interval[] = [nullInterval];

    // Normalize bonus times
    const night1Start = bonusNight1Start.slice(0, 5);
    const night1End = bonusNight1End.slice(0, 5);
    const night2Start = bonusNight2Start.slice(0, 5);
    const night2End = bonusNight2End.slice(0, 5);

    if (bonusNight1) {
        // Night bonus 1: 00:00 today - 06:00 today and 20:00 today - 06:00 tomorrow
        if (night1Start >= "20:00") {
            night1 = [
                night1End <= "06:00" ? parseTimes(a.date, "00:00", night1End) : nullInterval,
                parseTimes(a.date, night1Start, night1End),
            ] as Interval[];
        } else if (night1Start < "06:00") {
            night1 = [
                parseTimes(a.date, night1Start, night1End),
                parseTimes(dateAdd(a.date, { days: 1 }), night1Start, night1End),
            ] as Interval[];
        }
    }

    if (bonusNight2) {
        // Night bonus 2: 00:00 tomorrow - 04:00 tomorrow
        night2 = [parseTimes(dateAdd(a.date, { days: 1 }), night2Start, night2End)] as Interval[];
    }

    if (bonusSunday) {
        sunday = isSunday(year, month, date)
            ? // Sunday today: 00:00 today - 04:00 tomorrow
              ([
                  [new Date(year, month, date, 0), parseTime(new Date(year, month, date + 1), bonusSundayNextDayUntil)],
              ] as Interval[])
            : isSunday(year, month, date + 1)
              ? // Sunday tomorrow: 00:00 tomorrow - 24:00 tomorrow
                ([[new Date(year, month, date + 1, 0), new Date(year, month, date + 1, 24)]] as Interval[])
              : [nullInterval];
    }

    if (bonusHoliday) {
        holiday = union(
            // New years eve today: 14:00 - 00:00
            isNewYearsEve(year, month, date)
                ? [parseTime(new Date(year, month, date), bonusNewYearsEveFrom)!, new Date(year, month, date + 1, 0)]
                : nullInterval,
            // Holiday today: 00:00 today - 04:00 tomorrow
            isHoliday(year, month, date, { holidays, country })
                ? [
                      new Date(year, month, date, 0),
                      parseTime(new Date(year, month, date + 1), bonusHolidayNextDayUntil)!,
                  ]
                : nullInterval,
            // Holiday tomorrow: 00:00 tomorrow - 24:00 tomorrow
            isHoliday(year, month, date + 1, { holidays, country })
                ? [new Date(year, month, date + 1, 0), new Date(year, month, date + 1, 24)]
                : nullInterval
        );
    }

    if (bonusSpecial) {
        special = union(
            // Christmas eve today: 14:00 - 00:00
            isChristmasEve(year, month, date)
                ? [parseTime(new Date(year, month, date), bonusChristmasEveFrom)!, new Date(year, month, date + 1, 0)]
                : nullInterval,
            // Special holiday today: 00:00 today - 04:00 tomorrow
            isSpecial(year, month, date, { holidays, country })
                ? [
                      new Date(year, month, date, 0),
                      parseTime(new Date(year, month, date + 1), bonusHolidayNextDayUntil)!,
                  ]
                : nullInterval,
            // Special holiday tomorrow: 00:00 tomorrow - 24:00 tomorrow
            isSpecial(year, month, date + 1, { holidays, country })
                ? [new Date(year, month, date + 1, 0), new Date(year, month, date + 1, 24)]
                : nullInterval
        );
    }

    // Second night interval "overrides" first, so we have to subtract it
    night1 = subtract(night1, night2);

    // Special holidays "override" regular holidays and sundays
    holiday = subtract(holiday, special);
    sunday = subtract(sunday, special);

    // Holidays "override" sundays
    sunday = subtract(sunday, holiday);

    // If bonuses should not be stacked, we need to subtract sunday and holiday intervals from night intervals
    if (!stackBonuses) {
        night1 = subtract(night1, [...sunday, ...holiday, ...special]);
        night2 = subtract(night2, [...sunday, ...holiday, ...special]);
    }

    const nightTotal = overlap(work, union(...night1, ...night2));
    return {
        night1: nightTotal >= bonusMinDuration ? overlap(work, night1) : 0,
        night2: nightTotal >= bonusMinDuration ? overlap(work, night2) : 0,
        sunday: overlap(work, sunday),
        holiday: overlap(work, holiday),
        special: overlap(work, special),
    };
}

export function getBonusHourlyRate(
    company: Company,
    contract: Contract,
    obj?: TimeEntry | Position | number | null | undefined
) {
    const weekFactor = company.settings.weekFactor || 4.35;
    const comp = contract.getSalary(obj);

    if (!comp) {
        return 0;
    }

    if (comp.type === "hourly") {
        return comp?.amount;
    }

    // If weekly nonimal hours or week factor are 0, the below formular is not well defined. Thus, we return 0.
    if (!contract.nominalWeeklyHours || !weekFactor) {
        return 0;
    }

    const salaryTotal =
        comp.amount +
            contract.benefits
                ?.filter((b) => company.benefitTypes?.find((t) => t.id === b.typeId)?.includeInBonusPayments)
                .reduce((total, benefit) => total + benefit.amount, 0) || 0;

    return round(salaryTotal / contract.nominalWeeklyHours / weekFactor, 2);
}

function calcBonuses(company: Company, employee: Employee, entry: TimeEntry, work: Interval): BonusResult[] {
    const contract = employee.getContractForDate(entry.date);

    if (!contract) {
        return [];
    }

    const bonuses: {
        bonus: Bonus;
        type: BonusType;
        intervals: Interval[];
        hourlyRate: number;
        taxFree: boolean;
        costCenter?: string;
    }[] = [];

    const costCenter =
        company.getCostCenter({ date: entry.date, employee, position: entry.position })?.number || undefined;

    for (const bonus of contract.bonuses) {
        const type = company.bonusTypes?.find((t) => t.id === bonus.typeId);

        // Type couldn't be found
        if (!type) {
            continue;
        }

        // Bonus doesn't apply to this position
        if (bonus.positionId && entry.positionId !== bonus.positionId) {
            continue;
        }

        // Make sure "must start before" condition is met
        const mustStartBefore = type.shiftMustStartBefore && parseTime(entry.date, type.shiftMustStartBefore);
        if (mustStartBefore && entry.start >= mustStartBefore) {
            continue;
        }

        const matchingDates =
            type.mode === BonusTypeMode.Daily
                ? [entry.date]
                : [entry.date, dateAdd(entry.date, { days: 1 })].filter((date) =>
                      type.matchesDate(date, company.country)
                  );

        let intervals: Interval[] = [];
        for (const date of matchingDates) {
            intervals.push(
                ...type.intervals.map(([start, end]) => [parseTime(date, start), parseTime(date, end)] as Interval)
            );
        }

        if (!intervals.length) {
            continue;
        }

        intervals = union(...intervals);

        const hourlyRate = getBonusHourlyRate(company, contract, entry.positionId);
        const taxFreeRate = Math.min(hourlyRate, type.taxFreeUpToRate);
        const taxedRate = Math.max(0, hourlyRate - type.taxFreeUpToRate);

        if (taxFreeRate || (!hourlyRate && !!type.taxFreeUpToRate)) {
            bonuses.push({
                bonus,
                type,
                intervals,
                hourlyRate: taxFreeRate,
                taxFree: true,
                costCenter,
            });
        }

        if (taxedRate || (!hourlyRate && !type.taxFreeUpToRate)) {
            bonuses.push({
                bonus,
                type,
                intervals,
                hourlyRate: taxedRate,
                taxFree: false,
                costCenter,
            });
        }
    }

    for (const bonus of bonuses) {
        for (const override of bonus.type.overrides) {
            const otherBonuses = bonuses.filter((b) => b.type.id === override.objectId);
            for (const otherBonus of otherBonuses) {
                otherBonus.intervals = subtract(otherBonus.intervals, bonus.intervals);
            }
        }
    }

    return bonuses.map(({ bonus, type, intervals, hourlyRate, taxFree, costCenter }) => {
        const duration = overlap(work, intervals);
        return {
            bonusId: bonus.id,
            bonusTypeId: type.id,
            percent: bonus.percent,
            taxFree,
            duration: type.minDuration && duration < type.minDuration ? 0 : duration,
            hourlyRate,
            costCenter,
        };
    });
}

function calcHours(
    company: Company,
    employee: Employee,
    entry: TimeEntry,
    prevAverage: TimeResult,
    mode: CalcHoursMode = "final"
): TimeResult {
    const venue =
        (entry.position &&
            company.venues.find((v) => v.departments.some((d) => d.id === entry.position!.departmentId))) ||
        company.venues[0];
    const timeSettings = company.getTimeSettings({ timeEntry: entry }) || new TimeSettings();
    const holidays = venue?.enabledHolidays;
    const { iterativeBreaks } = company.settings;

    const contract = employee.getContractForDate(entry.date);
    const { breakMode, paidBreaksAuto, paidBreaksManual, breakTiming } = timeSettings;

    if (!contract) {
        // console.error(`no contract found for employee ${employee.name} on date ${a.date}`);
        return newTimeResult();
    }

    const {
        stackBonuses,
        absenceMode,
        absenceHours,
        fixedWorkDays,
        hoursPerDay,
        bonusSunday,
        bonusHoliday,
        bonusSpecial,
    } = contract;

    const year = entry.start.getFullYear();
    const month = entry.start.getMonth();
    const date = entry.start.getDate();

    if (entry.type === TimeEntryType.Work) {
        let interval: [Date | null, Date | null] | null = null;
        let breakAuto: number = 0;
        let breakManual: number = 0;
        const breakFinal = entry.break || 0;

        switch (mode) {
            case "planned":
                interval = entry.planned;
                breakAuto =
                    entry.breakAuto ??
                    (breakMode === BreakMode.Manual
                        ? getStatutoryBreak(entry.durationPlanned, { iterativeBreaks })
                        : [BreakMode.Planned, BreakMode.PlannedPlusManual].includes(breakMode)
                          ? entry.breakPlanned || 0
                          : getAutomaticBreak(entry.durationPlanned, timeSettings));
                breakManual = 0;
                break;
            case "logged":
                interval = entry.logged;
                breakAuto =
                    entry.breakAuto ||
                    ([BreakMode.Planned, BreakMode.PlannedPlusManual].includes(breakMode)
                        ? entry.breakPlanned || 0
                        : Math.min(breakFinal, getAutomaticBreak(entry.durationLogged, timeSettings)));
                breakManual = breakFinal - breakAuto;
                break;
            case "final":
                interval = entry.final;
                breakAuto =
                    entry.breakAuto ??
                    ([BreakMode.Planned, BreakMode.PlannedPlusManual].includes(breakMode)
                        ? entry.breakPlanned || 0
                        : Math.min(breakFinal, getAutomaticBreak(entry.durationFinal, timeSettings)));
                breakManual = breakFinal - breakAuto;
                break;
            case "mixed":
                if (entry.final || entry.isPast) {
                    interval = entry.final;
                    breakAuto =
                        entry.breakAuto ??
                        ([BreakMode.Planned, BreakMode.PlannedPlusManual].includes(breakMode)
                            ? entry.breakPlanned || 0
                            : Math.min(breakFinal, getAutomaticBreak(entry.durationFinal, timeSettings)));
                    breakManual = breakFinal - breakAuto;
                } else {
                    interval = entry.planned;
                    breakAuto =
                        entry.breakAuto ??
                        (breakMode === BreakMode.Manual
                            ? getStatutoryBreak(entry.durationPlanned, { iterativeBreaks })
                            : getAutomaticBreak(entry.durationPlanned, timeSettings));
                    breakManual = 0;
                }
                break;
            case "max":
                interval =
                    entry.logged && entry.final
                        ? [
                              new Date(Math.min(entry.logged[0].getTime(), entry.final[0].getTime())),
                              new Date(Math.max(entry.logged[1].getTime(), entry.final[1].getTime())),
                          ]
                        : entry.final;
                const duration =
                    (interval &&
                        interval[0] &&
                        interval[1] &&
                        (interval[1].getTime() - interval[0].getTime()) / hour) ||
                    0;
                breakAuto =
                    entry.breakAuto ??
                    ([BreakMode.Planned, BreakMode.PlannedPlusManual].includes(breakMode)
                        ? entry.breakPlanned || 0
                        : Math.min(breakFinal, getAutomaticBreak(duration as Hours, timeSettings)));
                breakManual = breakFinal - breakAuto;
                break;
        }

        const breakPaid = (paidBreaksAuto ? breakAuto : 0) + (paidBreaksManual ? breakManual : 0);
        const breakTotal = breakAuto + breakManual;

        if (!interval || !interval[0] || !interval[1]) {
            return newTimeResult();
        }

        let [start, end] = interval;

        // subtract break at start of end of shift, depending on setting
        if (breakTiming === BreakTiming.End) {
            end = new Date(end.getTime() - breakTotal * hour);
        } else {
            start = new Date(start.getTime() + breakTotal * hour);
        }
        const work = [start, end] as Interval;
        const full = (end.getTime() - start.getTime()) / hour;
        const paid = full + breakPaid;

        const revenue = entry.revenue;

        return newTimeResult({
            type: entry.type,
            position: (entry.position && entry.position!.id) || undefined,
            meals: entry.mealsLunch,
            mealsBreakfast: entry.mealsBreakfast,
            mealsDinner: entry.mealsDinner,
            full,
            paid,
            revenue,
            comment: entry.comment,
            ...calcLegacyBonuses(entry, work, employee, {
                holidays,
                iterativeBreaks,
                timeSettings,
                country: company.country,
            }),
            bonuses: calcBonuses(company, employee, entry, work),
            days: 1,
        });
    } else if (
        [TimeEntryType.Vacation, TimeEntryType.Sick, TimeEntryType.ChildSick, TimeEntryType.SickInKUG].includes(
            entry.type
        )
    ) {
        let { full, paid, night1, night2 } = prevAverage;

        if (absenceMode === AbsenceMode.FixedDays && fixedWorkDays) {
            full = paid = (hoursPerDay && hoursPerDay[(entry.start.getDay() + 6) % 7]) || 0;
        } else if (absenceMode === AbsenceMode.Fixed) {
            full = paid = absenceHours;
        }

        // No continued pay in the first 4 weeks of employment
        const firstContract = employee.contracts.sort((a, b) => Number(a.start) - Number(b.start))[0];
        if (!firstContract || Number(new Date(year, month, date)) - Number(firstContract.start) < 4 * week) {
            return newTimeResult({ full });
        }

        const isSpesh = !!bonusSpecial && isSpecial(year, month, date, { holidays, country: company.country });
        const isHo = !!bonusHoliday && !isSpesh && isHoliday(year, month, date, { holidays, country: company.country });
        const isSon = !!bonusSunday && !isSpesh && !isHo && isSunday(year, month, date);
        const isAny = isSpesh || isHo || isSon;

        const bonuses: BonusResult[] = [];

        const costCenter = company.getCostCenter({ employee, position: entry.position })?.number || undefined;

        for (const bonus of contract.bonuses) {
            const bonusType = company.bonusTypes?.find((t) => t.id === bonus.typeId);
            if (bonusType?.continuedPay && bonusType?.matchesDate(entry.date, company.country)) {
                let duration = 0;
                if (bonusType.mode === BonusTypeMode.Daily) {
                    const prevBonusAverage = prevAverage.bonuses?.find((b) => b.bonusTypeId === bonus.typeId);
                    duration = Math.min(full, prevBonusAverage?.duration || 0);
                } else {
                    duration = full;
                }
                bonuses.push({
                    bonusId: bonus.id,
                    bonusTypeId: bonusType.id,
                    duration,
                    taxFree: false,
                    percent: bonus.percent,
                    hourlyRate: getBonusHourlyRate(company, contract, null),
                    costCenter,
                });
            }
        }

        // // Apply overrides
        // for (const bonus of bonuses) {
        //     if (
        //         bonuses.some((b) => {
        //             const bonusType = company.bonusTypes?.find((t) => t.id === b.bonusTypeId);
        //             return bonusType?.overrides.some((o) => o.objectId === bonus.bonusTypeId);
        //         })
        //     ) {
        //         bonus.duration = 0;
        //     }
        // }

        return newTimeResult({
            type: entry.type,
            full,
            paid,
            night1: stackBonuses || !isAny ? night1 : 0,
            night2: stackBonuses || !isAny ? night2 : 0,
            sunday: isSon ? full : 0,
            holiday: isHo ? full : 0,
            special: isSpesh ? full : 0,
            comment: entry.comment,
            bonuses,
            days: 1,
        });
    } else if ([TimeEntryType.CompDay].includes(entry.type)) {
        let { full, paid } = prevAverage;

        if (absenceMode === AbsenceMode.FixedDays && fixedWorkDays) {
            full = paid = (hoursPerDay && hoursPerDay[(entry.start.getDay() + 6) % 7]) || 0;
        } else if (absenceMode === AbsenceMode.Fixed) {
            full = paid = absenceHours;
        }

        return newTimeResult({
            type: entry.type,
            full,
            paid,
            comment: entry.comment,
            days: 1,
        });
    } else if (entry.type === TimeEntryType.HourAdjustment) {
        return newTimeResult({
            type: entry.type,
            full: entry.hours || 0,
            paid: entry.paid && entry.hours ? Math.abs(entry.hours) : 0,
            comment: entry.comment,
        });
    } else if (entry.type === TimeEntryType.VacationAdjustment) {
        const { paid } = prevAverage;
        return newTimeResult({
            type: entry.type,
            paid: entry.paid && entry.days ? Math.abs(paid * entry.days) : 0,
            comment: entry.comment,
            days: entry.days || undefined,
        });
    } else {
        return newTimeResult();
    }
}

export function calcAllHours(
    employee: Employee,
    company: Company,
    entries: TimeEntry[],
    average: TimeResult = newTimeResult(),
    mode?: CalcHoursMode
): TimeResultItem[] {
    const items: TimeResultItem[] = [];

    const workDays = new Map<string, number>();

    for (const entry of entries) {
        // skip entries that don't belong to this employee
        if (entry.employeeId !== employee.id || entry.deleted) {
            continue;
        }

        const item = {
            entry,
            result: calcHours(company, employee, entry, average, mode),
        };
        calcPay(company, employee, item);
        items.push(item);

        if (entry.type === TimeEntryType.Work) {
            if (!workDays.has(entry.date)) {
                workDays.set(entry.date, 0);
            }
            workDays.set(entry.date, workDays.get(entry.date)! + 1);
        }
    }

    for (const item of items) {
        const shiftCount = workDays.get(item.entry.date);
        if (shiftCount) {
            item.result.days = 1 / shiftCount;
        }
    }

    return items;
}

export function calcNominalHours(company: Company, emp: Employee, { from, to }: DateRange) {
    const startLedgers = company.settings.startLedgers;
    const contracts = emp.contracts.filter((c) => c.start <= to && (!c.end || c.end > from));
    const defaultPosition = emp.positions[0];
    const venue =
        (defaultPosition &&
            company.venues.find((v) =>
                v.departments.some((d) => d.positions.some((p) => p.id === defaultPosition.id))
            )) ||
        company.venues[0];
    const holidays = venue?.enabledHolidays || undefined;
    const weekFactor = company.settings.weekFactor || 4.35;

    let total = 0;

    for (const contract of contracts) {
        const mode = contract.nominalHoursMode;
        let start = from >= contract.start ? from : contract.start;
        if (startLedgers && startLedgers > start) {
            start = startLedgers;
        }

        const end = !contract.end || contract.end > to ? to : contract.end;
        if (start > end) {
            start = end;
        }

        switch (mode) {
            case NominalHoursMode.WeekFactor:
                while (start < end) {
                    const { from, to } = getRange(start, "month");
                    const subEnd = to < end ? to : end;

                    const fraction = dateSub(start, subEnd) / dateSub(from, to);

                    total += weekFactor * fraction * contract.hoursPerWeek;

                    start = subEnd;
                }
                break;
            case NominalHoursMode.Exact:
            case NominalHoursMode.ExactWithoutHolidays:
                let days = dateSub(start, end);
                if (mode === NominalHoursMode.ExactWithoutHolidays) {
                    const holidaysInRange = getHolidaysInRange(start, end, { holidays, country: company.country });
                    days -= holidaysInRange.length;
                }
                const weeks = days / 7;
                total += weeks * contract.hoursPerWeek;
                break;
            case NominalHoursMode.FixedDays:
            case NominalHoursMode.FixedDaysWithoutHolidays:
                let day = start;
                while (day < end) {
                    const date = parseDateString(day)!;
                    const hours = contract.hoursPerDay?.[(date.getDay() + 6) % 7] || 0;

                    if (
                        mode === NominalHoursMode.FixedDays ||
                        !isHoliday(date.getFullYear(), date.getMonth(), date.getDate(), {
                            holidays,
                            country: company.country,
                        })
                    ) {
                        total += hours;
                    }

                    day = dateAdd(day, { days: 1 });
                }
                break;
        }
    }

    return total;
}

export function calcNominalBonus(emp: Employee, { from, to }: DateRange) {
    const contracts = emp.contracts.filter((c) => c.start <= to && (!c.end || c.end > from));

    let total = 0;

    for (const contract of contracts) {
        let start = from >= contract.start ? from : contract.start;
        const end = !contract.end || contract.end > to ? to : contract.end;
        if (start > end) {
            start = end;
        }

        while (start < end) {
            const { from, to } = getRange(start, "month");
            const subEnd = to < end ? to : end;

            const fraction = dateSub(start, subEnd) / dateSub(from, to);

            total += fraction * contract.sfnAdvance;

            start = subEnd;
        }
    }

    return total;
}

export function calcDistinctHours(items: TimeResultItem[], company: Company): TimeResult[] {
    const positions = [
        ...new Set(
            items
                .filter((i) => i.entry.position && (!!i.result.full || !!i.result.paid))
                .map((i) => i.entry.position!.id)
        ),
    ];

    const result: TimeResult[] = [];

    for (const position of positions) {
        const hours = calcTotalHours(items, TimeEntryType.Work, position);

        const pos = company.getPosition(position);
        if (pos && pos.department && pos.department.bonusesAreTaxed) {
            hours.bonusesAreTaxed = true;
        }

        result.push(hours);
    }

    const vac = calcTotalHours(items, TimeEntryType.Vacation);
    if (vac.full) {
        result.push(vac);
    }

    const sick = calcTotalHours(items, TimeEntryType.Sick);
    if (sick.full) {
        result.push(sick);
    }

    const childSick = calcTotalHours(items, TimeEntryType.ChildSick);
    if (childSick.full) {
        result.push(childSick);
    }

    const sickInKUG = calcTotalHours(items, TimeEntryType.SickInKUG);
    if (sickInKUG.full) {
        result.push(sickInKUG);
    }

    const comp = calcTotalHours(items, TimeEntryType.CompDay);
    if (comp.full) {
        result.push(comp);
    }

    const adjComments = new Set<string>(
        items.filter((item) => item.entry.type === TimeEntryType.HourAdjustment).map((item) => item.entry.comment || "")
    );
    for (const comment of adjComments) {
        const adjustments = calcTotalHours(items, TimeEntryType.HourAdjustment, undefined, comment);
        if (adjustments.full || adjustments.paid) {
            result.push(adjustments);
        }
    }

    const vacComments = new Set<string>(
        items
            .filter((item) => item.entry.type === TimeEntryType.VacationAdjustment)
            .map((item) => item.entry.comment || "")
    );
    for (const comment of vacComments) {
        const adjustments = calcTotalHours(items, TimeEntryType.VacationAdjustment, undefined, comment);
        if (adjustments.full || adjustments.paid) {
            result.push(adjustments);
        }
    }

    return result;
}
